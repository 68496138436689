/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useEffect, useState, useRef } from 'react';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { match } from 'react-router';
import { History } from 'history';
import { ApplicationState } from '../../lib/Redux/Reducers';
import { ReferralsActions } from '../../lib/Redux/Actions/ReferralsActions';
import { routes } from '../../navigation/Routes';
import { storage, STORAGE_CONSTANTS } from '../../lib/Redux/Services/LocalStorage';
import { styles, MyAccountStyleProps } from './MyAccountStyles';
import AgencyBackground from '../../components/agencyBackground/AgencyBackground';
import MyAccountTabs from './components/MyAccountTabs/MyAccountTabs';
import MyProperties from './components/MyProperties/MyProperties';
import MyReferrals from './components/MyReferrals/MyReferrals';
import { EnumAccountTab } from './MyAccountConstant';
import { TYPE } from '../login/LoginUtils';

interface MyAccountProps extends MyAccountStyleProps {
  history: History;
  match: match;
  myAccountFlag: boolean;
  setMyAccountFlag: (value: boolean) => void;
}
const MyAccount: FC<MyAccountProps> = ({ classes, history, myAccountFlag, setMyAccountFlag }) => {
  const type = storage.getItem(STORAGE_CONSTANTS.type);
  const [accountTab, setAccountTab] = useState<EnumAccountTab>(
    type === TYPE.OWNER ? EnumAccountTab.myProperties : EnumAccountTab.myReferral,
  );
  const firstRender = useRef(true);

  useEffect(() => {
    if (
      !storage.getItem(STORAGE_CONSTANTS.userId) ||
      storage.getItem(STORAGE_CONSTANTS.userId) === 'undefined'
    ) {
      history.push({
        pathname: routes.login.viewGeneric,
      });
    }
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    }
  });

  const changeTab = (tab: EnumAccountTab) => {
    setMyAccountFlag(tab === EnumAccountTab.myReferral);
    setAccountTab(tab); // This sets the context of where we
  };
  const renderTab = () => {
    switch (accountTab) {
      case EnumAccountTab.myProperties:
        return <MyProperties history={history} />;
      default:
        return <MyReferrals history={history} />;
    }
  };

  return (
    <div className={classes.root}>
      <AgencyBackground />
      <div className={classes.contentContainer}>
        <MyAccountTabs
          selected={accountTab}
          onTabChange={(tab: EnumAccountTab) => changeTab(tab)}
        />
        {renderTab()}
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  myAccountFlag: state.referral.myAccountFlag,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setMyAccountFlag: (data: boolean) => {
    dispatch(ReferralsActions.setMyAccountFlag(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyAccount));
