/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createStyles, WithStyles } from '@material-ui/core';
import { color } from '../../../../lib/Theme/Theme';
import { SIZES } from '../../../../lib/Constants';
import { globalStyles } from '../../../../lib/Theme/GlobalStyles';

export const styles = createStyles({
  secondaryTile: globalStyles.secondaryTile as any,
  titleHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: color.secondary,
    alignItems: 'center',
    padding: '25px 30px',
  },
  title: {
    color: color.sortedNavy,
    fontSize: 28,
    marginBottom: '10px',
  },
  subtitle: {
    fontWeight: 'lighter',
    opacity: 0.87,
  },
  contentContainer: {
    padding: '30px',
    backgroundColor: color.light,
  },
  inputContainerStyle: {
    width: '100%',
    paddingBottom: '8px',
  },
  inputStyle: {
    paddingTop: '5px',
    paddingBottom: '10px',
    color: color.secondary600,
  },

  enterEmail: {
    paddingTop: '5px',
    color: color.teal,
  },
  errorText: {
    color: color.warning,
    marginTop: '15px',
    fontWeight: 300,
  },

  footerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '30px',
  },
  backButton: {
    position: 'relative',
    backgroundColor: color.light,
    border: `${color.gray} 2px solid`,
    fontSize: 16,
    fontWeight: 300,
    borderRadius: '6px',
    padding: '15px',
    width: '180px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [`@media (max-width: ${SIZES.md}px)`]: {
      flex: 1,
    },
  },
  backarrow: {
    position: 'absolute',
    left: '15px',
  },
  loginButton: {
    backgroundColor: color.teal,
    fontSize: 16,
    fontWeight: 300,
    border: 'none',
    borderRadius: '6px',
    padding: '15px',
    width: '180px',
    color: color.light,
    cursor: 'pointer',
    textAlign: 'center',
    [`@media (max-width: ${SIZES.md}px)`]: {
      flex: 1,
      marginLeft: '30px',
    },
  },
});

export interface ForgotPasswordFormStyles extends WithStyles<typeof styles> {}
