import { color } from '../../../lib/Theme/Theme';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  filled: {
    background: color.light,
    borderRadius: '4px',
    border: `1px solid ${color.secondary600}`,
  },
  icon: {
    padding: '12px 8px',
  },
  errorMessageStyles: {
    color: color.warning,
    marginTop: 10,
  },
  label: {
    fontSize: 12,
    lineHeight: 1.5,
    fontWeight: 400,
  },
  inputStyle: {
    height: 50,
  },
});
