import { Box, Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core';

import { BlanketFormFieldOption } from '../../blanketForm/BlanketFormConstants';
import { FieldArray, useField } from 'formik';
import React, { useEffect } from 'react';
import { get } from 'lodash';
import { getGridProps } from '../../blanketForm/BlanketFormUtil';

interface RenderCheckboxGroupProps {
  name: string;
  options: BlanketFormFieldOption[];
  readOnly?: boolean;
}

const RenderCheckboxGroup = ({ name, options, readOnly }: RenderCheckboxGroupProps) => {
  const [field, meta, helper] = useField(name);
  useEffect(() => {
    if (!readOnly) {
      const checkedOptions = options
        .filter((option) => option.defaultChecked)
        .map((data) => data.value);
      helper.setValue(field.value ? [...field.value, ...checkedOptions] : [...checkedOptions]);
    }
  }, []);

  const getDefaultValue = (value: string) => {
    return (
      field.value && field.value.length > 0 && !!field.value.find((item: string) => item === value)
    );
  };

  return (
    <Grid container>
      <FieldArray
        name={name}
        render={({ push, remove, form: { values } }) => (
          <>
            {options.map((option) => {
              const gridProps = getGridProps(option.size);
              return (
                <Grid item xs={gridProps.xs} sm={gridProps.sm}>
                  <Box display="flex" justifyContent="space-between">
                    <FormControlLabel
                      labelPlacement="end"
                      control={
                        <Checkbox
                          color="primary"
                          defaultChecked={
                            readOnly ? getDefaultValue(option.value) : !!option.defaultChecked
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              push(option.value);
                            } else {
                              const idx = get(values, name)
                                .map((item: string) => item)
                                .indexOf(option.value);
                              remove(idx);
                            }
                          }}
                          id={`${name}${option.value}`}
                        />
                      }
                      label={option.label}
                      disabled={option.disabled || readOnly}
                    />
                  </Box>
                </Grid>
              );
            })}
          </>
        )}
      />
    </Grid>
  );
};

export default RenderCheckboxGroup;
