import { get } from 'lodash';
import DashboardState from '../State/DashboardState';
import { DashboardAction, DashboardActionTypes } from '../Actions/DashboardActions';
import {
  PropertyDetailsResponse,
  GetFailureDetails,
  AgencyResponse,
} from '../Types/agency/AgencyResponse.data';
import { storage, STORAGE_CONSTANTS } from '../Services/LocalStorage';

export const initialState: DashboardState = {
  loading: false,
  error: '',
  success: false,
  agencyDetails: undefined,
  propertyDetails: null,
  agency: '', // AU_TPCO
};

/* tslint:disable:cyclomatic-complexity */
export const dashboardReducer: (
  state: DashboardState | undefined,
  action: DashboardAction,
) => DashboardState = (state = initialState, action) => {
  switch (action.type) {
    case DashboardActionTypes.FETCH_PROPERTY_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        propertyDetails: action.data as PropertyDetailsResponse,
      };
    }
    case DashboardActionTypes.FETCH_PROPERTY_DETAILS_ERROR: {
      const data = action.data as GetFailureDetails;
      return {
        ...state,
        loading: false,
        error: data || 'Check the URL',
      };
    }

    case DashboardActionTypes.STORE_PROPERTY_AGENCY: {
      const { data } = action;
      return {
        ...state,
        agency: get(data, 'agency', '').toUpperCase(),
        branch: get(action, 'data.branch', ''),
      };
    }
    case DashboardActionTypes.GET_AGENCY_BY_AGENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        agencyDetails: action.data as AgencyResponse,
      };
    }
    case DashboardActionTypes.GET_AGENCY_BY_AGENT_ERROR: {
      return {
        ...state,
        loading: false,
        error:
          'Sorry, the address can not be identified. Please use the search bar to search and add properties.',
      };
    }
    default:
      return state;
  }
};

export default dashboardReducer;
