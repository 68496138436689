import { AppIcon, AppIconSize, AppIconType } from '../AppIcon/AppIcon';
import { ApplicationState, useAppSelector } from '../../lib/Redux/Reducers';
import { Grid, Snackbar, SnackbarContent } from '@material-ui/core';
import React, { FC } from 'react';

import { SuccessToastActionTypes } from '../../lib/Redux/Actions/SuccessToastAction';
import { TypeHelper } from '../../lib/helper/TypeHelper';
import { color } from '../../lib/Theme/Theme';
import { store } from '../../lib/Redux/Store';
import useStyles from './SuccessToastStyles.data';

export const SuccessToast: FC = () => {
  const classes = useStyles();
  const { snackbarFlag, message, errorMessage } = useAppSelector((state: ApplicationState) => ({
    snackbarFlag: state.successToast.snackbarFlag,
    message: state.successToast.message,
    errorMessage: state.successToast.errorMessage,
  }));

  const onClose = () => {
    store.dispatch({ type: SuccessToastActionTypes.HideSuccessToast });
  };
  if (!TypeHelper.isNullOrUndefined(errorMessage)) {
    return (
      <Snackbar
        open={snackbarFlag}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        autoHideDuration={2000}
        onClose={onClose}
      >
        <SnackbarContent
          className={`${classes.snackBarContentStyles} ${classes.errorStyle}`}
          message={
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <AppIcon
                  type={AppIconType.FilledTick}
                  size={AppIconSize.Large}
                  color={color.warning}
                />
              </Grid>
              <Grid item className={classes.snackBarTextStyles}>
                {errorMessage}
              </Grid>
            </Grid>
          }
        />
      </Snackbar>
    );
  }

  return (
    <Snackbar
      open={snackbarFlag}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      autoHideDuration={2000}
      onClose={onClose}
    >
      <SnackbarContent
        className={classes.snackBarContentStyles}
        message={
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <AppIcon
                type={AppIconType.FilledTick}
                size={AppIconSize.Large}
                color={color.success}
              />
            </Grid>
            <Grid item className={classes.snackBarTextStyles}>
              {message}
            </Grid>
          </Grid>
        }
      />
    </Snackbar>
  );
};

export default SuccessToast;
