/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// TODO: Refactor to MakeStyles with MaterialUI
import React, { FC, useEffect } from 'react';
import { Typography, withStyles, Button } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router';
import { Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';
import Like from '../../assets/images/like.svg';
import LinkExpired from '../../assets/images/shape.svg';
import { RegistrationConfirmationStyle } from './RegisterConfirmationStyles';
import { LABELS } from './RegisterConfirmationConstants';
import { routes } from '../../navigation/Routes';
import { ApplicationState } from '../../lib/Redux/Reducers';
import { LoginActions } from '../../lib/Redux/Actions/LoginActions';
import SortedLogo from '../../assets/images/powered-by-sorted-v.png';

interface RegistrationConfirmationProps extends RouteComponentProps {
  emailConfirmationError: any;
  verifyUserDetails: (token: string) => void;
  classes: any;
}

const RegistrationConfirmation: FC<RegistrationConfirmationProps> = ({
  history,
  location,
  emailConfirmationError,
  verifyUserDetails,
  classes,
}) => {
  const getToken = (): string => {
    const pair = location.search.split('token=');
    return decodeURIComponent(pair[1]);
  };

  useEffect(() => {
    const token = getToken();
    verifyUserDetails(token);
  }, []);

  const redirectTo = (path: string) => {
    history.push(path);
  };

  const registerRedirect = () => {
    const pair = location.search.split('redirectLink=');
    const baseURL = window.location.toString().split('#/')[0];
    window.location.replace(`${baseURL}#/${pair[1]}`);
  };
  return (
    <div>
      {emailConfirmationError &&
      emailConfirmationError.response &&
      emailConfirmationError.response.status === 400 ? (
        <div className={classes.registrationConfirmationContainer}>
          <img className={classes.successLogo} src={LinkExpired} alt="link expired" />
          <Typography variant="h6">{LABELS.LINK_EXPIRED}</Typography>
          <Typography variant="body2" className={classes.goToLoginLabel}>
            {LABELS.GO_TO_LOGIN}
          </Typography>
          <div className={classes.buttonContainer}>
            <Button
              fullWidth
              className={classes.loginButton}
              onClick={() => redirectTo(routes.login.viewGeneric)}
            >
              <Typography className={classes.loginLabel}>{LABELS.BUTTON_TEXT}</Typography>
            </Button>
          </div>
        </div>
      ) : (
        <div className={classes.registrationConfirmationContainer}>
          <div className={classes.registrationConfirmationWrapper}>
            <img className={classes.successLogo} src={Like} alt="success logo" />
            <div className={classes.titleText}>{LABELS.EMAIL_VERIFIED}</div>
            <div className={classes.bodyText}>{LABELS.MOVE_BACK}</div>
            <div className={classes.buttonContainer}>
              <Button fullWidth className={classes.loginButton} onClick={() => registerRedirect()}>
                <Typography className={classes.loginLabel}>{LABELS.BUTTON_TEXT}</Typography>
              </Button>
            </div>
            <img className={classes.sortedLogo} src={SortedLogo} alt="sorted logo" />
          </div>
        </div>
      )}
    </div>
  );
};
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  verifyUserDetails: (token: string) => {
    dispatch(LoginActions.postVerifyUserStart({ token }));
  },
});

const mapStateToProps = (state: ApplicationState) => ({
  emailConfirmationError: state.login.error,
});

export default withStyles(RegistrationConfirmationStyle)(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(RegistrationConfirmation)),
);
