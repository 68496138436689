import { Grid, Typography } from '@material-ui/core';
import { LABELS, SalutationsTypeOptions } from '../NewReferralConstant';
import React, { FC } from 'react';
import {
  ReferralDetailsFormPayload,
  ReferralPayload,
} from '../../../lib/Redux/Types/referrals/ReferralType.data';

import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import RenderInput from '../../../components/FormikComponents/RenderInputField/RenderInputField';
import RenderPhoneInput from '../../../components/FormikComponents/RenderPhoneInput/RenderPhoneInput';
import RenderSelect from '../../../components/FormikComponents/RenderSelect/RenderSelect';
import useStyles from './AddReferralCardStyles.data';

interface AddReferralProps {
  values: ReferralDetailsFormPayload;
  setFieldValue: (fieldName: string, fieldValue?: string | ReferralPayload[] | boolean) => void;
  deleteNewReferral: (values: ReferralDetailsFormPayload, idx: number) => void;
  idx: number;
}

const AddNewReferral: FC<AddReferralProps> = ({
  values,
  setFieldValue,
  deleteNewReferral,
  idx,
}) => {
  const classes = useStyles();

  const referralConstant = 'referrals';
  return (
    <div className={classes.newReferralcontainer}>
      <div className={classes.newReferralTitleContainer}>
        <Typography className={classes.newReferralTitle}>
          {LABELS.REFERRAL_HEAD(idx + 1)}
        </Typography>
        {values.referrals.length > 1 && (
          <DeleteOutlinedIcon
            onClick={() => deleteNewReferral(values, idx)}
            className={classes.crossLogo}
          />
        )}
      </div>

      <div className={classes.additionalPersonInputContainer}>
        <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={2}>
          <Grid item xs={12} sm={2}>
            <RenderSelect
              label={LABELS.TITLE}
              name={`${referralConstant}[${idx}].title`}
              options={SalutationsTypeOptions}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <RenderInput name={`${referralConstant}[${idx}].firstName`} label={LABELS.FIRST_NAME} />
          </Grid>
          <Grid item xs={12} sm={5}>
            <RenderInput name={`${referralConstant}[${idx}].lastName`} label={LABELS.LAST_NAME} />
          </Grid>
          <Grid item xs={12}>
            <RenderPhoneInput
              name={`${referralConstant}[${idx}].phoneNumber`}
              setFieldValue={setFieldValue}
              label={LABELS.MOBILE}
            />
          </Grid>
          {/* EMAIL ----------------------------------------------------------------------- //  */}
          <Grid item xs={12} sm={12}>
            <RenderInput name={`${referralConstant}[${idx}].email`} label={LABELS.EMAIL_ADDRESS} />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.additionalInfoContainer}>
              <Typography variant="caption">{LABELS.ADDITIONAL_HEADER}</Typography>

              <Typography variant="body2" className={classes.additionalInfo}>
                {LABELS.ADDITIONAL_SUBINFO}
              </Typography>
            </div>
            <RenderInput
              name={`${referralConstant}[${idx}].additionalInformation`}
              containerClass={classes.inputContainerStyle}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default AddNewReferral;
