/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useEffect, useState } from 'react';
import {
  withStyles,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  InputAdornment,
  FormHelperText,
  Input,
} from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Formik, ErrorMessage } from 'formik';
import { connect } from 'react-redux';
import { Dispatch, AnyAction } from 'redux';
import errorIcon from '../../../../assets/images/error-outline-24-px.svg';
import { VALIDATION_SCHEMA, LABELS } from './ForgotPasswordFormConstants';
import { ForgotPasswordFormStyles, styles } from './ForgotPasswordFormStyles';
import { ApplicationState } from '../../../../lib/Redux/Reducers';
import {
  ForgotPasswordPayload,
  RegisterUserPayload,
} from '../../../../lib/Redux/Services/login/LoginData';
import { LoginActions } from '../../../../lib/Redux/Actions/LoginActions';
import UserConfirmationDialog from '../../../../components/userConfirmationDialog/UserConfirmationDialog';
import { View } from '../../Login';

interface ForgotPasswordProps extends ForgotPasswordFormStyles {
  forgotPasswordError: any;
  postForgotPasswordEmail: (data: ForgotPasswordPayload, onSubmit: () => void) => void;
  setView: (view: View) => void;
  userDetails: RegisterUserPayload | null;
}

const ForgotPasswordForm: FC<ForgotPasswordProps> = ({
  forgotPasswordError,
  postForgotPasswordEmail,
  setView,
  classes,
  userDetails,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  useEffect(() => {
    if (forgotPasswordError && forgotPasswordError.response.status === 400) {
      setErrorMessage('User does not exist');
    }
  }, [forgotPasswordError]);

  const sendForgotPasswordEmail = (data: ForgotPasswordPayload) => {
    postForgotPasswordEmail(data, () => {
      setErrorMessage(null);
      setOpenConfirmDialog(true);
    });
  };

  return (
    <Paper className={classes.secondaryTile}>
      <Formik
        initialValues={{
          username: userDetails && userDetails.email ? userDetails.email : '',
        }}
        validateOnChange
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={(data) => {
          sendForgotPasswordEmail(data);
          console.log(data);
        }}
        render={({ values, handleSubmit, setFieldValue, errors, touched }) => (
          <form onSubmit={handleSubmit}>
            <div className={classes.titleHeader}>
              <div>
                <Typography variant="h4" className={classes.title}>
                  {LABELS.FORGOT_PASSWORD}
                </Typography>
                <Typography className={classes.subtitle}>
                  {LABELS.FORGOT_PASSWORD_SUBTITLE}
                </Typography>
              </div>
            </div>
            <div className={classes.contentContainer}>
              <FormControl
                error={!!errors.username && !!touched.username}
                className={classes.inputContainerStyle}
              >
                <InputLabel>
                  <Typography variant="subtitle1">{LABELS.EMAIL}</Typography>
                </InputLabel>
                <Input
                  className={classes.inputStyle}
                  name="username"
                  type="text"
                  onChange={(event) => {
                    setFieldValue('username', event.target.value);
                  }}
                  value={values.username}
                  endAdornment={
                    <InputAdornment position="end">
                      <img src={!!errors.username && !!touched.username ? errorIcon : ''} alt="" />
                    </InputAdornment>
                  }
                />
                {errorMessage && <div className={classes.errorText}>{errorMessage}</div>}
                <FormHelperText>
                  <ErrorMessage name="username" />
                </FormHelperText>
              </FormControl>

              <div className={classes.footerContainer}>
                <div className={classes.backButton} onClick={() => setView(View.Login)}>
                  <ArrowBack className={classes.backarrow} />
                  {LABELS.BACK}
                </div>
                <button
                  className={classes.loginButton}
                  onMouseDown={() => handleSubmit()}
                  type="submit"
                >
                  {LABELS.RESET_PASSWORD}
                </button>
              </div>
            </div>
          </form>
        )}
      />
      <UserConfirmationDialog
        isOpen={openConfirmDialog}
        title="A reset password email has been sent!"
        content={
          <div>
            If you don’t see it in your inbox, please make sure to also check your spam folders.
          </div>
        }
        primaryButtonTitle="OK"
        onPrimaryClick={() => setOpenConfirmDialog(false)}
        onClose={() => setOpenConfirmDialog(false)}
      />
    </Paper>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  postForgotPasswordEmail: (data: ForgotPasswordPayload, onSuccess: () => void) => {
    dispatch(LoginActions.postForgotPasswordLinkStart({ ...data, onSuccess }));
  },
});

const mapStateToProps = (state: ApplicationState) => ({
  forgotPasswordError: state.login.forgotPasswordError,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ForgotPasswordForm));
