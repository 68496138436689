export enum STEPS {
  OWNERS_INSTRUCTIONS_FORM,
  SIGNATURE,
  SUCCESS,
  LOADING,
}

export const getSteps = (previewMode: boolean): Array<{ key: STEPS; label: string }> => {
  return previewMode
    ? [{ key: STEPS.OWNERS_INSTRUCTIONS_FORM, label: "Owner's Instructions" }]
    : [
        { key: STEPS.OWNERS_INSTRUCTIONS_FORM, label: "Owner's Instructions" },
        { key: STEPS.SIGNATURE, label: 'Sign' },
      ];
};

export const getNextStep = (curStep?: STEPS): STEPS => {
  switch (curStep) {
    case STEPS.OWNERS_INSTRUCTIONS_FORM:
      return STEPS.SIGNATURE;
    case STEPS.SIGNATURE:
      return STEPS.SUCCESS;
    default:
      return STEPS.OWNERS_INSTRUCTIONS_FORM;
  }
};

export const getBackStep = (curStep?: STEPS): STEPS => {
  switch (curStep) {
    case STEPS.SIGNATURE:
      return STEPS.OWNERS_INSTRUCTIONS_FORM;
    default:
      return STEPS.OWNERS_INSTRUCTIONS_FORM;
  }
};

export const ERRORS = {
  maxStringLength: 'Can be at most 255 characters',
  requiredOwnership: 'Enter ownership type',
  requiredMobileNumber: 'Enter mobile number',
  invalidNameFormat: 'Invalid name format',
  invalidCountryNumber: 'Invalid phone number',
  requiredPrimaryContact: 'Select primary contact',
};

export const LABELS = {
  PROPERTY_BANNER_TEXT: 'Property Address:',
};
