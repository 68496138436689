import { createStyles, WithStyles } from '@material-ui/core';
import { globalStyles } from '../../lib/Theme/GlobalStyles';
import { color } from '../../lib/Theme/Theme';
import { SIZES } from '../../lib/Constants';

export const ResetPasswordStyle = createStyles({
  root: {
    [`@media (max-width: ${SIZES.md}px)`]: {
      height: 'calc(100vh - 48px)',
      overflowY: 'scroll',
      backgroundColor: color.secondary,
    },
    [`@media (min-width: ${SIZES.md}px)`]: {
      backgroundColor: color.light,
    },
  },
  paperBack: globalStyles.paperBack as any,
  formStyle: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 16px',
    [`@media (min-width: ${SIZES.md}px)`]: {
      padding: '0 133px',
    },
  },
  titleStyle: {
    [`@media (max-width: ${SIZES.md}px)`]: {
      textAlign: 'center',
      padding: '20px 0 54px 0',
    },
    [`@media (min-width: ${SIZES.md}px)`]: {
      textAlign: 'center',
      paddingTop: '75px',
      paddingBottom: '40px',
    },
  },
  inputContainerStyle: {
    [`@media (max-width: ${SIZES.md}px)`]: {
      paddingBottom: '10px',
    },
    [`@media (min-width: ${SIZES.md}px)`]: {
      paddingBottom: '8px',
      background: 'rgba(0, 0, 0, 0.05)',
      padding: 10,
      marginTop: 10,
    },
  },
  inputLabelStyle: {
    paddingLeft: '5px',
    width: '95%',
    color: '#1f0d33',
  },
  inputStyle: {
    paddingLeft: '5px',
    width: '95%',
  },
  labelStyle: {
    color: color.primary,
  },
  buttonContainer: {
    [`@media (max-width: ${SIZES.md}px)`]: {
      margin: '54px 0 24px',
      textAlign: 'center',
    },
    [`@media (min-width: ${SIZES.md}px)`]: {
      margin: '54px 0 54px',
      textAlign: 'center',
    },
  },
  enterPassword: {
    paddingTop: '5px',
    color: color.teal,
  },
  errorText: {
    color: color.incompleteRed,
    marginTop: 5,
  },
});

export interface ResetPasswordComponentStyle extends WithStyles<typeof ResetPasswordStyle> {}
