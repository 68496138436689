import { color } from '../../../lib/Theme/Theme';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  input: {
    color: color.darkBlack,
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      marginTop: '20px',
      marginBottom: '10px',
    },
  },
  input2: {
    color: color.darkBlack,
    display: 'flex',
    alignItems: 'center',
    overflowWrap: 'break-word',
    whiteSpace: 'normal',
    '@media (max-width: 768px)': {
      marginTop: '20px',
      marginBottom: '10px',
      fontSize: '0.9rem',
    },
  },
  labels: {
    // transform: `translate(${-10}px, ${1.5}px) scale(${0.75})`,
  },
  errorMessageStyles: {
    color: color.warning,
    marginTop: 10,
  },
  labelError: {
    color: color.warning,
  },
  inputNoArrow: {
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
    overflowWrap: 'break-word',
  },
});

export const loginLabels = {
  color: color.darkBlack,
  fontWeight: 500,
};

export const inputsFields = {
  width: '100%',
  backgroundColor: 'white',
  height: 50,
};

export const inputStylePropsGenerator = (classes: { input: any; labels: any }) => ({
  style: inputsFields,
  labelStyle: loginLabels,
  overrideInputStyles: { input: classes.input },
  overrideLabelStyles: { shrink: classes.labels },
});
