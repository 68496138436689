import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { SuccessToastActions } from '../Redux/Actions/SuccessToastAction';
import ToastType from '../Redux/Types/toastTypes/ToastTypesData.data';
import { store } from '../Redux/Store';
import { storage, STORAGE_CONSTANTS } from '../Redux/Services/LocalStorage';

export const showToast = (
  dispatch: Dispatch<AnyAction>,
  message = '',
  type: ToastType | undefined = undefined,
  autoHideDuration = 3000,
) => {
  if (type === ToastType.ERROR) dispatch(SuccessToastActions.showError(message));
  else dispatch(SuccessToastActions.showToast(message));
  setTimeout(() => dispatch(SuccessToastActions.hideToast()));
};

export function logOut() {
  const authTokenBasic = storage.getItem(STORAGE_CONSTANTS.authTokenBasic);
  storage.clearAll();
  storage.setItem(STORAGE_CONSTANTS.authTokenBasic, authTokenBasic!);
  store.dispatch({ type: 'LOG_OUT' });
  window.open('/#/login', '_self');
}
