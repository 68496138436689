import { SIZES } from '../../lib/Constants';
import { color } from '../../lib/Theme/Theme';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  card: {
    margin: 'auto',
    marginTop: '20px',
    maxWidth: '1000px',
    borderRadius: '6px',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
    backgroundColor: color.light,
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: '15px',
    },
  },

  titleContainer: {
    padding: '30px',
    paddingTop: '15px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '15px',
      paddingBottom: '35px',
    },
  },

  contentContainer: {
    padding: '32px',
    paddingTop: '8px',
    width: '100%',
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '15px',
      paddingTop: '8px',
      paddingBottom: '35px',
    },
  },
  signLabelContainer: {
    marginTop: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '15px',
  },
  signLabel: {
    fontSize: '14px',
    color: color.offBlack,
    marginLeft: '15px',
  },
  signAgain: {
    fontSize: '14px',
    color: color.success,
    fontWeight: 'bold',
    cursor: 'pointer',
    marginRight: '15px',
  },
  signatureDivStyle: {
    width: '100%',
    height: 260,
    backgroundColor: color.lightGray,
    overflow: 'hidden',
    borderRadius: '6px',
    border: `${color.borderGray} 2px solid`,
    position: 'relative',
    [`@media (max-width: ${SIZES.md}px)`]: {
      width: '90%',
    },
  },
  signpadLabelContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'none',
  },
  signpadLabel: {
    width: '220px',
    textAlign: 'center',
    opacity: 0.2,
    fontSize: '17px',
  },
  dontShow: {
    display: 'none',
  },
  blueborder: {
    border: `${color.success} 2px solid`,
  },
  buttonContainer: {
    margin: 'auto',
    marginTop: '20px',
    maxWidth: '1000px',
    borderRadius: '6px',
    marginBottom: '80px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  button: {
    marginLeft: '30px',
    width: '20%',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: color.sortedNavy,
    borderRadius: '6px',
    color: color.light,
    cursor: 'pointer',
    '&:hover': {
      color: color.primary,
    },
    '&:disabled': {
      color: color.light,
      backgroundColor: color.grey,
    },

    [`@media (max-width: ${SIZES.md}px)`]: {
      marginLeft: '10px',
      width: '100%',
    },
  },
});

export default useStyles;
