import { storage, STORAGE_CONSTANTS } from '../../lib/Redux/Services/LocalStorage';
import { LoginResponse, RegisterClientResponse } from '../../lib/Redux/Services/login/LoginData';

// Clear auth state in local storage, keep other items like agency code intact
export const clearStorage = () => {
  storage.setItem(STORAGE_CONSTANTS.authToken, '');
  storage.setItem(STORAGE_CONSTANTS.refreshToken, '');
  storage.setItem(STORAGE_CONSTANTS.firstname, '');
  storage.setItem(STORAGE_CONSTANTS.lastname, '');
  storage.setItem(STORAGE_CONSTANTS.email, '');
  storage.setItem(STORAGE_CONSTANTS.userId, '');
  storage.setItem(STORAGE_CONSTANTS.draftId, '');
  storage.setItem(STORAGE_CONSTANTS.draftTitle, '');
  storage.setItem(STORAGE_CONSTANTS.state, '');
  storage.setItem(STORAGE_CONSTANTS.stateCreatedDate, '');
};

// If user verifies email, use the previous login URL with the property data
export const checkPreviousURL = () => {
  const previousLoginURL = storage.getItem(STORAGE_CONSTANTS.previousLoginURL);
  if (previousLoginURL) {
    storage.removeItem(STORAGE_CONSTANTS.previousLoginURL);
    window.location.replace(previousLoginURL);
  }
};

// Registers the client window to allow authenticated API requests
export const registerClient = (registerClientDetails: RegisterClientResponse | undefined) => {
  if (registerClientDetails) {
    const { client_id, client_secret } = registerClientDetails;
    storage.setItem(STORAGE_CONSTANTS.clientId, client_id);
    storage.setItem(STORAGE_CONSTANTS.clientSecret, client_secret);
    const basicAuthHash = window.btoa(`${client_id}:${client_secret}`);
    storage.setItem(STORAGE_CONSTANTS.authTokenBasic, basicAuthHash);
  }
};

// Log in the client and redirect them based on their previous route
export const loginClient = (loginDetails: LoginResponse | undefined) => {
  if (loginDetails && !storage.getItem(STORAGE_CONSTANTS.userId)) {
    const { authToken, refreshToken, title, name, surname, email, userId } = loginDetails;
    if (authToken) {
      storage.setItem(STORAGE_CONSTANTS.authToken, authToken);
      storage.setItem(STORAGE_CONSTANTS.refreshToken, refreshToken);
      storage.setItem(STORAGE_CONSTANTS.title, title);
      storage.setItem(STORAGE_CONSTANTS.firstname, name);
      storage.setItem(STORAGE_CONSTANTS.lastname, surname);
      storage.setItem(STORAGE_CONSTANTS.email, email);
      storage.setItem(STORAGE_CONSTANTS.userId, userId.toString());
    }
  }
};

export const TYPE = {
  OWNER: 'owner',
  REFERRER: 'referrer',
};
