import * as Yup from 'yup';
import { EMAIL_PATTERN } from '../../../../lib/Constants';

export const LABELS = {
  LOGIN_TITLE: 'Sign in with your Sorted account',
  LOGIN_SUBTITLE: 'Enter your log in details below to get started!',
  SIGNUP_HEADER: 'Sign up',
  EMAIL_ADDRESS: 'Email',
  PASSWORD: 'Password',
  LOGIN: 'LOG IN',
  SIGNUP: 'CREATE ACCOUNT',
  DONT_HAVE_ACCOUNT: "Don't have an account?",
  FORGOT_PASSWORD: 'Forgot Password?',
  RESEND_LINK: 'Resend Link',
};

export const ERRORS = {
  requiredEmail: 'Please enter your email address',
  invalidEmailFormat: 'Invalid email format',
  requiredPassword: 'Please enter password',
  minimumRequired: 'Minimum 8 characters required',
};

export const VALIDATION_SCHEMA = Yup.object().shape({
  username: Yup.string()
    .required(ERRORS.requiredEmail)
    .max(255)
    .matches(EMAIL_PATTERN, ERRORS.invalidEmailFormat),
  password: Yup.string().required(ERRORS.requiredPassword).min(8, ERRORS.minimumRequired),
});
