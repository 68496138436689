import { WithStyles, createStyles } from '@material-ui/core';
import { color } from '../../lib/Theme/Theme';
import { SIZES } from '../../lib/Constants';

export const HeaderStyle = createStyles({
  headerContainer: {
    width: '100%',
    height: '65px',
    backgroundColor: color.lightGray,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover > div': {
      opacity: 1,
    },
  },
  headerButton: {
    backgroundColor: color.light,
    padding: '0px 18px',
    height: '36px',
    display: 'flex',
    marginLeft: '30px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '6px',
    border: `${color.gray} 2px solid`,
    color: color.offBlack,
    fontSize: '15px',
    cursor: 'pointer',
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginLeft: '16px',
    },
  },
  signoutButton: {
    backgroundColor: color.light,
    padding: '0px 18px',
    height: '36px',
    display: 'flex',
    marginLeft: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '6px',
    border: `${color.gray} 2px solid`,
    color: color.offBlack,
    fontSize: '15px',
    marginRight: 'auto',
    cursor: 'pointer',
    opacity: 0,
    transition: 'all 0.5s ease 0s',
    [`@media (max-width: ${SIZES.md}px)`]: {
      opacity: 1,
    },
  },
  sortedLogoContainer: {
    position: 'relative',
    marginRight: '30px',
    marginLeft: 'auto',
    height: '28px',
    display: 'flex',
    alignItems: 'center',
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginRight: '16px',
    },
  },
  sortedLogo: {
    height: '28px',
    transition: 'all 0.5s ease 0s',
  },
  poweredBySortedLogo: {
    height: '22px',
    transition: 'all 0.5s ease 0s',
  },
  sortedLogoText: {
    position: 'absolute',
    opacity: 0,
    top: 6,
    bottom: 0,
    left: 0,
    margin: 'auto',
    height: '9px',
    transition: 'all 0.5s ease 0s',
  },
  poweredByLogoText: {
    transition: 'all 0.5s ease 0s',
    left: -76,
    opacity: 1,
  },
});
export interface HeaderStyleProps extends WithStyles<typeof HeaderStyle> {}
