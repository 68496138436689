import { makeStyles } from '@material-ui/core';
import { color } from '../../lib/Theme/Theme';
import { SIZES } from '../../lib/Constants';

const useStyles = makeStyles({
  root: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },

  progressBarContainer: {
    position: 'relative',
    margin: 'auto',
    top: '-38px',
    height: '100px',
    maxWidth: '1000px',
    borderRadius: '6px',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'hidden',
  },
  progressBarWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 24px',
  },
  errorContainer: {
    marginTop: '32px',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '40px',
  },
  icon: {
    width: '60px',
    height: '60px',
  },
  propertyBanner: {
    margin: 'auto',
    marginTop: '20px',
    maxWidth: '1000px !important',
    borderRadius: '6px',
    boxShadow: 'none',
    backgroundColor: color.bannerBackground,
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: '15px',
    },
  },
  propertyBannerContainer: {
    color: color.inStyleAlmostBlack,
    padding: '1rem',
  },
  propertyBannerText: {
    color: color.inStyleAlmostBlack,
    padding: '1rem 1rem 0.25rem 1rem',
  },
  propertyBannerAddress: {
    color: color.inStyleAlmostBlack,
    padding: '0.25rem 1rem 1rem 1rem',
  },
  buttonStyle: {
    backgroundColor: color.completeBlue,
    color: color.light,
    '&:hover': {
      backgroundColor: color.completeBlue,
    },
  },
});

export default useStyles;
