/* eslint-disable import/prefer-default-export */
import { SIZES } from '../Constants';
import { color } from './Theme';

export const globalStyles = {
  paperBack: {
    margin: 'auto',
    top: '-100px',
    maxWidth: '1000px',
    borderRadius: '6px',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
    [`@media (min-width: ${SIZES.md}px)`]: {
      position: 'relative',
    },
    [`@media (max-width: ${SIZES.md}px)`]: {
      margin: 0,
      boxShadow: 'none',
    },
    // ipad-pro
    [`@media (min-device-width: ${SIZES.lg}px) and (max-device-width: ${SIZES.lg}px) and (min-device-height:${SIZES.lgMax}px) and (max-device-height:${SIZES.lgMax}px) and (orientation: portrait)`]: {
      position: 'relative',
    },
    [`@media (min-device-width:${SIZES.lgMax}px) and (max-device-width:${SIZES.lgMax}px) and (min-device-height:${SIZES.lg}px) and (max-device-height:${SIZES.lg}px) and (orientation: landscape)`]: {
      position: 'relative',
    },
  },
  secondaryTile: {
    margin: 'auto',
    position: 'relative',
    top: '-70px',
    maxWidth: '1000px',
    borderRadius: '6px',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
    overflow: 'hidden',
    [`@media (max-width: ${SIZES.md}px)`]: {
      margin: '25px 20px 20px 20px',
      top: 0,
    },
  },
  datePicker: {
    width: '100%',
    fontSize: '16px',
    paddingTop: '5px',
    paddingBottom: '10px',
  },
  title: {
    fontSize: '38px',
  },
  subtitle: {
    marginTop: '10px',
    fontSize: '20px',
    color: '#9e9e9e',
  },
  /* ---Main Container Styles--------------------------------------------------*/
  contentContainer: {
    padding: '30px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '15px',
      paddingBottom: '35px',
    },
  },

  /* ---Form Styles--------------------------------------------------*/
  inputContainerStyle: {
    paddingBottom: '8px',
    width: '100%',
  },
  errorText: {
    color: color.incompleteRed,
  },
  formStyle: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputStyle: {
    paddingTop: '5px',
    paddingBottom: '10px',
    color: color.secondary600,
  },
  mobileContainerStyle: {
    marginTop: '-26px',
    paddingBottom: '8px',
    width: '100%',
  },
};
