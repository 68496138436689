import { color } from '../../../lib/Theme/Theme';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  errorMessageStyles: {
    color: color.warning,
    marginTop: 10,
  },
  radioGroup: {
    display: 'inline',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      '& .MuiFormControlLabel-root': {
        marginBottom: theme.spacing(5), // Increase the spacing on small screens
      },
    },
    '& .MuiFormControlLabel-root': {
      marginBottom: theme.spacing(1), // Default spacing between options
    },
  },
  label: {
    fontWeight: 500,
    color: color.darkBlack,
  },
  radioItem: {
    minWidth: '7.5rem',
  },
}));

export default useStyles;
