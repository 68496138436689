import React, { FC, useEffect } from 'react';
import { Typography, withStyles, Paper, Button, Grid } from '@material-ui/core';
import { History } from 'history';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import { routes } from '../../../../navigation/Routes';
import { LABELS } from './MyReferralsConstants';
import { AppIcon, AppIconType, AppIconSize } from '../../../../components/AppIcon/AppIcon';
import { ApplicationState, useAppSelector } from '../../../../lib/Redux/Reducers';
import {
  ReferralPayload,
  ReferralListResponse,
} from '../../../../lib/Redux/Types/referrals/ReferralType.data';
import { BUTTON_LABEL } from '../../MyAccountConstant';
import ReferralDetailsCard from '../../../../components/referralDetailCard/ReferralDetailCard';
import { ReferralsActions } from '../../../../lib/Redux/Actions/ReferralsActions';
import AgencyLogoHeader from '../../../../components/agencyLogoHeader/AgencyLogoHeader';
import { MyReferralPropsStyleProps, styles } from './MyReferralsStyles';

interface MyReferralProps extends MyReferralPropsStyleProps {
  history: History;
}
const MyReferrals: FC<MyReferralProps> = ({ classes, history }) => {
  const dispatch = useDispatch();

  const { referralDetails, agencyCode } = useAppSelector((state: ApplicationState) => ({
    referralDetails: get(state, 'referral.referralDetails', '') as ReferralListResponse,
    agencyCode: state.dashboard.agency,
  }));
  const dispatcher = {
    getReferrals: () => {
      dispatch(ReferralsActions.onGetReferralRequest(agencyCode!));
    },
  };

  useEffect(() => {
    dispatcher.getReferrals();
  }, [agencyCode]);

  if (!referralDetails) return null;
  const { referrals, referralStats } = referralDetails;
  const ReferralDisplayDetails = () => {
    return (
      <>
        {referrals &&
          referrals.length > 0 &&
          referrals.map((referral: ReferralPayload, idx: number) => (
            <div className={classes.propertyListDraft} key={idx}>
              <div className={classes.propertyList}>
                <ReferralDetailsCard referral={referral} idx={idx + 1} />
              </div>
            </div>
          ))}
      </>
    );
  };

  return (
    <Paper className={classes.myAccountContentContainer}>
      <div className={classes.referralTitleContainer}>
        <Typography className={classes.referralTitle} variant="h5">
          {LABELS.TITLE}
        </Typography>
        <Typography className={classes.referralSubtitle} variant="subtitle1">
          {LABELS.SUBTITLE}
        </Typography>
        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
          <Grid item sm={4}>
            <div className={`${classes.staticCard}`}>
              <AppIcon type={AppIconType.Handshake} size={AppIconSize.Large} />
              {referralStats.submittedReferrals} {LABELS.TOTAL_REFERRALS}
            </div>
          </Grid>
          <Grid item sm={4}>
            <div className={`${classes.staticCard}`}>
              <AppIcon type={AppIconType.Grade} size={AppIconSize.Normal} />
              {referralStats.signedReferrals} {LABELS.SIGNED}
            </div>
          </Grid>
          <Grid item sm={4}>
            <div className={`${classes.staticCard}`}>
              <AppIcon type={AppIconType.Dollar} size={AppIconSize.Normal} />
              {referralStats.estimatedValue} {LABELS.ESTIMATES_RENT}
            </div>
          </Grid>
        </Grid>

        <div className={classes.card}>
          <div>
            <AgencyLogoHeader>
              <Button
                variant="outlined"
                className={classes.buttonContainer}
                endIcon={<AddIcon />}
                onClick={() => history.push(routes.addReferral.new())}
              >
                <Typography variant="subtitle1">{BUTTON_LABEL.ADD_REFERRAl}</Typography>
              </Button>
            </AgencyLogoHeader>
          </div>
          {ReferralDisplayDetails()}
        </div>
      </div>
    </Paper>
  );
};

export default withStyles(styles)(MyReferrals);
