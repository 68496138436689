import { SIZES } from '../../lib/Constants';
import { color } from '../../lib/Theme/Theme';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  draftTitle: {
    fontSize: '14px',
    color: color.offBlack,
    paddingTop: '16px',
    marginBottom: '-8px',
  },
  gridLabel: {
    // textAlign: "end",
    paddingRight: '5px',
    lineHeight: 1.9,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      // textAlign: "start",
    },
  },
  gridValue: {
    fontWeight: 100,
    lineHeight: 1.9,
  },
  gridContainer: {
    marginTop: '14px',
  },
  gridRow: {
    display: 'flex',
  },

  propertyContainer: {
    paddingTop: '8px',
    borderBottom: `${color.borderGray} 1px solid`,
    paddingBottom: '16px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  statusContainer: {
    marginLeft: 'auto',
    padding: '5px 0 4px',
    borderRadius: 12,
    minWidth: '80px',
    textAlign: 'center',
    width: '114px',
  },
  completeLabel: {
    backgroundColor: color.completeLabel,
  },
  incompleteLabel: {
    backgroundColor: color.incompleteLabel,
  },
  statusText: {
    fontSize: '14px',
  },
});

export default useStyles;
