import { WithStyles, createStyles, makeStyles } from '@material-ui/core';

import { color } from '../../lib/Theme/Theme';

export const useStyles = makeStyles({
  contianer: {
    backgroundColor: color.rdsBackground,
    height: '100vh',
    display: 'flex',
    flex: 1,
  },
  textStyle: {
    color: color.rdsTextColor,
    fontSize: 16,
    fontWeight: 300,
    textAlign: 'center',
    marginBottom: 20,
  },
  titleTextStyle: {
    color: color.rdsTextColor,
    marginBottom: 20,
    fontSize: 27,
    fontWeight: 700,
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  iconStyle: {
    width: 58,
    height: 58,
    marginBottom: 40,
  },
  appIconStyle: {
    marginBottom: 40,
  },
  rootContainer: {
    backgroundColor: color.rdsBackground,
  },
  mainContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
});
