import CustomTermsHeaderState from '../State/CustomTermsHeaderState';
import {
  CustomTermsHeaderAction,
  CustomTermsHeaderActionTypes,
} from '../Actions/CustomTermsHeaderActions';
import { CustomTermsHeaderResponse } from '../Types/customTermsHeader/CustomTermsHeaderResponse.data';

export const initialState: CustomTermsHeaderState = {
  error: '',
  loading: false,
  termsHeaderDetails: undefined,
};

export const termsHeaderReducer: (
  state: CustomTermsHeaderState,
  action: CustomTermsHeaderAction,
) => CustomTermsHeaderState = (state = initialState, action) => {
  switch (action.type) {
    case CustomTermsHeaderActionTypes.GET_TERMS_REQUEST_START:
      return {
        ...state,
        loading: true,
      };
    case CustomTermsHeaderActionTypes.GET_TERMS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        termsHeaderDetails: action.data as CustomTermsHeaderResponse,
      };
    case CustomTermsHeaderActionTypes.GET_TERMS_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    default:
      return state;
  }
};
