import { StoreAction } from '../Store/StoreHelper';
import { CustomTermsHeaderResponse } from '../Types/customTermsHeader/CustomTermsHeaderResponse.data';
import { CustomTermsHeaderPayload } from '../Types/customTermsHeader/CustomTermsHeaderRequest.data';

export enum CustomTermsHeaderActionTypes {
  GET_TERMS_REQUEST_START = 'GET_TERMS_REQUEST_START',
  GET_TERMS_REQUEST_SUCCESS = 'GET_TERMS_REQUEST_SUCCESS',
  GET_TERMS_REQUEST_ERROR = 'GET_TERMS_REQUEST_ERROR',
}

export type CustomTermsHeaderActionPayload =
  | string
  | CustomTermsHeaderResponse
  | CustomTermsHeaderPayload
  | Error;

export type CustomTermsHeaderAction = StoreAction<
  CustomTermsHeaderActionTypes,
  CustomTermsHeaderActionPayload
>;

export class CustomTermsHeaderActions {
  public static postCustomTermsHeaderStart(
    data: CustomTermsHeaderPayload,
  ): CustomTermsHeaderAction {
    return {
      type: CustomTermsHeaderActionTypes.GET_TERMS_REQUEST_START,
      data,
    };
  }
  public static postCustomTermsHeaderSuccess(
    data: CustomTermsHeaderResponse,
  ): CustomTermsHeaderAction {
    return {
      type: CustomTermsHeaderActionTypes.GET_TERMS_REQUEST_SUCCESS,
      data,
    };
  }
  public static postCustomTermsHeaderError(error: Error): CustomTermsHeaderAction {
    return {
      type: CustomTermsHeaderActionTypes.GET_TERMS_REQUEST_ERROR,
      data: error,
    };
  }
}
