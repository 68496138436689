/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core';
import { color } from '../../../lib/Theme/Theme';

export const useStyles = makeStyles({
  feeSubContainer: {
    display: 'flex',
    alignItems: 'center',
    '@media only screen and (min-width: 960px)': {
      justifyContent: 'center',
    },
  },
  feeSubContainer2: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 5,
  },
  orText: {
    color: color.secondary500,
    padding: '1rem 0 0 0.5rem',
  },
  feeContainer: {
    marginTop: '1rem',
    border: `2px solid ${color.borderGray}`,
    borderRadius: 6,
    padding: '1rem 2rem',
  },
  subSectionHeader: {
    marginBottom: '-1.75rem',
    zIndex: 1,
    width: 'fit-content',
    background: color.light,
    padding: '0 1rem',
    marginLeft: '0.75rem',
  },
  text: {
    color: color.secondary500,
  },
});
