export enum EnumAccountTab {
  myReferral,
  myProperties,
}
export enum AccountTabs {
  REFERRAL = `Referrals`,
  PROPERTY = `My Properties`,
}
export const LABELS = {
  AGENCY_LABEL: ' The Property Collective',
};

export const BUTTON_LABEL = {
  ADD_REFERRAl: 'New Referral',
  ADD_PROPERTY: 'Add Property',
};
