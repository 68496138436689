/* eslint-disable max-lines */
import React from 'react';

export enum AppIconType {
  AddCircle = 'add-circle',
  AddUser = 'add-user',
  Add = 'add',
  AndroidPhone = 'android-phone',
  ApplicationSingle = 'application-single',
  ApplicationMultiple = 'application-multiple',
  ArrowBack = 'arrow-back',
  Assignment = 'assignment',
  Attention = 'attention',
  Avatar = 'avatar',
  BarGraph = 'bar-graph',
  Bath = 'bath',
  Bed = 'bed',
  Briefcase = 'briefcase',
  Broadband = 'broadband',
  Calendar = 'calendar',
  Callback = 'callback',
  CameraAdd = 'camera-add',
  Camera = 'camera',
  Cancelled = 'cancelled-doc',
  Chat = 'chat',
  Check = 'check',
  CheckInCircle = 'check-in-circle',
  CheckInBadge = 'check-in-badge',
  Children = 'children',
  Circle = 'circle',
  City = 'city',
  Clock = 'clock',
  Cloud = 'cloud',
  Cross = 'cross',
  CrossInCircle = 'cross-in-circle',
  Dash = 'dash',
  Dashboard = 'dashboard',
  Docs = 'docs',
  Document = 'document',
  Domain = 'domain',
  Donut = 'donut',
  Download = 'download',
  DownloadDocs = 'download-docs',
  Dollar = 'dollar',
  DollarBadge = 'badge-dollar',
  Edit = 'edit',
  Electricity = 'electricity',
  Email = 'email',
  Error = 'error',
  Excalamation = 'exclamation',
  FileText = 'file-text',
  FilledTick = 'filled-tick',
  Filter = 'filter',
  Flag = 'flag',
  Flower = 'flower',
  Furnished = 'furnished',
  Gas = 'gas',
  Gym = 'gym',
  Grade = 'grade',
  HelpInCircle = 'help-in-circle',
  Handshake = 'hand-shake',
  Home = 'home',
  HouseAdd = 'house-add',
  Image = 'image',
  Income = 'income',
  Info_outline = 'info_outline',
  Insurance = 'insurance',
  Light = 'light',
  Link = 'link',
  Locked = 'lock',
  Location = 'location',
  Maintenance = 'maintenance',
  MapMarker = 'map-marker',
  Merge = 'merge',
  Mobile = 'mobile',
  NavArrowDown = 'nav--arrow-down',
  NavArrowUpDown = 'nav--arrow-up-down',
  NavArrowUp = 'nav--arrow-up',
  NavCaretDown = 'nav--caret-down',
  NavCaretLeft = 'nav--caret-left',
  NavCaretRight = 'nav--caret-right',
  NavCaretUp = 'nav--caret-up',
  NavChevronDown = 'nav--chevron-down',
  NavChevronLeft = 'nav--chevron-left',
  NavChevronRight = 'nav--chevron-right',
  NavChevronUp = 'nav--chevron-up',
  NavHamburger = 'nav--hamburger',
  Parking = 'parking',
  Password = 'password',
  Pause = 'pause',
  Paw = 'paw',
  People = 'people',
  Phone = 'phone',
  PlayCircleFilled = 'play-circle-filled',
  Print = 'print',
  Profile = 'profile',
  Receipt = 'receipt',
  Refresh = 'refresh',
  Retry = 'retry',
  Return = 'return',
  Search = 'search',
  Send = 'send',
  SocialScore = 'social-score',
  Star = 'star',
  Stop = 'stop',
  TickInCirle = 'tick-in-circle',
  ThumbsDown = 'thumbs-down',
  ThumbsUp = 'thumbs-up',
  ThumbsUpBorderIcon = 'thumb_up_off_alt',
  ToggleCheckBoxDeselect = 'toggle--checkbox-deselect',
  ToggleCheckBoxNeutral = 'toggle--checkbox-neutral',
  ToggleCheckBoxSelect = 'toggle--checkbox-select',
  ToggleCheckBoxWarning = 'toggle--checkbox-warning',
  Trash = 'trash',
  Unmerge = 'call-split',
  Uplaod = 'upload',
  UserCard = 'user-card',
  Water = 'water',
  DollarCircle = 'dollar-in-circle',
  ApplicationPending = 'application-pending',
  ApplicationLA = 'application-la',
  ApplicationPA = 'application-pa',
  ApplicationSL = 'application-sl',
  Affordability = 'Affordability',
  PermContactCalender = 'PermContactCalender',
  ExpiryDate = 'ExpiryDate',
}

export const paths: { [key: string]: string } = {};
paths[AppIconType.AddCircle] =
  'M13.208 5.972v4.845h4.778v2.366h-4.778v4.845h-2.417v-4.845h-4.778v-2.366h4.778v-4.845h2.417zM11.972 0c3.297 0 6.126 1.174 8.487 3.521s3.541 5.174 3.541 8.479c0 3.305-1.18 6.131-3.541 8.479s-5.19 3.521-8.487 3.521c-3.297 0-6.117-1.174-8.459-3.521s-3.513-5.174-3.513-8.479c0-3.305 1.171-6.131 3.513-8.479s5.162-3.521 8.459-3.521zM12 21.634c2.642 0 4.899-0.939 6.773-2.817s2.81-4.15 2.81-6.817c0-2.667-0.937-4.939-2.81-6.817s-4.131-2.817-6.773-2.817c-2.642 0-4.899 0.939-6.773 2.817s-2.81 4.15-2.81 6.817c0 2.667 0.937 4.939 2.81 6.817s4.131 2.817 6.773 2.817z';
paths[AppIconType.AddUser] =
  'M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4c-2.21 0-4 1.79-4 4s1.79 4 4 4zM6 10v-3h-2v3h-3v2h3v3h2v-3h3v-2h-3zM15 14c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z';
paths[AppIconType.Add] =
  'M10.286 0v10.286h-10.286v3.429h10.286v10.286h3.429v-10.286h10.286v-3.429h-10.286v-10.286z';
paths[AppIconType.AndroidPhone] =
  'M16,1 L8,1 C6.34,1 5,2.34 5,4 L5,20 C5,21.66 6.34,23 8,23 L16,23 C17.66,23 19,21.66 19,20 L19,4 C19,2.34 17.66,1 16,1 Z M17,18 L7,18 L7,4 L17,4 L17,18 Z M14,21 L10,21 L10,20 L14,20 L14,21 Z';
paths[AppIconType.ApplicationSingle] =
  'M12.984 9h5.531l-5.531-5.484v5.484zM6 2.016h8.016l6 6v12q0 0.797-0.609 1.383t-1.406 0.586h-12q-0.797 0-1.406-0.586t-0.609-1.383l0.047-16.031q0-0.797 0.586-1.383t1.383-0.586z';
paths[AppIconType.ApplicationMultiple] =
  'M14.016 12h5.484l-5.484-5.484v5.484zM15 5.016l6 6v9.984q0 0.797-0.609 1.406t-1.406 0.609h-11.016q-0.797 0-1.383-0.609t-0.586-1.406v-14.016q0-0.797 0.609-1.383t1.406-0.586h6.984zM15.984 0.984v2.016h-12v14.016h-1.969v-14.016q0-0.797 0.586-1.406t1.383-0.609h12z';
paths[AppIconType.ArrowBack] = 'M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20z';
paths[AppIconType.Assignment] =
  'M17.016 9v-2.016h-10.031v2.016h10.031zM17.016 12.984v-1.969h-10.031v1.969h10.031zM14.016 17.016v-2.016h-7.031v2.016h7.031zM12 3q-0.422 0-0.703 0.281t-0.281 0.703 0.281 0.727 0.703 0.305 0.703-0.305 0.281-0.727-0.281-0.703-0.703-0.281zM18.984 3q0.797 0 1.406 0.609t0.609 1.406v13.969q0 0.797-0.609 1.406t-1.406 0.609h-13.969q-0.797 0-1.406-0.609t-0.609-1.406v-13.969q0-0.797 0.609-1.406t1.406-0.609h4.172q0.328-0.891 1.078-1.453t1.734-0.563 1.734 0.563 1.078 1.453h4.172z';
paths[AppIconType.Attention] =
  'M23.288 17.506c0.847 1.513 0.941 2.986 0.265 4.146s-1.999 1.799-3.729 1.799h-15.648c-1.729 0-3.054-0.639-3.73-1.799-0.676-1.161-0.582-2.633 0.265-4.146l7.927-14.151c0.851-1.519 2.045-2.355 3.362-2.355 1.316 0 2.51 0.836 3.361 2.355l7.926 14.151zM21.961 20.717c0.326-0.561 0.223-1.401-0.282-2.303l-7.926-14.151c-0.502-0.896-1.141-1.41-1.753-1.41s-1.252 0.514-1.755 1.41l-7.926 14.151c-0.505 0.902-0.607 1.741-0.28 2.303 0.326 0.559 1.106 0.881 2.137 0.881h15.648c1.031 0 1.81-0.32 2.137-0.881zM11.999 16.484c0.642 0 1.162 0.52 1.162 1.16 0 0.642-0.52 1.162-1.162 1.162-0.641 0-1.161-0.52-1.161-1.162 0-0.641 0.52-1.16 1.161-1.16zM11.999 7.968c0.642 0 1.162 0.535 1.162 1.195 0 0.035-0.006 0.067-0.009 0.101h0.006l-0.463 5.788h-0.004c-0.030 0.367-0.326 0.658-0.691 0.658-0.364 0-0.661-0.291-0.691-0.658h-0.004l-0.463-5.788h0.006c-0.003-0.034-0.009-0.066-0.009-0.101 0-0.66 0.52-1.195 1.161-1.195z';
paths[AppIconType.Avatar] =
  'M12 0c-6.624 0-12 5.376-12 12s5.376 12 12 12c6.624 0 12-5.376 12-12s-5.376-12-12-12zM12 3.6c1.992 0 3.6 1.608 3.6 3.6s-1.608 3.6-3.6 3.6c-1.992 0-3.6-1.608-3.6-3.6s1.608-3.6 3.6-3.6zM12 20.64c-3 0-5.652-1.536-7.2-3.864 0.036-2.388 4.8-3.696 7.2-3.696 2.388 0 7.164 1.308 7.2 3.696-1.548 2.328-4.2 3.864-7.2 3.864z';
paths[AppIconType.BarGraph] =
  'M21.333 0h-18.667c-1.467 0-2.667 1.2-2.667 2.667v18.667c0 1.467 1.2 2.667 2.667 2.667h18.667c1.467 0 2.667-1.2 2.667-2.667v-18.667c0-1.467-1.2-2.667-2.667-2.667zM8 18.667h-2.667v-9.333h2.667v9.333zM13.333 18.667h-2.667v-13.333h2.667v13.333zM18.667 18.667h-2.667v-5.333h2.667v5.333z';
paths[AppIconType.Bath] =
  'M22.8 14.4v1.2c0 2.292-1.284 4.284-3.18 5.292l0.78 3.108h-2.4l-0.6-2.4c-0.204 0-0.396 0-0.6 0h-9.6c-0.204 0-0.396 0-0.6 0l-0.6 2.4h-2.4l0.78-3.108c-1.896-1.008-3.18-3-3.18-5.292v-1.2h-1.2v-2.4h21.6v-8.4c0-0.663-0.537-1.2-1.2-1.2-0.6 0-1.056 0.408-1.2 0.948 0.756 0.648 1.2 1.608 1.2 2.652h-7.2c0-1.988 1.612-3.6 3.6-3.6 0.072 0 0.132 0 0.204 0 0.492-1.392 1.824-2.4 3.396-2.4 1.988 0 3.6 1.612 3.6 3.6v10.8h-1.2zM20.4 14.4h-16.8v1.2c0 1.988 1.612 3.6 3.6 3.6h9.6c1.988 0 3.6-1.612 3.6-3.6v-1.2z';
paths[AppIconType.Bed] =
  'M19.636 6.182h-15.273v7.636h-2.182v-9.818h-2.182v16.364h2.182v-3.273h19.636v3.273h2.182v-9.818c0-2.41-1.954-4.364-4.364-4.364z';
paths[AppIconType.Briefcase] =
  'M8.571 3.429h6.857v-1.714h-6.857v1.714zM24 12v6.429c0 1.179-0.964 2.143-2.143 2.143h-19.714c-1.179 0-2.143-0.964-2.143-2.143v-6.429h9v2.143c0 0.469 0.388 0.857 0.857 0.857h4.286c0.469 0 0.857-0.388 0.857-0.857v-2.143h9zM13.714 12v1.714h-3.429v-1.714h3.429zM24 5.571v5.143h-24v-5.143c0-1.179 0.964-2.143 2.143-2.143h4.714v-2.143c0-0.71 0.576-1.286 1.286-1.286h7.714c0.71 0 1.286 0.576 1.286 1.286v2.143h4.714c1.179 0 2.143 0.964 2.143 2.143z';
paths[AppIconType.Broadband] =
  'M0 7.976l2.182 2.186c5.422-5.432 14.214-5.432 19.636 0L24 7.976c-6.622-6.635-17.367-6.635-24 0zm8.727 8.745L12 20l3.273-3.28a4.616 4.616 0 0 0-6.546 0zm-4.363-4.373l2.181 2.187a7.706 7.706 0 0 1 10.91 0l2.181-2.187c-4.21-4.219-11.05-4.219-15.272 0z';
paths[AppIconType.Calendar] =
  'M17.8 13.2h-6v6h6v-6zM16.6 0v2.4h-9.6v-2.4h-2.4v2.4h-1.2c-1.332 0-2.388 1.080-2.388 2.4l-0.012 16.8c0 1.32 1.068 2.4 2.4 2.4h16.8c1.32 0 2.4-1.080 2.4-2.4v-16.8c0-1.32-1.080-2.4-2.4-2.4h-1.2v-2.4h-2.4zM20.2 21.6h-16.8v-13.2h16.8v13.2z';
paths[AppIconType.Callback] =
  'M4.827 10.394a20.193 20.193 0 0 0 8.786 8.782l2.934-2.932c.36-.36.893-.48 1.36-.32 1.493.494 3.106.76 4.76.76.733 0 1.333.6 1.333 1.333v4.65C24 23.4 23.4 24 22.667 24 10.147 24 0 13.859 0 1.346 0 .613.6.013 1.333.013H6c.733 0 1.333.6 1.333 1.333 0 1.666.267 3.265.76 4.757.147.467.04.986-.333 1.36l-2.933 2.931zM22.88.933L21.933 0l-8.6 8.395V2.68H12v7.995h8V9.341h-5.533L22.88.933z';
paths[AppIconType.CameraAdd] =
  'M3.13 4.13v-3.13h2.087v3.13h3.13v2.087h-3.13v3.13h-2.087v-3.13h-3.13v-2.087h3.13zM6.261 10.391v-3.13h3.13v-3.13h7.304l1.91 2.087h3.308c1.148 0 2.087 0.939 2.087 2.087v12.522c0 1.148-0.939 2.087-2.087 2.087h-16.696c-1.148 0-2.087-0.939-2.087-2.087v-10.435h3.13zM13.565 19.783c2.88 0 5.217-2.337 5.217-5.217s-2.337-5.217-5.217-5.217c-2.88 0-5.217 2.337-5.217 5.217s2.337 5.217 5.217 5.217zM10.435 14.565c0 1.732 1.398 3.13 3.13 3.13s3.13-1.398 3.13-3.13c0-1.732-1.398-3.13-3.13-3.13s-3.13 1.398-3.13 3.13z';
paths[AppIconType.Camera] =
  'M12 16.84c2.121 0 3.84-1.719 3.84-3.84s-1.719-3.84-3.84-3.84c-2.121 0-3.84 1.719-3.84 3.84s1.719 3.84 3.84 3.84zM21.6 3.4h-3.804l-2.196-2.4h-7.2l-2.196 2.4h-3.804c-1.32 0-2.4 1.080-2.4 2.4v14.4c0 1.32 1.080 2.4 2.4 2.4h19.2c1.32 0 2.4-1.080 2.4-2.4v-14.4c0-1.32-1.080-2.4-2.4-2.4zM12 19c-3.312 0-6-2.688-6-6s2.688-6 6-6c3.312 0 6 2.688 6 6s-2.688 6-6 6z';
paths[AppIconType.Cancelled] =
  'M13 9h5.5L13 3.5V9zM6 2h8l6 6v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4c0-1.11.89-2 2-2zm4.5 9C8 11 6 13 6 15.5S8 20 10.5 20s4.5-2 4.5-4.5-2-4.5-4.5-4.5zm0 1.5a3 3 0 0 1 3 3c0 .56-.15 1.08-.42 1.5L9 12.92c.42-.27.94-.42 1.5-.42zm-3 3c0-.56.15-1.08.42-1.5L12 18.08c-.42.27-.94.42-1.5.42a3 3 0 0 1-3-3z';
paths[AppIconType.Chat] =
  'M21.6 0h-19.2c-1.32 0-2.4 1.080-2.4 2.4v21.6l4.8-4.8h16.8c1.32 0 2.4-1.080 2.4-2.4v-14.4c0-1.32-1.080-2.4-2.4-2.4zM8.4 10.8h-2.4v-2.4h2.4v2.4zM13.2 10.8h-2.4v-2.4h2.4v2.4zM18 10.8h-2.4v-2.4h2.4v2.4z';
paths[AppIconType.Check] =
  'M7.622 17.446l-5.691-5.691-1.931 1.931 7.622 7.622 16.378-16.378-1.931-1.931z';
paths[AppIconType.CheckInCircle] =
  'M9.984 17.016l9-9-1.406-1.453-7.594 7.594-3.563-3.563-1.406 1.406zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055-7.055 2.93-7.055-2.93-2.93-7.055 2.93-7.055 7.055-2.93z';
paths[AppIconType.CheckInBadge] =
  'M11.133 12.522l-1.566-1.578L8 12.511l3.133 3.156 5.1-5.09L14.656 9l-3.523 3.522zM12 3.991l6.222 2.827v4.273c0 4.109-2.649 7.9-6.222 9.027-3.573-1.127-6.222-4.918-6.222-9.027V6.818L12 3.991M12 2L4 5.636v5.455C4 16.136 7.413 20.855 12 22c4.587-1.145 8-5.864 8-10.91V5.637L12 2z" transform="translate(-857 -178) translate(180 126) translate(0 34) translate(533 18) translate(144)';
paths[AppIconType.Children] =
  'M9 11.75c-0.69 0-1.25 0.56-1.25 1.25s0.56 1.25 1.25 1.25c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25zM15 11.75c-0.69 0-1.25 0.56-1.25 1.25s0.56 1.25 1.25 1.25c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25zM12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10c5.52 0 10-4.48 10-10s-4.48-10-10-10zM12 20c-4.41 0-8-3.59-8-8 0-0.29 0.020-0.58 0.050-0.86 2.36-1.050 4.23-2.98 5.21-5.37 1.81 2.56 4.79 4.23 8.16 4.23 0.78 0 1.53-0.090 2.25-0.26 0.21 0.71 0.33 1.47 0.33 2.26 0 4.41-3.59 8-8 8z';
paths[AppIconType.Circle] =
  'M10 0.4c-5.302 0-9.6 4.298-9.6 9.6s4.298 9.6 9.6 9.6c5.301 0 9.6-4.298 9.6-9.601 0-5.301-4.299-9.599-9.6-9.599zM10 17.599c-4.197 0-7.6-3.402-7.6-7.6s3.402-7.599 7.6-7.599c4.197 0 7.601 3.402 7.601 7.6s-3.404 7.599-7.601 7.599z';
paths[AppIconType.City] =
  'M12 6.444v-4.444h-11v20h22v-15.556h-11zM5.4 19.778h-2.2v-2.222h2.2v2.222zM5.4 15.333h-2.2v-2.222h2.2v2.222zM5.4 10.889h-2.2v-2.222h2.2v2.222zM5.4 6.444h-2.2v-2.222h2.2v2.222zM9.8 19.778h-2.2v-2.222h2.2v2.222zM9.8 15.333h-2.2v-2.222h2.2v2.222zM9.8 10.889h-2.2v-2.222h2.2v2.222zM9.8 6.444h-2.2v-2.222h2.2v2.222zM20.8 19.778h-8.8v-2.222h2.2v-2.222h-2.2v-2.222h2.2v-2.222h-2.2v-2.222h8.8v11.111zM18.6 10.889h-2.2v2.222h2.2v-2.222zM18.6 15.333h-2.2v2.222h2.2v-2.222z';
paths[AppIconType.Clock] =
  'M10 20c-5.523 0-10-4.477-10-10s4.477-10 10-10v0c5.523 0 10 4.477 10 10s-4.477 10-10 10v0zM10 18c4.418 0 8-3.582 8-8s-3.582-8-8-8v0c-4.418 0-8 3.582-8 8s3.582 8 8 8v0zM9 10.41v-6.41h2v5.59l3.95 3.95-1.41 1.41-4.54-4.54z';
paths[AppIconType.Cloud] =
  'M19.35 10.040c-0.68-3.45-3.71-6.040-7.35-6.040-2.89 0-5.39 1.64-6.64 4.040-3.010 0.32-5.36 2.86-5.36 5.96 0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.050-4.78-4.65-4.96zM19 18h-13c-2.21 0-4-1.79-4-4s1.79-4 4-4c2.21 0 4 1.79 4 4h2c0-2.76-1.86-5.080-4.4-5.78 1.010-1.34 2.6-2.22 4.4-2.22 3.030 0 5.5 2.47 5.5 5.5v0.5h1.5c1.65 0 3 1.35 3 3s-1.35 3-3 3z';
paths[AppIconType.Cross] =
  'M24 2.4l-2.4-2.4-9.6 9.6-9.6-9.6-2.4 2.4 9.6 9.6-9.6 9.6 2.4 2.4 9.6-9.6 9.6 9.6 2.4-2.4-9.6-9.6z';
paths[AppIconType.CrossInCircle] =
  'M2.93 17.070c-1.884-1.821-3.053-4.37-3.053-7.193 0-5.523 4.477-10 10-10 2.823 0 5.372 1.169 7.19 3.050l0.003 0.003c1.737 1.796 2.807 4.247 2.807 6.947 0 5.523-4.477 10-10 10-2.7 0-5.151-1.070-6.95-2.81l0.003 0.003zM4.34 15.66c1.449 1.449 3.45 2.344 5.66 2.344 4.421 0 8.004-3.584 8.004-8.004 0-2.21-0.896-4.211-2.344-5.66v0c-1.449-1.449-3.45-2.344-5.66-2.344-4.421 0-8.004 3.584-8.004 8.004 0 2.21 0.896 4.211 2.344 5.66v0zM14.24 7.17l-2.83 2.83 2.83 2.83-1.41 1.41-2.83-2.83-2.83 2.83-1.41-1.41 2.83-2.83-2.83-2.83 1.41-1.41 2.83 2.83 2.83-2.83 1.41 1.41z';
paths[AppIconType.Dash] =
  'M19.907 11h-15.814c-0.604 0-1.093 0.672-1.093 1.5s0.489 1.5 1.093 1.5h15.814c0.604 0 1.093-0.672 1.093-1.5s-0.489-1.5-1.093-1.5z';
paths[AppIconType.Dashboard] =
  'M3,13 L11,13 L11,3 L3,3 L3,13 Z M3,21 L11,21 L11,15 L3,15 L3,21 Z M13,21 L21,21 L21,11 L13,11 L13,21 Z M13,3 L13,9 L21,9 L21,3 L13,3 Z';
paths[AppIconType.Docs] =
  'M2.182 4.364h-2.182v17.455c0 1.2 0.982 2.182 2.182 2.182h17.455v-2.182h-17.455v-17.455zM21.818 0h-15.273c-1.2 0-2.182 0.982-2.182 2.182v15.273c0 1.2 0.982 2.182 2.182 2.182h15.273c1.2 0 2.182-0.982 2.182-2.182v-15.273c0-1.2-0.982-2.182-2.182-2.182zM21.818 17.455h-15.273v-15.273h15.273v15.273z';
paths[AppIconType.Document] =
  'M4.4 0c-1.32 0-2.388 1.080-2.388 2.4l-0.012 19.2c0 1.32 1.068 2.4 2.388 2.4h14.412c1.32 0 2.4-1.080 2.4-2.4v-14.4l-7.2-7.2h-9.6zM12.8 8.4v-6.6l6.6 6.6h-6.6z';
paths[AppIconType.Domain] =
  'M15 11v-6l-3-3-3 3v2h-6v14h18v-10h-6zM7 19h-2v-2h2v2zM7 15h-2v-2h2v2zM7 11h-2v-2h2v2zM13 19h-2v-2h2v2zM13 15h-2v-2h2v2zM13 11h-2v-2h2v2zM13 7h-2v-2h2v2zM19 19h-2v-2h2v2zM19 15h-2v-2h2v2z';
paths[AppIconType.Donut] =
  'M0 12c0-6.624 5.376-12 12-12s12 5.376 12 12c0 6.624-5.376 12-12 12s-12-5.376-12-12zM12 19.2c3.972 0 7.2-3.228 7.2-7.2s-3.228-7.2-7.2-7.2c-3.972 0-7.2 3.228-7.2 7.2s3.228 7.2 7.2 7.2z';
paths[AppIconType.Download] =
  'M21.769 8.463l-9.917 9.851-9.851-9.851h5.62v-8.463h8.463v8.463h5.686zM2 21.157h19.769v2.843h-19.769v-2.843z';
paths[AppIconType.DownloadDocs] =
  'M11.9166667,5.04166667 L11.9166667,10.5416667 L12.9891667,10.5416667 L11,12.5308333 L9.01083333,10.5416667 L10.0833333,10.5416667 L10.0833333,5.04166667 L11.9166667,5.04166667 L11.9166667,5.04166667 Z M13.75,3.20833333 L8.25,3.20833333 L8.25,8.70833333 L4.58333333,8.70833333 L11,15.125 L17.4166667,8.70833333 L13.75,8.70833333 L13.75,3.20833333 Z M17.4166667,16.9583333 L4.58333333,16.9583333 L4.58333333,18.7916667 L17.4166667,18.7916667 L17.4166667,16.9583333 Z';
paths[AppIconType.Dollar] =
  'M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z';
paths[AppIconType.DollarBadge] =
  'M10.679 10.193c0-.6.564-1.022 1.5-1.022.985 0 1.357.472 1.385 1.172h1.222c-.036-.957-.622-1.836-1.779-2.122V7h-1.664v1.207c-1.079.229-1.943.929-1.943 2.007 0 1.279 1.064 1.922 2.614 2.293 1.393.329 1.672.822 1.672 1.336 0 .378-.279.993-1.5.993-1.143 0-1.593-.515-1.657-1.172H9.3c.071 1.215.971 1.9 2.043 2.122V17h1.671v-1.193c1.086-.207 1.943-.828 1.95-1.978-.007-1.572-1.357-2.115-2.614-2.443-1.264-.322-1.671-.672-1.671-1.193zM12 3.99l6.222 2.827v4.273c0 4.109-2.649 7.9-6.222 9.027-3.573-1.127-6.222-4.918-6.222-9.027V6.818L12 3.991M12 2L4 5.636v5.455C4 16.136 7.413 20.855 12 22c4.587-1.145 8-5.864 8-10.91V5.637L12 2z" transform="translate(-761 -237) translate(180 126) translate(0 93) translate(533 18) translate(48)';
paths[AppIconType.Edit] =
  'M0 19l14.75-14.75 5 5-14.75 14.75h-5v-5zM23.625 5.375l-2.438 2.437-5-5 2.438-2.438c0.25-0.25 0.563-0.375 0.938-0.375s0.687 0.125 0.938 0.375l3.125 3.125c0.25 0.25 0.375 0.563 0.375 0.938s-0.125 0.687-0.375 0.938z';
paths[AppIconType.Electricity] = 'M0 0v13.2h3.6V24L12 9.6H7.2L10.8 0z';
paths[AppIconType.Email] =
  'M21.6 2h-19.2c-1.32 0-2.388 1.080-2.388 2.4l-0.012 14.4c0 1.32 1.080 2.4 2.4 2.4h19.2c1.32 0 2.4-1.080 2.4-2.4v-14.4c0-1.32-1.080-2.4-2.4-2.4zM21.6 6.8l-9.6 6-9.6-6v-2.4l9.6 6 9.6-6v2.4z';
paths[AppIconType.Error] =
  'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z';
paths[AppIconType.Excalamation] =
  'M11.667 24c1.473 0 2.667-1.194 2.667-2.667s-1.194-2.667-2.667-2.667c-1.473 0-2.667 1.194-2.667 2.667s1.194 2.667 2.667 2.667zM9 0h5.333v16h-5.333v-16z';
paths[AppIconType.FileText] =
  'M20.25 0h-18c-1.237 0-2.25 1.013-2.25 2.25v19.5c0 1.237 1.013 2.25 2.25 2.25h18c1.237 0 2.25-1.013 2.25-2.25v-19.5c0-1.237-1.013-2.25-2.25-2.25zM19.5 21h-16.5v-18h16.5v18zM6 10.5h10.5v1.5h-10.5zM6 13.5h10.5v1.5h-10.5zM6 16.5h10.5v1.5h-10.5zM6 7.5h10.5v1.5h-10.5z';
paths[AppIconType.FilledTick] =
  'M9.984 17.016l9-9-1.406-1.453-7.594 7.594-3.563-3.563-1.406 1.406zM12 2.016c5.531 0 9.984 4.453 9.984 9.984s-4.453 9.984-9.984 9.984-9.984-4.453-9.984-9.984 4.453-9.984 9.984-9.984z';
paths[AppIconType.Filter] = 'M9 20v-3h5v3h-5zM0 4h24v3h-24v-3zM4 14v-3h16v3h-16z';
paths[AppIconType.Flag] =
  'M14.289 2.843h7.868v14.083h-9.851l-0.595-2.777h-7.868v9.851h-2.843v-24h12.694z';
paths[AppIconType.Flower] =
  'M12.286 24c5.68 0 10.286-4.606 10.286-10.286-5.68 0-10.286 4.606-10.286 10.286zM4.971 10.571c0 1.577 1.28 2.857 2.857 2.857 0.606 0 1.154-0.183 1.623-0.503l-0.023 0.217c0 1.577 1.28 2.857 2.857 2.857s2.857-1.28 2.857-2.857l-0.023-0.217c0.457 0.32 1.017 0.503 1.623 0.503 1.577 0 2.857-1.28 2.857-2.857 0-1.143-0.674-2.114-1.634-2.571 0.96-0.457 1.634-1.429 1.634-2.571 0-1.577-1.28-2.857-2.857-2.857-0.606 0-1.154 0.183-1.623 0.503l0.023-0.217c0-1.577-1.28-2.857-2.857-2.857s-2.857 1.28-2.857 2.857l0.023 0.217c-0.457-0.32-1.017-0.503-1.623-0.503-1.577 0-2.857 1.28-2.857 2.857 0 1.143 0.674 2.114 1.634 2.571-0.96 0.457-1.634 1.429-1.634 2.571zM12.286 5.143c1.577 0 2.857 1.28 2.857 2.857s-1.28 2.857-2.857 2.857c-1.577 0-2.857-1.28-2.857-2.857s1.28-2.857 2.857-2.857zM2 13.714c0 5.68 4.606 10.286 10.286 10.286 0-5.68-4.606-10.286-10.286-10.286z';
paths[AppIconType.Furnished] =
  'M4 18v3h3v-3h10v3h3v-6h-16v3zM19 10h3v3h-3v-3zM2 10h3v3h-3v-3zM17 13h-10v-8c0-1.1 0.9-2 2-2h6c1.1 0 2 0.9 2 2v8z';
paths[AppIconType.Gas] =
  'M13.688 0s.832 2.982.832 5.4c0 2.319-1.519 4.198-3.836 4.198C8.355 9.598 6.6 7.718 6.6 5.4l.034-.405A15.493 15.493 0 0 0 3 14.999 8.998 8.998 0 0 0 12 24c4.973 0 9-4.028 9-9.001C21 8.934 18.086 3.522 13.687 0zm-2.014 20.624c-2.003 0-3.623-1.575-3.623-3.533 0-1.822 1.181-3.105 3.162-3.51 1.99-.405 4.05-1.362 5.197-2.903.439 1.451.664 2.982.664 4.546 0 2.981-2.419 5.4-5.4 5.4z';
paths[AppIconType.Grade] =
  'M3 6.444h17.778v2.223H3V6.444zm0 6.667h17.778V10.89H3v2.222zm0 4.445h7.778v-2.223H3v2.223zM3 22h7.778v-2.222H3V22zm12.678-3.144l-1.567-1.578-1.567 1.566L15.678 22l5.1-5.089-1.578-1.578-3.522 3.523zM3 2v2.222h17.778V2H3z" transform="translate(-833 -178) translate(180 126) translate(0 34) translate(533 18) translate(120)';
paths[AppIconType.Gym] =
  'M20.57 14.86l1.43-1.43-1.43-1.43-3.57 3.57-8.57-8.57 3.57-3.57-1.43-1.43-1.43 1.43-1.43-1.43-2.14 2.14-1.43-1.43-1.43 1.43 1.43 1.43-2.14 2.14 1.43 1.43-1.43 1.43 1.43 1.43 3.57-3.57 8.57 8.57-3.57 3.57 1.43 1.43 1.43-1.43 1.43 1.43 2.14-2.14 1.43 1.43 1.43-1.43-1.43-1.43 2.14-2.14z';
paths[AppIconType.HelpInCircle] =
  'M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z';
paths[AppIconType.Home] = 'M10 20v-6h4v6h5v-8h3l-10-9-10 9h3v8z';
paths[AppIconType.Handshake] =
  'M10.318 6.648c.243 0 .44.196.44.44 0 .314-.075.608-.197.878H8.664c-.666 0-1.274.376-1.572.972l-1.546 3.093c-.112.224-.013.495.214.599.56.254 1.031.45 1.712.394.925-.075 1.735-.661 2.15-1.491l.025-.051h.307l3.122 3.121c-.154.158-.343.282-.568.345l-3.753 1.18c-.448.15-.968.036-1.329-.325l-1.245-1.245H4.166V8.406h.775c.167 0 .319-.095.393-.243l.393-.786c.224-.447.68-.73 1.18-.73zm5.743 1.758c.242 0 .439.196.439.439v5.273c0 .243-.197.44-.44.44h-1.347V8.406zm-12.774 0v6.152H1.94c-.242 0-.439-.197-.439-.44V8.845c0-.243.197-.44.44-.44h1.347zm10.547.439v5.273l-3.258-3.258c-.164-.163-.39-.257-.622-.257h-.85l-.29.578c-.3.601-.901.974-1.57.974-.243 0-.48-.05-.703-.148l1.337-2.676c.15-.3.451-.486.786-.486h5.17zM5.174 4.14c.171-.172.45-.172.621 0l.879.879c.172.171.172.45 0 .621-.172.172-.45.172-.621 0l-.88-.879c-.171-.171-.171-.45 0-.621zm7.03 0c.173-.172.45-.172.622 0 .172.171.172.45 0 .621l-.879.88c-.171.17-.45.17-.621 0-.172-.173-.172-.45 0-.622zM9 2.253c.243 0 .44.197.44.44V4.01c0 .243-.197.44-.44.44s-.44-.197-.44-.44V2.693c0-.243.197-.44.44-.44z';
paths[AppIconType.HouseAdd] =
  'M12.4 0c-4.632 0-8.4 3.768-8.4 8.4 0 6.3 8.4 15.6 8.4 15.6s8.4-9.3 8.4-15.6c0-4.632-3.768-8.4-8.4-8.4zM17.2 9.6h-3.6v3.6h-2.4v-3.6h-3.6v-2.4h3.6v-3.6h2.4v3.6h3.6v2.4z';
paths[AppIconType.Image] =
  'M18.555,15.354V4.592c0-0.248-0.202-0.451-0.45-0.451H1.888c-0.248,0-0.451,0.203-0.451,0.451v10.808c0,0.559,0.751,0.451,0.451,0.451h16.217h0.005C18.793,15.851,18.478,14.814,18.555,15.354 M2.8,14.949l4.944-6.464l4.144,5.419c0.003,0.003,0.003,0.003,0.003,0.005l0.797,1.04H2.8z M13.822,14.949l-1.006-1.317l1.689-2.218l2.688,3.535H13.822z M17.654,14.064l-2.791-3.666c-0.181-0.237-0.535-0.237-0.716,0l-1.899,2.493l-4.146-5.42c-0.18-0.237-0.536-0.237-0.716,0l-5.047,6.598V5.042h15.316V14.064z M12.474,6.393c-0.869,0-1.577,0.707-1.577,1.576s0.708,1.576,1.577,1.576s1.577-0.707,1.577-1.576S13.343,6.393,12.474,6.393 M12.474,8.645c-0.371,0-0.676-0.304-0.676-0.676s0.305-0.676,0.676-0.676c0.372,0,0.676,0.304,0.676,0.676S12.846,8.645,12.474,8.645';
paths[AppIconType.Income] =
  'M11.304 9.311l-0 0c-0.154 0.124-0.216 0.248-0.247 0.401 0 0.031 0.030 0.465 1.605 1.179 1.081 0.497 1.821 0.962 2.284 1.489 0.525 0.558 0.772 1.272 0.772 2.109 0 0.775-0.277 1.489-0.833 2.109-0.432 0.497-1.018 0.869-1.667 1.085v1.767h-2.563v-1.674c-0.865-0.155-1.636-0.465-2.254-0.962l-0.402-0.342 1.235-2.171 0.556 0.465c0.555 0.435 1.203 0.652 1.975 0.652 0.463 0 0.802-0.092 1.049-0.279 0.216-0.155 0.309-0.34 0.309-0.589 0-0.558-0.926-1.024-1.451-1.24-2.13-0.93-3.21-2.079-3.21-3.443 0-0.806 0.279-1.489 0.803-2.077 0.402-0.465 0.958-0.775 1.575-0.992v-1.799h2.563v1.737c0.617 0.125 1.203 0.372 1.821 0.713l0.556 0.31-1.389 2.264-0.556-0.465c-0.37-0.342-0.926-0.497-1.667-0.497-0.34 0-0.649 0.093-0.865 0.248zM2.296 9.306l0 0c-0.53 2.016-0.413 4.122 0.294 6.050l-1.708 1.008c-2.208-5.396-0.177-11.771 5.006-14.767 4.947-2.876 11.071-1.749 14.693 2.431l1.236-0.711-0.472 3.914-3.622-1.542 1.119-0.652c-1.236-1.305-2.767-2.224-4.534-2.699-2.533-0.682-5.183-0.325-7.45 0.979s-3.886 3.439-4.564 5.989zM23.116 7.636l-0 0c2.211 5.386 0.177 11.75-5.043 14.768-4.924 2.871-11.086 1.746-14.713-2.398l-1.179 0.681 0.471-3.937 3.627 1.539-1.149 0.681c1.238 1.303 2.771 2.22 4.54 2.694 2.536 0.681 5.19 0.326 7.46-0.977 2.27-1.302 3.892-3.432 4.57-5.978 0.56-2.042 0.442-4.144-0.295-6.067l1.71-1.006z';
paths[AppIconType.Info_outline] =
  'M11.016 9v-2.016h1.969v2.016h-1.969zM12 20.016q3.281 0 5.648-2.367t2.367-5.648-2.367-5.648-5.648-2.367-5.648 2.367-2.367 5.648 2.367 5.648 5.648 2.367zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055-7.055 2.93-7.055-2.93-2.93-7.055 2.93-7.055 7.055-2.93zM11.016 17.016v-6h1.969v6h-1.969z';
paths[AppIconType.Insurance] =
  'M12 0c6.075 0 11 4.835 11 10.8h-9.778v9.6c0 1.988-1.641 3.6-3.666 3.6s-3.667-1.612-3.667-3.6v-1.2h2.444v1.2c0 .663.548 1.2 1.223 1.2s1.222-.537 1.222-1.2v-9.6H1C1 4.835 5.925 0 12 0z';
paths[AppIconType.Light] =
  'M2.782 19.589l1.538 1.538 1.953-1.964-1.538-1.538-1.953 1.964zM10.909 23.855h2.182v-3.218h-2.182v3.218zM3.273 10.818h-3.273v2.182h3.273v-2.182zM15.273 6.247v-5.247h-6.545v5.247c-1.953 1.135-3.273 3.24-3.273 5.662 0 3.611 2.935 6.545 6.545 6.545s6.545-2.935 6.545-6.545c0-2.422-1.32-4.527-3.273-5.662zM20.727 10.818v2.182h3.273v-2.182h-3.273zM17.716 19.175l1.953 1.964 1.538-1.538-1.964-1.953-1.527 1.527z';
paths[AppIconType.Link] =
  'M15 8h3c0.544 0 1.060 0.108 1.529 0.303 0.489 0.202 0.929 0.5 1.299 0.869s0.667 0.81 0.869 1.299c0.195 0.469 0.303 0.985 0.303 1.529s-0.108 1.060-0.303 1.529c-0.202 0.489-0.5 0.929-0.869 1.299s-0.81 0.667-1.299 0.869c-0.469 0.195-0.985 0.303-1.529 0.303h-3c-0.552 0-1 0.448-1 1s0.448 1 1 1h3c0.811 0 1.587-0.161 2.295-0.455 0.735-0.304 1.395-0.75 1.948-1.303s0.998-1.213 1.303-1.948c0.293-0.707 0.454-1.483 0.454-2.294s-0.161-1.587-0.455-2.295c-0.304-0.735-0.75-1.395-1.303-1.948s-1.213-0.998-1.948-1.303c-0.707-0.293-1.483-0.454-2.294-0.454h-3c-0.552 0-1 0.448-1 1s0.448 1 1 1zM9 16h-3c-0.544 0-1.060-0.108-1.529-0.303-0.489-0.202-0.929-0.5-1.299-0.869s-0.667-0.81-0.869-1.299c-0.195-0.469-0.303-0.985-0.303-1.529s0.108-1.060 0.303-1.529c0.202-0.489 0.5-0.929 0.869-1.299s0.81-0.667 1.299-0.869c0.469-0.195 0.985-0.303 1.529-0.303h3c0.552 0 1-0.448 1-1s-0.448-1-1-1h-3c-0.811 0-1.587 0.161-2.295 0.455-0.735 0.304-1.395 0.75-1.948 1.302s-0.998 1.213-1.302 1.948c-0.294 0.708-0.455 1.484-0.455 2.295s0.161 1.587 0.455 2.295c0.304 0.735 0.75 1.395 1.303 1.948s1.213 0.998 1.948 1.303c0.707 0.293 1.483 0.454 2.294 0.454h3c0.552 0 1-0.448 1-1s-0.448-1-1-1zM8 13h8c0.552 0 1-0.448 1-1s-0.448-1-1-1h-8c-0.552 0-1 0.448-1 1s0.448 1 1 1z';
paths[AppIconType.Locked] =
  'M4.8 9.6v-2.4c0-3.976 3.224-7.2 7.2-7.2s7.2 3.224 7.2 7.2v0 2.4h1.2c1.325 0 2.4 1.075 2.4 2.4v0 9.6c0 1.325-1.075 2.4-2.4 2.4v0h-16.8c-1.325 0-2.4-1.075-2.4-2.4v0-9.6c0-1.32 1.080-2.4 2.4-2.4h1.2zM10.8 17.676v2.724h2.4v-2.724c0.722-0.423 1.2-1.195 1.2-2.078 0-1.325-1.075-2.4-2.4-2.4s-2.4 1.075-2.4 2.4c0 0.884 0.478 1.656 1.189 2.072l0.011 0.006zM8.4 7.2v2.4h7.2v-2.4c0-1.988-1.612-3.6-3.6-3.6s-3.6 1.612-3.6 3.6v0z';
paths[AppIconType.Location] =
  'M12,12c-1.1,0-2-0.9-2-2s0.9-2,2-2c1.1,0,2,0.9,2,2S13.1,12,12,12z M18,10.2C18,6.57,15.35,4,12,4s-6,2.57-6,6.2 c0,2.34,1.95,5.44,6,9.14C16.05,15.64,18,12.54,18,10.2z M12,2c4.2,0,8,3.22,8,8.2c0,3.32-2.67,7.25-8,11.8 c-5.33-4.55-8-8.48-8-11.8C4,5.22,7.8,2,12,2z';
paths[AppIconType.Maintenance] =
  'M16.745 11l3.98-3.98c.39-.39.39-1.02 0-1.41l-4.34-4.34c-.187-.187-.44-.292-.705-.292-.265 0-.518.105-.705.292l-3.98 3.98-3.98-3.98c-.2-.19-.45-.29-.71-.29-.25 0-.51.1-.7.29l-4.34 4.34c-.39.39-.39 1.02 0 1.41L5.245 11l-3.98 3.98c-.39.39-.39 1.02 0 1.41l4.34 4.34c.39.39 1.02.39 1.41 0l3.98-3.98 3.98 3.98c.2.2.45.29.71.29.26 0 .51-.1.71-.29l4.34-4.34c.39-.39.39-1.02 0-1.41L16.745 11zm-5.73-3.02c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-4.71 1.96l-3.63-3.62 3.63-3.63 3.62 3.62-3.62 3.63zm2.71 2.04c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm2 2c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm2-4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm2.66 9.34l-3.63-3.62 3.63-3.63 3.62 3.62-3.62 3.63z';
paths[AppIconType.MapMarker] =
  'M12.4 0c-4.644 0-8.4 3.756-8.4 8.4 0 6.3 8.4 15.6 8.4 15.6s8.4-9.3 8.4-15.6c0-4.644-3.756-8.4-8.4-8.4zM12.4 11.4c-1.656 0-3-1.344-3-3s1.344-3 3-3c1.656 0 3 1.344 3 3s-1.344 3-3 3z';
paths[AppIconType.Merge] =
  'M7.5 8.016l4.5-4.5 4.5 4.5h-3.516v6.375l-6 6-1.406-1.406 5.438-5.391v-5.578h-3.516zM17.016 20.391l-3.422-3.375 1.406-1.406 3.422 3.375z';
paths[AppIconType.Mobile] =
  'M17.016 5.016h-9.188l-2.719-2.719q0.469-1.313 1.875-1.313h10.031q0.797 0 1.383 0.609t0.586 1.406v13.172l-1.969-2.016v-9.141zM6.984 18.984h9.75l-9.75-9.703v9.703zM2.766 2.484l19.219 19.219-1.266 1.266-1.734-1.734q-0.094 0.75-0.656 1.266t-1.313 0.516h-10.031q-0.797 0-1.383-0.609t-0.586-1.406v-13.734l-3.516-3.516z';
paths[AppIconType.NavArrowDown] =
  'M10.688 0h2.625v18.875l4.812-4.75 1.875 1.875-8 8-8-8 1.875-1.875 4.812 4.75z';
paths[AppIconType.NavArrowUpDown] =
  'M17.688 18.687h4l-5.375 5.313-5.313-5.313h4v-9.375h2.688v9.375zM8.313 0l5.375 5.313h-4v9.375h-2.688v-9.375h-4l5.313-5.313z';
paths[AppIconType.NavArrowUp] =
  'M10.688 24v-18.875l-4.812 4.75-1.875-1.875 8-8 8 8-1.875 1.875-4.812-4.75v18.875z';
paths[AppIconType.NavCaretDown] = 'M5 9h14l-6.967 6z';
paths[AppIconType.NavCaretLeft] = 'M15 5v14l-6-6.967z';
paths[AppIconType.NavCaretRight] = 'M9 19v-14l6 6.967z';
paths[AppIconType.NavCaretUp] = 'M5 16l7-7 7 7z';
paths[AppIconType.NavChevronDown] = 'M2.82 5l9.18 9.16 9.18-9.16 2.82 2.82-12 12-12-12z';
paths[AppIconType.NavChevronLeft] = 'M19.82 2.82l-2.82-2.82-12 12 12 12 2.82-2.82-9.16-9.18z';
paths[AppIconType.NavChevronRight] = 'M5 21.18l9.16-9.18-9.16-9.18 2.82-2.82 12 12-12 12z';
paths[AppIconType.NavChevronUp] = 'M21.18 19.41l-9.18-9.16-9.18 9.16-2.82-2.82 12-12 12 12z';
paths[AppIconType.NavHamburger] = 'M0 20h24v-2h-24v2zM0 13h24v-2h-24v2zM0 4v2h24v-2h-24z';
paths[AppIconType.Parking] =
  'M21.227 2.347c-0.267-0.787-1.013-1.347-1.893-1.347h-14.667c-0.88 0-1.613 0.56-1.893 1.347l-2.773 7.987v10.667c0 0.733 0.6 1.333 1.333 1.333h1.333c0.733 0 1.333-0.6 1.333-1.333v-1.333h16v1.333c0 0.733 0.6 1.333 1.333 1.333h1.333c0.733 0 1.333-0.6 1.333-1.333v-10.667l-2.773-7.987zM4.667 15.667c-1.107 0-2-0.893-2-2s0.893-2 2-2c1.107 0 2 0.893 2 2s-0.893 2-2 2zM19.333 15.667c-1.107 0-2-0.893-2-2s0.893-2 2-2c1.107 0 2 0.893 2 2s-0.893 2-2 2zM2.667 9l2-6h14.667l2 6h-18.667z';
paths[AppIconType.Password] =
  'M17.81 4.47c-0.080 0-0.16-0.020-0.23-0.060-1.92-0.99-3.58-1.41-5.57-1.41-1.98 0-3.86 0.47-5.57 1.41-0.24 0.13-0.54 0.040-0.68-0.2-0.13-0.24-0.040-0.55 0.2-0.68 1.86-1.010 3.9-1.53 6.050-1.53 2.13 0 3.99 0.47 6.030 1.52 0.25 0.13 0.34 0.43 0.21 0.67-0.090 0.18-0.26 0.28-0.44 0.28zM3.5 9.72c-0.1 0-0.2-0.030-0.29-0.090-0.23-0.16-0.28-0.47-0.12-0.7 0.99-1.4 2.25-2.5 3.75-3.27 3.14-1.62 7.16-1.63 10.31-0.010 1.5 0.77 2.76 1.86 3.75 3.25 0.16 0.22 0.11 0.54-0.12 0.7s-0.54 0.11-0.7-0.12c-0.9-1.26-2.040-2.25-3.39-2.94-2.87-1.47-6.54-1.47-9.4 0.010-1.36 0.7-2.5 1.7-3.4 2.96-0.080 0.14-0.23 0.21-0.39 0.21zM9.75 21.79c-0.13 0-0.26-0.050-0.35-0.15-0.87-0.87-1.34-1.43-2.010-2.64-0.69-1.23-1.050-2.73-1.050-4.34 0-2.97 2.54-5.39 5.66-5.39s5.66 2.42 5.66 5.39c0 0.28-0.22 0.5-0.5 0.5s-0.5-0.22-0.5-0.5c0-2.42-2.090-4.39-4.66-4.39s-4.66 1.97-4.66 4.39c0 1.44 0.32 2.77 0.93 3.85 0.64 1.15 1.080 1.64 1.85 2.42 0.19 0.2 0.19 0.51 0 0.71-0.11 0.1-0.24 0.15-0.37 0.15zM16.92 19.94c-1.19 0-2.24-0.3-3.1-0.89-1.49-1.010-2.38-2.65-2.38-4.39 0-0.28 0.22-0.5 0.5-0.5s0.5 0.22 0.5 0.5c0 1.41 0.72 2.74 1.94 3.56 0.71 0.48 1.54 0.71 2.54 0.71 0.24 0 0.64-0.030 1.040-0.1 0.27-0.050 0.53 0.13 0.58 0.41 0.050 0.27-0.13 0.53-0.41 0.58-0.57 0.11-1.070 0.12-1.21 0.12zM14.91 22c-0.040 0-0.090-0.010-0.13-0.020-1.59-0.44-2.63-1.030-3.72-2.1-1.4-1.39-2.17-3.24-2.17-5.22 0-1.62 1.38-2.94 3.080-2.94s3.080 1.32 3.080 2.94c0 1.070 0.93 1.94 2.080 1.94s2.080-0.87 2.080-1.94c0-3.77-3.25-6.83-7.25-6.83-2.84 0-5.44 1.58-6.61 4.030-0.39 0.81-0.59 1.76-0.59 2.8 0 0.78 0.070 2.010 0.67 3.61 0.1 0.26-0.030 0.55-0.29 0.64-0.26 0.1-0.55-0.040-0.64-0.29-0.49-1.31-0.73-2.61-0.73-3.96 0-1.2 0.23-2.29 0.68-3.24 1.33-2.79 4.28-4.6 7.51-4.6 4.55 0 8.25 3.51 8.25 7.83 0 1.62-1.38 2.94-3.080 2.94s-3.080-1.32-3.080-2.94c0-1.070-0.93-1.94-2.080-1.94s-2.080 0.87-2.080 1.94c0 1.71 0.66 3.31 1.87 4.51 0.95 0.94 1.86 1.46 3.27 1.85 0.27 0.070 0.42 0.35 0.35 0.61-0.050 0.23-0.26 0.38-0.47 0.38z';
paths[AppIconType.Pause] =
  'M14.016 5.016h3.984v13.969h-3.984v-13.969zM6 18.984v-13.969h3.984v13.969h-3.984z';
paths[AppIconType.Paw] =
  'M3 11.85c-1.657 0-3-1.349-3-3.014s1.343-3.014 3-3.014c1.657 0 3 1.349 3 3.014s-1.343 3.014-3 3.014zM8.4 7.028c-1.657 0-3-1.349-3-3.014s1.343-3.014 3-3.014c1.657 0 3 1.349 3 3.014s-1.343 3.014-3 3.014zM15.6 7.028c-1.657 0-3-1.349-3-3.014s1.343-3.014 3-3.014c1.657 0 3 1.349 3 3.014s-1.343 3.014-3 3.014zM21 11.85c-1.657 0-3-1.349-3-3.014s1.343-3.014 3-3.014c1.657 0 3 1.349 3 3.014s-1.343 3.014-3 3.014zM18.408 15.298c1.578 1.573 3.498 3.327 3.15 5.781-0.354 1.23-1.23 2.453-2.802 2.803-0.876 0.175-3.672-0.524-6.648-0.524-0.036 0-0.072 0.006-0.108 0.006s-0.072-0.006-0.108-0.006c-2.976 0-5.772 0.699-6.648 0.524-1.578-0.35-2.454-1.579-2.802-2.803-0.348-2.453 1.578-4.207 3.15-5.781 1.050-1.224 1.926-2.278 2.976-3.502 0.558-0.651 1.26-1.308 2.094-1.591 0.132-0.042 0.264-0.078 0.396-0.102 0.306-0.060 0.63-0.060 0.942-0.060s0.636 0 0.942 0.060c0.138 0.024 0.27 0.060 0.396 0.102 0.834 0.283 1.536 0.94 2.094 1.591 1.050 1.224 1.926 2.278 2.976 3.502z';
paths[AppIconType.People] =
  'M16.364 10.545c1.811 0 3.262-1.462 3.262-3.273s-1.451-3.273-3.262-3.273c-1.811 0-3.273 1.462-3.273 3.273s1.462 3.273 3.273 3.273zM7.636 10.545c1.811 0 3.262-1.462 3.262-3.273s-1.451-3.273-3.262-3.273c-1.811 0-3.273 1.462-3.273 3.273s1.462 3.273 3.273 3.273zM7.636 12.727c-2.542 0-7.636 1.276-7.636 3.818v2.727h15.273v-2.727c0-2.542-5.095-3.818-7.636-3.818zM16.364 12.727c-0.316 0-0.676 0.022-1.058 0.055 1.265 0.916 2.149 2.149 2.149 3.764v2.727h6.545v-2.727c0-2.542-5.095-3.818-7.636-3.818z';
paths[AppIconType.Phone] =
  'M4.827 10.387c1.92 3.773 5.013 6.853 8.787 8.787l2.933-2.933c0.36-0.36 0.893-0.48 1.36-0.32 1.493 0.493 3.107 0.76 4.76 0.76 0.733 0 1.333 0.6 1.333 1.333v4.653c0 0.733-0.6 1.333-1.333 1.333-12.52 0-22.667-10.147-22.667-22.667 0-0.733 0.6-1.333 1.333-1.333h4.667c0.733 0 1.333 0.6 1.333 1.333 0 1.667 0.267 3.267 0.76 4.76 0.147 0.467 0.040 0.987-0.333 1.36l-2.933 2.933z';
paths[AppIconType.PlayCircleFilled] =
  'M9.984 16.5l6-4.5-6-4.5v9zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055-7.055 2.93-7.055-2.93-2.93-7.055 2.93-7.055 7.055-2.93z';
paths[AppIconType.Print] =
  'M20.403 7.014c0.974 0 1.817 0.347 2.529 1.040s1.068 1.546 1.068 2.557v7.194h-4.834v4.778h-14.389v-4.778h-4.778v-7.194c0-1.012 0.347-1.864 1.040-2.557s1.546-1.040 2.557-1.040h16.806zM16.806 20.166v-5.958h-9.611v5.958h9.611zM20.375 11.792c0.318 0 0.6-0.122 0.843-0.365s0.365-0.525 0.365-0.843c0-0.318-0.122-0.6-0.365-0.843s-0.525-0.365-0.843-0.365c-0.318 0-0.6 0.122-0.843 0.365s-0.365 0.525-0.365 0.843c0 0.318 0.122 0.6 0.365 0.843s0.525 0.365 0.843 0.365zM19.166 1v4.778h-14.389v-4.778h14.389z';
paths[AppIconType.Profile] =
  'M12 24c-6.627 0-12-5.373-12-12s5.373-12 12-12v0c6.627 0 12 5.373 12 12s-5.373 12-12 12v0zM8.4 7.2v2.4c0 1.988 1.612 3.6 3.6 3.6s3.6-1.612 3.6-3.6v0-2.4c0-1.988-1.612-3.6-3.6-3.6s-3.6 1.612-3.6 3.6v0zM4.020 17.328c1.747 2.586 4.668 4.263 7.98 4.263s6.233-1.678 7.958-4.229l0.022-0.034c-2.342-1.101-5.086-1.744-7.98-1.744s-5.638 0.643-8.097 1.793l0.117-0.049z';
paths[AppIconType.Receipt] =
  'M19.5 3.5L18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2 4.5 3.5 3 2v20l1.5-1.5L6 22l1.5-1.5L9 22l1.5-1.5L12 22l1.5-1.5L15 22l1.5-1.5L18 22l1.5-1.5L21 22V2l-1.5 1.5zM19 19.09H5V4.91h14v14.18zM6 15h12v2H6zm0-4h12v2H6zm0-4h12v2H6z';
paths[AppIconType.Refresh] =
  'M18.75 7.734q1.266 1.922 1.266 4.266 0 3.281-2.367 5.648t-5.648 2.367v3l-3.984-4.031 3.984-3.984v3q2.484 0 4.242-1.758t1.758-4.242q0-1.406-0.703-2.813zM12 6q-2.484 0-4.242 1.758t-1.758 4.242q0 1.5 0.703 2.813l-1.453 1.453q-1.266-1.922-1.266-4.266 0-3.281 2.367-5.648t5.648-2.367v-3l3.984 4.031-3.984 3.984v-3z';
paths[AppIconType.Retry] =
  'M4.474 12.575l3.068-3.068h-1.798c0.016-0.045 0.034-0.089 0.050-0.133 1.066-2.702 3.7-4.613 6.78-4.613 4.024 0 7.287 3.262 7.287 7.286s-3.263 7.287-7.287 7.287c-2.96 0-5.507-1.765-6.647-4.3-0.011-0.023-0.018-0.050-0.029-0.074l-1.954 1.942c0.014 0.023 0.023 0.048 0.036 0.072 0.003 0.005 0.006 0.011 0.009 0.015 1.724 2.979 4.946 4.982 8.635 4.982 5.506 0 9.97-4.464 9.97-9.971s-4.465-9.972-9.971-9.972c-4.604 0-8.477 3.119-9.625 7.359-0.011 0.039-0.015 0.080-0.024 0.119h-1.568l3.068 3.068z';
paths[AppIconType.Return] =
  'M9.471 7.059l2.005 2.005-5.068 5.054h12.946v-14.118h2.824v16.941h-15.769l5.068 5.054-2.005 2.005-8.471-8.471z';
paths[AppIconType.Search] =
  'M17.154 15.093h-1.089l-0.379-0.376c1.338-1.56 2.152-3.583 2.152-5.799 0-4.926-3.993-8.919-8.919-8.919s-8.919 3.993-8.919 8.919c0 4.926 3.993 8.919 8.919 8.919 2.216 0 4.237-0.812 5.797-2.149l0.379 0.376v1.087l6.858 6.848 2.046-2.046-6.844-6.861zM8.919 15.093c-3.411 0-6.175-2.763-6.175-6.175 0-3.41 2.763-6.175 6.175-6.175 3.41 0 6.175 2.765 6.175 6.175 0 3.411-2.765 6.175-6.175 6.175v0z';
paths[AppIconType.Send] = 'M0 0l24 12-24 12v-24zM0 9.6v4.8l12-2.4-12-2.4z';
paths[AppIconType.SocialScore] =
  'M17.751 0h-11.824c-2.721 0-4.927 2.224-4.927 4.968v8.703c0 2.744 2.206 4.969 4.927 4.969h2.844l3.068 5.36 3.067-5.36h2.844c2.722 0 4.927-2.225 4.927-4.969l0.001-8.703c0-2.744-2.206-4.968-4.927-4.968h0zM7.967 13.325c0 0.765-0.519 1.384-1.161 1.384s-1.161-0.619-1.161-1.384l0.001-4.199c0-0.766 0.52-1.384 1.161-1.384s1.161 0.618 1.161 1.384l-0.001 4.199zM18.032 12.585c0 1.174-0.919 2.125-2.050 2.125h-5.191c-1.133 0-2.050-0.951-2.050-2.125v-2.749c0-1.029 0.705-1.887 1.641-2.084 0.895-1.365 1.316-2.571 1.316-2.571s0.121-0.533 0.328-1.141c0.022-0.093 0.054-0.182 0.094-0.266 0.196-0.402 0.597-0.677 1.061-0.677 0.551 0 1.015 0.388 1.149 0.918 0.015 0.058 0.026 0.115 0.032 0.176 0.219 1.391-0.245 2.735-0.606 3.521h2.225c1.132 0 2.050 0.951 2.050 2.125l0.001 2.748z';
paths[AppIconType.Star] =
  'M12 17.27l6.18 3.73-1.64-7.030 5.46-4.73-7.19-0.61-2.81-6.63-2.81 6.63-7.19 0.61 5.46 4.73-1.64 7.030z';
paths[AppIconType.Stop] = 'M6 6h12v12h-12v-12z';
paths[AppIconType.TickInCirle] =
  'M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75-4.365-9.75-9.75-9.75zM17.444 9.23l-7.115 7.114c-0.178 0.178-0.467 0.178-0.646 0l-0.143-0.143-0.001 0.001-3.914-3.942c-0.179-0.178-0.179-0.468 0-0.646l0.97-0.97c0.178-0.178 0.468-0.178 0.646 0l2.767 2.787 5.817-5.817c0.178-0.178 0.468-0.178 0.646 0l0.97 0.97c0.179 0.178 0.179 0.467 0.001 0.647z';
paths[AppIconType.ThumbsDown] =
  'M3.429 7.714c0 0.469-0.388 0.857-0.857 0.857-0.482 0-0.857-0.388-0.857-0.857 0-0.482 0.375-0.857 0.857-0.857 0.469 0 0.857 0.375 0.857 0.857zM5.571 14.571v-8.571c0-0.469-0.388-0.857-0.857-0.857h-3.857c-0.469 0-0.857 0.388-0.857 0.857v8.571c0 0.469 0.388 0.857 0.857 0.857h3.857c0.469 0 0.857-0.388 0.857-0.857zM20.692 12.576c0.455 0.509 0.737 1.286 0.737 1.996-0.013 1.393-1.179 2.571-2.571 2.571h-3.71c0.107 0.429 0.241 0.563 0.388 0.857 0.348 0.696 0.75 1.473 0.75 2.571 0 1.031 0 3.429-3 3.429-0.228 0-0.442-0.094-0.603-0.254-0.576-0.563-0.737-1.393-0.884-2.183-0.161-0.777-0.308-1.58-0.83-2.103-0.415-0.415-0.871-0.991-1.353-1.607-0.589-0.777-1.875-2.371-2.371-2.411-0.442-0.040-0.817-0.415-0.817-0.857v-8.585c0-0.469 0.402-0.844 0.857-0.857 0.469-0.013 1.272-0.295 2.116-0.589 1.446-0.496 3.254-1.125 5.17-1.125h1.728c1.179 0.013 2.063 0.362 2.638 1.045 0.509 0.603 0.737 1.42 0.656 2.424 0.335 0.321 0.589 0.763 0.723 1.259 0.147 0.536 0.147 1.071 0 1.567 0.402 0.536 0.603 1.165 0.576 1.835 0 0.188-0.054 0.589-0.201 1.018z';
paths[AppIconType.ThumbsUp] =
  'M3.429 18c0-0.469-0.388-0.857-0.857-0.857-0.482 0-0.857 0.388-0.857 0.857 0 0.482 0.375 0.857 0.857 0.857 0.469 0 0.857-0.375 0.857-0.857zM5.571 11.143v8.571c0 0.469-0.388 0.857-0.857 0.857h-3.857c-0.469 0-0.857-0.388-0.857-0.857v-8.571c0-0.469 0.388-0.857 0.857-0.857h3.857c0.469 0 0.857 0.388 0.857 0.857zM21.429 11.143c0 0.71-0.281 1.473-0.737 1.996 0.147 0.429 0.201 0.83 0.201 1.018 0.027 0.67-0.174 1.299-0.576 1.835 0.147 0.496 0.147 1.031 0 1.567-0.134 0.496-0.388 0.938-0.723 1.259 0.080 1.004-0.147 1.821-0.656 2.424-0.576 0.683-1.46 1.031-2.638 1.045h-1.728c-1.915 0-3.723-0.629-5.17-1.125-0.844-0.295-1.647-0.576-2.116-0.589-0.455-0.013-0.857-0.388-0.857-0.857v-8.585c0-0.442 0.375-0.817 0.817-0.857 0.496-0.040 1.781-1.634 2.371-2.411 0.482-0.616 0.938-1.192 1.353-1.607 0.522-0.522 0.67-1.326 0.83-2.103 0.147-0.79 0.308-1.621 0.884-2.183 0.161-0.161 0.375-0.254 0.603-0.254 3 0 3 2.397 3 3.429 0 1.098-0.388 1.875-0.75 2.571-0.147 0.295-0.281 0.429-0.388 0.857h3.71c1.393 0 2.571 1.179 2.571 2.571z';
paths[AppIconType.ToggleCheckBoxDeselect] =
  'M21.333 2.667v18.667h-18.667v-18.667h18.667zM21.333 0h-18.667c-1.467 0-2.667 1.2-2.667 2.667v18.667c0 1.467 1.2 2.667 2.667 2.667h18.667c1.467 0 2.667-1.2 2.667-2.667v-18.667c0-1.467-1.2-2.667-2.667-2.667z';
paths[AppIconType.ToggleCheckBoxNeutral] =
  'M21.333 0h-18.667c-1.467 0-2.667 1.2-2.667 2.667v18.667c0 1.467 1.2 2.667 2.667 2.667h18.667c1.467 0 2.667-1.2 2.667-2.667v-18.667c0-1.467-1.2-2.667-2.667-2.667zM18.667 13.333h-13.333v-2.667h13.333v2.667z';
paths[AppIconType.ToggleCheckBoxSelect] =
  'M21.333 0h-18.667c-1.48 0-2.667 1.2-2.667 2.667v18.667c0 1.467 1.187 2.667 2.667 2.667h18.667c1.48 0 2.667-1.2 2.667-2.667v-18.667c0-1.467-1.187-2.667-2.667-2.667zM9.333 18.667l-6.667-6.667 1.88-1.88 4.787 4.773 10.12-10.12 1.88 1.893-12 12z';
paths[AppIconType.ToggleCheckBoxWarning] =
  'M21.333 0c1.467 0 2.667 1.2 2.667 2.667v18.667c0 1.467-1.2 2.667-2.667 2.667h-18.667c-1.467 0-2.667-1.2-2.667-2.667v-18.667c0-1.467 1.2-2.667 2.667-2.667h18.667zM11.998 20c0.982 0 1.778-0.796 1.778-1.778s-0.796-1.778-1.778-1.778c-0.982 0-1.778 0.796-1.778 1.778s0.796 1.778 1.778 1.778zM10.22 4v10.667h3.556v-10.667h-3.556z';
paths[AppIconType.Trash] =
  'M4.313 21.312v-16h16v16c0 0.75-0.26 1.385-0.781 1.906s-1.135 0.781-1.844 0.781h-10.688c-0.75 0-1.385-0.26-1.906-0.781s-0.781-1.156-0.781-1.906zM21.687 1.313v2.688h-18.687v-2.688h4.688l1.313-1.313h6.687l1.313 1.313h4.688z';
paths[AppIconType.Unmerge] =
  'M14 4l2.29 2.29l-2.88 2.88l1.42 1.42l2.88-2.88L20 10V4zm-4 0H4v6l2.29-2.29l4.71 4.7V20h2v-8.41l-5.29-5.3z';
paths[AppIconType.UserCard] =
  'M13.714 16.848c0 1.152-0.763 2.009-1.714 2.009h-6.857c-0.951 0-1.714-0.857-1.714-2.009 0-2.089 0.522-4.406 2.625-4.406 0.656 0.616 1.54 1.004 2.518 1.004s1.862-0.388 2.518-1.004c2.103 0 2.625 2.317 2.625 4.406zM11.652 9.897c0 1.688-1.379 3.040-3.080 3.040s-3.080-1.353-3.080-3.040c0-1.674 1.379-3.040 3.080-3.040s3.080 1.366 3.080 3.040zM15.429 21.857v-18.429h-13.714v18.429c0 0.228 0.201 0.429 0.429 0.429h12.857c0.228 0 0.429-0.201 0.429-0.429zM17.143 2.143v19.714c0 1.179-0.964 2.143-2.143 2.143h-12.857c-1.179 0-2.143-0.964-2.143-2.143v-19.714c0-1.179 0.964-2.143 2.143-2.143h4.714v1.286c0 0.241 0.188 0.429 0.429 0.429h2.571c0.241 0 0.429-0.188 0.429-0.429v-1.286h4.714c1.179 0 2.143 0.964 2.143 2.143z';
paths[AppIconType.Uplaod] = 'M15.6 12v7.2h-7.2v-7.2h-6l9.6-9.6 9.6 9.6h-6zM0 21.6h24v2.4h-24v-2.4z';
paths[AppIconType.Water] =
  'M12.5 24C7.806 24 4 20.208 4 15.53 4 9.881 12.5.352 12.5.352S21 9.883 21 15.529C21 20.208 17.194 24 12.5 24z';
paths[AppIconType.ThumbsUpBorderIcon] =
  'M3.984 9q0.422 0 0.727 0.281t0.305 0.703v9q0 0.422-0.305 0.727t-0.727 0.305h-1.969v-11.016h1.969zM14.016 2.016l0.422 0.469q0.563 0.563 0.891 1.219 0.141 0.328 0.094 0.656l-0.938 4.641h5.531q0.797 0 1.383 0.609t0.586 1.406v1.078q0 0.422-0.141 0.797l-2.672 6.141q-0.609 0.984-1.734 0.984h-8.109q-0.984 0-1.664-0.68t-0.68-1.664v-7.828q0-0.844 0.609-1.453zM13.125 5.719l-4.125 4.078v7.875q0 0.328 0.328 0.328h8.109l2.578-5.906v-1.078h-5.531q-0.891 0-1.523-0.727t-0.398-1.664z';
paths[AppIconType.DollarCircle] =
  'M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M12,20c-4.41,0-8-3.59-8-8c0-4.41,3.59-8,8-8 s8,3.59,8,8C20,16.41,16.41,20,12,20z M12.89,11.1c-1.78-0.59-2.64-0.96-2.64-1.9c0-1.02,1.11-1.39,1.81-1.39 c1.31,0,1.79,0.99,1.9,1.34l1.58-0.67c-0.15-0.44-0.82-1.91-2.66-2.23V5h-1.75v1.26c-2.6,0.56-2.62,2.85-2.62,2.96 c0,2.27,2.25,2.91,3.35,3.31c1.58,0.56,2.28,1.07,2.28,2.03c0,1.13-1.05,1.61-1.98,1.61c-1.82,0-2.34-1.87-2.4-2.09L8.1,14.75 c0.63,2.19,2.28,2.78,3.02,2.96V19h1.75v-1.24c0.52-0.09,3.02-0.59,3.02-3.22C15.9,13.15,15.29,11.93,12.89,11.1z';
paths[AppIconType.ApplicationPending] =
  'M10.274 16.8V13.678H12.444C14.488 13.678 15.804 12.138 15.804 10.332C15.804 8.54 14.488 7 12.444 7H9V16.8H10.274ZM12.444 12.516H10.274V8.148H12.444C13.718 8.148 14.53 9.156 14.53 10.332C14.53 11.522 13.718 12.516 12.444 12.516Z';
paths[AppIconType.ApplicationLA] =
  'M11.48 16.8V15.652H8.27399V7H7V16.8H11.48ZM13.072 16.8L13.576 15.302H17.552L18.07 16.8H19.414L16.026 7H15.102L11.728 16.8H13.072ZM17.16 14.14H13.968L15.578 9.086L17.16 14.14Z';
paths[AppIconType.ApplicationPA] =
  'M6.27399 16.8V13.678H8.444C10.488 13.678 11.804 12.138 11.804 10.332C11.804 8.54 10.488 7 8.444 7H5V16.8H6.27399ZM8.444 12.516H6.27399V8.148H8.444C9.718 8.148 10.53 9.156 10.53 10.332C10.53 11.522 9.718 12.516 8.444 12.516ZM12.766 16.8L13.27 15.302H17.246L17.764 16.8H19.108L15.72 7H14.796L11.422 16.8H12.766ZM16.854 14.14H13.662L15.272 9.086L16.854 14.14Z';
paths[AppIconType.ApplicationSL] =
  'M9.42999 17.248C11.25 17.248 12.706 16.17 12.706 14.336C12.706 12.39 11.054 11.83 9.444 11.312C8.492 11.004 7.62399 10.668 7.62399 9.646C7.62399 8.708 8.35199 8.148 9.37399 8.148C10.508 8.148 11.18 8.82 11.208 9.996H12.482C12.468 8.19 11.194 7 9.36 7C7.708 7 6.34999 7.966 6.34999 9.73C6.34999 11.592 7.87599 12.124 9.56999 12.656C10.466 12.936 11.432 13.272 11.432 14.42C11.432 15.47 10.62 16.1 9.48599 16.1C8.15599 16.1 7.274 15.246 7.274 13.902H6C6 15.988 7.46999 17.248 9.42999 17.248ZM18.736 17.024V15.876H15.53V7.224H14.256V17.024H18.736Z';
paths[AppIconType.Affordability] =
  'M21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41 0-.55-.23-1.06-.59-1.42zM5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4 7 4.67 7 5.5 6.33 7 5.5 7z';
paths[AppIconType.PermContactCalender] =
  'M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H6v-1c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1z';
paths[AppIconType.ExpiryDate] =
  'M9.31 17l2.44-2.44L14.19 17l1.06-1.06-2.44-2.44 2.44-2.44L14.19 10l-2.44 2.44L9.31 10l-1.06 1.06 2.44 2.44-2.44 2.44L9.31 17zM19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11z';

export enum AppIconSize {
  Small = 12,
  Smaller = 14,
  Normal = 18,
  Larger = 24,
  Large = 30,
  Size20 = 20,
  ExtraLarge = 58,
}

export interface AppIconProps {
  readonly type: AppIconType;
  readonly size: AppIconSize;
  readonly color?: string | undefined; // default: black
  readonly style?: React.CSSProperties | undefined;
  readonly className?: string | undefined;
  readonly showToolTip?: boolean | undefined;
}

/// <summary>
/// Creating an SVG Icon System with React
/// https://css-tricks.com/creating-svg-icon-system-react/
/// </summary>
export class AppIcon extends React.Component<AppIconProps, {}> {
  public constructor(props: AppIconProps) {
    super(props);
  }

  public render(): JSX.Element | null {
    const { size, type, color, style, className, showToolTip } = {
      ...this.props,
    };

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        aria-labelledby={type}
        style={style}
        className={className}
      >
        {showToolTip && <title>{type}</title>}
        <path d={paths[type]} fill={color} />
      </svg>
    );
  }
}
