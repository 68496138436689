import React, { FC } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
  withStyles,
} from '@material-ui/core';
import {
  UserConfirmationDialogStyle,
  UserConfirmationDialogComponentStyle,
} from './UserConfirmationDialogStyles';
import Cancel from '../../assets/images/cancelIcon.svg';

interface UserConfirmationDialogProps extends UserConfirmationDialogComponentStyle {
  title: string;
  content: JSX.Element;
  primaryButtonTitle?: string | undefined | null;
  secondaryButtonTitle?: string | undefined | null;
  isOpen: boolean;
  onClose: () => void;
  onPrimaryClick: () => void;
  onSecondaryClick?: () => void;
}

const UserConfirmationDialog: FC<UserConfirmationDialogProps> = ({
  classes,
  isOpen,
  title,
  content,
  primaryButtonTitle,
  secondaryButtonTitle,
  onClose,
  onPrimaryClick,
  onSecondaryClick,
}) => {
  return (
    <Dialog open={isOpen} onClose={() => onClose()}>
      <DialogActions className={classes.cancelButtonContainer}>
        <IconButton onClick={() => onClose()}>
          <img src={Cancel} alt="Close Dialog" />
        </IconButton>
      </DialogActions>
      {title && (
        <DialogTitle className={classes.titleContainerStyle}>
          <Typography variant="h6" className={classes.titleStyle}>
            {title}
          </Typography>
        </DialogTitle>
      )}
      {content && (
        <DialogContent>
          <Typography variant="body2" className={classes.contentStyle}>
            {content}
          </Typography>
        </DialogContent>
      )}
      {primaryButtonTitle && !secondaryButtonTitle && (
        <DialogActions className={classes.buttonContainer}>
          <Button className={classes.buttonStyle} onClick={() => onPrimaryClick()} color="primary">
            {primaryButtonTitle}
          </Button>
        </DialogActions>
      )}
      {primaryButtonTitle && secondaryButtonTitle && onSecondaryClick && (
        <DialogActions className={classes.buttonsPosition}>
          {secondaryButtonTitle && (
            <Button
              className={classes.buttonStyle}
              onClick={() => onSecondaryClick()}
              color="primary"
            >
              {secondaryButtonTitle}
            </Button>
          )}
          {primaryButtonTitle && (
            <Button className={classes.buttonStyle} onClick={() => onPrimaryClick()}>
              {primaryButtonTitle}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default withStyles(UserConfirmationDialogStyle)(UserConfirmationDialog);
