import * as React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import { ErrorPageStyle, ErrorPageStyles } from './ErrorPageStyles';
import AgencyBackground from '../agencyBackground/AgencyBackground';
import img404 from '../../assets/images/404.png';
import * as LABEL from './ErrorPageConstants';

type OwnProps = ErrorPageStyles;

export function ErrorPage(props: OwnProps): React.ReactElement {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <AgencyBackground />
      <img src={img404} alt="" />
      <br />
      <Typography variant="h6" className={classes.errorMessage}>
        {LABEL.INCORRECT_LINK}
      </Typography>
    </div>
  );
}

export default withStyles(ErrorPageStyle)(ErrorPage);
