import { AppIcon, AppIconSize, AppIconType } from '../AppIcon/AppIcon';
import {
  Box,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Typography,
} from '@material-ui/core';

import { ExpandMore } from '@material-ui/icons';
import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { color } from '../../lib/Theme/Theme';
import useStyles from '../blanketForm/BlanketFormStyles';

const FormLoader = () => {
  const classes = useStyles();
  return (
    <>
      {Array.from({ length: 3 }, () => (
        <div className={classes.card}>
          <ExpansionPanel className={classes.expansionPanelRoot} defaultExpanded>
            <ExpansionPanelSummary className={classes.expansionPanelSummary}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Skeleton variant="rect" />
                </Grid>
              </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container spacing={1} style={{ padding: '1rem 2rem' }}>
                <Grid item xs={12}>
                  <Skeleton variant="rect" />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rect" />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rect" />
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      ))}
    </>
  );
};

export default FormLoader;
