/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createStyles, WithStyles } from '@material-ui/core';
import { color } from '../../../../lib/Theme/Theme';
import { SIZES } from '../../../../lib/Constants';
import { globalStyles } from '../../../../lib/Theme/GlobalStyles';

export const styles = createStyles({
  secondaryTile: globalStyles.secondaryTile as any,
  titleHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: color.secondary,
    alignItems: 'center',
    padding: '25px 30px',
  },
  title: {
    color: color.sortedNavy,
    fontSize: 28,
    marginBottom: '10px',
  },
  subtitle: {
    fontWeight: 'lighter',
    opacity: 0.87,
  },

  contentContainer: {
    padding: '30px',
    backgroundColor: color.light,
  },
  inputContainerStyle: {
    width: '100%',
    paddingBottom: '8px',
  },
  inputStyle: {
    paddingTop: '5px',
    paddingBottom: '10px',
    color: color.secondary600,
  },
  errorContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '15px',
  },
  errorText: {
    color: color.warning,
    fontWeight: 300,
  },
  resendButton: {
    backgroundColor: color.success,
    borderRadius: '6px',
    padding: '8px 25px',
    cursor: 'pointer',
    color: color.light,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '30px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      flexDirection: 'column-reverse',
      width: '100%',
    },
  },

  forgotPassword: {
    color: color.sortedNavy,
    fontWeight: 300,
    width: 'max-content',
    cursor: 'pointer',
  },

  loginButton: {
    backgroundColor: color.teal,
    fontSize: 16,
    fontWeight: 300,
    border: 'none',
    borderRadius: '6px',
    padding: '15px 70px',
    color: color.light,
    cursor: 'pointer',
    textAlign: 'center',
    [`@media (max-width: ${SIZES.md}px)`]: {
      flex: 1,
      marginTop: '15px',
      padding: '15px',
      width: '100%',
    },
  },
  secondaryActionContainer: {
    display: 'flex',
    justifyContent: 'center',
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: '30px',
    },
  },
  secondaryActionText: {
    fontWeight: 100,
  },
  secondaryAction: {
    marginLeft: '8px',
    color: color.sortedNavy,
    cursor: 'pointer',
  },
  errordiv: {
    minHeight: '1rem',
  },
});

export interface LoginFormStyleProps extends WithStyles<typeof styles> {}
