import { StoreAction } from '../Store/StoreHelper';

export enum CommonActionTypes {
  AddBannerUrl = 'ADD_BANNER_URL',
}
export type CommonActionPayload = string | Error | null;

export type CommonAction = StoreAction<CommonActionTypes, CommonActionPayload>;
// export type SuccessToastAction = StoreAction<SuccessToastActionTypes, JSX.Element | string | null>;

export class CommonActions {
  public static addAgencyBannerUrl(data: string | undefined): CommonAction {
    return { type: CommonActionTypes.AddBannerUrl, data };
  }
}
