import { MultipartFileData, MultipartFormData } from '../MultipartFormData.data';

import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { Content } from '../../../helper/Content/ContentType.data';
import { FileUploadResponse } from '../../Types/fileUpload/FileUpload.data';
import ServiceType from '../ServiceType.data';

export class FileService {
  private readonly service: ApiServiceBase = new ApiService(ServiceType.Ownership);

  public postFile(payload: Content): Promise<FileUploadResponse[]> | FileUploadResponse[] {
    const data: MultipartFormData[] = [{ name: 'files', content: payload }];
    return this.service.postMultipartData({ route: ['upload'] }, data);
  }

  public deleteFile(payload: string): Promise<void> | void {
    return this.service.delete({
      route: ['delete'],
      query: { key: payload },
    });
  }
}

const fileService = new FileService();
export default fileService;
