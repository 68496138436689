import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import './App.css';
import LogRocket from 'logrocket';
import { AppSettings } from './AppSettings';
import { apiSanitizer } from './lib/helper/LogRocketHelper';
import { storage, STORAGE_CONSTANTS } from './lib/Redux/Services/LocalStorage';
import Layout from './components/layout/Layout';
import LoadingIndicator from './components/loadingIndicator/LoadingIndicator';
import SuccessToast from './components/SuccessToast/SuccessToast';

LogRocket.init(AppSettings.logRocket.group, {
  network: {
    requestSanitizer: (request) => {
      const shouldExclude = apiSanitizer(request.url);
      return shouldExclude ? null : request;
    },
  },
});

const userEmail = storage.getItem(STORAGE_CONSTANTS.email);
const userId = storage.getItem(STORAGE_CONSTANTS.userId);
if (userEmail && userId) {
  LogRocket.identify(userEmail, {
    email: userEmail,
    userId,
  });
}
interface AppProps extends RouteComponentProps {
  refId: string | undefined;
  propertyDetails: undefined;
}

const App: FC<AppProps> = (props) => {
  return (
    <>
      <Layout {...props} />
      <LoadingIndicator />
      <SuccessToast />
    </>
  );
};

export default App;
