import { Button, Typography } from '@material-ui/core';
import {
  OwnershipDetails,
  ReferralProperty,
  ReferralPropertyResponse,
  userType,
} from '../../lib/Redux/Types/ownershipForm/OwnershipFormResponse.data';
import React, { FC, useEffect, useState } from 'react';

import BlanketForm from '../blanketForm/BlanketForm';
import { BlanketForm as Form } from '../blanketForm/BlanketFormConstants';
import { Formik } from 'formik';
import useStyles from './OwnershipFormStyles';

interface PropertiesFormProps {
  property?: ReferralPropertyResponse | null;
  onSubmit: (properties: ReferralProperty) => void;
  onNext: () => void;
  ownershipDetails: OwnershipDetails;
  userType?: userType;
  formUrl: string;
  onBack: () => void;
  previewMode?: boolean;
}

const PropertiesForm: FC<PropertiesFormProps> = ({
  ownershipDetails,
  property: propertyResponse,
  onSubmit,
  onNext,
  userType,
  formUrl,
  onBack,
  previewMode,
}) => {
  const classes = useStyles();

  const [showSectionErrors, setShowSectionErrors] = useState(false);
  const [showAlert, setShowAlert] = useState(true);

  useEffect(() => {
    const listener = (e: BeforeUnloadEvent) => {
      const msg = showAlert ? 'You have unsaved changes' : null;
      e.returnValue = msg;
      return msg;
    };
    if (showAlert) {
      window.addEventListener('beforeunload', listener);
    } else {
      window.removeEventListener('beforeunload', listener);
    }
    return () => {
      window.removeEventListener('beforeunload', listener);
    };
  }, [showAlert]);

  const initialValues: { property: ReferralProperty | undefined } = {
    property: propertyResponse
      ? {
          ...propertyResponse.property,
          id: propertyResponse.id,
        }
      : undefined,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(data) => {
        onSubmit(data.property!);
      }}
      enableReinitialize
      validateOnChange={false}
    >
      {({ setFieldValue, values, submitForm, errors, dirty }) => {
        setShowAlert(dirty);
        const hasProperty = !!values.property;
        const hasError = !!Object.keys(errors).length;
        return (
          <>
            {(JSON.parse(formUrl) as Form[])
              .filter((form) => userType && form.canView?.includes(userType))
              .map((form) => {
                const readOnly =
                  !(userType && form.canEdit?.includes(userType)) ||
                  (ownershipDetails.status === 'Signed' && form.lockAfterSigning) ||
                  previewMode;
                return (
                  <BlanketForm
                    name={form.name}
                    heading={form.heading}
                    isCollapsible={form.isCollapsible}
                    fields={form.fields}
                    canBeMultiple={form.canBeMultiple}
                    countLabel={form.countLabel}
                    setFieldValue={setFieldValue}
                    values={values}
                    errors={errors}
                    min={form.min}
                    showSectionErrors={showSectionErrors}
                    readonly={readOnly}
                    lockCounter={
                      ownershipDetails.status === 'Signed' &&
                      form.canBeMultiple &&
                      form.lockAfterSigning
                    }
                    userType={userType}
                  />
                );
              })}
            {hasError && (
              <div className={classes.errorContainer}>
                <Typography variant="caption" color="error" align="right">
                  Please Enter all required Fields
                </Typography>
              </div>
            )}
            <div className={classes.buttonContainer}>
              <Button size="small" className={classes.button} color="secondary" onClick={onBack}>
                Back
              </Button>
              {!previewMode && (
                <Button
                  size="small"
                  className={classes.button}
                  color="primary"
                  disabled={!hasProperty}
                  onClick={() => {
                    setShowSectionErrors(true);
                    submitForm();
                  }}
                >
                  {dirty || !previewMode ? 'Submit & Next' : 'Next'}
                </Button>
              )}
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default PropertiesForm;
