import { ErrorMessage, Field, FieldProps, useField } from 'formik';
import { FormControl, FormHelperText } from '@material-ui/core';
import React, { FC } from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { SelectItemDef } from '../RenderdataTypes.data';
import useStyles from './RenderRadioButtonStyles';

interface RenderRadioButtonProps {
  options: SelectItemDef[];
  name: string;
  label: string;
  setFieldValue: (fieldName: string, fieldValue: string | boolean) => void;
  variant?: 'filled' | 'outlined' | 'standard';
  containerClass?: string;
  disabled?: boolean;
  fieldType?: string;
  validate?: (value: any) => void;
  showError?: boolean;
}

const RenderRadioButton: FC<RenderRadioButtonProps> = ({
  name,
  options,
  label,
  variant,
  containerClass,
  setFieldValue,
  disabled,
  fieldType,
  validate,
  showError
}) => {
  const [field, meta] = useField(name);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(
      name,
      fieldType === 'boolean'
        ? (event.target as HTMLInputElement).value === 'true'
        : (event.target as HTMLInputElement).value,
    );
  };
  const showerror = !!meta.error && (meta.touched || showError)

  const classes = useStyles();

  return (
    <div className={containerClass || ''}>
      <FormControl fullWidth variant={variant || 'outlined'} error={showerror}>
        <>
          {label && (
            <FormLabel
              classes={{
                root: classes.label,
              }}
            >
              {label}
            </FormLabel>
          )}
          <Field name={name} validate={validate}>
            {({ field }: FieldProps) => {
              const { value } = field;
              return (
                <RadioGroup
                  name={name}
                  value={value === null || value === undefined ? '' : value}
                  onChange={handleChange}
                  classes={{ root: classes.radioGroup }}
                >
                  {options.map((option, index) => (
                    <FormControlLabel
                      disabled={!!disabled}
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                      className={classes.radioItem}
                    />
                  ))}
                </RadioGroup>
              );
            }}
          </Field>
          {name && (
            <FormHelperText className={classes.errorMessageStyles}>
              <ErrorMessage name={name} />
            </FormHelperText>
          )}
        </>
      </FormControl>
    </div>
  );
};

export default RenderRadioButton;
