import { color } from '../../lib/Theme/Theme';

export interface AddressPickerStyles {
  readonly root: any;
  readonly searchIcon: any;
  readonly inputField: any;
}

export const addressPickerStyles: AddressPickerStyles = {
  root: {
    backgroundColor: color.light,
  },
  searchIcon: {
    margin: 10,
  },
  inputField: {
    height: 50,
  },
};
