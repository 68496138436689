import { BlanketForm, BlanketFormFieldSize } from './BlanketFormConstants';

import { GridSize } from '@material-ui/core';

export const getGridProps = (size?: BlanketFormFieldSize): { xs: GridSize; sm: GridSize } => {
  switch (size) {
    case 'XXS':
      return { xs: 6, sm: 2 };
    case 'XS':
      return { xs: 6, sm: 3 };
    case 'S':
      return { xs: 6, sm: 4 };
    case 'M':
      return { xs: 12, sm: 5 };
    case 'L':
      return { xs: 12, sm: 6 };
    case 'XL':
      return { xs: 12, sm: 9 };
    case 'XXL':
    default:
      return { xs: 12, sm: 12 };
  }
};

export const getInitialValues = (forms: BlanketForm[]) => {
  const values = {};
  forms.forEach((form) => {
    const formValues = {};
    form.fields.forEach((field) => {
      formValues[field.name] = field.initialValue;
    });
    if (form.canBeMultiple) {
      values[form.name] = [];

      if (form.min) {
        Array.from(Array(form.min)).forEach(() => {
          values[form.name].push(formValues);
        });
      }
    } else {
      values[form.name] = formValues;
    }
  });

  return values;
};
