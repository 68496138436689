import { Button, Typography } from '@material-ui/core';
import {
  OwnersInstructions,
  userType,
} from '../../lib/Redux/Types/ownershipForm/OwnershipFormResponse.data';
import React, { FC, useEffect, useState } from 'react';

import BlanketForm from '../blanketForm/BlanketForm';
import { BlanketForm as Form } from '../blanketForm/BlanketFormConstants';
import { Formik } from 'formik';
import useStyles from './OwnershipFormStyles';

interface OwnersInstructionsFormProps {
  ownersInstructions: OwnersInstructions;
  formUrl: string;
  userType: userType;
  onSubmit: (ownersInstructions: OwnersInstructions) => void;
  previewMode?: boolean;
  onNext: () => void;
  submitting: boolean;
}

const OwnersInstructionsForm: FC<OwnersInstructionsFormProps> = ({
  ownersInstructions,
  formUrl,
  userType,
  onSubmit,
  previewMode,
  onNext,
  submitting,
}) => {
  const [showSectionErrors, setShowSectionErrors] = useState(false);
  const [showAlert, setShowAlert] = useState(true);

  useEffect(() => {
    const listener = (e: BeforeUnloadEvent) => {
      const msg = showAlert ? 'You have unsaved changes' : null;
      e.returnValue = msg;
      return msg;
    };
    if (showAlert) {
      window.addEventListener('beforeunload', listener);
    } else {
      window.removeEventListener('beforeunload', listener);
    }
    return () => {
      window.removeEventListener('beforeunload', listener);
    };
  }, [showAlert]);

  const forms = (JSON.parse(formUrl) as Form[]).filter(
    (form) => userType && form.canView?.includes(userType) && !form.hideOnPrepare,
  );

  const classes = useStyles();

  return (
    <Formik
      initialValues={ownersInstructions}
      onSubmit={(data) => {
        onSubmit(data);
      }}
      enableReinitialize
    >
      {({ setFieldValue, values, submitForm, errors, dirty }) => {
        setShowAlert(dirty);
        const hasError = !!Object.keys(errors).length;
        const setFieldValue2 = (field: string, value: string) => {
          setFieldValue(field, value);
        };
        return (
          <>
            {forms.map((form) => {
              const readOnly = !(userType && form.canEdit?.includes(userType)) || previewMode;
              return (
                <BlanketForm
                  name={form.name}
                  heading={form.heading}
                  isCollapsible={form.isCollapsible}
                  fields={form.fields}
                  canBeMultiple={form.canBeMultiple}
                  countLabel={form.countLabel}
                  setFieldValue={setFieldValue2}
                  min={form.min}
                  values={values}
                  errors={errors}
                  showSectionErrors={showSectionErrors}
                  readonly={readOnly}
                  userType={userType}
                />
              );
            })}
            {hasError && (
              <div className={classes.errorContainer}>
                <Typography variant="caption" color="error" align="right">
                  Please enter all required fields
                </Typography>
              </div>
            )}
            <div className={classes.buttonContainer}>
              {!previewMode && (
                <Button
                  size="small"
                  className={classes.button}
                  color="primary"
                  onClick={() => {
                    setShowSectionErrors(true);
                    submitForm();
                  }}
                  disabled={submitting}
                >
                  {submitting ? 'Submitting...' : 'Submit & Next'}
                </Button>
              )}
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default OwnersInstructionsForm;
