import { WithStyles, createStyles } from '@material-ui/core';
import { color } from '../../lib/Theme/Theme';

export const UserConfirmationDialogStyle = createStyles({
  cancelButtonContainer: {
    marginBottom: '0px',
  },
  titleContainerStyle: {
    paddingTop: '0px',
  },
  titleStyle: {
    textAlign: 'center',
    lineHeight: 1.29,
  },
  contentStyle: {
    textAlign: 'center',
    color: color.secondary500,
  },
  buttonContainer: {
    justifyContent: 'center',
  },
  buttonStyle: {
    height: '36px',
    width: '200px',
    marginBottom: '30px',
  },
  buttonsPosition: {
    justifyContent: 'center',
  },
});

export interface UserConfirmationDialogComponentStyle
  extends WithStyles<typeof UserConfirmationDialogStyle> {}
