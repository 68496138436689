import { ReferralsAction, ReferralsActionTypes } from '../Actions/ReferralsActions';
import ReferralState from '../State/ReferralState';
import {
  GetFailureDetails,
  ReferralDetailsFormPayload,
  ReferralListResponse,
} from '../Types/referrals/ReferralType.data';

export const initialState: ReferralState = {
  refId: null,
  id: undefined,
  error: '',
  loading: false,
  myAccountFlag: false,
  referralData: undefined,
  referralDetails: undefined,
};

export const referralReducer: (state: ReferralState, action: ReferralsAction) => ReferralState = (
  state = initialState,
  action: ReferralsAction,
) => {
  switch (action.type) {
    case ReferralsActionTypes.MY_ACCOUNT_FLAG:
      return {
        ...state,
        myAccountFlag: action.data as boolean,
      };
    case ReferralsActionTypes.ADD_REFERRAL:
      return {
        ...state,
        referralData: action.data as ReferralDetailsFormPayload,
      };
    case ReferralsActionTypes.SAVE_REFERRALS_REQUEST:
      return {
        ...state,
        loading: true,
        referralData: action.data as ReferralDetailsFormPayload,
      };
    case ReferralsActionTypes.SAVE_REFERRALS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ReferralsActionTypes.SAVE_REFERRALS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data as GetFailureDetails,
      };

    case ReferralsActionTypes.FETCH_REFERRAL_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        referralDetails: action.data as ReferralListResponse,
      };
    }
    case ReferralsActionTypes.FETCH_REFERRAL_DETAILS_ERROR: {
      const data = action.data as GetFailureDetails;
      return {
        ...state,
        loading: false,
        error: data || 'Check the URL',
      };
    }
    default:
      return state;
  }
};
