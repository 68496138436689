/* eslint-disable max-lines */
import {
  Box,
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Typography,
} from '@material-ui/core';
import React, { FC, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { get } from 'lodash';
import moment from 'moment';
import { AppIcon, AppIconSize, AppIconType } from '../AppIcon/AppIcon';
import {
  BlanketFormField,
  BlanketFormFieldOption,
  BlanketFormFieldValidations,
  BlanketForm as IBlanketForm,
} from './BlanketFormConstants';
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import RenderAddressPicker from '../FormikComponents/RenderAddressPicker/RenderAddressPicker';
import RenderCheckbox from '../FormikComponents/RenderCheckbox/RenderCheckbox';
import RenderCheckboxGroup from '../FormikComponents/RenderCheckbox/RenderCheckboxGroup';
import RenderFee from '../FormikComponents/RenderFee/RenderFee';
import RenderFileInput from '../FormikComponents/RenderFileInput/RenderFileInput';
import RenderInput from '../FormikComponents/RenderInputField/RenderInputField';
import RenderMarketingFeeItems from '../FormikComponents/RenderCheckbox/RenderMarketingFeeItems';
import RenderPhoneInput from '../FormikComponents/RenderPhoneInput/RenderPhoneInput';
import RenderPicker from '../FormikComponents/RenderPicker/RenderPicker';
import RenderRadioButton from '../FormikComponents/RenderRadioButton/RenderRadioButton';
import RenderSelect from '../FormikComponents/RenderSelect/RenderSelect';
import { color } from '../../lib/Theme/Theme';
import { getGridProps } from './BlanketFormUtil';
import useStyles from './BlanketFormStyles';
import { userType } from '../../lib/Redux/Types/ownershipForm/OwnershipFormResponse.data';
import RenderHtmlContent from '../FormikComponents/RenderHtmlContent/RenderHtmlContent';
import RenderCheckboxGroup2 from '../FormikComponents/RenderCheckbox/RenderCheckboxGroup2';

interface BlanketFormProps extends IBlanketForm {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  values: any;
  errors: any;
  showSectionErrors?: boolean;
  lockCounter?: boolean;
  isSigned?: boolean;
  userType?: userType;
}

const BlanketForm: FC<BlanketFormProps> = ({
  name,
  heading,
  fields,
  isCollapsible,
  canBeMultiple,
  setFieldValue,
  values,
  errors,
  showSectionErrors,
  countLabel,
  min,
  readonly,
  lockCounter,
  isSigned,
  userType,
}) => {
  const classes = useStyles();

  const renderCheckboxOption = (
    option: BlanketFormFieldOption,
    name: string,
    disabled: boolean,
    validations?: BlanketFormFieldValidations,
  ) => {
    const gridProps = getGridProps(option.size);
    const { forceCheck, label, defaultChecked } = option;
    return (
      <Grid item xs={gridProps.xs} sm={gridProps.sm}>
        <RenderCheckbox
          forceCheck={forceCheck}
          name={name}
          label={label}
          color="primary"
          disabled={disabled}
          defaultChecked={defaultChecked}
          validate={userType && validations && validations.isRequired.includes(userType)}
        />
      </Grid>
    );
  };

  const renderField = (field: BlanketFormField, baseName: string, index: string) => {
    const {
      type,
      label,
      name,
      options,
      fields,
      props,
      lockAfterSigning,
      validations,
      initialValue,
      gst,
      defaultPercentFee,
      defaultCalculatedValue,
      fieldType,
      defaultDollerAmount,
      defaultGstAmount,
      defaultFeeType,
      currencyField,
      calculatedBasedOnYearRent,
    } = field;
    const disabled = !!readonly || (isSigned && lockAfterSigning);
    const fieldName = baseName ? (name ? `${baseName}.${name}` : baseName) : name;
    const secondFieldName =
      baseName && props?.secondFieldName
        ? `${baseName}.${props.secondFieldName}`
        : props?.secondFieldName;
    const validate = (value: any) =>
      userType &&
      validations &&
      validations.isRequired.includes(userType) &&
      !(value || value === false)
        ? 'Required'
        : undefined;

    switch (type) {
      case 'Select':
        return (
          <RenderSelect
            label={label}
            name={fieldName}
            options={options || []}
            disabled={disabled}
            validate={validate}
            defaultValue={(initialValue as string) || undefined}
          />
        );

      case 'PhoneNumber':
        return (
          <RenderPhoneInput
            containerClass={classes.phoneNumberInput}
            showError={showSectionErrors}
            name={fieldName}
            setFieldValue={setFieldValue}
            label={label}
            disabled={disabled}
            validate={validate}
          />
        );

      case 'Heading':
        return (
          <Typography
            variant={props?.headingVariant}
            color={props?.color}
            className={classes.heading}
          >
            <div dangerouslySetInnerHTML={{ __html: label.replace('##COUNT##', index) || '' }} />
          </Typography>
        );

      case 'Checkbox':
        return (
          <>
            <Typography variant="body1">{label}</Typography>
            <Grid container>
              {options?.map((option) =>
                renderCheckboxOption(
                  option,
                  `${baseName}.${option.value}`,
                  !!disabled,
                  validations,
                ),
              )}
            </Grid>
          </>
        );

      case 'CheckboxGroup2':
        return (
          <RenderCheckboxGroup2
            options={options}
            name={`${baseName}.${name}`}
            showError={showSectionErrors}
            disabled={!!disabled}
            validateForm={userType && validations && validations.isRequired.includes(userType)}
          />
        );

      case 'Upload':
        return (
          <RenderFileInput
            fieldName={fieldName}
            label={label}
            disabled={disabled}
            validate={validate}
          />
        );

      case 'CheckboxGroup':
        if (!options) return;
        return <RenderCheckboxGroup name={fieldName} options={options} readOnly={disabled} />;

      case 'MarketingFeesItem':
        if (!options) return;
        return (
          <Grid container spacing={2}>
            <RenderMarketingFeeItems
              name={fieldName}
              options={options}
              label={label}
              readOnly={disabled}
            />
          </Grid>
        );

      case 'Fee':
        return (
          <RenderFee
            fieldName={fieldName}
            label={label}
            disabled={disabled}
            validate={validate}
            showError={showSectionErrors}
            defaultGst={gst || 1}
            defaultPercentFee={defaultPercentFee || 0}
            defaultCalculatedValue={defaultCalculatedValue || ''}
            defaultDollerAmount={defaultDollerAmount}
            defaultGstAmount={defaultGstAmount}
            defaultFeeType={defaultFeeType}
            calculatedBasedOnYearRent={calculatedBasedOnYearRent}
          />
        );

      case 'Radio':
        return (
          <RenderRadioButton
            name={fieldName}
            options={options!}
            label={label}
            setFieldValue={setFieldValue}
            disabled={disabled}
            fieldType={fieldType}
            validate={validate}
            showError={showSectionErrors}
          />
        );

      case 'Number':
        return (
          <RenderInput
            name={fieldName}
            label={label}
            type="number"
            disabled={disabled}
            validate={validate}
            initialValue={initialValue}
          />
        );

      case 'Date':
        return (
          <RenderPicker
            diasbleFormControlMargins
            name={fieldName}
            label={label}
            UIFormat="DD-MM-YYYY"
            type="date"
            disabled={disabled}
            maxDate={moment()}
            validate={validate}
          />
        );

      case 'SubSection':
        return (
          <Box display="flex" flexDirection="column">
            <Typography variant="body2" color={props?.color} className={classes.subSectionHeader}>
              {label}
            </Typography>
            <Grid container spacing={2} className={classes.subSectionContainer}>
              {fields?.length && renderFormFields(fields, fieldName, index)}
            </Grid>
          </Box>
        );

      case 'Address':
        return (
          <RenderAddressPicker
            name={fieldName}
            label={label}
            disabled={disabled}
            showError={showSectionErrors}
            componentsFieldname={secondFieldName}
            initialComponents={secondFieldName ? get(values, secondFieldName) : undefined}
            setFieldValue={setFieldValue}
            validate={validate}
          />
        );
      case 'Html':
        return <RenderHtmlContent value={label} />;
      case 'Text':
      default:
        return (
          <RenderInput
            name={fieldName}
            label={label}
            disabled={disabled}
            showError={showSectionErrors}
            validate={validate}
            initialValue={initialValue}
            currencyField={currencyField}
          />
        );
    }
  };

  const renderFormFields = (fields: BlanketFormField[], baseName: string, index: string) => {
    return fields.map((field) => {
      const { size, dependencyQuestionName, dependencyAnswer, wrapperStyle } = field;
      const gridProps = getGridProps(size);

      if (dependencyQuestionName) {
        const dependencyValue = get(
          values,
          dependencyQuestionName.replace('#INDEX#', `${parseInt(index) - 1}`),
        );
        if (dependencyValue !== dependencyAnswer) {
          return null;
        }
      }

      return (
        <Grid style={{ ...wrapperStyle }} item xs={gridProps.xs} sm={gridProps.sm}>
          {renderField(field, baseName, index)}
        </Grid>
      );
    });
  };

  const renderForm = () => {
    return (
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        spacing={2}
        className={classes.contentContainer}
      >
        {canBeMultiple ? (
          <>
            {values[name]?.map((f: any, i: number) => {
              return (
                <Box display="flex" flexDirection="column" className={classes.subCard}>
                  <Box className={classes.subCardContentContainer}>
                    {!(readonly || lockCounter) && (
                      <Box className={classes.subSectionBox}>
                        <Button
                          className={classes.cardRemoveButton}
                          onClick={() => {
                            const newValues = values[name].filter(
                              (value: Object, idx: number) => idx !== i,
                            );
                            setFieldValue(name, newValues);
                          }}
                        >
                          REMOVE
                        </Button>
                      </Box>
                    )}
                    <Grid container spacing={2} className={classes.multiSectionGrid}>
                      {renderFormFields(fields, `${name}[${i}]`, `${i + 1}`)}
                    </Grid>
                  </Box>
                </Box>
              );
            })}
            {readonly || lockCounter ? (
              values[name].length ? (
                ''
              ) : (
                <Box display="flex" justifyContent="center" flex={100}>
                  <Typography className={classes.noItemsLabel}>Not applicable</Typography>
                </Box>
              )
            ) : (
              <Grid xs={12} sm={12}>
                <Button
                  className={classes.addNewButton}
                  onClick={() => {
                    setFieldValue(name, [...values[name], {}]);
                  }}
                >
                  {`+ ${values[name].length ? 'Add more' : countLabel}` || '+ Add new item'}
                </Button>
              </Grid>
            )}
            {min && values[name].length < min ? (
              <Box display="flex" justifyContent="flex-end" flex={100} style={{ marginTop: 12 }}>
                <Typography variant="caption" color="error" align="right">
                  {`Add at least ${min}`}
                </Typography>
              </Box>
            ) : null}
          </>
        ) : (
          renderFormFields(fields, name, '')
        )}
      </Grid>
    );
  };

  const showErrorMark = showSectionErrors && errors && errors[name];

  return isCollapsible ? (
    <div className={classes.card}>
      <ExpansionPanel className={classes.expansionPanelRoot} defaultExpanded>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.expansionPanelSummary}
        >
          <Grid container spacing={1}>
            <Grid item xs={11}>
              <Typography variant="body2">{heading}</Typography>
            </Grid>

            {showErrorMark && (
              <Grid item xs={1}>
                <AppIcon type={AppIconType.Error} size={AppIconSize.Normal} color={color.warning} />
              </Grid>
            )}
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>{renderForm()}</ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  ) : (
    <div className={classes.card}>
      <Box className={classes.cardContentContainer}>
        <Typography variant="body2" style={{ padding: '1rem' }}>
          {heading}
        </Typography>
        <Box className={classes.cardContent}>{renderForm()}</Box>
      </Box>
    </div>
  );
};

export default BlanketForm;
