/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createStyles, WithStyles } from '@material-ui/core';
import { color } from '../../../../lib/Theme/Theme';
import { globalStyles } from '../../../../lib/Theme/GlobalStyles';
import { SIZES } from '../../../../lib/Constants';

export const styles = createStyles({
  secondaryTile: globalStyles.secondaryTile as any,
  titleHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: color.secondary,
    alignItems: 'center',
    padding: '25px 30px',
  },
  title: {
    color: color.sortedNavy,
    fontSize: 28,
    marginBottom: '10px',
  },
  mailIcon: {
    width: '40px',
    height: '40px',
  },
  contentContainer: {
    padding: '30px',
    backgroundColor: color.light,
  },
  subtext: {
    color: color.offBlack,
    fontWeight: 100,
  },
  verifyEmailContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '30px',
    marginBottom: '30px',
    backgroundColor: color.backgroundBlue,
    padding: '25px',
    borderRadius: '8px',
  },
  tick: {
    height: '30px',
    width: '30px',
  },
  emailText: {
    color: color.offBlack,
    fontWeight: 300,
    marginRight: 'auto',
    marginLeft: '20px',
  },
  resendButton: {
    backgroundColor: color.light,
    border: `${color.borderGray} 2px solid`,
    borderRadius: '6px',
    padding: '8px 25px',
    textAlign: 'center',
    width: '80px',
    cursor: 'pointer',
  },
  sentButton: {
    backgroundColor: color.success,
    border: `${color.borderGray} 2px solid`,
    color: color.light,
    borderRadius: '6px',
    padding: '8px 25px',
    textAlign: 'center',
    width: '80px',
    cursor: 'pointer',
  },
  infoText: {
    fontWeight: 100,
    opacity: 0.4,
    marginBottom: '90px',
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '30px',
  },
  backButton: {
    position: 'relative',
    backgroundColor: color.light,
    border: `${color.gray} 2px solid`,
    fontSize: 16,
    fontWeight: 300,
    borderRadius: '6px',
    padding: '15px',
    width: '180px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [`@media (max-width: ${SIZES.md}px)`]: {
      flex: 1,
    },
  },
  backarrow: {
    position: 'absolute',
    left: '15px',
  },
});

export interface VerifyEmailStyles extends WithStyles<typeof styles> {}
