import { Box, Button, CircularProgress, IconButton, Typography } from '@material-ui/core';
import React, { FC } from 'react';

import { ContentType } from '../../../lib/helper/Content/ContentType.data';
import { Delete, Launch } from '@material-ui/icons';
import FileService from '../../../lib/Redux/Services/file/FileService';
import { FileUploadResponse } from '../../../lib/Redux/Types/fileUpload/FileUpload.data';
import RenderInput from '../RenderInputField/RenderInputField';
import useFetch from '../../../hooks/UseFetch';
import { useField } from 'formik';
import { useStyles } from './RenderFileInputStyles';

interface RenderFileInputProps {
  fieldName: string;
  label: string;
  disabled?: boolean;
  validate?: (value: any) => void;
}

const RenderFileInput: FC<RenderFileInputProps> = ({ fieldName, label, disabled, validate }) => {
  const {
    state: { loading },
    fetchData: postFileData,
  } = useFetch(FileService.postFile.bind(FileService), (data: FileUploadResponse[]) =>
    helpers.setValue(data[0]),
  );

  const {
    state: { loading: deleteLoading },
    fetchData: deleteFile,
  } = useFetch(FileService.deleteFile.bind(FileService), () => helpers.setValue(undefined));

  const setFile = (file: File) => {
    if (file)
      postFileData({
        contentRef: {
          contentType: ContentType[file.type],
          name: file.name,
        },
        data: file,
        size: file.size,
      });
  };

  const [field, meta, helpers] = useField<FileUploadResponse | undefined>(fieldName);

  const { value } = field;

  const classes = useStyles();

  return (
    <>
      {loading || deleteLoading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          padding="0.75rem"
          className={classes.bottomBorder}
          alignSelf="center"
          marginTop="1rem"
        >
          <CircularProgress size={20} />
        </Box>
      ) : value ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          padding="0.5rem"
          className={classes.bottomBorder}
          alignSelf="center"
          marginTop="0.75rem"
        >
          <Typography variant="subtitle1">{value.fileName}</Typography>
          {!disabled ? (
            <Button onClick={() => deleteFile(value.fileKey)}>
              <Delete />
            </Button>
          ) : value.fileLink ? (
            <IconButton
              aria-label="open-new-tab"
              onClick={() => window.open(value.fileLink, '_blank')}
            >
              <Launch color="primary" />
            </IconButton>
          ) : null}
        </Box>
      ) : (
        <RenderInput
          name={fieldName}
          label={label}
          type="file"
          disabled={disabled}
          validate={validate}
          shrink
          onChange={(e: any) => {
            setFile(e.target.files[0]);
          }}
        />
      )}
    </>
  );
};

export default RenderFileInput;
