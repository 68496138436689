/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import ServiceType from '../ServiceType.data';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import {
  GetOwnershipDetailsResponse,
  GetOwnershipPropertiesResponse,
  Owner,
  OwnershipDetailsRequest,
  ReferralProperty,
} from '../../Types/ownershipForm/OwnershipFormResponse.data';
import { Content } from '../../../helper/Content/ContentType.data';
import { MultipartFormData } from '../MultipartFormData.data';

const serviceType = ServiceType.Ownership;

export class OwnershipService {
  private readonly service: ApiServiceBase = new ApiService(serviceType);

  public getOwnershipDetail(
    token: string,
    prepareMode?: boolean,
  ): Promise<GetOwnershipDetailsResponse> | GetOwnershipDetailsResponse {
    return this.service.get<GetOwnershipDetailsResponse>({
      route: ['detail', token],
      query: { prepareMode },
    });
  }

  public putOwnershipDetail(
    token: string,
    ownershipDetail: OwnershipDetailsRequest,
    prepareMode?: boolean,
  ): Promise<void> | void {
    return this.service.put<void>(
      {
        route: [token],
        query: { prepareMode },
      },
      ownershipDetail,
    );
  }

  public getProperties(
    token: string,
    prepareMode?: boolean,
  ): Promise<GetOwnershipPropertiesResponse> | GetOwnershipPropertiesResponse {
    return this.service.get<GetOwnershipPropertiesResponse>({
      route: ['property', token],
      query: { prepareMode },
    });
  }

  public putProperty(
    token: string,
    property: ReferralProperty,
    prepareMode?: boolean,
  ): Promise<void> | void {
    return this.service.put<void>(
      {
        route: ['property', token],
        query: { prepareMode },
      },
      property,
    );
  }

  public postSignature(token: string, signatureContent: Content[]) {
    const route: string[] = ['signatures'];
    const signatures: MultipartFormData[] = [
      {
        name: 'token',
        content: token,
      },
    ];

    signatureContent.map((signature: Content) =>
      signatures.push({
        name: 'signatures',
        content: signature,
      }),
    );

    return this.service.postMultipartData({ route }, signatures);
  }

  public updateOwner(token: string, details: Owner, prepareMode?: boolean): Promise<void> | void {
    return this.service.put<void>(
      {
        route: ['owner', token],
        query: { prepareMode },
      },
      details,
    );
  }
}

const ownershipService = new OwnershipService();
export default ownershipService;
