/* eslint-disable import/prefer-default-export */
export const LABELS = {
  VERIFY_TITLE: 'Verify your email',
  VERIFY_SUBTEXT:
    'Thanks for creating your Sorted account. Before starting your application we need to verify your email to ensure the information you add to your profile will be protected. Follow the link in the email to verify your email address.',
  EMAIL_SENT: 'Email sent to ',
  RESEND: 'Resend',
  SENT: 'Sent',
  INFO_TEXT:
    "Didn't receive an email? Hit 'resend' and we'll send another. You can also check your junk folder or if you've entered the wrong email address, you can go back and change it.",
  BACK: 'BACK',
};
