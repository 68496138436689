import { StoreAction } from '../Store/StoreHelper';
import { ApplicationState } from '../Reducers';
import {
  ReferralDetailsFormPayload,
  ReferralListResponse,
  ReferralPayload,
  GetFailureDetails,
} from '../Types/referrals/ReferralType.data';

export enum ReferralsActionTypes {
  MY_ACCOUNT_FLAG = 'MY_ACCOUNT_FLAG',
  ADD_REFERRAL = 'ADD_REFERRAL',
  SAVE_REFERRALS_REQUEST = 'SAVE_REFERRALS_REQUEST',
  SAVE_REFERRALS_SUCCESS = 'SAVE_REFERRALS_SUCCESS',
  SAVE_REFERRALS_ERROR = 'SAVE_REFERRALS_ERROR',

  FETCH_REFERRAL_DETAILS_REQUEST = 'FETCH_REFERRAL_DETAILS_REQUEST',
  FETCH_REFERRAL_DETAILS_SUCCESS = 'FETCH_REFERRAL_DETAILS_SUCCESS',
  FETCH_REFERRAL_DETAILS_ERROR = 'FETCH_REFERRAL_DETAILS_ERROR',
}

export type ReferralsActionPayload =
  | string
  | ApplicationState
  | GetFailureDetails
  | Error
  | null
  | number
  | boolean
  | any
  | ReferralPayload
  | ReferralListResponse
  | ReferralPayload[];

export type ReferralsAction = StoreAction<ReferralsActionTypes, ReferralsActionPayload>;
export class ReferralsActions {
  public static setMyAccountFlag(data: boolean): ReferralsAction {
    return {
      type: ReferralsActionTypes.MY_ACCOUNT_FLAG,
      data,
    };
  }
  public static addReferral(data: ReferralDetailsFormPayload): ReferralsAction {
    return {
      type: ReferralsActionTypes.ADD_REFERRAL,
      data,
    };
  }
  public static saveReferrals(data: ReferralDetailsFormPayload): ReferralsAction {
    return {
      type: ReferralsActionTypes.SAVE_REFERRALS_REQUEST,
      data,
    };
  }

  public static saveReferralsSuccess(data: any): ReferralsAction {
    return {
      type: ReferralsActionTypes.SAVE_REFERRALS_SUCCESS,
      data,
    };
  }

  public static saveReferralsError(data: ReferralDetailsFormPayload): ReferralsAction {
    return {
      type: ReferralsActionTypes.SAVE_REFERRALS_ERROR,
      data,
    };
  }

  public static onGetReferralRequest(data: string): ReferralsAction {
    return { type: ReferralsActionTypes.FETCH_REFERRAL_DETAILS_REQUEST, data };
  }

  public static onGetReferralSuccess(data: ReferralListResponse): ReferralsAction {
    return { type: ReferralsActionTypes.FETCH_REFERRAL_DETAILS_SUCCESS, data };
  }
  public static onGetReferralError(data: ReferralPayload): ReferralsAction {
    return { type: ReferralsActionTypes.FETCH_REFERRAL_DETAILS_ERROR, data };
  }
}
