import { Grid } from '@material-ui/core';
import React, { FC } from 'react';

import { useStyles } from './RenderHtmlContentStyles';

interface RenderHtmlContentProps {
  value: string;
}

const RenderHtmlContent: FC<RenderHtmlContentProps> = ({ value }) => {
  const styles = useStyles();
  return (
    <Grid container spacing={1}>
      <div className={styles.contentContainer}>
        <div className={styles.scrollWell}>
          <div dangerouslySetInnerHTML={{ __html: value }} />
        </div>
      </div>
    </Grid>
  );
};

export default RenderHtmlContent;
