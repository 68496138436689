import { createStyles, WithStyles } from '@material-ui/core';
import { color } from '../../../../lib/Theme/Theme';

export const styles = createStyles({
  myAccountTabsContainer: {
    position: 'relative',
    margin: 'auto',
    top: '-38px',
    height: '75px',
    maxWidth: '1000px',
    borderRadius: '6px',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
    display: 'flex',
    justifyContent: 'center',
  },
  tab: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 300,
    color: color.gray,
    height: '100%',
    width: '150px',
    marginLeft: '12px',
    marginRight: '12px',
    cursor: 'pointer',
  },
  tabActive: {
    color: color.sortedNavy,
    '& > div': {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      height: '4px',
      backgroundColor: color.sortedNavy,
      borderTopLeftRadius: 3,
      borderTopRightRadius: 3,
    },
  },
});

export interface MyAccountTabsStyleProps extends WithStyles<typeof styles> {}
