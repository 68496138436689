import { createStyles, WithStyles } from '@material-ui/core';
import { color } from '../../lib/Theme/Theme';
import { SIZES } from '../../lib/Constants';

export const styles = createStyles({
  root: {
    backgroundColor: color.secondary,
    [`@media (min-width: ${SIZES.md}px)`]: {
      backgroundColor: color.light,
    },
  },
  formStyle: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px',
    maxWidth: '750px',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '20px',
  },
  title: {
    fontSize: '36px',
  },
  isUpdatingText: {
    color: color.charcoal,
    fontSize: '16px',
    marginBottom: '20px',
  },
  closeIcon: {
    width: 30,
    height: 30,
    cursor: 'pointer',
  },
  declarationPoints: {
    padding: '0 10px 0 10px',
  },
  signHereStyle: {
    color: color.sortedNavy,
    fontSize: '0.875rem',
  },
  signaturePadStyle: {
    // fontFamily: 'LaBelleAurore',
  },
  headerWrraperStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.5rem',
  },
  signAgainStyle: {
    color: '#2BA7C0',
    cursor: 'pointer',
    fontSize: '0.75rem',
    textDecoration: 'underline',
  },
  agreeOnSignStyle: {
    color: '#272727',
    fontSize: '16px',
    fontFamily: 'GT Walsheim',
    letterSpacing: '0.13px',
    marginBottom: '6px',
    marginTop: '15px',
  },
  confirmMessageStyle: {
    fontSize: '13px',
    fontFamily: 'GT Walsheim',
    letterSpacing: '-0.2px',
    color: color.offBlack,
    paddingBottom: '30px',
  },
  optionIconWrpStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    height: '3rem',
  },
  signBtnStyle: {
    display: 'flex',
    width: '40%',
    justifyContent: 'space-evenly',
    height: '40%',
    alignItems: 'baseline',
  },
  signContainerStyle: {
    display: 'flex',
  },
  signPadStyle: {
    width: '80%',
    background: '#eeeeee',
  },
  uploadStyle: {
    width: '80%',
    background: '#eeeeee',
    display: 'flex',
  },
  docNameStyle: {
    color: color.sortedNavy,
    fontSize: '0.85rem',
  },
  updateSignatureBtnWrapperStyle: {
    padding: '0.5rem',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    background: '#eeeeee',
    alignItems: 'center',
  },
  updateSignTextStyle: {
    fontFamily: 'GT Walsheim',
    fontSize: '0.788rem',
    color: '#a8a8a8',
    paddingBottom: '0.813rem',
    textAlign: 'center',
    width: '89.2%',
  },
  updateSignatureStyle: {
    background: '#2BA7C0',
    color: '#ffff',
  },
  signPanelStyle: {
    margin: 0,
  },
  signSummaryStyle: {
    height: '0.875rem',
    minHeight: '1.5rem',
    color: '#2ca7c0',
    fontFamily: 'GT Walsheim',
    fontSize: '0.788rem',
    fontWeight: 500,
    padding: '0 0.3125rem',
  },
  signGuideStyle: {
    width: '100%',
  },
  opacityStyle: {
    opacity: 0.5,
  },
  ButtonTextStyle: {
    fontSize: '1rem',
    padding: '0.25rem',
    color: '#808080',
  },
  expendIconStyle: {
    color: '#2ca7c0',
    padding: 0,
  },
  imageStyle: {
    width: '100%',
    height: '100%',
  },
  scrollBox: {
    height: '830px',
    border: `${color.borderGray} 2px solid`,
    borderRadius: '6px',
    padding: 15,
    marginBottom: 15,
    overflow: 'scroll',
    '&:hover': {
      border: `${color.teal} 2px solid`,
    },
  },
  inputContainerStyle: {
    width: '100%',
  },
  signingContainer: {
    border: `${color.borderGray} 2px solid`,
    borderRadius: '6px',
    padding: 15,
  },
  signingLabel: {
    fontSize: '16px',
    color: color.offBlack,
  },
  inputStyle: {
    color: color.secondary600,
    background: color.secondary,
    padding: '10px 15px',
    borderRadius: 4,
    marginTop: '15px',
  },
  buttonContainer: {
    margin: 'auto',
    width: '100%',
    marginTop: '20px',
    maxWidth: '1000px',
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  secondaryButton: {
    width: '50%',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: color.light,
    border: `${color.borderGray} 2px solid`,
    borderRadius: '6px',
    color: color.offBlack,
    cursor: 'pointer',
    [`@media (max-width: ${SIZES.md}px)`]: {
      width: '100%',
    },
  },
  primaryButton: {
    marginLeft: '30px',
    width: '50%',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: color.sortedNavy,
    borderRadius: '6px',
    color: color.light,
    cursor: 'pointer',
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginLeft: '0px',
      width: '100%',
    },
  },
  disabled: {
    backgroundColor: color.textarea,
    color: color.greyText,
  },
});

export interface DeclarationFormStyleProps extends WithStyles<typeof styles> {}
