import { ErrorMessage, Field, FieldProps, useField } from 'formik';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import React, { FC, useEffect } from 'react';

import { SelectItemDef } from '../RenderdataTypes.data';
import { useStyles } from './RenderSelectStyles';

interface RenderSelectProps {
  options: SelectItemDef[];
  name: string;
  label: string;
  variant?: 'filled' | 'outlined' | 'standard';
  containerClass?: string;
  disabled?: boolean;
  defaultValue?: string;
  validate?: (value: any) => void;
}

const RenderSelect: FC<RenderSelectProps> = ({
  name,
  options,
  label,
  variant,
  containerClass,
  disabled,
  defaultValue,
  validate,
}) => {
  const classes = useStyles({ filled: variant === 'filled' });
  const [field, meta, helpers] = useField(name);
  const showerror = !!meta.error;

  useEffect(() => {
    if (!field.value && defaultValue) helpers.setValue(defaultValue);
  }, []);

  return (
    <div className={containerClass || ''}>
      <FormControl fullWidth error={showerror}>
        <>
          {label && (
            <InputLabel
              htmlFor={name}
              className={classes.label}
              classes={{ shrink: classes.shrink, formControl: classes.labelFormControl }}
            >
              <Typography variant="subtitle1" className={showerror ? classes.labelError : ''}>
                {label}
              </Typography>
            </InputLabel>
          )}
          <Field name={name} validate={validate}>
            {({ field }: FieldProps) => {
              const { value } = field;
              return (
                <Select
                  name={name}
                  value={value === null || value === undefined ? defaultValue || '' : value}
                  onChange={field.onChange}
                  className={classes.inputSelect}
                  disabled={disabled}
                >
                  {options.map((option, index) => (
                    <MenuItem
                      classes={{ root: classes.menuItemRoot }}
                      key={index}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
          </Field>
          {name && (
            <FormHelperText className={classes.errorMessageStyles}>
              <ErrorMessage name={name} />
            </FormHelperText>
          )}
        </>
      </FormControl>
    </div>
  );
};

export default RenderSelect;
