import { ApplicationState, useAppSelector } from '../../../../lib/Redux/Reducers';
import { BUTTON, LABELS } from './MyPropertiesConstants';
import { Button, Paper, Typography } from '@material-ui/core';
import React, { FC } from 'react';

import AddIcon from '@material-ui/icons/Add';
import AgencyLogoHeader from '../../../../components/agencyLogoHeader/AgencyLogoHeader';
import { BUTTON_LABEL } from '../../MyAccountConstant';
import { History } from 'history';
import { routes } from '../../../../navigation/Routes';
import { useDispatch } from 'react-redux';
import useStyles from './MyPropertiesStyles.data';

interface MyPropertiesProps {
  history: History;
}

const MyProperties: FC<MyPropertiesProps> = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dispatcher = {};
  const { agencyCode } = useAppSelector((state: ApplicationState) => ({
    agencyCode: state.dashboard.agency,
  }));

  const propertyDetails = () => {
    return (
      <>
        <div>Propert Details</div>
      </>
    );
  };
  const addOwnerShip = () => {
    // history.push(routes.ownershipForm.new);
  };
  return (
    <>
      {/* MY Properties ---------------------------------------------------------- */}
      <Paper className={classes.myAccountContentContainer}>
        <div className={classes.referralTitleContainer}>
          <Typography className={classes.referralTitle} variant="h5">
            {LABELS.TITLE}
          </Typography>
          <Typography className={classes.referralSubtitle} variant="subtitle1">
            {LABELS.SUBTITLE}
          </Typography>
          <div className={classes.groupedApplicationCard}>
            <div>
              <AgencyLogoHeader>
                <Button
                  variant="outlined"
                  className={classes.addButtonContainer}
                  endIcon={<AddIcon />}
                  onClick={() => history.push(routes.addReferral.new())}
                >
                  <Typography variant="subtitle1">{BUTTON_LABEL.ADD_PROPERTY}</Typography>
                </Button>
              </AgencyLogoHeader>
            </div>
            {propertyDetails()}
            <div className={classes.footerContainer}>
              <button
                className={classes.addOwnershipButton}
                onMouseDown={() => addOwnerShip()}
                type="submit"
              >
                {BUTTON.ADD_OWNERSHIP}
              </button>
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
};

export default MyProperties;
