import { ApplicationState, useAppSelector } from '../../lib/Redux/Reducers';
import React, { FC } from 'react';
import { fetchAgencyLogo, fetchAgencyName } from './AgencyLogoHeaderUtils';

import useStyles from './AgencyLogoHeaderStyles';

interface AgencyLogoHeaderProps {
  children: JSX.Element;
}

const AgencyLogoHeader: FC<AgencyLogoHeaderProps> = ({ children }) => {
  const classes = useStyles();
  const { agencyCode } = useAppSelector((state: ApplicationState) => ({
    agencyCode: state.dashboard.agency,
  }));
  return (
    <div className={classes.agencyGroup}>
      <div className={classes.agencyHeader}>
        <img
          src={fetchAgencyLogo(agencyCode)}
          alt="Agency Logo"
          width={98}
          className={classes.agencyLogo}
        />
        {agencyCode && <div className={classes.agencyName}>{fetchAgencyName(agencyCode)}</div>}
        <div>{children}</div>
      </div>
    </div>
  );
};

export default AgencyLogoHeader;
