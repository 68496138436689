import { Button, Divider, Grid, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { OwnershipDetails } from '../../lib/Redux/Types/ownershipForm/OwnershipFormResponse.data';
import TnCPageStyles from './TnCPageStyles';

interface TnCPageProps {
  ownershipDetails?: OwnershipDetails;
  onNext: () => void;
  tnc: string;
  onBack: () => void;
}

const TnCPage: FC<TnCPageProps> = ({ ownershipDetails, onNext, tnc, onBack }) => {
  const classes = TnCPageStyles();
  if (!ownershipDetails) return null;
  return (
    <>
      <div className={classes.card}>
        <Grid container spacing={1}>
          <div className={classes.contentContainer}>
            <div className={classes.scrollWell}>
              {/* <Typography variant="h6"> Terms and Conditions</Typography> */}
              <div dangerouslySetInnerHTML={{ __html: tnc }} />
            </div>
          </div>
        </Grid>
      </div>
      <div className={classes.buttonContainer}>
        <Button size="small" className={classes.button} color="secondary" onClick={onBack}>
          Back
        </Button>
        <Button size="small" className={classes.button} color="primary" onClick={onNext}>
          Agree
        </Button>
      </div>
    </>
  );
};

export default TnCPage;
