import { color } from '../../../lib/Theme/Theme';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  inputLabelStyle: {
    padding: '16px',
    font: 'inherit',
    background: color.dark600,
    height: 150,
    border: 'none',
    borderRadius: '6px',
    resize: 'none',
  },
  remainingCharacters: {
    textAlign: 'right',
  },
  label: { margin: '10px 0px' },
  errorMessageStyles: {
    color: color.warning,
    marginTop: 10,
  },
  marketingFeeContainer: {
    marginTop: '1rem',
    border: `2px solid ${color.borderGray}`,
    borderRadius: 6,
    padding: '1rem 2rem',
  },
  subSectionHeader: {
    marginTop: '1.5rem',
    marginBottom: '-1.75rem',
    zIndex: 1,
    width: 'fit-content',
    background: color.light,
    padding: '0 1rem',
    marginLeft: '0.75rem',
  },
  width100: {
    width: '100%',
  },
  checkboxOption: {
    color: color.gray,
    textAlign: 'end',
    marginLeft: '0.25rem',
  },
  fontWeight100: {
    fontWeight: 100,
  },
  label2: {
    fontSize: '16px',
    lineHeight: 1.5,
    letterSpacing: '0.1px',
    color: color.offBlack,
  },
});

export const inputStylePropsGenerator = (classes: { input: any; labels: any }) => ({
  overrideInputStyles: { input: classes.input },
  overrideLabelStyles: { shrink: classes.labels },
});
