import { createStyles } from '@material-ui/core';
import { color } from '../../../lib/Theme/Theme';

export const ErrorScreenStyle = createStyles({
  errorScreenRootStyle: {
    background: color.sortedNavy,
    minHeight: '100vh',
  },
  sortedLogoStyle: {
    display: 'flex',
    padding: '2.563rem 0 3.5rem 2.25rem',
    alignItems: 'center',
  },
  imageStyle: {
    height: '2rem',
  },
  referenceToolStyle: {
    paddingLeft: '1rem',
    fontFamily: 'GT Walsheim',
    fontSize: '1.5rem',
    fontWeight: 500,
    color: color.light,
    alignItems: 'center',
    borderLeft: `0.125rem solid ${color.light}`,
    marginLeft: '1rem',
  },
  oopsTextStyle: {
    padding: '0 10.938rem',
    fontWeight: 500,
    fontSize: '2.625rem',
    color: color.light,
    fontFamily: 'GT Walsheim',
    [`@media (max-width: 53.125rem)`]: {
      padding: '0 5.438rem',
      fontSize: '2.297rem',
    },
    [`@media (max-width: 43.5rem)`]: {
      padding: '0 2.25rem',
      fontSize: '1.969rem',
    },
  },
  finishedReferenceStyle: {
    color: color.light,
    padding: '2rem 10.938rem',
    fontSize: '1.375rem',
    textAlign: 'justify',
    [`@media (max-width: 53.125rem)`]: {
      padding: '1.75rem 5.438rem',
      fontSize: '1.203rem',
    },
    [`@media (max-width: 43.5rem)`]: {
      padding: '1.5rem 2.25rem',
      fontSize: '1.031rem',
    },
  },
  errorTextStyle: {
    color: color.light,
    padding: '0 10.938rem 2.063rem 10.938rem',
    fontSize: '1rem',
    [`@media (max-width: 53.125rem)`]: {
      padding: '0 5.438rem 1.813rem 5.438rem',
      fontSize: '0.875rem',
    },
    [`@media (max-width: 43.5rem)`]: {
      padding: '0 2.25rem 1.563rem 2.25rem',
      fontSize: '0.75rem',
    },
  },
  sortedInfoStyle: {
    display: 'flex',
    alignItems: 'center',
    color: color.light,
    padding: '0 10.938rem',
    [`@media (max-width: 53.125rem)`]: {
      padding: '0 5.438rem',
    },
    [`@media (max-width: 43.5rem)`]: {
      padding: '0 2.25rem',
    },
  },
  knowMoreStyle: {
    fontSize: '0.675rem',
    letterSpacing: '-0.0125rem',
  },
  learnMoreStyle: {
    color: color.light,
    fontSize: '0.675rem',
    letterSpacing: '-0.0125rem',
    textTransform: 'none',
    fontFamily: 'GT Walsheim',
  },
  dataLoadingStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    background: color.sortedNavy,
    color: 'white',
    textAlign: 'center',
  },
});
