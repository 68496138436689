/* eslint-disable import/prefer-default-export */
export const API_ROUTE = {
  REGISTER_CLIENT: 'registerClient',
  LOGIN: 'user/login',
  SIGNUP_USER: 'user/sign-up',
  SAVE_REFERRALS: '/api/referral',
  FETCH_REFERRALS: '/api/referral',
  VERIFY_USER: 'user/verify',
  REFRESH_TOKEN: 'user/refreshToken',
};
