import React, { FC, useState, useEffect } from 'react';
import {
  withStyles,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  InputAdornment,
  FormHelperText,
  IconButton,
  Input,
  Link,
} from '@material-ui/core';
import { Formik, ErrorMessage } from 'formik';
import { History } from 'history';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { LoginActions } from '../../../../lib/Redux/Actions/LoginActions';
import { ApplicationState, useAppSelector } from '../../../../lib/Redux/Reducers';
import { LoginFormStyleProps, styles } from './LoginFormStyles';
import { VALIDATION_SCHEMA, LABELS } from './LoginFormConstants';
import visibilityOff from '../../../../assets/images/outline-visibility-off-24-px.svg';
import visibilityOn from '../../../../assets/images/outline-remove-red-eye-24-px.svg';
import errorIcon from '../../../../assets/images/error-outline-24-px.svg';
import {
  LoginPayload,
  RegisterUserPayload,
  VerificationLinkPayload,
} from '../../../../lib/Redux/Services/login/LoginData';
import UserConfirmationDialog from '../../../../components/userConfirmationDialog/UserConfirmationDialog';
import { View } from '../../Login';
import { routes } from '../../../../navigation/Routes';

interface LoginFormProps extends LoginFormStyleProps {
  history: History;
  setView: (view: View) => void;
  userDetails: RegisterUserPayload | null;
}

const LoginForm: FC<LoginFormProps> = ({ classes, setView, userDetails }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [verifyError, setVerifyError] = useState<string | null>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  // variables and functions
  const dispatcher = {
    clearErrors: () => {
      dispatch(LoginActions.clearErrors());
    },
    postLogin: (data: LoginPayload) => {
      dispatch(LoginActions.postLoginStart(data));
    },
    sendVerificationEmail: (data: VerificationLinkPayload) => {
      dispatch(LoginActions.postVerificationLinkStart(data));
    },
  };

  const resendEmail = (email: string) => {
    dispatcher.sendVerificationEmail({ email });
    setOpenConfirmDialog(true);
  };
  const { loginError } = useAppSelector((state: ApplicationState) => ({
    loginError: state.login.loginError,
  }));

  useEffect(() => {
    dispatcher.clearErrors();
  }, []);

  useEffect(() => {
    if (loginError && loginError.response) {
      if (loginError.response.status === 401) {
        setErrorMessage('Incorrect email or password');
      } else if (loginError.response.status === 400) {
        setVerifyError(
          'Your email hasn’t been verified. Check your email to verify or resend the link.',
        );
      }
    }
  }, [loginError]);

  return (
    <Paper className={classes.secondaryTile}>
      <Formik
        initialValues={{
          username: userDetails && userDetails.email ? userDetails.email : '',
          password: '',
        }}
        validateOnChange
        onSubmit={(data) => dispatcher.postLogin(data)}
        validationSchema={VALIDATION_SCHEMA}
        render={({ values, handleSubmit, setFieldValue, errors, touched }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className={classes.titleHeader}>
                <div>
                  <Typography variant="h4" className={classes.title}>
                    {LABELS.LOGIN_TITLE}
                  </Typography>
                  <Typography className={classes.subtitle} variant="body2">
                    {LABELS.LOGIN_SUBTITLE}
                  </Typography>
                </div>
              </div>
              <div className={classes.contentContainer}>
                {/* EMAIL ----------------------------------------------------------------- */}
                <FormControl
                  error={!!errors.username && !!touched.username}
                  className={classes.inputContainerStyle}
                >
                  <InputLabel>
                    <Typography variant="subtitle1">{LABELS.EMAIL_ADDRESS}</Typography>
                  </InputLabel>
                  <Input
                    className={classes.inputStyle}
                    name="username"
                    type="text"
                    onChange={(event) => {
                      setFieldValue('username', event.target.value);
                    }}
                    value={values.username}
                    endAdornment={
                      <InputAdornment position="end">
                        <img
                          src={
                            (!!errors.username && !!touched.username) || verifyError
                              ? errorIcon
                              : ''
                          }
                          alt=""
                        />
                      </InputAdornment>
                    }
                  />
                  {verifyError && (
                    <div className={classes.errorContainer}>
                      <div className={classes.errorText}>{verifyError}</div>
                      <div
                        onClick={() => resendEmail(values.username)}
                        className={classes.resendButton}
                      >
                        {LABELS.RESEND_LINK}
                      </div>
                    </div>
                  )}
                  <FormHelperText>
                    <ErrorMessage name="username" />
                  </FormHelperText>
                </FormControl>

                {/* PASSWORD -------------------------------------------------------------- */}
                <FormControl
                  error={!!errors.password && !!touched.password}
                  className={classes.inputContainerStyle}
                >
                  <InputLabel>
                    <Typography variant="subtitle1">{LABELS.PASSWORD}</Typography>
                  </InputLabel>
                  <Input
                    className={classes.inputStyle}
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={(event) => {
                      setFieldValue('password', event.target.value);
                    }}
                    value={values.password}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)}>
                          <img src={showPassword ? visibilityOn : visibilityOff} alt="" />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {errorMessage && <div className={classes.errorText}>{errorMessage}</div>}

                  <FormHelperText className={classes.errordiv}>
                    <ErrorMessage name="password" />
                  </FormHelperText>
                </FormControl>

                <div
                  className={classes.forgotPassword}
                  onClick={() => setView(View.ForgotPassword)}
                >
                  {LABELS.FORGOT_PASSWORD}
                </div>
                <div className={classes.footerContainer}>
                  <div className={classes.secondaryActionContainer}>
                    <Typography className={classes.secondaryActionText} variant="body2">
                      {LABELS.DONT_HAVE_ACCOUNT}
                    </Typography>
                    <div className={classes.secondaryAction} onClick={() => setView(View.Signup)}>
                      {LABELS.SIGNUP_HEADER}
                    </div>
                  </div>
                  <button
                    className={classes.loginButton}
                    onMouseDown={() => handleSubmit()}
                    type="submit"
                  >
                    {LABELS.LOGIN}
                  </button>
                </div>
              </div>
            </form>
          );
        }}
      />
      <UserConfirmationDialog
        isOpen={openConfirmDialog}
        title="A new confirmation email has been sent!"
        content={
          <div>Please verify your account by clicking the link in the email within 24 hours.</div>
        }
        primaryButtonTitle="OK"
        onPrimaryClick={() => setOpenConfirmDialog(false)}
        onClose={() => setOpenConfirmDialog(false)}
      />
    </Paper>
  );
};

export default withStyles(styles)(LoginForm);
