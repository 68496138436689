import {
  GetOwnershipDetailsRequest,
  GetOwnershipDetailsResponse,
  GetOwnershipPropertiesRequest,
  GetOwnershipPropertiesResponse,
  PutOwnershipDetailsRequest,
  SignOwnershipRequest,
  UpdateOwnerRequest,
  UpdateOwnershipPropertiesRequest,
} from '../Types/ownershipForm/OwnershipFormResponse.data';
import {
  OwnershipAction,
  OwnershipActionTypes,
  OwnershipActions,
} from '../Actions/OwnershipActions';
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { put, takeEvery } from 'redux-saga/effects';

import { LoadingIndicatorActions } from '../Actions/LoadingIndicatorAction';
import { SuccessToastActions } from '../Actions/SuccessToastAction';
import ownershipService from '../Services/ownership/OwnershipService';
import { CommonActions } from '../Actions/CommonActions';

export function* getOwnershipDetailsWorker(action: OwnershipAction): IterableIterator<any> {
  try {
    const { token, prepareMode } = action.data as GetOwnershipDetailsRequest;
    const response:
      | GetOwnershipDetailsResponse
      | undefined = yield ownershipService.getOwnershipDetail(token, prepareMode);
    if (response) {
      yield put(OwnershipActions.getOwnershipDetailsSuccess(response));
      yield put(CommonActions.addAgencyBannerUrl((response as GetOwnershipDetailsResponse).agency.bannerUrl))
    }
  } catch (error) {
    yield put(OwnershipActions.getOwnershipDetailsError(error));
  }
}

export function* updateOwnershipDetailsWorker(action: OwnershipAction): IterableIterator<any> {
  try {
    const {
      token,
      prepareMode,
      ownershipDetails,
      onSuccess,
    } = action.data as PutOwnershipDetailsRequest;
    yield ownershipService.putOwnershipDetail(token, ownershipDetails, prepareMode);
    yield put(OwnershipActions.getOwnershipDetails({ token, prepareMode }));
    yield put(OwnershipActions.updateOwnershipDetailsSuccess());

    if (onSuccess) {
      onSuccess();
    }
  } catch (error: any) {
    const { onError } = action.data as PutOwnershipDetailsRequest;
    const errorMessage = error.message.split(':');
    if (onError && errorMessage && errorMessage.length > 1 && errorMessage[0] === 'Alert') {
      onError(errorMessage[1]);
    } else {
      yield put(SuccessToastActions.showError(error.message));
    }
    yield put(OwnershipActions.updateOwnershipDetailsDiaLogError());
  }
}

export function* getOwnershipPropertiesWorker(action: OwnershipAction): IterableIterator<any> {
  try {
    const { token, prepareMode, onSuccess } = action.data as GetOwnershipPropertiesRequest;
    const response:
      | GetOwnershipPropertiesResponse
      | undefined = yield ownershipService.getProperties(token, prepareMode);

    yield put(OwnershipActions.getOwnershipPropertiesSuccess(response));

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    yield put(OwnershipActions.getOwnershipPropertiesError(error));
  }
}

export function* updateOwnershipPropertyWorker(action: OwnershipAction): IterableIterator<any> {
  try {
    const {
      token,
      prepareMode,
      property: properties,
      onSuccess,
    } = action.data as UpdateOwnershipPropertiesRequest;
    yield ownershipService.putProperty(token, properties, prepareMode);
    yield put(OwnershipActions.updateOwnershipPropertiesSuccess());
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    yield put(SuccessToastActions.showError(error.message));
  }
}

export function* signOwnershipWorker(action: OwnershipAction): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const { token, signatures, onSuccess } = action.data as SignOwnershipRequest;
    yield ownershipService.postSignature(token, signatures);
    yield put(OwnershipActions.signOwnershipSuccess());
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    yield put(OwnershipActions.signOwnershipError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* updateOwnerRequestWorker(action: OwnershipAction): IterableIterator<any> {
  try {
    const { token, details, prepareMode, onSuccess } = action.data as UpdateOwnerRequest;
    yield ownershipService.updateOwner(token, details, prepareMode);
    yield ownershipService.getOwnershipDetail(token, prepareMode);
    yield put(OwnershipActions.updateOwnerDetailsSuccess());
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    yield put(OwnershipActions.updateOwnerDetailsError(error));
  }
}

const referralWatcher = [
  takeEvery(OwnershipActionTypes.GET_OWNERSHIP_DETAILS_REQUEST, getOwnershipDetailsWorker),
  takeEvery(OwnershipActionTypes.UPDATE_OWNERSHIP_DETAILS_REQUEST, updateOwnershipDetailsWorker),
  takeEvery(OwnershipActionTypes.GET_OWNERSHIP_PROPERTIES_REQUEST, getOwnershipPropertiesWorker),
  takeEvery(
    OwnershipActionTypes.UPDATE_OWNERSHIP_PROPERTIES_REQUEST,
    updateOwnershipPropertyWorker,
  ),
  takeEvery(OwnershipActionTypes.SIGN_OWNERSHIP_REQUEST, signOwnershipWorker),
  takeEvery(OwnershipActionTypes.UPDATE_OWNER_REQUEST, updateOwnerRequestWorker),
];

export default referralWatcher;
