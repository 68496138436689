import {
  DatePicker,
  DatePickerView,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { ErrorMessage, Field, FieldProps, useField } from 'formik';
import { FormControl, FormHelperText, Typography } from '@material-ui/core';
import React, { FC } from 'react';

import { DateFormats } from '../../../AppConstants';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { useStyles } from './RenderPickerStyles.data';

interface RenderDatePickerProps {
  name: string;
  label: string;
  UIFormat?: string;
  disabled?: boolean;
  hidden?: boolean;
  inputClassName?: string;
  inputStyle?: any;
  type?: string;
  containerClass?: string;
  diasbleFormControlMargins?: boolean;
  disablePast?: boolean;
  filled?: boolean;
  maxDate?: moment.Moment;
  calendarView?: string;
  validate?: (value: any) => void;
}

/* tslint:disable:cyclomatic-complexity */
const RenderDatePicker: FC<RenderDatePickerProps> = ({
  label,
  containerClass,
  disabled,
  diasbleFormControlMargins,
  disablePast,
  name,
  filled,
  maxDate,
  calendarView,
  UIFormat,
  validate,
}) => {
  const classes = useStyles();
  const isFullcalender = calendarView !== 'year';
  const [fields] = useField(name);
  const { value } = fields;
  let date: moment.Moment | null = null;
  let view: DatePickerView[] = ['year', 'date', 'month'];

  if (calendarView === 'year') {
    view = ['year'];
  } else if (typeof value === 'string') date = moment(value, DateFormats.apiFormat);

  const setDate = date || value;

  return (
    <div className={`${containerClass || ''}`}>
      <FormControl margin={diasbleFormControlMargins ? 'none' : 'normal'} style={{ width: '100%' }}>
        {label && (
          <Typography variant="subtitle1" className={classes.label}>
            {label}
          </Typography>
        )}
        <Field name={name} validate={validate}>
          {({ field, form }: FieldProps) => (
            <MuiPickersUtilsProvider utils={MomentUtils}>
              {isFullcalender && (
                <KeyboardDatePicker
                  autoOk
                  animateYearScrolling
                  InputProps={{
                    disableUnderline: !!filled,
                    className: filled
                      ? `${classes.filled}${classes.inputStyle}`
                      : classes.inputStyle,
                  }}
                  emptyLabel=""
                  disabled={disabled}
                  variant="inline"
                  format={DateFormats.apiFormat}
                  margin={diasbleFormControlMargins ? 'none' : 'normal'}
                  placeholder="DD/MM/YYYY"
                  disablePast={!!disablePast}
                  value={setDate || null}
                  onChange={(selecteddate: moment.Moment) => {
                    form.setFieldValue(
                      name,
                      selecteddate ? moment(selecteddate).format(UIFormat) : null,
                    );
                  }}
                  onBlur={field.onBlur}
                  clearable
                  maxDate={maxDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                    className: classes.icon,
                  }}
                />
              )}
              {!isFullcalender && (
                <DatePicker
                  autoOk
                  animateYearScrolling
                  InputProps={{
                    disableUnderline: !!filled,
                    className: filled
                      ? `${classes.filled}${classes.inputStyle}`
                      : classes.inputStyle,
                  }}
                  disabled={disabled}
                  variant="inline"
                  margin={diasbleFormControlMargins ? 'none' : 'normal'}
                  disablePast={!!disablePast}
                  label={label}
                  value={setDate || null}
                  onChange={(selecteddate: moment.Moment) =>
                    form.setFieldValue(
                      name,
                      selecteddate ? moment(selecteddate).format(UIFormat) : null,
                    )
                  }
                  onBlur={field.onBlur}
                  views={view}
                />
              )}
            </MuiPickersUtilsProvider>
          )}
        </Field>
        <FormHelperText className={classes.errorMessageStyles}>
          <ErrorMessage name={name} />
        </FormHelperText>
      </FormControl>
    </div>
  );
};

export default RenderDatePicker;
