import React, { FC } from 'react';

import { Grid } from '@material-ui/core';
import { LABELS } from './ReferralDetailCardConstants';
import { OpportunityStatusType } from '../../lib/Redux/Types/referrals/ReferralsEnum.data';
import { ReferralPayload } from '../../lib/Redux/Types/referrals/ReferralType.data';
import Typography from '@material-ui/core/Typography';
import referralDetailCardStyles from './ReferralDetailCardStyles.data';
import { toPascalCase } from '../../lib/helper/CaseHelper';

interface ReferralCardProps {
  referral: ReferralPayload;
  idx?: number;
}

const referralDisplayDetails: FC<ReferralCardProps> = ({ referral, idx }) => {
  const classes = referralDetailCardStyles();
  return (
    <div className={classes.propertyContainer}>
      {idx && (
        <>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Typography variant="h4" className={classes.draftTitle}>
              {LABELS.REFERRAL_HEAD(idx)}
            </Typography>
            {referral.status && (
              <div
                className={`${classes.statusContainer} 
          ${
            referral.status.includes(OpportunityStatusType.NOT_SIGNED)
              ? classes.incompleteLabel
              : classes.completeLabel
          }
         
          `}
              >
                <div>
                  <Typography className={classes.statusText}>
                    {toPascalCase(referral.status)}
                  </Typography>
                </div>
              </div>
            )}
          </Grid>
        </>
      )}
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12} sm={12} className={classes.gridRow}>
          <Typography variant="h4" className={classes.gridLabel}>
            {`${LABELS.CONTACT_PERSON} :`}
          </Typography>
          <Typography variant="h4" className={classes.gridValue}>
            {`${referral.title} ${referral.firstName} ${referral.lastName}`}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} className={classes.gridRow}>
          <Typography variant="h4" className={classes.gridLabel}>
            {`${LABELS.DISPLAY_MOBILE} :`}
          </Typography>
          <Typography variant="h4" className={classes.gridValue}>
            {`${referral.phoneNumber}`}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.gridRow}>
          <Typography variant="h4" className={classes.gridLabel}>
            {`${LABELS.DISPLAY_EMAIL} :`}
          </Typography>
          <Typography variant="h4" className={classes.gridValue}>
            {`${referral.email}`}
          </Typography>
        </Grid>
        {referral.properties && (
          <Grid item xs={12} className={classes.gridRow}>
            <Typography variant="h4" className={classes.gridLabel}>
              {`${LABELS.PROPERTIES} :`}
            </Typography>
            <Typography variant="h4" className={classes.gridValue}>
              {`${referral.properties}`}
            </Typography>
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography variant="h4" className={classes.gridLabel}>
            {`${LABELS.ADDITIONAL_HEADER} :`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.gridValue}>
            {`${referral.additionalInformation}`}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};
export default referralDisplayDetails;
