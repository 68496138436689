import 'react-phone-number-input/style.css';

import { ErrorMessage, Field, FieldProps, useField } from 'formik';
import { FormControl, FormHelperText, InputLabel, Typography } from '@material-ui/core';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import React, { FC, useState } from 'react';

import useStyles from './RenderPhoneInputStyles';

interface RenderPhoneInputProps {
  name: string;
  label?: string;
  containerClass?: string;
  setFieldValue: (fieldName: string, fieldValue?: string | boolean) => void;
  disabled?: boolean;
  showError?: boolean;
  validate?: (value: any) => void;
}

const RenderPhoneInput: FC<RenderPhoneInputProps> = ({
  name,
  label,
  containerClass,
  setFieldValue,
  disabled,
  showError,
  validate,
}) => {
  const classes = useStyles();
  const [phoneNumber, setphoneNumber] = useState<string | undefined>(undefined);
  const [isValidNumber, setIsValidNumber] = useState<boolean>(true);
  const [field, meta] = useField(name);
  const showerror = !!meta.error && (meta.touched || showError);
  return (
    <div className={`${containerClass || ''}`}>
      <FormControl fullWidth error={showerror}>
        <>
          {label && (
            <InputLabel
              htmlFor={name}
              className={classes.label}
              // classes={{ shrink: classes.shrink }}
            >
              <Typography variant="subtitle1" className={showerror ? classes.labelError : ''}>
                {label}
              </Typography>
            </InputLabel>
          )}
          <Field name={name} validate={validate}>
            {({ field }: FieldProps) => {
              const { value } = field;
              return (
                <PhoneInput
                  flagUrl="https://public.sortedservices.com/flags/4x3/{xx}.svg"
                  defaultCountry="AU"
                  value={value || ''}
                  className={` ${showerror ? classes.errorLine : ''}`}
                  disabled={disabled}
                  onChange={(getPhone) => {
                    const isValid = !!(getPhone && isValidPhoneNumber(getPhone || ''));
                    setFieldValue(name, getPhone);
                    setphoneNumber(getPhone || '');
                    setIsValidNumber(isValid);
                  }}
                />
              );
            }}
          </Field>

          {!isValidNumber && phoneNumber && (
            <FormHelperText className={classes.errorMessageStyles}>
              Invalid Phone Number
            </FormHelperText>
          )}
          {name && (
            <FormHelperText className={classes.errorMessageStyles}>
              <ErrorMessage name={name} />
            </FormHelperText>
          )}
        </>
      </FormControl>
    </div>
  );
};

export default RenderPhoneInput;
