/// <summary>
/// Generates url: {AppSettings.service.baseUrl}/{this.serviceApi}
// DO NOT append or prepend '/' here!
enum ServiceType {
  Referral = 'referral',
  AuthReferral = 'api/referral',
  RefreshToken = 'referral/user/refreshToken',
  Terms = 'api/referral/tnc',
  ReferenceTool = 'api/questionnaire',
  ResendEmail = 'api/user/email',
  Ownership = 'authority',
  OwnersInstructions = 'authority/owners-instructions',
}
export default ServiceType;
