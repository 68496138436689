import { WithStyles, createStyles } from '@material-ui/core';
import { color } from '../../lib/Theme/Theme';
import { SIZES } from '../../lib/Constants';

export const FooterStyle = createStyles({
  button: {
    background: color.primary,
    [`@media (min-width: ${SIZES.md}px)`]: {
      width: '50%',
      height: 50,
    },
    [`@media (max-width: ${SIZES.md}px)`]: {
      width: '100%',
      height: 50,
    },
  },
  buttonFullWidth: {
    background: color.primary,
    width: '100%',
    height: 50,
  },
  buttonText: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.14,
    color: color.light,
  },
  disabledButtonText: {
    color: color.secondary300,
  },
});

export interface FooterComponentStyle extends WithStyles<typeof FooterStyle> {}
