/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { takeEvery, put } from 'redux-saga/effects';
import {
  DashboardAction,
  DashboardActions,
  DashboardActionTypes,
} from '../Actions/DashboardActions';
import { GetAgencyByAgentPayload } from '../Types/agency/AgencyRequest.data';
import { AgencyResponse } from '../Types/agency/AgencyResponse.data';
import { LoadingIndicatorActions } from '../Actions/LoadingIndicatorAction';
import { Logging } from '../../helper/Logging';
import DashboardService from '../Services/dashboard/DashboardServices';

export function* getAgencyByAgentWorker(action: DashboardAction) {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data as GetAgencyByAgentPayload;
    const response: AgencyResponse = (yield DashboardService.getAgencyByAgent(data))!;
    yield put(DashboardActions.getAgencyByAgentSuccess(response));
  } catch (error) {
    Logging.error('PropertySaga.getAgencyByAgentWorker', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(DashboardActions.getAgencyByAgentFailure({ error }));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

const propertyWatcher = [
  takeEvery(DashboardActionTypes.GET_AGENCY_BY_AGENT_REQUEST, (action) =>
    getAgencyByAgentWorker({
      type: action.type,
      data: (action as DashboardAction).data,
    }),
  ),
];

export default propertyWatcher;
