import * as React from 'react';
import { Typography, withStyles, Button } from '@material-ui/core';
import { withRouter, match } from 'react-router';
import { History } from 'history';
import Like from '../../assets/images/like.svg';
import {
  ResetPasswordConfirmationStyle,
  ResetPasswordConfirmationComponentStyle,
} from './ResetPasswordConfirmationStyles';
import { routes } from '../../navigation/Routes';

interface Props {
  history: History;
  match: match;
}

type OwnProps = ResetPasswordConfirmationComponentStyle & Props;

export class ResetPasswordConfirmationComponent extends React.Component<OwnProps> {
  private redirectTo = (path: string) => {
    const { history } = this.props;
    history.push(path);
  };
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.resetPasswordConfirmationContainer}>
        <img className={classes.successLogo} src={Like} alt="password reset success" />
        <Typography variant="h6">Your password has been reset!</Typography>
        <div className={classes.buttonContainer}>
          <Button
            fullWidth
            className={classes.loginButton}
            onClick={() => this.redirectTo(routes.login.viewGeneric)}
          >
            <Typography className={classes.loginLabel}>LOGIN</Typography>
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(
  withStyles(ResetPasswordConfirmationStyle)(ResetPasswordConfirmationComponent) as any,
);
