import * as React from 'react';
import { withStyles } from '@material-ui/core';
import { ApplicationState, useAppSelector } from '../../lib/Redux/Reducers';
import './LoadingIndicator.css';

interface LoadingIndicatorStyles {
  readonly overlay: any;
}

const styles: LoadingIndicatorStyles = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    zIndex: 99999,
    padding: '1em',
  },
};

interface LoadingIndicatorProps {
  readonly classes: any;
}

export const LoadingIndicator = (props: LoadingIndicatorProps) => {
  const { isLoading } = useAppSelector((state: ApplicationState) => ({
    isLoading: state.loadingIndicator.isLoading,
  }));
  return (
    <div>
      {isLoading ? (
        <div className={props.classes.overlay}>
          <div className="loadingIndicator" />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const LoadingIndicatorComponent = withStyles(styles)(LoadingIndicator as any);

export default LoadingIndicatorComponent;
