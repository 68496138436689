import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { DeclarationFormStyleProps, styles } from './DeclarationFormStyles';
import LABELS from './DeclarationFormConstants';
import { ApplicationState, useAppSelector } from '../../lib/Redux/Reducers';
import ScrollBox from './components/ScrollBox';
import { CustomTermsHeaderPayload } from '../../lib/Redux/Types/customTermsHeader/CustomTermsHeaderRequest.data';
import { CustomTermsHeaderActions } from '../../lib/Redux/Actions/CustomTermsHeaderActions';

interface DeclarationFormProps extends DeclarationFormStyleProps {
  readonly onDeclarationAccept: () => void;
  setShowDeclaration: (show: boolean) => void;
}

const DeclarationForm: FC<DeclarationFormProps> = ({
  classes,
  setShowDeclaration,
  onDeclarationAccept,
}) => {
  const handleSubmit = () => {
    onDeclarationAccept();
  };
  const dispatch = useDispatch();

  const dispatcher = {
    getTermsHeader: (data: CustomTermsHeaderPayload) => {
      dispatch(CustomTermsHeaderActions.postCustomTermsHeaderStart(data));
    },
  };

  const { headerDetails, agencyCode } = useAppSelector((state: ApplicationState) => ({
    applicationState: state,
    headerDetails: state.termsHeader.termsHeaderDetails,
    agencyCode: state.dashboard.agency!,
  }));

  useEffect(() => {
    if (agencyCode) {
      dispatcher.getTermsHeader({ agency: agencyCode.toUpperCase() });
    }
  }, [agencyCode]);

  return (
    <div className={classes.formStyle}>
      <div className={classes.titleContainer}>
        <div className={classes.title}>{LABELS.DECLARATION}</div>
        <CloseIcon
          htmlColor="#000"
          className={classes.closeIcon}
          onClick={() => setShowDeclaration(false)}
        />
      </div>

      <ScrollBox headerDetails={headerDetails} />
      <Typography className={classes.confirmMessageStyle}>{LABELS.CONFIRMATION_MESSGE}</Typography>

      <div className={classes.buttonContainer}>
        <div className={classes.secondaryButton} onClick={() => setShowDeclaration(false)}>
          Back
        </div>
        <div className={`${classes.primaryButton} `} onClick={() => handleSubmit()}>
          Accept
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(DeclarationForm);
