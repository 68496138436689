import { StoreAction } from '../Store/StoreHelper';

export enum SuccessToastActionTypes {
  ShowSuccessToast = 'SHOW_SUCCESS_TOAST',
  HideSuccessToast = 'HIDE_SUCCESS_TOAST',
  ShowErrorToast = 'SHOW_ERROR_TOAST',
}
export type SuccessActionPayload = string | Error | null | number | boolean | any;

export type SuccessToastAction = StoreAction<SuccessToastActionTypes, SuccessActionPayload>;
// export type SuccessToastAction = StoreAction<SuccessToastActionTypes, JSX.Element | string | null>;

export class SuccessToastActions {
  public static showToast(
    message: null | string = 'Your details have been saved',
  ): SuccessToastAction {
    return { type: SuccessToastActionTypes.ShowSuccessToast, data: message };
  }

  //  Not working
  public static hideToast(): SuccessToastAction {
    return { type: SuccessToastActionTypes.HideSuccessToast };
  }

  public static showError(message = 'Something went wrong!'): SuccessToastAction {
    return { type: SuccessToastActionTypes.ShowErrorToast, data: message };
  }
}
