import { SIZES } from '../../../lib/Constants';
import { color } from '../../../lib/Theme/Theme';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  dialogBox: {
    minWidth: '700px',
    padding: '16px',
  },

  button: {
    marginLeft: '30px',
    width: '20%',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: color.sortedNavy,
    borderRadius: '6px',
    color: color.light,
    cursor: 'pointer',
    '&:hover': {
      color: color.primary,
    },
    '&:disabled': {
      color: color.light,
      backgroundColor: color.grey,
    },

    [`@media (max-width: ${SIZES.md}px)`]: {
      marginLeft: '0px',
      width: '100%',
    },
  },
  buttonSecondary: {
    marginLeft: '30px',
    width: '20%',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: color.grey,
    borderRadius: '6px',
    color: color.primary,
    cursor: 'pointer',
    '&:hover': {
      color: color.primary,
    },
    '&:disabled': {
      color: color.light,
      backgroundColor: color.grey,
    },

    [`@media (max-width: ${SIZES.md}px)`]: {
      marginLeft: '0px',
      width: '100%',
    },
  },
  addManuallyButton: {
    width: '20%',
    height: '30px',
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: color.grey,
    borderRadius: '6px',
    color: color.primary,
    cursor: 'pointer',
    '&:hover': {
      color: color.primary,
    },
    '&:disabled': {
      color: color.light,
      backgroundColor: color.grey,
    },

    [`@media (max-width: ${SIZES.md}px)`]: {
      marginLeft: '0px',
      width: '100%',
    },
  },
  inputField: {
    height: 50,
  },
});

export default useStyles;
