import * as Yup from 'yup';
import { EMAIL_PATTERN, NAME_PATTERN } from '../../../../lib/Constants';

export const LABELS = {
  SIGNUP_TITLE: 'Create your Sorted Account',
  SIGNUP_SUBTITLE: 'Enter your details below to get started!',
  LOGIN_HEADER: 'Log In',
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  EMAIL_ADDRESS: 'Email Address',
  PASSWORD: 'Password',
  SIGNUP_BUTTON: 'SIGN UP',
  ALREADY_HAVE_ACCOUNT: 'Already have an account?',
};

export const ERRORS = {
  required: 'Required',
  invalidNameFormat: 'Invalid name format',
  requiredEmail: 'Please enter your email address',
  invalidEmailFormat: 'Invalid email format',
  requiredPassword: 'Please enter password',
  minimumRequired: 'Minimum 8 characters required',
};

export const VALIDATION_SCHEMA = Yup.object().shape({
  firstName: Yup.string()
    .required(ERRORS.required)
    .max(255)
    .matches(NAME_PATTERN, ERRORS.invalidNameFormat),
  lastName: Yup.string()
    .required(ERRORS.required)
    .max(255)
    .matches(NAME_PATTERN, ERRORS.invalidNameFormat),
  email: Yup.string()
    .required(ERRORS.requiredEmail)
    .matches(EMAIL_PATTERN, ERRORS.invalidEmailFormat)
    .max(255),
  password: Yup.string().required(ERRORS.requiredPassword).min(8, ERRORS.minimumRequired),
});
