import ServiceType from '../ServiceType.data';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { ReferralListResponse } from '../../Types/referrals/ReferralType.data';

const serviceType = ServiceType.AuthReferral;

export class ReferralService {
  private readonly service: ApiServiceBase = new ApiService(serviceType);

  public getReferrals(agencyCode: string): Promise<ReferralListResponse> | ReferralListResponse {
    return this.service.get<ReferralListResponse>({
      route: agencyCode ? ['', agencyCode] : [''],
    });
  }
  public saveReferrals(payload: any): Promise<void> | void {
    return this.service.post({ route: [''] }, payload);
  }
}

const referralService = new ReferralService();
export default referralService;
