import {
  Grid,
  Box,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  FormControl,
  Typography,
} from '@material-ui/core';
import { Field, ErrorMessage, useField } from 'formik';
import { get } from 'lodash';
import React, { FC } from 'react';
import { getGridProps } from '../../blanketForm/BlanketFormUtil';
import { BlanketFormFieldOption } from '../../blanketForm/BlanketFormConstants';

interface Props {
  options: BlanketFormFieldOption[] | undefined;
  name: string;
  disabled?: boolean;
  showError?: boolean;
  validateForm?: boolean;
}

const RenderCheckboxGroup2: FC<Props> = ({ options, name, disabled, showError, validateForm }) => {
  const [field, meta, helper] = useField(name);
  const showerror = !!meta.error && (meta.touched || showError);

  const { value: fieldValues } = field;

  return (
    <FormControl fullWidth variant="outlined" error={showerror}>
      <>
        <Grid container spacing={1}>
          <Field
            name={name}
            validate={() => {
              if (validateForm) {
                let checkedValues = false;
                options?.forEach(({ value }) => {
                  if (get(fieldValues, value)) checkedValues = true;
                });
                if (!checkedValues) {
                  return 'At least one is required';
                }
              }
              return undefined;
            }}
            render={() => (
              <>
                {options?.map((option) => {
                  const { size, label, value, forceCheck } = option;

                  const gridProps = getGridProps(size);
                  return (
                    <Grid item xs={gridProps.xs} sm={gridProps.sm}>
                      <Box display="flex" justifyContent="space-between">
                        <FormControlLabel
                          labelPlacement="end"
                          control={
                            <Checkbox
                              checked={get(fieldValues, value) || forceCheck}
                              defaultChecked={get(fieldValues, value) || forceCheck}
                              color="primary"
                              onChange={(e) => {
                                helper.setValue({ ...fieldValues, [value]: e.target.checked });
                              }}
                              id={`${name}.${value}`}
                            />
                          }
                          label={label}
                          disabled={disabled}
                        />
                      </Box>
                    </Grid>
                  );
                })}
              </>
            )}
          />
          <FormHelperText id={`${name}-error-text`}>
            <ErrorMessage name={name} />
          </FormHelperText>
        </Grid>
      </>
    </FormControl>
  );
};

export default RenderCheckboxGroup2;
