import SuccessToastState from '../State/SuccessToastState';
import { SuccessToastAction, SuccessToastActionTypes } from '../Actions/SuccessToastAction';

export const initialState: SuccessToastState = {
  snackbarFlag: false,
  message: null,
  errorMessage: null,
};

export const successToastReducers: (
  state: SuccessToastState,
  action: SuccessToastAction,
) => SuccessToastState = (state = initialState, action: SuccessToastAction) => {
  switch (action.type) {
    // Shows the toast
    case SuccessToastActionTypes.ShowSuccessToast:
      return {
        ...state,
        snackbarFlag: true,
        message: action.data as string,
      };

    // Hides the toast
    case SuccessToastActionTypes.HideSuccessToast:
      return {
        ...state,
        snackbarFlag: false,
        message: null,
        errorMessage: null,
      };
    // shows the error toast
    case SuccessToastActionTypes.ShowErrorToast:
      return {
        ...state,
        snackbarFlag: true,
        errorMessage: action.data as string,
      };
    default:
      return state;
  }
};
