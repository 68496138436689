import { takeEvery, put } from 'redux-saga/effects';
import { LoadingIndicatorActions } from '../Actions/LoadingIndicatorAction';
import {
  CustomTermsHeaderAction,
  CustomTermsHeaderActions,
  CustomTermsHeaderActionTypes,
} from '../Actions/CustomTermsHeaderActions';
import { CustomTermsHeaderPayload } from '../Types/customTermsHeader/CustomTermsHeaderRequest.data';
import { CustomTermsHeaderResponse } from '../Types/customTermsHeader/CustomTermsHeaderResponse.data';
import CustomTermsHeaderService from '../Services/customServices/CustomTermsHeaderService';
import { storage, STORAGE_CONSTANTS } from '../Services/LocalStorage';

export function* getCustomTermsHeaderWorker(
  action: CustomTermsHeaderAction,
): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data as CustomTermsHeaderPayload;
    const response: CustomTermsHeaderResponse = (yield CustomTermsHeaderService.getCustomTermsHeader(
      data,
    ))!;
    const config = response && response.config ? response.config : null;
    storage.setItem(STORAGE_CONSTANTS.config, JSON.stringify(config));
    yield put(CustomTermsHeaderActions.postCustomTermsHeaderSuccess(response));
  } catch (error) {
    yield put(CustomTermsHeaderActions.postCustomTermsHeaderError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

const customTermsHeaderWatcher = [
  takeEvery(CustomTermsHeaderActionTypes.GET_TERMS_REQUEST_START, (action) =>
    getCustomTermsHeaderWorker({
      type: action.type,
      data: (action as CustomTermsHeaderAction).data,
    }),
  ),
];

export default customTermsHeaderWatcher;
