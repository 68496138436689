export const requestedReferee = {
  Personal: `{applicantName} has requested a character reference to help them find their next rental property.`,
  Employment: `{applicantName} has requested an employment reference to help them find their next rental property`,
  Rental: `{applicantName} has requested a rental reference for {address} to help them find their next rental property`,
};

export const purposeDiscription = (
  applicantName: string,
) => `You only need to submit this once and it will take less than 5 minutes!
 ${applicantName} will be able to attach this reference to all their future tenancy applications.
 We will keep your responses private and secure, only the real estate agent can read them.
 Thanks for your help supporting ${applicantName}'s application!`;

export const knowMore = `Want to know more about Sorted?`;
export const learnMore = `Click here to learn more`;
export const referenceTool = `Reference tool`;
export const referenceBtnLebel = `Give reference`;
export const thankingReferee = `Thanks for helping {applicantName} find their next home`;
export const sortedHelpingOffer = `Sorted is dedicated to helping agencies like yours get more done in less time`;
export const sortedAppInfo = `We have automated the majority of the tenancy process from
  applications to Tenancy Agreement generation, to rental setup through Sorted Payments.
  We are also integrated with leading Property Management Systems so we can help you grow 
  with no disruption to how you manage your bussiness today.`;
export const requestDemoBtnLebel = `PRESS HERE TO REQUEST A DEMO TODAY`;
export const requestDemoUrl = `https://pipedrivewebforms.com/form/0fa934998a39836056b6f2c4b0f6ce1f7420441`;
export const sortedHelpYouToo = `Sorted is here to help you too`;
export const sortedAvailableFacilities = `Just a few quick taps and everything from applying for a new property, 
to managing your payments and services, even across multiple properties is under control. Life made simple.`;
export const learnMoreUrl = `https://www.sortedservices.com/rea`;
export const errorExpiredLink = `Sorry it looks like this link has expired.`;
export const errorExpiredMsg = `We’ve let the person requesting the reference know this link 
  has expired and they need to send you a new link. Once they have requested the new link, you will receive another 
  email from us in your inbox!`;
export const errorUsedLink = `It looks like you've already finished this reference. Thank you.`;
export const errorUsedMesg = `Your reference has already been submitted and attached to the persons profile.
  It will be used for their tenancy applications moving forward.
  We appreciate you taking the time to help someone find their new home. Thanks!`;
export const errorInvalidLink = `There seems to be something wrong with the link you've followed`;
export const errorInvalidMsg = `The link you have followed can't be matched to a reference request. Sometimes
  this happens if you have copied the link from the request and missed character. Try the link in 
  your original invite again, or check to see if you have received a new invite.`;
export const playStoreUrl = `https://play.google.com/store/apps/details?hl=en&id=com.sortedservices.sorted`;
export const appStoreUrl = `https://apps.apple.com/au/app/sorted/id1232960956`;
export enum errorType {
  ALREADY_USED = 'already used',
  DOES_NOT_EXIST = 'does not exists',
  EXPIRED = 'expired',
}
export const RENTING_THROUGH_AGENT = 'Renting through an agent';
