import { all } from 'redux-saga/effects';
import loginWatcher from './LoginSaga';
import referralWatcher from './ReferralSaga';
import customTermHeaderWatcher from './CustomTermsHeaderSaga';
import dashboardSaga from './DashboardSaga';
import ownershipSaga from './OwnershipSaga';

export default function* sagas() {
  yield all([loginWatcher, referralWatcher, customTermHeaderWatcher, dashboardSaga, ownershipSaga]);
}
