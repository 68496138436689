import { createStyles, WithStyles } from '@material-ui/core';
import { color } from '../../lib/Theme/Theme';
import { SIZES } from '../../lib/Constants';

export const styles = createStyles({
  agencyBackgroundContainer: {
    height: '280px',
    maxHeight: '280px',
    width: '100%',
    position: 'relative',
  },
  agencyBackground: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  agencyLogoContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',

    margin: 'auto',
  },
  agencyText: {
    fontSize: '38px',
    color: color.offBlack,
    maxWidth: '350px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      fontSize: '30px',
    },
  },
  agencyLogo: {
    position: 'relative',
    top: '30px',
    left: '30px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      top: '15px',
      left: '15px',
    },
  },
});

export interface AgencyBackgroundStyleProps extends WithStyles<typeof styles> {}
