/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createStyles, WithStyles } from '@material-ui/core';
import { color } from '../../lib/Theme/Theme';
import { SIZES } from '../../lib/Constants';
import { globalStyles } from '../../lib/Theme/GlobalStyles';

export const styles = createStyles({
  root: {
    [`@media (max-width: ${SIZES.md}px)`]: {
      height: 'calc(100vh - 48px)',
      overflowY: 'scroll',
    },
    backgroundColor: color.secondary,
    height: '100%',
  },
  paperBack: globalStyles.paperBack as any,
  contentContainer: {
    paddingLeft: '80px',
    paddingRight: '80px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
  },
});

export interface LoginStyleProps extends WithStyles<typeof styles> {}
