import { StoreAction } from '../Store/StoreHelper';
import {
  AgencyResponse,
  PropertyDetailsResponse,
  GetFailureDetails,
} from '../Types/agency/AgencyResponse.data';
import {
  GetPropertiesPayload,
  StoreAgencyPayload,
  GetAgencyByAgentPayload,
} from '../Types/agency/AgencyRequest.data';

import { ApplicationState } from '../Reducers';

export enum DashboardActionTypes {
  GET_AGENCY_BY_AGENT_REQUEST = 'GET_AGENCY_BY_AGENT_REQUEST',
  GET_AGENCY_BY_AGENT_SUCCESS = 'GET_AGENCY_BY_AGENT_SUCCESS',
  GET_AGENCY_BY_AGENT_ERROR = 'GET_AGENCY_BY_AGENT_ERROR',

  FETCH_REFERRAL_DETAILS_REQUEST = 'FETCH_REFERRAL_DETAILS_REQUEST',
  FETCH_REFERRAL_DETAILS_SUCCESS = 'FETCH_REFERRAL_DETAILS_SUCCESS',
  FETCH_REFERRAL_DETAILS_ERROR = 'FETCH_REFERRAL_DETAILS_ERROR',

  FETCH_PROPERTY_DETAILS_REQUEST = 'FETCH_PROPERTY_DETAILS_REQUEST',
  FETCH_PROPERTY_DETAILS_SUCCESS = 'FETCH_PROPERTY_DETAILS_SUCCESS',
  FETCH_PROPERTY_DETAILS_ERROR = 'FETCH_PROPERTY_DETAILS_ERROR',

  CLEAR_REFERRAL_ERROR = 'CLEAR_REFERRAL_ERROR',

  FETCH_PROPERTIES_REQUEST = 'FETCH_PROPERTIES_REQUEST',
  FETCH_PROPERTIES_SUCCESS = 'FETCH_PROPERTIES_SUCCESS',
  FETCH_PROPERTIES_ERROR = 'FETCH_PROPERTIES_ERROR',

  STORE_REFERRAL_AGENCY = 'STORE_REFERRAL_AGENCY',
  STORE_PROPERTY_AGENCY = 'STORE_PROPERTY_AGENCY',

  IS_PREFILL_HELPER_CALLED = 'IS_PREFILL_HELPER_CALLED',
  CLEAR_PROPERTIES = 'CLEAR_PROPERTIES',
}

export type DashboardActionPayload =
  | string
  | GetFailureDetails
  | PropertyDetailsResponse
  | PropertyDetailsResponse[]
  | ApplicationState
  | GetPropertiesPayload
  | StoreAgencyPayload
  | GetAgencyByAgentPayload
  | AgencyResponse
  | Error
  | boolean
  | null;

export type DashboardAction = StoreAction<DashboardActionTypes, DashboardActionPayload>;

export class DashboardActions {
  public static doGetReferralDetails(data: string): DashboardAction {
    return { type: DashboardActionTypes.FETCH_REFERRAL_DETAILS_REQUEST, data };
  }
  public static doGetPropertyDetails(data: string): DashboardAction {
    return { type: DashboardActionTypes.FETCH_PROPERTY_DETAILS_REQUEST, data };
  }
  public static storePropertyAgency(data: StoreAgencyPayload): DashboardAction {
    return { type: DashboardActionTypes.STORE_PROPERTY_AGENCY, data };
  }

  public static onGetReferralFailure(data: GetFailureDetails): DashboardAction {
    return { type: DashboardActionTypes.FETCH_REFERRAL_DETAILS_ERROR, data };
  }

  public static clearApplyErrror(): DashboardAction {
    return { type: DashboardActionTypes.CLEAR_REFERRAL_ERROR };
  }

  public static getPropertiesFailure(data: GetFailureDetails): DashboardAction {
    return { type: DashboardActionTypes.FETCH_PROPERTIES_ERROR, data };
  }

  public static storeReferralAgency(data: GetPropertiesPayload): DashboardAction {
    return { type: DashboardActionTypes.STORE_REFERRAL_AGENCY, data };
  }
  public static getAgencyByAgent(data: GetAgencyByAgentPayload): DashboardAction {
    return { type: DashboardActionTypes.GET_AGENCY_BY_AGENT_REQUEST, data };
  }
  public static getAgencyByAgentFailure(data: any): DashboardAction {
    return { type: DashboardActionTypes.GET_AGENCY_BY_AGENT_ERROR, data };
  }
  public static getAgencyByAgentSuccess(data: AgencyResponse): DashboardAction {
    return { type: DashboardActionTypes.GET_AGENCY_BY_AGENT_SUCCESS, data };
  }
}
