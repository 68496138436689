/* eslint-disable no-shadow */
import { Location } from 'history';
// import localStorage from '../../../../services/LocalStorage';

export interface MatchParams {
  agencyCode: string;
  branchCode: string;
}

export const getQueryParams = (location: Location, param: string): string => {
  const query = location.search.substring(1).toLowerCase();
  const vars = query.split('&');
  let returnVal = '';
  vars.forEach((key: string) => {
    const pair = key.split('=');
    if (decodeURIComponent(pair[0]) === param.toLowerCase()) {
      returnVal = decodeURIComponent(pair[1]);
    }
  });
  return returnVal;
};

export const getAllQueryParams = (location: Location, params: MatchParams) => {
  const { agencyCode, branchCode } = params;
  const agency = agencyCode ? agencyCode.toLowerCase() : 'AU_TPCO'; // getQueryParams(location, 'agencyCode');

  // TODO: This is used to set the header, move into the header component
  // localStorage.setItem('agency', agency);
  // localStorage.setItem('branch', branchCode);
  // localStorage.setItem('landingURL', `${location.pathname}${location.search}`);

  const refId = getQueryParams(location, 'id');
  const type = getQueryParams(location, 'type');
  const agentId = getQueryParams(location, 'agentId');
  const address = getQueryParams(location, 'address');
  const suburb = getQueryParams(location, 'suburb');
  const state = getQueryParams(location, 'state');
  const postcode = getQueryParams(location, 'postcode');
  const streetNumber = getQueryParams(location, 'streetNumber');
  const streetName = getQueryParams(location, 'streetName');
  const unitNumber = getQueryParams(location, 'unitNumber');

  return {
    agency,
    refId,
    type,
    agentId,
    address,
    suburb,
    state,
    postcode,
    streetNumber,
    streetName,
    unitNumber,
  };
};
