import CommonState from '../State/CommonState';
import { CommonAction, CommonActionTypes } from '../Actions/CommonActions';

export const initialState: CommonState = {
  bannerUrl: null,
};

export const commonReducers: (state: CommonState, action: CommonAction) => CommonState = (
  state = initialState,
  action: CommonAction,
) => {
  switch (action.type) {
    // Shows the toast
    case CommonActionTypes.AddBannerUrl:
      return {
        ...state,
        bannerUrl: action.data as string,
      };
    default:
      return state;
  }
};
