import { SIZES } from '../../../lib/Constants';
import { color } from '../../../lib/Theme/Theme';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    [`@media (max-width: ${SIZES.md}px)`]: {
      height: 'calc(100vh - 48px)',
    },
    backgroundColor: color.secondary,
    height: '100%',
  },
  newReferralcontainer: {
    marginTop: '20px',
    '&:first-child': {
      marginTop: '0px',
    },
  },

  newReferralTitle: {
    fontSize: '20px',
    color: color.offBlack,
  },
  newReferralTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '15px',
    borderBottom: `2px ${color.borderGray} solid`,
  },
  additionalInfoContainer: {
    marginTop: '10px',
  },
  otherApplicantPaper: {
    padding: '20px',
    position: 'relative',
    marginBottom: 20,
    borderRadius: '6px',
  },

  crossLogo: {
    width: 25,
    height: 25,
    cursor: 'pointer',
    marginLeft: 'auto',
    opacity: 0.6,
  },
  additionalPersonInputContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
  },

  nameStyles: {
    [`@media (max-width: ${SIZES.sm}px)`]: {
      maxWidth: '100%',
      width: '100%',
      flexBasis: 'unset',
      flexGrow: 'unset',
      paddingBottom: '5px !important',
    },
  },
  inputContainerStyle: {
    paddingBottom: '8px',
    width: '100%',
  },

  inputLabelStyle: {
    paddingBottom: '15px',
  },
  errorText: {
    color: color.rusticRed,
    textAlign: 'center',
    marginTop: '10px',
  },
  mobileLabel: {
    marginLeft: '5rem',
  },
  additionalInfo: {
    color: color.secondary300,
    marginTop: '0.4rem',
  },
});

export default useStyles;
