import React, { FC, useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory, withRouter } from 'react-router';
import { useLocation, RouteComponentProps } from 'react-router-dom';
import { ApplicationState, useAppSelector } from '../../lib/Redux/Reducers';
import poweredByText from '../../assets/images/poweredByText.png';
import { HeaderStyle, HeaderStyleProps } from './HeaderStyles';
import sortedLogoHeader from '../../assets/images/sortedLogoHeader.png';
import { routes } from '../../navigation/Routes';
import { LABELS } from './HeaderConstants';
import { storage, STORAGE_CONSTANTS } from '../../lib/Redux/Services/LocalStorage';
import { store } from '../../lib/Redux/Store';
import { SIZES } from '../../lib/Constants';
import { Agency } from '../../lib/Redux/Types/ownershipForm/OwnershipFormResponse.data';

interface HeaderProps extends HeaderStyleProps, RouteComponentProps {
  agencyCode?: string | null | undefined;
}

export interface PropsStyles extends WithStyles<typeof HeaderStyle> {}

/* tslint:disable:cyclomatic-complexity */
const Header: FC<HeaderProps> = (props) => {
  const [isPoweredBy, setIsPoweredBy] = useState(false);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= SIZES.md);
  const isUserLoggedIn = !!(
    storage.getItem(STORAGE_CONSTANTS.userId) &&
    storage.getItem(STORAGE_CONSTANTS.userId) !== 'undefined'
  );
  const history = useHistory();
  const { classes } = props;

  const location = useLocation();
  const dispatch = useDispatch();
  const { applicationState, agencyCode } = useAppSelector((state: ApplicationState) => ({
    applicationState: state,
    agencyCode: state.dashboard.agency,
  }));

  const dispatcher = {};

  const signOut = () => {
    const authTokenBasic = storage.getItem(STORAGE_CONSTANTS.authTokenBasic);
    storage.clearAll();
    storage.setItem(STORAGE_CONSTANTS.authTokenBasic, authTokenBasic!);
    store.dispatch({ type: 'LOG_OUT' });
    history.push(routes.login.viewGeneric);
  };

  useEffect(() => {
    if (location.pathname.includes('applications') || location.pathname.includes('login')) {
      setIsPoweredBy(false);
    } else if (agencyCode === '' || agencyCode === 'SORTED') {
      setIsPoweredBy(false);
    } else {
      setIsPoweredBy(true);
    }
  }, [location.pathname, agencyCode]);

  useEffect(() => {
    const resize = () => {
      const isMobileSize = window.innerWidth <= SIZES.md;
      setIsMobile(isMobileSize);
    };
    resize();
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  const renderLeftButton = () => {
    return (
      <div className={classes.headerButton} onClick={() => history.push(routes.dashboard.new)}>
        {LABELS.MY_ACCOUNT}
      </div>
    );
  };
  return (
    <div className={classes.headerContainer}>
      {isUserLoggedIn && (
        <>
          {renderLeftButton()}
          <div className={classes.signoutButton} onClick={() => signOut()}>
            {LABELS.SIGN_OUT}
          </div>
        </>
      )}
      <div className={classes.sortedLogoContainer}>
        <img
          src={sortedLogoHeader}
          className={`${classes.sortedLogo} ${
            !isPoweredBy ? classes.poweredBySortedLogo : undefined
          }`}
          alt="sorted-logo"
        />
        <img
          src={poweredByText}
          className={`${classes.sortedLogoText} ${
            !isPoweredBy ? classes.poweredByLogoText : undefined
          }`}
          alt="sorted-logo-text"
        />
      </div>
    </div>
  );
};

const HeaderComponent = withStyles(HeaderStyle)(Header);
export default withRouter(HeaderComponent);
