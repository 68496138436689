/* eslint-disable import/prefer-default-export */
export const LABELS = {
  REFERRAL_HEAD: (Referral_COUNT: number): string => {
    return `Referral  ${Referral_COUNT}`;
  },
  ADDITIONAL_HEADER: 'Additional information',
  CONTACT_PERSON: 'Contact',
  DISPLAY_MOBILE: 'Phone',
  DISPLAY_EMAIL: 'Email',
  PROPERTIES: 'Properties',
};
