/* eslint-disable @typescript-eslint/no-unsafe-assignment */
export enum LogLevel {
  None,
  Errors,
  Debug,
  Trace,
}

/**
 * 1. set placeholder ###CONTAINER_REFERRAL_SERVICE_URL### for BaseUrl in build time, set it to uat if it's running locally and process.env.NODE_ENV is 'development'
 * 2. In terraform, ECS task configuration sets REFERRAL_SERVICE_URL to env variable based on the value from Consul node config
 * 3. replaced the placeholder at docker start time with REFERRAL_SERVICE_URL in docker-entrypoint.sh
 */

// baseUrls object
const urls = {
  uat: 'https://tenancy-bff.uat.sortedservices.com/tenancy',
  local: 'http://localhost:8080/tenancy',
  production: 'https://tenancy-bff.prodau.sortedservices.com/tenancy',
};

const BaseUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://###CONTAINER_REFERRAL_SERVICE_URL###/tenancy'
    : urls.uat;

export interface TestSettings {
  readonly testing: boolean;
  readonly logLevel: LogLevel;
}

const logRocketGroup = BaseUrl.includes('tenancy-bff.prodau.sortedservices.com/tenancy')
  ? 'sup/referral-ui-prod'
  : 'sup/referral-ui-uat';

export class AppSettings {
  public static readonly server = {
    baseUrl: BaseUrl, // DO NOT end with '/'!
    mockServices: false, // test use mocks by using jest.mock(), this is for manual testing
  };

  public static readonly logging = {
    logLevel: LogLevel.Trace,
  };

  public static initTesting() {
    AppSettings.logging.logLevel = AppSettings.testSettings.logLevel;

    this.testSettings = {
      ...this.testSettings,
      testing: true,
    };
  }

  public static get currentTestSetting(): TestSettings {
    return AppSettings.testSettings;
  }

  private static testSettings: TestSettings = {
    testing: false,
    logLevel: LogLevel.Debug,
  };

  public static readonly logRocket = {
    group: logRocketGroup,
  };
}
