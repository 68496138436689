import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import React, { FC } from 'react';
import {
  extractStreetTypeAndName,
  manualAddressValidation,
  stateList,
  streetTypeList,
} from './ManualAddressFormConstants';

import { AddressComponent } from '../../../lib/helper/GooglePlaces/GooglePlacesHelper';
import { Formik } from 'formik';
import RenderInput from '../RenderInputField/RenderInputField';
import RenderSelect from '../RenderSelect/RenderSelect';
import useStyles from './AddressPickerStyles';

interface ManualAddressFormProps {
  isOpen: boolean;
  onSubmit: (data: AddressComponent) => void;
  onClose: () => void;
  initialData?: AddressComponent;
}
const fields = {
  unitNumber: 'unitNumber',
  streetName: 'streetName',
  suburb: 'suburb',
  postcode: 'postcode',
  streetType: 'streetType',
  streetNumber: 'streetNumber',
  state: 'state',
  country: 'country',
};

const ManualAddressForm: FC<ManualAddressFormProps> = ({
  isOpen,
  onClose,
  initialData,
  onSubmit,
}) => {
  const classes = useStyles();
  return (
    <Formik
      initialValues={
        initialData
          ? { ...initialData, ...extractStreetTypeAndName(initialData.streetName) }
          : {
              unitNumber: undefined,
              streetName: undefined,
              suburb: undefined,
              postcode: undefined,
              streetNumber: undefined,
              state: undefined,
              streetType: undefined,
              country: 'Australia',
            }
      }
      validationSchema={manualAddressValidation}
      onSubmit={(data) => {
        onSubmit(data);
      }}
    >
      {({ values, submitForm, dirty }) => {
        const country = (values.country || '').trim().toLowerCase();
        const isAustralia = country === 'australia';
        return (
          <Dialog open={isOpen}>
            <DialogTitle>Add Address Manually</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <RenderInput name={fields.unitNumber} label="Unit Number" />
                </Grid>
                <Grid item xs={6}>
                  <RenderInput name={fields.streetNumber} label="Street Number" />
                </Grid>
                <Grid item xs={6}>
                  <RenderInput name={fields.streetName} label="Street Name" />
                </Grid>
                <Grid item xs={6}>
                  <RenderSelect
                    name={fields.streetType}
                    label="Street Type"
                    options={streetTypeList.map((s) => {
                      return { label: s.street_type, value: s.abbreviation };
                    })}
                  />
                </Grid>
                <Grid item xs={6}>
                  <RenderInput name={fields.suburb} label="Suburb" />
                </Grid>
                {isAustralia ? (
                  <Grid item xs={6}>
                    <RenderSelect
                      name={fields.state}
                      label="State"
                      options={stateList.map((s) => {
                        return { label: s, value: s };
                      })}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={6}>
                    <RenderInput name={fields.state} label="State" />
                  </Grid>
                )}
                <Grid item xs={6}>
                  <RenderInput name={fields.postcode} label="PostCode" />
                </Grid>
                <Grid item xs={6}>
                  <RenderInput name={fields.country} label="Country" />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color="secondary"
                fullWidth
                className={classes.buttonSecondary}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                fullWidth
                className={classes.button}
                disabled={!dirty}
                onClick={submitForm}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default ManualAddressForm;
