import React, { FC, useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { withRouter } from 'react-router';
import { styles, AgencyBackgroundStyleProps } from './AgencyBackgroundStyles';
import { ApplicationState, useAppSelector } from '../../lib/Redux/Reducers';
import { DashboardActions } from '../../lib/Redux/Actions/DashboardActions';
import {
  getAgencyBG,
  getAgencyLogos,
  checkURLCode,
  selectAgency,
} from '../../lib/helper/AgencyAssetsHelper';
import { AGENCY } from '../../lib/Theme/Theme';
import { Skeleton } from '@material-ui/lab';

interface AgencyBackgroundProps extends AgencyBackgroundStyleProps, RouteComponentProps {
  logo?: string;
}

export interface AgencyAssets {
  agencyLogo: string;
  agencyBG: string;
  agencyString: string;
}

const AgencyBackground: FC<AgencyBackgroundProps> = ({ classes, location, logo }) => {
  const [isPoweredBy, setIsPoweredBy] = useState(false);
  const [showImageLoader, setImageLoader] = useState(true);
  const history = useHistory();

  const { agencyCode, myAccountFlag, agencyDetails } = useAppSelector(
    (state: ApplicationState) => ({
      myAccountFlag: false,
      agencyDetails: state.dashboard.agencyDetails,
      agencyCode: state.dashboard.agency,
    }),
  );

  const { bannerUrl } = useAppSelector((state: ApplicationState) => ({
    bannerUrl: state.common.bannerUrl,
  }));

  const dispatch = useDispatch();

  const dispatcher = {
    getAgencyByAgent: (agentId: string) => {
      dispatch(DashboardActions.getAgencyByAgent({ agentId, type: 'a' }));
    },
  };

  const agencyAssets = useMemo<AgencyAssets>(() => {
    let code = agencyCode;
    if (location.pathname.includes('login') || myAccountFlag) {
      code = 'SORTED';
      setIsPoweredBy(false);
    } else if (!agencyCode || agencyCode === '' || agencyCode === 'SORTED') {
      code = 'SORTED';
      setIsPoweredBy(false);
    } else {
      setIsPoweredBy(true);
    }
    const agencyLogo = getAgencyLogos(code);
    const agencyBG = getAgencyBG(code);
    const agencyString = selectAgency(code);

    return { agencyLogo, agencyBG, agencyString };
  }, [location.pathname, myAccountFlag, agencyCode]);

  useEffect(() => {
    checkURLCode();
  }, [location.pathname]);

  return (
    <div className={classes.agencyBackgroundContainer}>
      {showImageLoader ? <Skeleton variant="rect" height={280} /> : null}
      {bannerUrl ? (
        <img
          src={bannerUrl}
          className={classes.agencyBackground}
          style={{ display: showImageLoader ? 'none' : 'block' }}
          onLoad={() => setImageLoader(false)}
        />
      ) : null}
      {isPoweredBy && (
        <div className={classes.agencyLogoContainer}>
          {agencyAssets.agencyString === 'SORTED' ? (
            <div className={classes.agencyText}>
              {agencyDetails ? agencyDetails.tradingName : ''}
            </div>
          ) : (
            <img
              src={
                agencyDetails && !!agencyDetails.imageUrl
                  ? agencyDetails.imageUrl
                  : agencyAssets.agencyLogo
              }
              className={classes.agencyLogo}
              style={AGENCY[agencyAssets.agencyString]}
              alt="agency-logo"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(withRouter(AgencyBackground));
