import { SIZES } from '../../../../lib/Constants';
import { color } from '../../../../lib/Theme/Theme';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    [`@media (max-width: ${SIZES.md}px)`]: {
      height: 'calc(100vh - 48px)',
      overflowY: 'scroll',
      backgroundColor: color.secondary,
    },
    [`@media (min-width: ${SIZES.md}px)`]: {
      backgroundColor: color.light,
    },
  },

  myAccountContentContainer: {
    margin: 'auto',
    marginTop: '-15px',
    maxWidth: '1000px',
    borderRadius: '6px',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
  },
  referralTitleContainer: {
    padding: '30px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '15px',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  referralTitle: {
    fontSize: '38px',
    fontWeight: 300,
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: '10px',
      fontSize: '30px',
    },
  },
  referralSubtitle: {
    marginTop: '10px',
    fontSize: '20px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      fontSize: '18px',
    },
  },
  addButtonContainer: {
    marginLeft: '8px',
    padding: '2px 8px 2px 12px',
    borderRadius: 6,
    minWidth: '75px',
    width: 'max-content',
    border: `${color.gray} 2px solid`,
    background: color.light,
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  groupedApplicationCard: {
    marginTop: '15px',
    border: `${color.borderGray} 2px solid`,
    borderRadius: 8,
    padding: '15px',
    paddingTop: '0px',
    paddingBottom: '0px',
    overflow: 'hidden',
  },
  addOwnershipButton: {
    backgroundColor: color.teal,
    fontSize: 16,
    fontWeight: 300,
    border: 'none',
    borderRadius: '6px',
    padding: '15px 70px',
    color: color.light,
    cursor: 'pointer',
    textAlign: 'center',
    flex: 1,
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: '15px',
      padding: '15px',
    },
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '30px',
  },
});

export default useStyles;
