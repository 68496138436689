import React, { FC } from 'react';
import {
  ReferralDetailsFormPayload,
  ReferralPayload,
} from '../../../lib/Redux/Types/referrals/ReferralType.data';

import LABELS from './SubmitReferralConstants';
import ReferralDetailCard from '../../../components/referralDetailCard/ReferralDetailCard';
import Typography from '@material-ui/core/Typography';
import useStyles from './SubmitReferralStyles.data';

interface SubmitReferralProps {
  newReferrals: ReferralDetailsFormPayload | undefined;
}
const SubmitReferralDetails: FC<SubmitReferralProps> = ({ newReferrals }) => {
  const classes = useStyles();
  return (
    <div>
      <div className={`${classes.card} ${classes.titleContainer}`}>
        <div className={classes.contentContainer}>
          <Typography className={classes.title}>{LABELS.SUBMIT}</Typography>
          <Typography className={classes.subtitle} variant="body2">
            {LABELS.CONFIRM_DETAILS}
          </Typography>
        </div>
      </div>

      <div className={classes.card}>
        <div className={classes.contentContainer}>
          <div>
            <Typography>{LABELS.REFERRALS}</Typography>
          </div>
          <hr />
          {newReferrals?.referrals.map((referral: ReferralPayload, idx: number) => (
            <ReferralDetailCard referral={referral} idx={idx + 1} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubmitReferralDetails;
