import { AppIcon, AppIconSize, AppIconType } from '../AppIcon/AppIcon';
import { Grid, Typography, withStyles } from '@material-ui/core';
import React, { FC } from 'react';

import { color } from '../../lib/Theme/Theme';
import { useStyles } from './UploadingScreenStyles';

interface UploadingScreenProps {
  icon: string | AppIconType;
  iconType: string;
  title: string;
  primaryText?: string;
  secondaryText?: string;
  ternaryText?: string;
}

const UploadingScreen: FC<UploadingScreenProps> = ({
  icon,
  title,
  primaryText,
  secondaryText,
  iconType,
  ternaryText,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.rootContainer}>
      <Grid container>
        <Grid item sm={4} xs={3} />
        <Grid item sm={4} xs={6}>
          <div className={classes.mainContainer}>
            {iconType === 'png' ? (
              <img src={icon} className={classes.iconStyle} alt="icon" />
            ) : (
              <AppIcon
                type={icon as AppIconType}
                size={AppIconSize.ExtraLarge}
                color={color.rdsTextColor}
                className={classes.appIconStyle}
              />
            )}
            <Typography className={classes.titleTextStyle}>{title}</Typography>
            {primaryText && <Typography className={classes.textStyle}>{primaryText}</Typography>}
            {secondaryText && (
              <Typography className={classes.textStyle}>{secondaryText}</Typography>
            )}
            {ternaryText && <Typography className={classes.textStyle}>{ternaryText}</Typography>}
          </div>
        </Grid>
        <Grid item sm={4} xs={3} />
      </Grid>
    </div>
  );
};

export default UploadingScreen;
