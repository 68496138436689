import * as React from 'react';
import { Button, Typography, withStyles, WithStyles } from '@material-ui/core';
import { FooterStyle, FooterComponentStyle } from './FooterStyles';

interface FooterComponentProps {
  redirectLink?: string;
  buttonText: string;
  history?: any;
  onClick?: any;
  disabled?: boolean;
  fullWidth?: boolean;
  type?: string;
}
type OwnProps = FooterComponentProps & FooterComponentStyle;

export interface PropsStyles extends WithStyles<typeof FooterStyle> {}

export class FooterComponent extends React.Component<OwnProps> {
  private handleClick = () => {
    const { history, redirectLink } = this.props;
    history.push(redirectLink);
  };
  public render() {
    const { classes, buttonText, onClick, disabled, fullWidth, type } = this.props;
    const classDisabled = disabled ? classes.disabledButtonText : classes.buttonText;
    return (
      <Button
        className={fullWidth ? classes.buttonFullWidth : classes.button}
        onClick={onClick || this.handleClick}
        disabled={disabled}
        color={disabled ? 'secondary' : 'inherit'}
        variant="contained"
      >
        <Typography className={classDisabled}>{buttonText}</Typography>
      </Button>
    );
  }
}

export default withStyles(FooterStyle)(FooterComponent);
