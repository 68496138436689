import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { createMuiTheme } from '@material-ui/core/styles';

export const layerIndex = {
  labelAboveInput: 0x100,
  iconAboveForm: 0x200,
  dialogAboveForm: 0x300,
  autoCompletePopUp: 0x400,
};

export const color = {
  primary: '#1f0d33',
  secondary: '#fafafa',
  secondary100: 'rgba(0, 0, 0, 0.12)',
  secondary200: '#202020',
  secondary300: '#a9a9a9',
  offBlack: 'rgba(0, 0, 0, 0.87)',
  textarea: 'rgba(0, 0, 0, 0.07)',
  atomic: '#3d505a',
  charcoal: '#4a4a4a',
  secondary500: 'rgba(0, 0, 0, 0.6)',
  secondary600: '#1e1e28',
  light: '#ffffff',
  dark: '#000000',
  darkBlack: '#222222',
  dark600: '#F6F6F6',
  dark900: '#666666',
  grey: '#e7e7e7',
  greyText: '#b9b9b9',
  selected: '#ebebeb',
  completeBlue: '#2ca7c0',
  sapling: '#1F2D61',
  pbs: '#002664',
  carrotOrange: '#f28f1d',
  sherpaBlue: '#003348',
  rusticRed: '#1e0f14',
  skyBlue: '#40BCA8',
  incompleteRed: '#ff3848',
  optionalGrey: '#757575',
  teal: '#2ca7c0',
  backgroundBlue: 'rgba(27, 173, 193, 0.1)',
  sortedNavy: '#2d1b49',
  inStyleAlmostBlack: '#011A2B',
  civm: '#252525',
  harcourtsBlue: '#001F48',
  theAgencyGrey: '#313131',
  errorColorRed: '#f44336',
  warning: '#FF525B',
  success: '#03b2cb',
  lightGray: '#f6f6f6',
  gray: '#c5cbd5',
  progressIconGray: '#c6cbd4',
  borderGray: '#eeeeee',
  dellore: '#2A2B2C',
  completeLabel: '#baebf4',
  incompleteLabel: '#ffd3d3',
  yellowLabel: '#fef2de',
  rdsTextColor: '#FFFFFF',
  rdsBackground: '#2D1B49',
  red1: '#b80000',
  grey1: 'grey',
  borderBottom: 'rgba(0, 0, 0, 0.1)',
  boxShadow: 'rgba(0, 0, 0, 0.15)',
  bannerBackground: '#e8f5fdcf',
};

export const AGENCY = {
  IPG: {
    height: '45px',
  },
  PBS: {
    height: '40px',
  },
  LSI: {
    height: '45px',
  },
  NAX: {
    height: '60px',
  },
  SORTED: {
    height: '40px',
  },
  INS: {
    height: '50px',
  },
  GEO: {
    height: '40px',
  },
  AU_PROP: {
    height: '40px',
  },
  AU_BUXT: {
    height: '45px',
  },
  AU_CIVM: {
    height: '40px',
  },
  AU_PFLN: {
    height: '85px',
  },
  AU_HRCT: {
    height: '50px',
  },
  AU_OHOS: {
    height: '115px',
  },
  AU_LUTN: {
    height: '40px',
  },
  AU_AGCY: {
    height: '40px',
  },
  AU_BRNT: {
    height: '65px',
  },
  AU_EXCD: {
    height: '70px',
  },
  AU_FUSE: {
    height: '90px',
  },
  AU_MTRO: {
    height: '120px',
  },
  AU_DLPM: {
    height: '130px',
  },
  AU_GLPM: {
    height: '50px',
  },
  AU_TPCO: {
    height: '125px',
  },
  AU_ELDR: {
    height: '65px',
  },
  AU_REDB: {
    height: '85px',
  },
};

export const themeOptions: ThemeOptions = {
  typography: {
    // useNextVariants: true,
    fontFamily: ['GT Walsheim', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    h4: {
      fontSize: 13,
      fontWeight: 300,
      lineHeight: 1.15,
    },
    h5: {
      fontSize: 27.2,
      fontWeight: 600,
      lineHeight: 'normal',
      color: color.primary,
    },
    h6: {
      fontSize: 23.3,
      fontWeight: 300,
      color: color.secondary200,
    },
    subtitle1: {
      fontSize: 16,
      lineHeight: 1.5,
      color: color.secondary500,
    },
    body1: {
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 300,
      color: color.secondary200,
    },
    body2: {
      fontSize: 19,
      lineHeight: 1.23,
      color: color.secondary200,
    },
    caption: {
      fontSize: 13.6,
      fontWeight: 600,
      lineHeight: 1.18,
      letterSpacing: 0.4,
      color: color.primary,
    },
    button: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 1.14,
      letterSpacing: 0.8,
      color: color.secondary200,
    },
  },
  overrides: {
    MuiTab: {
      root: {
        textTransform: 'initial',
        fontWeight: 'normal',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'initial',
        '&$disabled': {
          backgroundColor: '#202020',
          color: '#000000',
          textAlign: 'center',
        },
      },
    },
    MuiSvgIcon: {
      root: {
        cursor: 'pointer',
      },
    },
    MuiInputLabel: {
      formControl: {
        transform: 'translate(10px, 34px)',
        zIndex: layerIndex.labelAboveInput,
        '&:after': {
          color: color.primary,
        },
      },
    },
    MuiSelect: {
      icon: {
        right: 8,
      },
      select: {
        padding: '14px 10px 6px 10px',
      },
      selectMenu: {
        height: '30px',
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: `2px solid rgba(0, 0, 0, 0.1)`,
        },
        '&:after': {
          borderBottom: `2px solid #1f0d33`,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        fontWeight: 'unset',
        fontSize: '0.75rem',
      },
    },
    MuiRadio: {
      root: {
        color: color.dark,
      },
      colorSecondary: {
        '&$checked': {
          color: color.dark,
        },
      },
    },
  },
  palette: {
    primary: {
      light: color.light,
      main: color.primary,
      dark: color.dark,
      contrastText: '#fff',
    },
    secondary: {
      light: color.light,
      main: color.secondary,
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
};
export const themeConstants = {
  inputBackgroundColor: color.dark600,
  fontSize: {
    lg: 16,
    md: 16,
    sm: 14,
    xs: 12,
    xs2: 10,
    xs3: 8,
  },
};

const theme = createMuiTheme(themeOptions);

export default theme;
