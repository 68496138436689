import { SIZES } from '../../lib/Constants';
import { color } from '../../lib/Theme/Theme';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  card: {
    margin: 'auto',
    marginTop: '20px',
    marginBottom: '20px',
    maxWidth: '1000px',
    borderRadius: '6px',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
    backgroundColor: color.light,
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: '15px',
    },
  },

  subCard: {
    margin: 'auto',
    marginBottom: '20px',
    borderRadius: '6px',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
    backgroundColor: color.light,
    paddingRight: '4rem',
    width: '100%',
    [`@media (max-width: ${SIZES.md}px)`]: {
      paddingRight: '1.5rem',
    },
  },

  subSectionBox: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  cardRemoveButton: {
    position: 'absolute',
    marginTop: '2rem',
    marginRight: '0',
    color: color.red1,
    [`@media (max-width: ${SIZES.md}px)`]: {
      position: 'relative',
    },
  },

  addNewButton: {
    color: color.success,
    padding: '0.5rem',
    width: '100%',
    borderRadius: '0',
    background: color.backgroundBlue,
  },

  noItemsLabel: {
    fontSize: '0.9rem',
    fontStyle: 'italic',
    color: color.grey1,
  },

  titleContainer: {
    padding: '30px',
    paddingTop: '15px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '15px',
      paddingBottom: '35px',
    },
  },

  contentContainer: {
    padding: '32px',
    paddingTop: '8px',
    width: '100%',
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '15px',
      paddingTop: '8px',
      paddingBottom: '35px',
    },
  },
  cardContentContainer: {
    padding: '1rem',
    width: '100%',
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '5px',
      paddingTop: '8px',
      paddingBottom: '35px',
    },
  },
  cardContent: {
    paddingRight: '1.5rem',
  },
  subCardContentContainer: {
    padding: '0 32px',
    paddingBottom: '16px',
    width: '100%',
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '15px',
      paddingTop: '8px',
      paddingBottom: '35px',
    },
  },
  subSectionContainer: {
    marginTop: '1rem',
    border: '2px solid #eeeeee',
    borderRadius: 6,
    padding: '1rem 2rem',
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '0.4rem',
      border: '1px solid #eeeeee',
    },
  },
  expansionPanelRoot: {
    'width': '100%',
    '&:before': {
      backgroundColor: color.light,
    },
  },
  expansionPanelSummary: {
    margin: '0 8',
    padding: '8px 20px 8px 32px',
    boxShadow: 'none',
  },

  buttonContainer: {
    margin: 'auto',
    marginTop: '20px',
    maxWidth: '1000px',
    borderRadius: '6px',
    marginBottom: '80px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  button: {
    marginLeft: '30px',
    width: '50%',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: color.sortedNavy,
    borderRadius: '6px',
    color: color.light,
    cursor: 'pointer',
    '&:hover': {
      color: color.primary,
    },

    [`@media (max-width: ${SIZES.md}px)`]: {
      marginLeft: '0px',
      width: '100%',
    },
  },
  phoneNumberInput: {
    marginTop: '-15px',
  },
  counterWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 'auto',
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: '15px',
    },
  },
  numberChangeStyle: {
    cursor: 'pointer',
    filter: 'brightness(0)',
    height: '25px',
    width: '25px',
  },
  numberStyle: {
    padding: '0 9px 0',
  },
  counter: {
    minWidth: '20px',
    textAlign: 'center',
  },
  heading: {
    marginTop: '8px',
  },
  subSectionHeader: {
    paddingTop: '1rem',
    marginBottom: '-1.75rem',
    zIndex: 1,
    width: 'fit-content',
    background: color.light,
    padding: '0 1rem',
    marginLeft: '0.75rem',
  },
  multiSectionGrid: {
    marginTop: '8px',
    padding: '8px',
  },
  counterContainer: {
    width: '100%',
    paddingBottom: '0.5rem',
  },
});

export default useStyles;
