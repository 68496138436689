/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useEffect, useState } from 'react';
import {
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Paper,
  Typography,
  withStyles,
  Link,
} from '@material-ui/core';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import errorIcon from '../../../../assets/images/error-outline-24-px.svg';
import visibilityOn from '../../../../assets/images/outline-remove-red-eye-24-px.svg';
import visibilityOff from '../../../../assets/images/outline-visibility-off-24-px.svg';
import { storage, STORAGE_CONSTANTS } from '../../../../lib/Redux/Services/LocalStorage';
import { RegisterUserPayload } from '../../../../lib/Redux/Services/login/LoginData';
import { LoginActions } from '../../../../lib/Redux/Actions/LoginActions';
import { ApplicationState, useAppSelector } from '../../../../lib/Redux/Reducers';
import { View } from '../../Login';
import { LABELS, VALIDATION_SCHEMA } from './SignupFormConstants';
import { SignupFormStyles, styles } from './SignupFormStyles';

interface SignupFormProps extends SignupFormStyles {
  setView: (view: View) => void;
  setUserDetails: (userDetails: RegisterUserPayload) => void;
  userDetails: RegisterUserPayload | null;
}

const SignupForm: FC<SignupFormProps> = ({ classes, setView, setUserDetails, userDetails }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { registerError, registerClientDetails } = useAppSelector((state: ApplicationState) => ({
    registerError: state.login.registerError,
    registerClientDetails: state.login.registerClientDetails,
  }));
  const dispatcher = {
    postRegister: (data: RegisterUserPayload, onSuccess: () => void) => {
      dispatch(LoginActions.postRegisterUserStart({ ...data, onSuccess }));
    },
  };

  useEffect(() => {
    // TODO move out to higher up auth handler
    if (registerClientDetails) {
      const { client_id, client_secret } = registerClientDetails;
      storage.setItem(STORAGE_CONSTANTS.clientId, client_id);
      storage.setItem(STORAGE_CONSTANTS.clientSecret, client_secret);
      const basicAuthHash = window.btoa(`${client_id}:${client_secret}`);
      storage.setItem(STORAGE_CONSTANTS.authTokenBasic, basicAuthHash);
    }

    if (registerError && registerError.response && registerError.response.status === 400) {
      setErrorMessage('User already exists. Please use the sorted credentials to login');
    }
  }, [registerError, registerClientDetails]);

  const postRegisterDetails = (data: RegisterUserPayload) => {
    dispatcher.postRegister(data, () => {
      setUserDetails({ ...data });
      setView(View.Verify);
    });
  };

  return (
    <Paper className={classes.secondaryTile}>
      <Formik
        initialValues={{
          firstName: userDetails && userDetails.firstName ? userDetails.firstName : '',
          lastName: userDetails && userDetails.lastName ? userDetails.lastName : '',
          email: userDetails && userDetails.email ? userDetails.email : '',
          password: '',
          // redirectionParams: `redirectLink=${window.location.toString().split('#/')[1] || ''}`,
        }}
        validateOnChange
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={(data) => postRegisterDetails(data)}
        render={({ values, handleSubmit, setFieldValue, errors, touched }) => (
          <form onSubmit={handleSubmit}>
            <div className={classes.titleHeader}>
              <div>
                <Typography variant="h4" className={classes.title}>
                  {LABELS.SIGNUP_TITLE}
                </Typography>
                <Typography className={classes.subtitle}>{LABELS.SIGNUP_SUBTITLE}</Typography>
              </div>
            </div>
            <div className={classes.contentContainer}>
              {/* EMAIL ----------------------------------------------------------------- */}
              <FormControl
                error={!!errors.email && !!touched.email}
                className={classes.inputContainerStyle}
              >
                <InputLabel>
                  <Typography variant="subtitle1">{LABELS.EMAIL_ADDRESS}</Typography>
                </InputLabel>

                <Input
                  className={classes.inputStyle}
                  name="email"
                  type="text"
                  onChange={(event) => {
                    setFieldValue('email', event.target.value);
                  }}
                  value={values.email}
                  endAdornment={
                    <InputAdornment position="end">
                      <img src={!!errors.email && !!touched.email ? errorIcon : ''} alt="" />
                    </InputAdornment>
                  }
                />
                {errorMessage && <div className={classes.errorText}>{errorMessage}</div>}
                <FormHelperText>
                  <ErrorMessage name="email" />
                </FormHelperText>
              </FormControl>

              {/* FIRSTNAME ----------------------------------------------------------------- */}
              <div className={classes.inputRow}>
                <FormControl
                  error={!!errors.firstName && !!touched.firstName}
                  className={classes.inputContainerStyle}
                >
                  <InputLabel>
                    <Typography variant="subtitle1">{LABELS.FIRST_NAME}</Typography>
                  </InputLabel>

                  <Input
                    className={classes.inputStyle}
                    name="firstName"
                    type="text"
                    onChange={(event) => {
                      setFieldValue('firstName', event.target.value);
                    }}
                    value={values.firstName}
                    endAdornment={
                      <InputAdornment position="end">
                        <img
                          src={!!errors.firstName && !!touched.firstName ? errorIcon : ''}
                          alt=""
                        />
                      </InputAdornment>
                    }
                  />
                  <FormHelperText>
                    <ErrorMessage name="firstName" />
                  </FormHelperText>
                </FormControl>

                {/* LASTNAME ----------------------------------------------------------------- */}
                <FormControl
                  error={!!errors.lastName && !!touched.lastName}
                  className={classes.inputContainerStyle}
                >
                  <InputLabel>
                    <Typography variant="subtitle1">{LABELS.LAST_NAME}</Typography>
                  </InputLabel>

                  <Input
                    className={classes.inputStyle}
                    name="lastName"
                    type="text"
                    onChange={(event) => {
                      setFieldValue('lastName', event.target.value);
                    }}
                    value={values.lastName}
                    endAdornment={
                      <InputAdornment position="end">
                        <img
                          src={!!errors.lastName && !!touched.lastName ? errorIcon : ''}
                          alt=""
                        />
                      </InputAdornment>
                    }
                  />
                  <FormHelperText>
                    <ErrorMessage name="lastName" />
                  </FormHelperText>
                </FormControl>
              </div>

              {/* PASSWORD ----------------------------------------------------------------- */}
              <FormControl
                error={!!errors.password && !!touched.password}
                className={classes.inputContainerStyle}
              >
                <InputLabel>
                  <Typography variant="subtitle1">{LABELS.PASSWORD}</Typography>
                </InputLabel>

                <Input
                  className={classes.inputStyle}
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={(event) => {
                    setFieldValue('password', event.target.value);
                  }}
                  value={values.password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)}>
                        <img src={showPassword ? visibilityOn : visibilityOff} alt="" />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText>
                  <ErrorMessage name="password" />
                </FormHelperText>
              </FormControl>

              <div className={classes.footerContainer}>
                <div className={classes.secondaryActionContainer}>
                  <Typography className={classes.secondaryActionText} variant="body2">
                    {LABELS.ALREADY_HAVE_ACCOUNT}
                  </Typography>
                  <div className={classes.secondaryAction} onClick={() => setView(View.Login)}>
                    {LABELS.LOGIN_HEADER}
                  </div>
                </div>
                <button
                  className={classes.signupButton}
                  onMouseDown={() => handleSubmit()}
                  type="submit"
                >
                  {LABELS.SIGNUP_BUTTON}
                </button>
              </div>
            </div>
          </form>
        )}
      />
    </Paper>
  );
};
export default withStyles(styles)(SignupForm);
