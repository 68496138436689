import { SIZES } from '../../lib/Constants';
import { color } from '../../lib/Theme/Theme';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    [`@media (max-width: ${SIZES.md}px)`]: {
      height: 'calc(100vh - 48px)',
      overflowY: 'scroll',
      backgroundColor: color.secondary,
    },
    [`@media (min-width: ${SIZES.md}px)`]: {
      backgroundColor: color.light,
    },
  },
  agencyGroup: {
    paddingBottom: '16px',
    width: '100%',
  },
  agencyHeader: {
    backgroundColor: color.lightGray,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '-16px',
    marginRight: '-16px',
    padding: '8px 16px',
    minHeight: '50px',
  },
  agencyLogo: {
    width: '50px',
    height: '50px',
    borderRadius: 5000,
    backgroundColor: color.light,
  },
  agencyName: {
    marginRight: 'auto',
    marginLeft: '12px',
    fontSize: '16px',
    color: color.offBlack,
  },
});

export default useStyles;
