/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { takeEvery, put } from 'redux-saga/effects';
import { LoadingIndicatorActions } from '../Actions/LoadingIndicatorAction';
import { SuccessToastActions } from '../Actions/SuccessToastAction';
import {
  ReferralsAction,
  ReferralsActions,
  ReferralsActionTypes,
} from '../Actions/ReferralsActions';
import { ReferralListResponse } from '../Types/referrals/ReferralType.data';
import ReferralService from '../Services/referral/ReferralService';

export function* saveReferrals(action: ReferralsAction): IterableIterator<any> {
  try {
    const { referrals, onSuccess, agencyCode } = action.data;
    const data = { 'referrals': referrals, agencyCode };
    yield put(LoadingIndicatorActions.show());
    const response: string = (yield ReferralService.saveReferrals(data))!;
    if (response) {
      yield put(ReferralsActions.saveReferralsSuccess(response));
      yield put(SuccessToastActions.showToast(response));
      if (onSuccess) {
        onSuccess();
      }
    }
  } catch (error) {
    yield put(SuccessToastActions.showError(error.message));
    yield put(ReferralsActions.saveReferralsError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* getReferrals(action: ReferralsAction): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const agencyCode = action.data;
    const response: ReferralListResponse | undefined = yield ReferralService.getReferrals(
      agencyCode,
    );
    if (response) yield put(ReferralsActions.onGetReferralSuccess(response));
  } catch (error) {
    yield put(SuccessToastActions.showError(error.message));
    yield put(ReferralsActions.onGetReferralError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

const referralWatcher = [
  takeEvery(ReferralsActionTypes.SAVE_REFERRALS_REQUEST, (action) =>
    saveReferrals({
      type: action.type,
      data: (action as ReferralsAction).data,
    }),
  ),
  takeEvery(ReferralsActionTypes.FETCH_REFERRAL_DETAILS_REQUEST, getReferrals),
];

export default referralWatcher;
