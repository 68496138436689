import {
  GetFailureDetails,
  GetOwnershipDetailsResponse,
  GetOwnershipPropertiesResponse,
} from '../Types/ownershipForm/OwnershipFormResponse.data';
import { OwnershipAction, OwnershipActionTypes } from '../Actions/OwnershipActions';

import OwnershipState from '../State/OwnershipState';

export const initialState: OwnershipState = {
  error: '',
  loading: false,
  ownershipDetails: undefined,
  userType: undefined,
  property: undefined,
  forms: undefined,
  canUpdateOwnership: true,
};

export const ownershipReducer: (
  state: OwnershipState,
  action: OwnershipAction,
) => OwnershipState = (state = initialState, action: OwnershipAction) => {
  switch (action.type) {
    case OwnershipActionTypes.GET_OWNERSHIP_DETAILS_REQUEST:
      return {
        ...state,
        ownershipDetails: undefined,
        forms: undefined,
        ownerId: undefined,
        ownershipAgency: undefined,
        loading: true,
      };
    case OwnershipActionTypes.GET_OWNERSHIP_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        ownershipDetails: (action.data as GetOwnershipDetailsResponse).ownershipDetails,
        userType: (action.data as GetOwnershipDetailsResponse).userType,
        ownerId: (action.data as GetOwnershipDetailsResponse).ownerId,
        ownershipAgency: (action.data as GetOwnershipDetailsResponse).agency,
        error: undefined,
        forms: (action.data as GetOwnershipDetailsResponse).forms,
        canUpdateOwnership: (action.data as GetOwnershipDetailsResponse).canUpdateOwnership,
      };
    case OwnershipActionTypes.GET_OWNERSHIP_DETAILS_ERROR:
      return {
        ...state,
        ownershipDetails: undefined,
        loading: false,
        error: action.data as GetFailureDetails,
      };

    case OwnershipActionTypes.UPDATE_OWNERSHIP_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case OwnershipActionTypes.UPDATE_OWNERSHIP_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    case OwnershipActionTypes.UPDATE_OWNERSHIP_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data as GetFailureDetails,
      };
    case OwnershipActionTypes.UPDATE_OWNERSHIP_DIALOG_ERROR:
      return {
        ...state,
        loading: false,
      };
    case OwnershipActionTypes.UPDATE_OWNERSHIP_PROPERTIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case OwnershipActionTypes.UPDATE_OWNERSHIP_PROPERTIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    case OwnershipActionTypes.UPDATE_OWNERSHIP_PROPERTIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data as GetFailureDetails,
      };
    case OwnershipActionTypes.GET_OWNERSHIP_PROPERTIES_REQUEST:
      return {
        ...state,
        property: undefined,
        loading: true,
        error: undefined,
      };
    case OwnershipActionTypes.GET_OWNERSHIP_PROPERTIES_SUCCESS:
      return {
        ...state,
        property: action.data as GetOwnershipPropertiesResponse,
        loading: false,
        error: undefined,
      };
    case OwnershipActionTypes.GET_OWNERSHIP_PROPERTIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data as GetFailureDetails,
        property: undefined,
      };
    case OwnershipActionTypes.UPDATE_OWNER_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case OwnershipActionTypes.UPDATE_OWNER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    case OwnershipActionTypes.UPDATE_OWNER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data as GetFailureDetails,
      };
    default:
      return state;
  }
};
