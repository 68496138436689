/* eslint-disable */
import { isNull } from 'lodash';
import { GooglePlacesAutoComplete } from './GooglePlacesAutoComplete.data';
import AddressValue from './AddressValue.data';
import { TypeHelper } from '../TypeHelper';
import { Logging } from '../Logging';
import './ContainerStyle.css';

export type GooglePlaceResult = google.maps.places.PlaceResult;

export class GooglePlacesHelper {
  // google is not avaiable when running the tests in jest
  public static createGooglePlacesAutoComplete(
    input: HTMLInputElement,
    listener: (value: AddressValue) => void,
  ): GooglePlacesAutoComplete {
    let ctr: google.maps.places.Autocomplete | null = null;

    if (typeof google !== 'undefined') {
      ctr = new google.maps.places.Autocomplete(input, {});
      ctr.setComponentRestrictions({ country: ['au'] });
      ctr.addListener('place_changed', () =>
        listener({ inputValue: input.value, place: ctr!.getPlace() }),
      );
    } else {
      // Mock implementation for testing
      input.addEventListener('change', (e) => listener({ inputValue: input.value, place: null }));
    }

    return { input, listener, autoComplete: ctr };
  }

  public static fixAddressSubPremise(address: AddressValue): AddressValue {
    if (address && address.inputValue && address.place) {
      const { inputValue } = address;
      const { place } = address;
      const route = place.address_components!.find(
        (t) => t.types && !!t.types.find((t2) => t2 === 'route'),
      );

      if (route && route.long_name) {
        const routePrefix = route.long_name.split(' ', 1)[0];
        const i = inputValue.indexOf(routePrefix);
        const j = place.formatted_address!.indexOf(routePrefix);

        if (i >= 0 && j >= 0) {
          const fullStreetNumber = inputValue.substr(0, i).trim();
          const routeIndex = place.address_components!.findIndex(
            (t) =>
              !!t.long_name &&
              t.long_name !== fullStreetNumber &&
              t.types &&
              !!t.types.find((t2) => t2 === 'street_number'),
          );

          if (routeIndex >= 0) {
            const newFormattedAddress = `${fullStreetNumber} ${place.formatted_address!.substr(j)}`;
            Logging.trace(
              `GooglePlacesHelper.fixAddressSubPremise: Changing Google address from '${place.formatted_address}' to '${newFormattedAddress}'`,
            );

            return {
              ...address,
              place: {
                ...place,
                formatted_address: newFormattedAddress,
                // arrayReplaceAt() handles invalid index
                address_components: TypeHelper.arrayReplaceAt(
                  place.address_components!,
                  routeIndex,
                  {
                    ...place.address_components![routeIndex],
                    long_name: fullStreetNumber,
                    short_name: fullStreetNumber,
                  },
                ),
              },
            };
          }
        }
      }
    }

    return address;
  }
}

export interface AddressComponent {
  unitNumber?: string;
  POBoxNumber?: string;
  streetNumber?: string;
  streetName?: string;
  streetType?: string;
  suburb?: string;
  state?: string;
  postcode?: string;
  country?: string;
}

export const getAddressComponents = (address: AddressValue): AddressComponent => {
  const addressComponents: AddressComponent = {};
  (address.place?.address_components || []).forEach((element: any) => {
    if (element.types.includes('street_number')) {
      addressComponents.streetNumber = element.long_name;
    } else if (element.types.includes('route')) {
      addressComponents.streetName = element.short_name;
    } else if (element.types.includes('locality')) {
      addressComponents.suburb = element.long_name;
    } else if (element.types.includes('administrative_area_level_1')) {
      addressComponents.state = element.short_name;
    } else if (element.types.includes('postal_code')) {
      addressComponents.postcode = element.long_name;
    } else if (element.types.includes('country')) {
      addressComponents.country = element.long_name;
    }
  });
  return addressComponents;
};

export const createAddressString = (data: AddressComponent) => {
  const unitNumber = data.unitNumber ? `${data.unitNumber}/` : '';
  return `${unitNumber}${data.streetNumber} ${data.streetName}${
    isNull(data.streetType) ? '' : ` ${data.streetType}`
  }, ${data.suburb} ${data.state} ${data.postcode}, ${data.country}`;
};
