import React, { FC, useEffect } from 'react';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';

import ErrorPage from '../errorPage/ErrorPage';
import ErrorScreen from '../referenceToolScreens/errorScreen/ErrorScreen';
import Header from '../header/Header';
import LoginContainer from '../../screens/login/Login';
import MyAccount from '../../screens/myAccount/MyAccount';
import NewReferral from '../../screens/newReferral/NewReferral';
import OwnersInstructionsPage from '../../screens/OwnersInstructionsPage/OwnersInstructionsPage';
import OwnershipForm from '../../screens/ownership/Ownership';
import PrivateRoute from '../privateRoute/PrivateRoute';
import RegistrationConfirmationComponent from '../../screens/registerConfirmation/RegisterConfirmation';
import ResetPasswordConfirmationComponent from '../resetPasswordConfirmation/ResetPasswordConfirmation';
import ResetPasswordContainer from '../resetPassword/ResetPassword';
import { Typography } from '@material-ui/core';
import { routes } from '../../navigation/Routes';
import { useHistory } from 'react-router';

// import AgencyBackground from '../agencyBackground/AgencyBackground';

const referenceToolScreens = [
  'reference-tool',
  'questionnaire',
  'thankyou-screen',
  'error-screen',
  'ownership',
];

const hideAgencyBackgroundScreens = ['login'];

const isReferenceScreen = () => {
  let isReferenceScreen = false;
  const url = window.location.href;
  referenceToolScreens.forEach((element) => {
    if (url.includes(element)) {
      isReferenceScreen = true;
    }
  });
  return isReferenceScreen;
};

const Layout = () => {
  const history = useHistory();
  useEffect(() => {
    const url = window.location.href;
    if (url.includes('new-referral') && url.includes('type=owner')) {
      history.push({
        pathname: routes.login.viewGeneric,
      });
    }
  }, []);
  return (
    <Route
      path="*"
      render={({ location }) => (
        <>
          {!isReferenceScreen() && <Header />}
          {/* <AgencyBackground /> */}

          <Switch>
            <Route path={routes.login.viewGeneric} exact component={LoginContainer} />
            <Route path={routes.login.view(':agencyCode')} exact component={LoginContainer} />
            <Route path={routes.error.new} exact component={ErrorPage} />
            <Route
              path={routes.registrationConfirmation.new}
              exact
              component={RegistrationConfirmationComponent}
            />
            <Route path={routes.thankyouScreen.view} exact render={() => <div>Thanku</div>} />
            <PrivateRoute path={routes.dashboard.new} component={MyAccount} />
            <PrivateRoute path={routes.addReferral.new()} component={NewReferral} />
            <Route path={routes.referenceToolError.view} exact component={ErrorScreen} />

            <Route
              path={routes.resetPasswordConfirmation.new}
              exact
              component={ResetPasswordConfirmationComponent}
            />
            <Route path={routes.resetPassword.view} exact component={ResetPasswordContainer} />
            <Route path={routes.authorityForm.edit} exact component={OwnershipForm} />
            <Route path={routes.ownersInstructions.edit} exact component={OwnersInstructionsPage} />
            <Route path={routes.ownersInstructions.preview} exact component={OwnersInstructionsPage} />
            <Route path={routes.authorityForm.prepare(':token')} exact component={OwnershipForm} />
            <Route path={routes.authorityForm.preview(':token')} exact component={OwnershipForm} />
            <Route component={LoginContainer} />
          </Switch>
        </>
      )}
    />
  );
};

export default withRouter(Layout);
