import { WithStyles, createStyles } from '@material-ui/core';
import { color } from '../../lib/Theme/Theme';

export const ErrorPageStyle = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 72px)',
  },
  errorMessage: {
    color: color.skyBlue,
  },
});

export interface ErrorPageStyles extends WithStyles<typeof ErrorPageStyle> {}
