import * as Yup from 'yup';
import { userType } from '../../lib/Redux/Types/ownershipForm/OwnershipFormResponse.data';

export enum SIGNING_STEPS {
  OWNERSHIP_FORM,
  AUTHORITY_FORM,
  REFERRAL_PROPERTY_FORM,
  TNC,
  SIGNATURE,
  SUCCESS,
  LOADING,
}

export const getSteps = (userType: userType): Array<{ key: SIGNING_STEPS; label: string }> => {
  switch (userType) {
    case 'PrimaryOwner':
      return [
        { key: SIGNING_STEPS.OWNERSHIP_FORM, label: 'Ownership Details' },
        { key: SIGNING_STEPS.AUTHORITY_FORM, label: 'Essential Particulars' },
        { key: SIGNING_STEPS.REFERRAL_PROPERTY_FORM, label: 'Property Details' },
        { key: SIGNING_STEPS.TNC, label: 'Terms & Conditions' },
        { key: SIGNING_STEPS.SIGNATURE, label: 'Sign' },
      ];

    case 'Agent':
      return [
        { key: SIGNING_STEPS.OWNERSHIP_FORM, label: 'Ownership Details' },
        { key: SIGNING_STEPS.AUTHORITY_FORM, label: 'Essential Particulars' },
        { key: SIGNING_STEPS.REFERRAL_PROPERTY_FORM, label: 'Property Details' },
      ];
    case 'SecondaryOwner':
      return [
        { key: SIGNING_STEPS.OWNERSHIP_FORM, label: 'Ownership Details' },
        { key: SIGNING_STEPS.AUTHORITY_FORM, label: 'Essential Particulars' },
        { key: SIGNING_STEPS.REFERRAL_PROPERTY_FORM, label: 'Property Details' },
        { key: SIGNING_STEPS.TNC, label: 'Terms & Conditions' },
        { key: SIGNING_STEPS.SIGNATURE, label: 'Sign' },
      ];
    default:
      return [];
  }
};

const getNextStepForPO = (curStep?: SIGNING_STEPS): SIGNING_STEPS => {
  switch (curStep) {
    case SIGNING_STEPS.OWNERSHIP_FORM:
      return SIGNING_STEPS.AUTHORITY_FORM;
    case SIGNING_STEPS.AUTHORITY_FORM:
      return SIGNING_STEPS.REFERRAL_PROPERTY_FORM;
    case SIGNING_STEPS.REFERRAL_PROPERTY_FORM:
      return SIGNING_STEPS.TNC;
    case SIGNING_STEPS.TNC:
      return SIGNING_STEPS.SIGNATURE;
    case SIGNING_STEPS.SIGNATURE:
      return SIGNING_STEPS.SUCCESS;
    default:
      return SIGNING_STEPS.OWNERSHIP_FORM;
  }
};
const getNextStepForSO = (curStep?: SIGNING_STEPS): SIGNING_STEPS => {
  switch (curStep) {
    case SIGNING_STEPS.OWNERSHIP_FORM:
      return SIGNING_STEPS.AUTHORITY_FORM;
    case SIGNING_STEPS.AUTHORITY_FORM:
      return SIGNING_STEPS.REFERRAL_PROPERTY_FORM;
    case SIGNING_STEPS.REFERRAL_PROPERTY_FORM:
      return SIGNING_STEPS.TNC;
    case SIGNING_STEPS.TNC:
      return SIGNING_STEPS.SIGNATURE;
    case SIGNING_STEPS.SIGNATURE:
      return SIGNING_STEPS.SUCCESS;
    default:
      return SIGNING_STEPS.OWNERSHIP_FORM;
  }
};
const getNextStepForAgent = (curStep?: SIGNING_STEPS): SIGNING_STEPS => {
  switch (curStep) {
    case SIGNING_STEPS.OWNERSHIP_FORM:
      return SIGNING_STEPS.AUTHORITY_FORM;
    case SIGNING_STEPS.AUTHORITY_FORM:
      return SIGNING_STEPS.REFERRAL_PROPERTY_FORM;
    case SIGNING_STEPS.REFERRAL_PROPERTY_FORM:
      return SIGNING_STEPS.SUCCESS;
    default:
      return SIGNING_STEPS.OWNERSHIP_FORM;
  }
};

export const getNextStep = (userType?: userType, curStep?: SIGNING_STEPS): SIGNING_STEPS => {
  switch (userType) {
    case 'PrimaryOwner':
      return getNextStepForPO(curStep);
    case 'Agent':
      return getNextStepForAgent(curStep);
    case 'SecondaryOwner':
    default:
      return getNextStepForSO(curStep);
  }
};

export const getBackStep = (userType?: userType, curStep?: SIGNING_STEPS): SIGNING_STEPS => {
  switch (userType) {
    case 'PrimaryOwner':
      return getBackStepForPO(curStep);
    case 'Agent':
      return getBackStepForAgent(curStep);
    case 'SecondaryOwner':
    default:
      return getBackStepForSO(curStep);
  }
};

export const getBackStepForPO = (curStep?: SIGNING_STEPS): SIGNING_STEPS => {
  switch (curStep) {
    case SIGNING_STEPS.AUTHORITY_FORM:
      return SIGNING_STEPS.OWNERSHIP_FORM;
    case SIGNING_STEPS.REFERRAL_PROPERTY_FORM:
      return SIGNING_STEPS.AUTHORITY_FORM;
    case SIGNING_STEPS.TNC:
      return SIGNING_STEPS.REFERRAL_PROPERTY_FORM;
    case SIGNING_STEPS.SIGNATURE:
      return SIGNING_STEPS.TNC;
    default:
      return SIGNING_STEPS.OWNERSHIP_FORM;
  }
};

const getBackStepForAgent = (curStep?: SIGNING_STEPS): SIGNING_STEPS => {
  switch (curStep) {
    case SIGNING_STEPS.AUTHORITY_FORM:
      return SIGNING_STEPS.OWNERSHIP_FORM;
    case SIGNING_STEPS.REFERRAL_PROPERTY_FORM:
      return SIGNING_STEPS.AUTHORITY_FORM;
    default:
      return SIGNING_STEPS.OWNERSHIP_FORM;
  }
};

const getBackStepForSO = (curStep?: SIGNING_STEPS): SIGNING_STEPS => {
  switch (curStep) {
    case SIGNING_STEPS.AUTHORITY_FORM:
      return SIGNING_STEPS.OWNERSHIP_FORM;
    case SIGNING_STEPS.REFERRAL_PROPERTY_FORM:
      return SIGNING_STEPS.AUTHORITY_FORM;
    case SIGNING_STEPS.TNC:
      return SIGNING_STEPS.REFERRAL_PROPERTY_FORM;
    case SIGNING_STEPS.SIGNATURE:
      return SIGNING_STEPS.TNC;
    default:
      return SIGNING_STEPS.OWNERSHIP_FORM;
  }
};

export const ERRORS = {
  maxStringLength: 'Can be at most 255 characters',
  requiredOwnership: 'Enter ownership type',
  requiredMobileNumber: 'Enter mobile number',
  invalidNameFormat: 'Invalid name format',
  invalidCountryNumber: 'Invalid phone number',
  requiredPrimaryContact: 'Select primary contact',
};

export const VALIDATION_SCHEMA = Yup.object().shape({
  ownershipType: Yup.string().required(ERRORS.requiredOwnership),
});

export const CONTACT_METHODS = {
  SMS: 'SMS',
  EMAIL: 'Email',
  PHONE: 'Phone',
};

export const ContactMethodTypeOptions = [
  {
    value: CONTACT_METHODS.EMAIL,
    label: CONTACT_METHODS.EMAIL,
  },
  {
    value: CONTACT_METHODS.SMS,
    label: CONTACT_METHODS.SMS,
  },
  {
    value: CONTACT_METHODS.PHONE,
    label: CONTACT_METHODS.PHONE,
  },
];

export const DISBURSEMENT_FREQUENCIES = {
  MONTHLY: 'Monthly',
  MID_MONTH: 'Mid Month',
  MID_AND_END_MONTH: 'Mid and end of Month',
};

export const DisbursementFrequencyOptions = [
  {
    value: DISBURSEMENT_FREQUENCIES.MONTHLY,
    label: `${DISBURSEMENT_FREQUENCIES.MONTHLY} (Payments will be disbursed on the last business day each month)`,
  },
  {
    value: DISBURSEMENT_FREQUENCIES.MID_MONTH,
    label: `${DISBURSEMENT_FREQUENCIES.MID_MONTH} (Payments will be disbursed as close as possible to the 15th of each month)`,
  },
  {
    value: DISBURSEMENT_FREQUENCIES.MID_AND_END_MONTH,
    label: `${DISBURSEMENT_FREQUENCIES.MID_AND_END_MONTH} (Can only be arranged if we do not pay bills on your behalf)`,
  },
];

export enum OwnershipType {
  BUSINESS = 'Business',
  PRIVATE = 'Private',
  TRUST = 'Trust',
}

export const OwnershipTypeOptions = [
  {
    value: OwnershipType.BUSINESS,
    label: 'Business',
  },
  {
    value: OwnershipType.PRIVATE,
    label: 'Private',
  },
  {
    value: OwnershipType.TRUST,
    label: 'Trust',
  },
];

export const LABELS = {
  PROPERTY_BANNER_TEXT:
    'Please review all information carefully. This is a Management Authority for',
  SAME_EMAIL_ERROR:
    "If one or more secondary owner shares the the same email address as the primary, add a plus sign followed by a number to the end of the secondary owner's email handle (e.g. test+1@dummy.com, test+2@dummy.com)",
};
