export enum DateFormats {
  uiFormat = 'YYYY-MM-DD',
  apiFormat = 'DD/MM/YYYY',
  datePickerFormat = 'dd/MM/yyyy',
  displayFormat = 'DD MMM YYYY',
  CommentDateAndTime = 'MMM Do YYYY [at] h:mm A',
  medicareFormat = 'MM/YYYY',
  timeFormat = 'HH:mm',
  dateTime = 'Do MMMM YYYY, hh:mmA',
}
