import {
  GetOwnersInstructionsDetailsResponse,
  OwnersInstructions,
} from '../../Types/ownershipForm/OwnershipFormResponse.data';

import ApiService from '../ApiService';
import { Content } from '../../../helper/Content/ContentType.data';
import { MultipartFormData } from '../MultipartFormData.data';
import ServiceType from '../ServiceType.data';

const serviceType = ServiceType.OwnersInstructions;
const service = new ApiService(serviceType);

export const OwnersInstructionsService = {
  getDetails: (
    token: string,
    previewMode: boolean
  ): Promise<GetOwnersInstructionsDetailsResponse> | GetOwnersInstructionsDetailsResponse => {
    return service.get<GetOwnersInstructionsDetailsResponse>({
      route: [token],
      query: {
        previewMode
      }
    });
  },
  postDetails: (token: string, payload: OwnersInstructions) => {
    return service.post(
      {
        route: [token],
      },
      payload,
    );
  },
  postSignature: (token: string, signatureContent: Content[]) => {
    const route: string[] = [token, 'signatures'];
    const signatures: MultipartFormData[] = [];

    signatureContent.map((signature: Content) =>
      signatures.push({
        name: 'signatures',
        content: signature,
      }),
    );

    return service.postMultipartData({ route }, signatures);
  },
};
