import { store } from '../Redux/Store';
import { DashboardActions } from '../Redux/Actions/DashboardActions';

// Agency Logos

import IPG from '../../assets/logos/ipg.png';
import LSI from '../../assets/logos/lsi.png';
import PBS from '../../assets/logos/pbs.png';
import NAX from '../../assets/logos/nax.png';
import SORTED from '../../assets/logos/sorted.png';
import INS from '../../assets/logos/ins.png';
import GEO from '../../assets/logos/geo.png';
import AU_PROP from '../../assets/logos/auprop.png';
import AU_BUXT from '../../assets/logos/aubuxt.png';
import AU_CIVM from '../../assets/logos/aucivm.png';
import AU_PFLN from '../../assets/logos/aupfln.png';
import AU_HRCT from '../../assets/logos/auhrct.png';
import AU_OHOS from '../../assets/logos/auohos.png';
import AU_LUTN from '../../assets/logos/aulutn.svg';
import AU_AGCY from '../../assets/logos/auagcy.png';
import AU_BRNT from '../../assets/logos/aubrnt.png';
import AU_EXCD from '../../assets/logos/auexcd.png';
import AU_FUSE from '../../assets/logos/aufuse.png';
import AU_MTRO from '../../assets/logos/aumtro.png';
import AU_GLPM from '../../assets/logos/auglpm.png';
import AU_DLPM from '../../assets/logos/audlpm.png';
import AU_TPCO from '../../assets/logos/autpco.png';
import AU_ELDR from '../../assets/logos/aueldr.png';
import AU_REDB from '../../assets/logos/auredb.png';

// Agency BGs
import IPG_BG from '../../assets/bgs/ipg.jpg';
import LSI_BG from '../../assets/bgs/lsi.jpg';
import PBS_BG from '../../assets/bgs/pbs.jpg';
import NAX_BG from '../../assets/bgs/nax.jpg';
import SORTED_BG from '../../assets/bgs/sorted.jpg';
import INS_BG from '../../assets/bgs/ins.jpg';
import GEO_BG from '../../assets/bgs/geo.jpg';
import AU_PROP_BG from '../../assets/bgs/au_prop.jpg';
import AU_BUXT_BG from '../../assets/bgs/au_buxt.jpg';
import AU_CIVM_BG from '../../assets/bgs/au_civm.jpg';
import AU_PFLN_BG from '../../assets/bgs/au_pfln.jpg';
import AU_HRCT_BG from '../../assets/bgs/au_hrct.jpg';
import AU_OHOS_BG from '../../assets/bgs/au_ohos.svg';
import AU_LUTN_BG from '../../assets/bgs/au_lutn.svg';
import AU_AGCY_BG from '../../assets/bgs/au_agcy.jpg';
import AU_BRNT_BG from '../../assets/bgs/au_brnt.jpg';
import AU_EXCD_BG from '../../assets/bgs/au_excd.jpg';
import AU_FUSE_BG from '../../assets/bgs/au_fuse.jpg';
import AU_MTRO_BG from '../../assets/bgs/au_mtro.jpg';
import AU_GLPM_BG from '../../assets/bgs/au_glpm.jpg';
import AU_DLPM_BG from '../../assets/bgs/au_dlpm.jpg';
import AU_TPCO_BG from '../../assets/bgs/au_tpco.jpg';
import AU_ELDR_BG from '../../assets/bgs/au_eldr.jpg';
import AU_REDB_BG from '../../assets/bgs/au_redb.jpg';
import DEFAULT_BG from '../../assets/bgs/default.jpg';

export const DEFAULT_AGENCY = 'SORTED';
export const DEFAULT_AGENCY_BG = 'DEFAULT_BG';

const AGENCY_LOGOS = {
  SORTED,
  IPG,
  PBS,
  LSI,
  NAX,
  INS,
  INSAD: INS,
  GEO,
  AU_PROP,
  AU_BUXT,
  AU_CIVM,
  AU_PFLN,
  AU_HRCT,
  AU_OHOS,
  AU_LUTN,
  AU_AGCY,
  AU_BRNT,
  AU_EXCD,
  AU_FUSE,
  AU_MTRO,
  AU_GLPM,
  AU_DLPM,
  AU_TPCO,
  AU_ELDR,
  AU_REDB,
};

const AGENCY_BGS = {
  DEFAULT_BG,
  SORTED: SORTED_BG,
  IPG: IPG_BG,
  PBS: PBS_BG,
  LSI: LSI_BG,
  NAX: NAX_BG,
  INS: INS_BG,
  INSAD: INS_BG,
  GEO: GEO_BG,
  AU_PROP: AU_PROP_BG,
  AU_BUXT: AU_BUXT_BG,
  AU_CIVM: AU_CIVM_BG,
  AU_PFLN: AU_PFLN_BG,
  AU_HRCT: AU_HRCT_BG,
  AU_OHOS: AU_OHOS_BG,
  AU_LUTN: AU_LUTN_BG,
  AU_AGCY: AU_AGCY_BG,
  AU_BRNT: AU_BRNT_BG,
  AU_EXCD: AU_EXCD_BG,
  AU_FUSE: AU_FUSE_BG,
  AU_MTRO: AU_MTRO_BG,
  AU_GLPM: AU_GLPM_BG,
  AU_DLPM: AU_DLPM_BG,
  AU_TPCO: AU_TPCO_BG,
  AU_ELDR: AU_ELDR_BG,
  AU_REDB: AU_REDB_BG,
};

export const checkURLCode = (): void => {
  const codes = [
    'IPG',
    'PBS',
    'IMP',
    'LSI',
    'NAX',
    'INS',
    'INSAD',
    'GEO',
    'AU_PROP',
    'AU_BUXT',
    'AUSTAR',
    'AU_CIVM',
    'AU_PFLN',
    'AU_HRCT',
    'AU_OHOS',
    'AU_LUTN',
    'AU_AGCY',
    'AU_BRNT',
    'AU_EXCD',
    'AU_FUSE',
    'AU_MTRO',
    'AU_DLPM',
    'AU_GLPM',
    'AU_TPCO',
    'AU_ELDR',
    'AU_REDB',
  ];
  const URL = window.location.toString();

  codes.map((code) => {
    if (URL.includes(code)) {
      store.dispatch(DashboardActions.storePropertyAgency({ agency: code }));
    }
  });
};

export function selectAgency(
  agencyCode: string | null | undefined,
  isBackground?: boolean,
): string {
  if (agencyCode) {
    const agencyCodeUpperCased = agencyCode.toUpperCase();
    return agencyCodeUpperCased in AGENCY_LOGOS
      ? agencyCodeUpperCased
      : isBackground
      ? DEFAULT_AGENCY_BG
      : DEFAULT_AGENCY;
  }
  return DEFAULT_AGENCY;
}

export function getAgencyLogos(agencyCode: string | null | undefined): string {
  return AGENCY_LOGOS[selectAgency(agencyCode, false)] as string;
}

export function getAgencyBG(agencyCode: string | null | undefined): string {
  return AGENCY_BGS[selectAgency(agencyCode, true)] as string;
}
