import { Content, ContentType } from './ContentType.data';

export const base64ToBlob = (dataURI: string) => {
  const arr: string[] = dataURI.split(',');
  const byteCharacters = atob(arr[1]);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'image/png' });
  return blob;
};

export const convertbase64ToPngContent = (base64: string): Content => {
  const data = base64ToBlob(base64);
  return {
    contentRef: {
      contentType: ContentType.png,
      name: 'signature',
    },
    data,
    size: data.size,
    dataBase64: base64,
  };
};
