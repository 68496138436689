import ServiceType from '../ServiceType.data';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { API_ROUTE } from '../ApiRoute';
import {
  LoginPayload,
  RegisterUserPayload,
  RefreshTokenPayload,
  VerifyUserPayload,
  VerificationLinkPayload,
  FindUserPayload,
  ForgotPasswordPayload,
  ResetPasswordPayload,
  LoginResponse,
} from './LoginData';

const serviceType = ServiceType.Referral;

export class LoginService {
  private readonly service: ApiServiceBase = new ApiService(serviceType);

  public postLogin(payload: LoginPayload): Promise<LoginResponse> | LoginResponse {
    return this.service.post({ route: [API_ROUTE.LOGIN] }, payload);
  }

  public postRegisterUser(payload: RegisterUserPayload): Promise<void> | void {
    return this.service.post({ route: [API_ROUTE.SIGNUP_USER] }, payload);
  }

  public postRegisterClient(): Promise<void> | void {
    return this.service.post({ route: [API_ROUTE.REGISTER_CLIENT] }, {});
  }

  public postRefreshToken(payload: RefreshTokenPayload): Promise<void> | void {
    return this.service.post({ route: [API_ROUTE.REFRESH_TOKEN] }, payload);
  }

  public postVerifyUser(payload: VerifyUserPayload): Promise<void> | void {
    return this.service.post(
      { route: [API_ROUTE.VERIFY_USER], query: { token: payload.token } },
      {},
    );
  }

  public postVerificationLink(payload: VerificationLinkPayload): Promise<void> | void {
    return this.service.post({ route: ['sendVerificationLink'] }, payload);
  }

  public getUserDetails(payload: FindUserPayload): Promise<void> | void {
    return this.service.get({
      route: ['api', 'apply', 'user'],
      query: { username: payload.username },
    });
  }

  public postForgotPasswordLink(payload: ForgotPasswordPayload): Promise<void> | void {
    return this.service.post({ route: ['forgotPassword', payload.username] }, {});
  }

  public postResetPassword(payload: ResetPasswordPayload): Promise<void> | void {
    return this.service.post(
      {
        route: ['forgotPassword', 'token', 'verify'],
      },
      payload,
    );
  }

  public getGuestToken(): Promise<void> | void {
    return this.service.get({
      route: ['guestToken'],
    });
  }
}

const loginService = new LoginService();
export default loginService;
