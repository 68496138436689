import { values } from 'lodash';
import * as Yup from 'yup';
import { EMAIL_PATTERN, NAME_PATTERN } from '../../lib/Constants';

/* eslint-disable import/prefer-default-export */
export const LABELS = {
  FIRST_NAME: 'First Name*',
  LAST_NAME: 'Last Name*',
  TITLE: 'Title*',
  EMAIL_ADDRESS: 'Email address*',
  MOBILE: 'Phone Number*',
  NEWREFERRAL_TITLE: 'New Referral',
  NEWREFERRAL_SUBTITLE:
    'Add as many referrals as you need and any know properties linked to each contact ',
  HOW_MANY_REFERRAL: 'How many referrals are you submitting today?',

  REFERRALS: 'Referrals',
  OWNERSHIP_LABEL: 'Please select the ownership type *',

  ADDITIONAL_SUBINFO:
    'Let us know how you know them, their role, or important information we should know when getting in touch ',
  CHK_AGRREMENTLABEL: 'I have permission to share the contact details of the referred owners',
  REFERRAL_HEAD: (Referral_COUNT: number): string => {
    return `Referral   ${Referral_COUNT}`;
  },
  ADDITIONAL_HEADER: 'Additional information',
};
export const BUTTON_LABELS = {
  BACK: 'Back',
  NEXT: 'Next',
};
export const ERRORS = {
  requiredTitle: 'Title',
  requiredFirstName: 'Enter first name',
  requiredSurname: 'Enter surname',
  requiredDob: 'Enter date of birth ',
  requiredEmail: 'Please provide your email address',
  requiredMobileNumber: 'Enter mobile number',
  requiredAgreemment: 'You must accept the terms and conditions',
  maxStringLength: 'Can be at most 255 characters',
  invalidNameFormat: 'Invalid name format',
  invalidEmailFormat: 'Invalid email format',
  invalidCountryNumber: 'Invalid phone number',
  duplicateEmail: 'Email should be unique',
};

export const SALUTATIONS = {
  MR: 'Mr.',
  MRS: 'Mrs.',
  MISS: 'Ms.',
};

export const SalutationsTypeOptions = [
  {
    value: SALUTATIONS.MR,
    label: SALUTATIONS.MR,
  },
  {
    value: SALUTATIONS.MRS,
    label: SALUTATIONS.MRS,
  },
  {
    value: SALUTATIONS.MISS,
    label: SALUTATIONS.MISS,
  },
];

export const CONTACT_TYPES = {
  Solicitor: 'Solicitor',
  Accountant: 'Accountant',
  Broker: 'Broker',
  Other: 'Other',
};

export const ContactTypeOptions = [
  { value: CONTACT_TYPES.Solicitor, label: CONTACT_TYPES.Solicitor },
  { value: CONTACT_TYPES.Accountant, label: CONTACT_TYPES.Accountant },
  { value: CONTACT_TYPES.Broker, label: CONTACT_TYPES.Broker },
  { value: CONTACT_TYPES.Other, label: CONTACT_TYPES.Other },
];

export const getSteps = (): string[] => {
  return ['Referral', 'Submit'];
};
const checkEmail = () => (value: any, values: any) => {
  const [a, formValues] = values.from;
  const { referrals } = formValues.value;
  const len = referrals.filter((element: any) => element.email === value).length;
  if (len > 1) return false;
  else return true;
};

export const VALIDATION_SCHEMA = Yup.object().shape({
  referrals: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string()
        .required(ERRORS.requiredFirstName)
        .nullable()
        .max(255, ERRORS.maxStringLength)
        .matches(NAME_PATTERN, ERRORS.invalidNameFormat),
      title: Yup.string().nullable().required(ERRORS.requiredTitle),
      lastName: Yup.string()
        .nullable()
        .required(ERRORS.requiredSurname)
        .max(255, ERRORS.maxStringLength)
        .matches(NAME_PATTERN, ERRORS.invalidNameFormat),
      email: Yup.string()
        .max(255, ERRORS.maxStringLength)
        .nullable()
        .required(ERRORS.requiredEmail)
        .matches(EMAIL_PATTERN, ERRORS.invalidEmailFormat)
        .test('emailTest', ERRORS.duplicateEmail, checkEmail()),
      phoneNumber: Yup.string().required(ERRORS.requiredMobileNumber).nullable(),
    }),
  ),
  chkAgreement: Yup.boolean().oneOf([true], ERRORS.requiredAgreemment),
});
