export const SIZES = {
  sm: 560,
  md: 780,
  lg: 1024,
  lgMax: 1366,
};

export const EMAIL_PATTERN = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export const PASSWORD_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*d)[a-zA-Zd]$/;
export const NAME_PATTERN = /^$|^([A-Za-z])([A-Za-z0-9]+)/;
export const NUMBER_PATTERN = /^$|^[1-9.,][0-9.,]*$/;
export const NUMBER_PATTERN_LESS_THAN_0 = /^$|^[0-9.,]*$/;
export const DATE_OF_BIRTH_PATTERN = /^([0-2^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;
export const MONTH_AND_YEAR_FORMAT = /[01][0-9]\/[0-9][0-9][0-9][0-9]/;
export const MOBILE_NUMBER_PATTERN = /^$|^(04|614|\+614)([0-9]{2})([ .-]?)([0-9]{3})([ .-]?)([0-9]{3})$/;
export const CONTACT_NUMBER_PATTERN = /^$|^(02|03|07|08|04|614|\+614)([^$]?)([0-9]{2})([ .-]?)([0-9]{3})([ .-]?)([0-9]{3})$/;
export const MIN_ID_POINTS = 80;
