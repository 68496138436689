import React, { FC } from 'react';
import { withStyles, Paper } from '@material-ui/core';
import { styles, MyAccountTabsStyleProps } from './MyAccountTabsStyles';
import { EnumAccountTab, AccountTabs } from '../../MyAccountConstant';

interface MyAccountTabsProps extends MyAccountTabsStyleProps {
  onTabChange: (tab: EnumAccountTab) => void;
  selected: EnumAccountTab;
}

const MyAccountTabs: FC<MyAccountTabsProps> = ({ classes, onTabChange, selected }) => {
  return (
    <Paper className={classes.myAccountTabsContainer}>
      <div
        className={`${classes.tab} ${
          selected === EnumAccountTab.myProperties ? classes.tabActive : undefined
        }`}
        onClick={
          selected === EnumAccountTab.myReferral
            ? undefined
            : () => onTabChange(EnumAccountTab.myProperties)
        }
      >
        {AccountTabs.PROPERTY}
        <div />
      </div>
      <div
        className={`${classes.tab} ${
          selected === EnumAccountTab.myReferral ? classes.tabActive : undefined
        }`}
        onClick={
          selected === EnumAccountTab.myProperties
            ? undefined
            : () => onTabChange(EnumAccountTab.myReferral)
        }
      >
        {AccountTabs.REFERRAL}
        <div />
      </div>
    </Paper>
  );
};

export default withStyles(styles)(MyAccountTabs);
