import IPG from '../../assets/logosCircle/ipg.png';
import LSI from '../../assets/logosCircle/lsi.png';
import PBS from '../../assets/logosCircle/pbs.png';
import NAX from '../../assets/logosCircle/nax.png';
import GEO from '../../assets/logosCircle/geo.png';
import AU_PROP from '../../assets/logosCircle/prop.png';
import AU_BUXT from '../../assets/logosCircle/buxt.png';
import AU_CIVM from '../../assets/logosCircle/civm.png';
import AU_PFLN from '../../assets/logosCircle/pfln.png';
import AU_HRCT from '../../assets/logosCircle/hrct.png';
import AU_OHOS from '../../assets/logosCircle/ohos.png';
import AU_LUTN from '../../assets/logosCircle/lutn.png';
import AU_AGCY from '../../assets/logosCircle/ta.png';
import AU_BRNT from '../../assets/logosCircle/brnt.png';
import AU_EXCD from '../../assets/logosCircle/excd.png';
import AU_FUSE from '../../assets/logosCircle/fuse.png';
import AU_MTRO from '../../assets/logosCircle/mtro.png';
import AU_GLPM from '../../assets/logosCircle/glpm.png';
import AU_DLPM from '../../assets/logosCircle/dlpm.png';
import AU_TPCO from '../../assets/logosCircle/tpco.png';
import AU_REDB from '../../assets/logosCircle/redb.png';

enum AgencyName {
  IPG = 'Independent Property Group',
  LSI = 'Laing & Simmons',
  PBS = 'Peter Blackshaw',
  NAX = 'Nelson Alexander',
  GEO = 'Geocon',
  AU_PROP = 'Propic',
  AU_BUXT = 'Buxton',
  AU_CIVM = 'Civium',
  AU_PFLN = 'Paul Flynn',
  AU_HRCT = 'Harcourts',
  AU_OHOS = 'Openhome Online',
  AU_LUTN = 'Luton',
  AU_AGCY = 'The Agency',
  AU_BRNT = 'Barnett',
  AU_EXCD = 'Exceed',
  AU_FUSE = 'Fuse Property',
  AU_MTRO = 'Metropolis Rooms',
  AU_GLPM = 'Geelong Property Managers',
  AU_DLPM = 'Dellore',
  AU_TPCO = 'The Property Collective',
  AU_REDB = 'Red Brick',
}

export const fetchAgencyName = (agency: string): string | undefined => {
  return agency && (AgencyName[agency.toUpperCase()] as string);
};

export const fetchAgencyLogo = (agency: string | undefined | null): string | undefined => {
  const agencyLogos = {
    IPG,
    LSI,
    PBS,
    NAX,
    GEO,
    AU_PROP,
    AU_BUXT,
    AU_CIVM,
    AU_PFLN,
    AU_HRCT,
    AU_OHOS,
    AU_LUTN,
    AU_AGCY,
    AU_BRNT,
    AU_EXCD,
    AU_FUSE,
    AU_MTRO,
    AU_GLPM,
    AU_DLPM,
    AU_TPCO,
    AU_REDB,
  };
  return agency && agencyLogos[agency.toUpperCase()]
    ? (agencyLogos[agency.toUpperCase()] as string)
    : undefined;
};
