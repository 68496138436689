import theme, { color } from '../../../lib/Theme/Theme';

import { makeStyles } from '@material-ui/core/styles';

interface StylesProps {
  filled: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export const useStyles = makeStyles({
  errorMessageStyles: {
    color: color.warning,
    marginTop: 10,
  },
  labelError: {
    color: color.warning,
  },
  labelFormControl: {
    marginTop: '-10px',
  },
  menuItemRoot: {
    fontSize: '16px',
    color: color.dark900,
  },
  label: {
    fontWeight: 500,
    marginLeft: '10px',
  },
  shrink: {
    //  transform: 'translate(0px, -20px) scale(0.75) !important',
  },

  inputSelect: {
    marginTop: theme.spacing(2),
    backgroundColor: '#F6F6F6',
  },
});
