import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { routes } from '../../navigation/Routes';
import { storage, STORAGE_CONSTANTS } from '../../lib/Redux/Services/LocalStorage';

import { TYPE } from '../../screens/login/LoginUtils';

interface PrivateRouteProps {
  component: any;
  path: string;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props;
  const userId = storage.getItem(STORAGE_CONSTANTS.userId);
  const loginType = storage.getItem(STORAGE_CONSTANTS.type);
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        userId ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: routes.login.viewGeneric,
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
