import { SIZES } from '../../lib/Constants';
import { color } from '../../lib/Theme/Theme';
import { globalStyles } from '../../lib/Theme/GlobalStyles';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  title: globalStyles.title as any,
  subtitle: globalStyles.subtitle as any,

  card: {
    margin: 'auto',
    marginTop: '20px',
    maxWidth: '1000px',
    borderRadius: '6px',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
    backgroundColor: color.light,
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: '15px',
    },
  },
  contentContainer: {
    padding: '30px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '15px',
      paddingBottom: '35px',
    },
  },
  titleContainer: {
    marginTop: '-15px',
  },
  howManyReferralContainer: {
    marginTop: '25px',
    borderTop: `2px ${color.borderGray} solid`,
    paddingTop: '25px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    [`@media (min-width: ${SIZES.md}px)`]: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  },

  progressBarContainer: {
    position: 'relative',
    margin: 'auto',
    top: '-38px',
    height: '75px',
    maxWidth: '1000px',
    borderRadius: '6px',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'hidden',
  },
  progressBarWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  counterContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  counterWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 'auto',
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: '15px',
    },
  },
  numberChangeStyle: {
    cursor: 'pointer',
    filter: 'brightness(0)',
    height: '25px',
    width: '25px',
  },
  numberStyle: {
    padding: '0 9px 0',
  },
  counter: {
    minWidth: '20px',
    textAlign: 'center',
  },
  groupIconStyle: {
    paddingRight: '16px',
  },

  buttonContainer: {
    margin: 'auto',
    marginTop: '20px',
    maxWidth: '1000px',
    borderRadius: '6px',
    marginBottom: '80px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  button: {
    marginLeft: '30px',
    width: '50%',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: color.sortedNavy,
    borderRadius: '6px',
    color: color.light,
    cursor: 'pointer',
    '&:hover': {
      color: color.primary,
    },

    [`@media (max-width: ${SIZES.md}px)`]: {
      marginLeft: '0px',
      width: '100%',
    },
  },
  backButton: {
    width: '50%',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: color.light,
    border: `${color.borderGray} 2px solid`,
    borderRadius: '6px',
    color: color.offBlack,
    cursor: 'pointer',
    [`@media (max-width: ${SIZES.md}px)`]: {
      width: '100%',
    },
  },

  agreementContainer: {
    fontSize: '1em',
    color: 'rgba(0,0,0,.6)',
    padding: '20px 30px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '10px 15px',
    },
  },
});

export default useStyles;
