import * as Yup from 'yup';

export const LABELS = {
  RESET_PASSWORD: 'Reset Password',
  PASSWORD: 'Password',
  UPDATE_PASSWORD: 'UPDATE PASSWORD',
};

export const ERRORS = {
  requiredPassword: 'Please enter password',
  minimumRequired: 'Minimum 8 characters required',
};

export const VALIDATION_SCHEMA = Yup.object().shape({
  password: Yup.string().required(ERRORS.requiredPassword).min(8, ERRORS.minimumRequired),
});
