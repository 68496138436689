import { combineReducers, AnyAction } from 'redux';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import LoginState from '../State/LoginState';
import DashboardState from '../State/DashboardState';
import LoadingIndicatorState from '../State/LoadingIndicatorState';
import ReferralState from '../State/ReferralState';
import SuccessToastState from '../State/SuccessToastState';
import CustomTermsHeaderState from '../State/CustomTermsHeaderState';
import OwnershipState from '../State/OwnershipState';
import CommonState from '../State/CommonState';

import { loginReducer } from './LoginReducer';
import dashboardReducer from './DashboardReducer';
import { loadingIndicatorReducer } from './LoadingIndicatorReducer';
import { referralReducer } from './ReferralReducer';
import { successToastReducers } from './SuccessToastReducer';
import { termsHeaderReducer } from './CustomTermsHeaderReducer';
import { ownershipReducer } from './OwnershipReducer';
import { commonReducers } from './CommonReducer';

export interface ApplicationState {
  dashboard: DashboardState;
  loadingIndicator: LoadingIndicatorState;
  login: LoginState;
  referral: ReferralState;
  successToast: SuccessToastState;
  termsHeader: CustomTermsHeaderState;
  ownership: OwnershipState;
  common: CommonState;
}

const appReducer = combineReducers<ApplicationState>({
  login: loginReducer,
  referral: referralReducer,
  dashboard: dashboardReducer,
  loadingIndicator: loadingIndicatorReducer,
  successToast: successToastReducers,
  termsHeader: termsHeaderReducer,
  ownership: ownershipReducer,
  common: commonReducers,
});

const rootReducer: (state: ApplicationState | undefined, action: AnyAction) => ApplicationState = (
  state,
  action,
) => {
  if (action.type === 'LOG_OUT') {
    state = undefined;
  }
  return appReducer(state, action);
};

export const useAppSelector: TypedUseSelectorHook<ApplicationState> = useSelector;

export default rootReducer;
