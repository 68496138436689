import { Button, Grid, Typography } from '@material-ui/core';
import React, { FC, useState } from 'react';

import SignaturePad from '../signaturePad/SignaturePad';
import useStyles from './SignatureFormStyles';

interface SignatureFormProps {
  onSubmit: (signature: string, initials: string) => void;
  onBack?: () => void;
  previewMode?: boolean;
  submitting?: boolean;
}

const SignatureForm: FC<SignatureFormProps> = ({ onSubmit, onBack, previewMode, submitting }) => {
  const classes = useStyles();
  const [signatureBase64, setSignatureBase64] = useState<string | undefined>();
  const [initialsBase64, setInitialsBase64] = useState<string | undefined>();

  return (
    <>
      <div className={classes.card}>
        <div className={classes.contentContainer}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h6">Please sign here</Typography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <>
                <div className={classes.signLabelContainer}>
                  <div className={classes.signLabel}>Sign here</div>
                  {signatureBase64 && (
                    <div
                      className={classes.signAgain}
                      id="signAgain"
                      onClick={() => {
                        setSignatureBase64(undefined);
                      }}
                    >
                      Sign again
                    </div>
                  )}
                </div>

                <div
                  className={`${classes.signatureDivStyle} ${
                    signatureBase64 && classes.blueborder
                  }`}
                >
                  <div
                    className={`${classes.signpadLabelContainer} ${
                      signatureBase64 && classes.dontShow
                    }`}
                  >
                    <div className={classes.signpadLabel}>
                      Use your finger or mouse to sign this space
                    </div>
                  </div>
                  <SignaturePad
                    width="90%"
                    height={260}
                    contentBase64={signatureBase64}
                    onChange={(signature: string) => {
                      setSignatureBase64(signature);
                    }}
                  />
                </div>
              </>
            </Grid>
            <Grid item xs={12} sm={1} />
            <Grid item xs={12} sm={5}>
              <>
                <div className={classes.signLabelContainer}>
                  <div className={classes.signLabel}>Initials here</div>
                  {initialsBase64 && (
                    <div
                      className={classes.signAgain}
                      id="signAgain"
                      onClick={() => {
                        setInitialsBase64(undefined);
                      }}
                    >
                      Initials again
                    </div>
                  )}
                </div>

                <div
                  className={`${classes.signatureDivStyle} ${initialsBase64 && classes.blueborder}`}
                >
                  <div
                    className={`${classes.signpadLabelContainer} ${
                      initialsBase64 && classes.dontShow
                    }`}
                  >
                    <div className={classes.signpadLabel}>
                      Use your finger or mouse to sign this space
                    </div>
                  </div>
                  <SignaturePad
                    width="90%"
                    height={260}
                    contentBase64={initialsBase64}
                    onChange={(signature: string) => {
                      setInitialsBase64(signature);
                    }}
                  />
                </div>
              </>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          size="small"
          className={classes.button}
          color="secondary"
          onClick={() => {
            if (onBack) {
              onBack();
            }
          }}
        >
          Back
        </Button>
        {!previewMode && (
          <Button
            size="small"
            className={classes.button}
            color="primary"
            onClick={() => {
              if (signatureBase64 && initialsBase64) {
                onSubmit(signatureBase64, initialsBase64);
              }
            }}
            disabled={!signatureBase64 || !initialsBase64 || submitting}
          >
            {submitting ? 'Submitting...' : 'Submit'}
          </Button>
        )}
      </div>
    </>
  );
};

export default SignatureForm;
