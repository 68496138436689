import React, { FC, useEffect, useRef, useState } from 'react';

import SignatureCanvas from 'react-signature-canvas';

interface SignaturePadProps {
  width?: string | number | undefined;
  height?: string | number | undefined;
  contentBase64: string | undefined | null; // png
  remoteAppointment?: boolean;
  onChange?: ((contentBase64: string | undefined) => void) | undefined | null;
}

const SignaturePad: FC<SignaturePadProps> = ({ height, width, onChange, contentBase64 }) => {
  const sigCanvasRef = useRef<SignatureCanvas>(null);

  useEffect(() => {
    if (contentBase64) {
      sigCanvasRef.current?.fromDataURL(contentBase64);
    }
  }, []);

  useEffect(() => {
    if (!contentBase64) {
      sigCanvasRef.current?.clear();
    }
  }, [contentBase64]);

  return (
    <SignatureCanvas
      ref={sigCanvasRef}
      canvasProps={{ style: { height, width } }}
      onEnd={() => {
        if (onChange) {
          onChange(sigCanvasRef.current?.toDataURL('image/png'));
        }
      }}
      clearOnResize={false}
    />
  );
};

export default SignaturePad;
