/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useState } from 'react';
import { Paper, Typography, withStyles } from '@material-ui/core';
import MailOutline from '@material-ui/icons/MailOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { useDispatch } from 'react-redux';
import { color } from '../../../../lib/Theme/Theme';
import {
  RegisterUserPayload,
  VerificationLinkPayload,
} from '../../../../lib/Redux/Services/login/LoginData';
import { LoginActions } from '../../../../lib/Redux/Actions/LoginActions';
import { View } from '../../Login';
import { LABELS } from './VerifyEmailConstants';
import { VerifyEmailStyles, styles } from './VerifyEmailStyles';

interface VerifyEmail extends VerifyEmailStyles {
  setView: (view: View) => void;

  userDetails: RegisterUserPayload | null;
}

const VerifyEmail: FC<VerifyEmail> = ({ classes, setView, userDetails }) => {
  const [verifySentAgain, setVerifySentAgain] = useState<boolean>(false);
  const dispatch = useDispatch();
  const dispatcher = {
    sendVerificationEmail: (data: VerificationLinkPayload) => {
      dispatch(LoginActions.postVerificationLinkStart(data));
    },
  };
  return (
    <Paper className={classes.secondaryTile}>
      <div className={classes.titleHeader}>
        <Typography variant="h4" className={classes.title}>
          {LABELS.VERIFY_TITLE}
        </Typography>
        <MailOutline className={classes.mailIcon} htmlColor={color.gray} />
      </div>
      <div className={classes.contentContainer}>
        <Typography className={classes.subtext}>{LABELS.VERIFY_SUBTEXT}</Typography>

        <div className={classes.verifyEmailContainer}>
          <CheckCircleOutlineIcon htmlColor={color.teal} className={classes.tick} />
          <Typography className={classes.emailText}>
            {LABELS.EMAIL_SENT}&apos;{userDetails!.email}&apos;
          </Typography>
          {verifySentAgain ? (
            <div className={classes.sentButton}>{LABELS.SENT}</div>
          ) : (
            <div
              className={classes.resendButton}
              onMouseDown={() => {
                dispatcher.sendVerificationEmail({ email: userDetails!.email });
                setVerifySentAgain(true);
              }}
            >
              {LABELS.RESEND}
            </div>
          )}
        </div>

        <Typography className={classes.infoText}>{LABELS.INFO_TEXT}</Typography>
        <div className={classes.footerContainer}>
          <div className={classes.backButton} onClick={() => setView(View.Signup)}>
            <ArrowBack className={classes.backarrow} />
            {LABELS.BACK}
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default withStyles(styles)(VerifyEmail);
