import { StoreAction } from '../Store/StoreHelper';
import { ApplicationState } from '../Reducers';

import {
  GetFailureDetails,
  GetOwnershipDetailsRequest,
  GetOwnershipDetailsResponse,
  GetOwnershipPropertiesRequest,
  GetOwnershipPropertiesResponse,
  PutOwnershipDetailsRequest,
  SignOwnershipRequest,
  UpdateOwnerRequest,
  UpdateOwnershipPropertiesRequest,
} from '../Types/ownershipForm/OwnershipFormResponse.data';

export enum OwnershipActionTypes {
  GET_OWNERSHIP_DETAILS_REQUEST = 'GET_OWNERSHIP_DETAILS_REQUEST',
  GET_OWNERSHIP_DETAILS_SUCCESS = 'GET_OWNERSHIP_DETAILS_SUCCESS',
  GET_OWNERSHIP_DETAILS_ERROR = 'GET_OWNERSHIP_DETAILS_ERROR',

  UPDATE_OWNERSHIP_DETAILS_REQUEST = 'UPDATE_OWNERSHIP_DETAILS_REQUEST',
  UPDATE_OWNERSHIP_DETAILS_SUCCESS = 'UPDATE_OWNERSHIP_DETAILS_SUCCESS',
  UPDATE_OWNERSHIP_DETAILS_ERROR = 'UPDATE_OWNERSHIP_DETAILS_ERROR',
  UPDATE_OWNERSHIP_DIALOG_ERROR = 'UPDATE_OWNERSHIP_DIALOG_ERROR',

  GET_OWNERSHIP_PROPERTIES_REQUEST = 'GET_OWNERSHIP_PROPERTIES_REQUEST',
  GET_OWNERSHIP_PROPERTIES_SUCCESS = 'GET_OWNERSHIP_PROPERTIES_SUCCESS',
  GET_OWNERSHIP_PROPERTIES_ERROR = 'GET_OWNERSHIP_PROPERTIES_ERROR',

  UPDATE_OWNERSHIP_PROPERTIES_REQUEST = 'UPDATE_OWNERSHIP_PROPERTIES_REQUEST',
  UPDATE_OWNERSHIP_PROPERTIES_SUCCESS = 'UPDATE_OWNERSHIP_PROPERTIES_SUCCESS',
  UPDATE_OWNERSHIP_PROPERTIES_ERROR = 'UPDATE_OWNERSHIP_PROPERTIES_ERROR',

  SIGN_OWNERSHIP_REQUEST = 'SIGN_OWNERSHIP_REQUEST',
  SIGN_OWNERSHIP_SUCCESS = 'SIGN_OWNERSHIP_SUCCESS',
  SIGN_OWNERSHIP_ERROR = 'SIGN_OWNERSHIP_ERROR',

  UPDATE_OWNER_REQUEST = 'UPDATE_OWNER_REQUEST',
  UPDATE_OWNER_SUCCESS = 'UPDATE_OWNER_SUCCESS',
  UPDATE_OWNER_ERROR = 'UPDATE_OWNER_ERROR',
}

export type OwnershipActionPayload =
  | string
  | ApplicationState
  | GetFailureDetails
  | Error
  | null
  | number
  | boolean
  | any
  | GetOwnershipDetailsRequest
  | GetOwnershipPropertiesRequest
  | UpdateOwnerRequest
  | SignOwnershipRequest;

export type OwnershipAction = StoreAction<OwnershipActionTypes, OwnershipActionPayload>;
export class OwnershipActions {
  public static getOwnershipDetails(data: GetOwnershipDetailsRequest): OwnershipAction {
    return {
      type: OwnershipActionTypes.GET_OWNERSHIP_DETAILS_REQUEST,
      data,
    };
  }
  public static getOwnershipDetailsSuccess(data?: GetOwnershipDetailsResponse): OwnershipAction {
    return {
      type: OwnershipActionTypes.GET_OWNERSHIP_DETAILS_SUCCESS,
      data,
    };
  }
  public static getOwnershipDetailsError(data: GetFailureDetails): OwnershipAction {
    return {
      type: OwnershipActionTypes.GET_OWNERSHIP_DETAILS_ERROR,
      data,
    };
  }

  public static updateOwnershipDetails(data: PutOwnershipDetailsRequest): OwnershipAction {
    return {
      type: OwnershipActionTypes.UPDATE_OWNERSHIP_DETAILS_REQUEST,
      data,
    };
  }
  public static updateOwnershipDetailsSuccess(): OwnershipAction {
    return {
      type: OwnershipActionTypes.UPDATE_OWNERSHIP_DETAILS_SUCCESS,
      data: null,
    };
  }
  public static updateOwnershipDetailsError(data: GetFailureDetails): OwnershipAction {
    return {
      type: OwnershipActionTypes.UPDATE_OWNERSHIP_DETAILS_ERROR,
      data,
    };
  }

  public static updateOwnershipDetailsDiaLogError(): OwnershipAction {
    return {
      type: OwnershipActionTypes.UPDATE_OWNERSHIP_DETAILS_ERROR,
      data: null,
    };
  }

  public static getOwnershipProperties(data: GetOwnershipPropertiesRequest): OwnershipAction {
    return {
      type: OwnershipActionTypes.GET_OWNERSHIP_PROPERTIES_REQUEST,
      data,
    };
  }
  public static getOwnershipPropertiesSuccess(
    data?: GetOwnershipPropertiesResponse,
  ): OwnershipAction {
    return {
      type: OwnershipActionTypes.GET_OWNERSHIP_PROPERTIES_SUCCESS,
      data,
    };
  }
  public static getOwnershipPropertiesError(data: GetFailureDetails): OwnershipAction {
    return {
      type: OwnershipActionTypes.GET_OWNERSHIP_PROPERTIES_ERROR,
      data,
    };
  }

  public static updateOwnershipProperties(data: UpdateOwnershipPropertiesRequest): OwnershipAction {
    return {
      type: OwnershipActionTypes.UPDATE_OWNERSHIP_PROPERTIES_REQUEST,
      data,
    };
  }
  public static updateOwnershipPropertiesSuccess(): OwnershipAction {
    return {
      type: OwnershipActionTypes.UPDATE_OWNERSHIP_PROPERTIES_SUCCESS,
      data: null,
    };
  }
  public static updateOwnershipPropertiesError(data: GetFailureDetails): OwnershipAction {
    return {
      type: OwnershipActionTypes.UPDATE_OWNERSHIP_PROPERTIES_ERROR,
      data,
    };
  }

  public static signOwnership(data: SignOwnershipRequest): OwnershipAction {
    return {
      type: OwnershipActionTypes.SIGN_OWNERSHIP_REQUEST,
      data,
    };
  }
  public static signOwnershipSuccess(): OwnershipAction {
    return {
      type: OwnershipActionTypes.SIGN_OWNERSHIP_SUCCESS,
      data: null,
    };
  }
  public static signOwnershipError(data: GetFailureDetails): OwnershipAction {
    return {
      type: OwnershipActionTypes.SIGN_OWNERSHIP_ERROR,
      data,
    };
  }

  public static updateOwnerDetails(data: UpdateOwnerRequest): OwnershipAction {
    return {
      type: OwnershipActionTypes.UPDATE_OWNER_REQUEST,
      data,
    };
  }
  public static updateOwnerDetailsSuccess(): OwnershipAction {
    return {
      type: OwnershipActionTypes.UPDATE_OWNER_SUCCESS,
      data: null,
    };
  }
  public static updateOwnerDetailsError(data: GetFailureDetails): OwnershipAction {
    return {
      type: OwnershipActionTypes.UPDATE_OWNER_ERROR,
      data,
    };
  }
}
