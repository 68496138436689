/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createStyles, WithStyles } from '@material-ui/core';
import { unset } from 'lodash';
import { color } from '../../../../lib/Theme/Theme';
import { SIZES } from '../../../../lib/Constants';
import { globalStyles } from '../../../../lib/Theme/GlobalStyles';

export const styles = createStyles({
  root: {
    [`@media (max-width: ${SIZES.md}px)`]: {
      height: 'calc(100vh - 48px)',
      overflowY: 'scroll',
      backgroundColor: color.secondary,
    },
    [`@media (min-width: ${SIZES.md}px)`]: {
      backgroundColor: color.light,
    },
  },
  paperBack: globalStyles.paperBack as any,
  contentContainer: {
    padding: '30px',
  },
  welcomeTitle: {
    color: color.sortedNavy,
    fontSize: 40,
    marginBottom: '10px',
  },
  welcomeSubtitle: {
    fontSize: 18,
  },
  propertyContainer: {
    marginTop: '30px',
    border: `${color.borderGray} 2px solid`,
    borderRadius: '6px',
    padding: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  propertyImageContainer: {
    borderRadius: 1000,
    backgroundColor: color.light,
    border: `${color.borderGray} 1px solid`,
    overflow: 'hidden',
    width: '42px',
    height: '42px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  propertyImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  propertyImageBlank: {
    position: 'relative',
    top: '-1px',
  },
  propertyDetails: {
    marginRight: 'auto',
    marginLeft: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  propertyAddress: {
    fontSize: 18,
    opacity: 0.87,
  },
  propertySuburb: {
    opacity: 0.38,
  },
  weeklyAmount: {
    fontSize: 26,
    color: color.success,
    marginRight: '15px',
  },
  poweredBySorted: {
    marginTop: '30px',
  },
});

export interface WelcomeStyleProps extends WithStyles<typeof styles> {}
