import {
  AddressComponent,
  createAddressString,
  getAddressComponents,
} from '../../../lib/helper/GooglePlaces/GooglePlacesHelper';
import { Button, FormControl, FormHelperText, InputLabel, Typography } from '@material-ui/core';
import { ErrorMessage, Field, FieldProps, useField } from 'formik';
import React, { FC, useEffect, useState } from 'react';

import { AddressPicker } from '../../AddressPicker/AddressPicker';
import AddressValue from '../../../lib/helper/GooglePlaces/AddressValue.data';
import ManualAddressForm from './ManualAddressForm';
import useStyles from './AddressPickerStyles';

interface RenderAddressPickerProps {
  label: string;
  name: string;
  disabled?: boolean;
  placeholder?: string;
  clearValueOnUnmount?: boolean;
  showError?: boolean;
  componentsFieldname?: string;
  initialComponents?: AddressComponent;
  setFieldValue: (fieldName: string, fieldValue: string | boolean | AddressComponent) => void;
  validate?: (value: any) => void;
}
// Pick address from Google api
const RenderAddressPicker: FC<RenderAddressPickerProps> = ({
  label,
  name,
  placeholder,
  disabled,
  clearValueOnUnmount,
  showError,
  componentsFieldname,
  initialComponents,
  setFieldValue,
  validate,
}) => {
  const [fields, meta, helpers] = useField(name);
  const [showManualForm, setShowManualForm] = useState(false);
  const [addressData, setAddressData] = useState<AddressComponent | undefined>();
  const hasError = !!meta.error && (meta.touched || showError);
  const classes = useStyles();
  useEffect(() => {
    if (initialComponents) {
      setAddressData(initialComponents);
    }
    return () => {
      if (clearValueOnUnmount) {
        helpers.setValue(undefined);
      }
    };
  }, []);

  return (
    <div className="item-render">
      <FormControl error={hasError} fullWidth>
        {label && (
          <InputLabel htmlFor={name}>
            <Typography variant="subtitle1">{label}</Typography>
          </InputLabel>
        )}
        <Field name={name} validate={validate}>
          {({ field, form }: FieldProps) => {
            const { value } = fields;
            return (
              <AddressPicker
                id={name}
                value={typeof value === 'string' ? { inputValue: value, place: null } : value} // AddressPickerValue
                disabled={disabled}
                placeholder={placeholder}
                handleAddressChanged={(valuee: AddressValue) => {
                  form.setFieldValue(name, valuee.place?.formatted_address);
                  if (componentsFieldname) {
                    const address = getAddressComponents(valuee);
                    setAddressData(address);
                    form.setFieldValue(componentsFieldname, address);
                  }
                }}
                fixSubPremise={false}
              />
            );
          }}
        </Field>

        {!disabled && (
          <FormHelperText>
            <ErrorMessage name={name} />
          </FormHelperText>
        )}
      </FormControl>
      {!disabled && (
        <Button
          color="primary"
          className={classes.addManuallyButton}
          onClick={() => setShowManualForm(true)}
        >
          Enter Manually
        </Button>
      )}
      {showManualForm && (
        <ManualAddressForm
          isOpen
          onClose={() => setShowManualForm(false)}
          initialData={addressData}
          onSubmit={(address) => {
            if (componentsFieldname) {
              setFieldValue(componentsFieldname, address);
            }
            setFieldValue(name, createAddressString(address));
            setShowManualForm(false);
          }}
        />
      )}
    </div>
  );
};

export default RenderAddressPicker;
