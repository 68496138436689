import LogRocket from 'logrocket';
import * as redux from 'redux';
import createSagaMiddleware from 'redux-saga';
import moment from 'moment';
import sagas from '../Sagas';
import { storage, STORAGE_CONSTANTS } from '../Services/LocalStorage';
import reducers, { ApplicationState } from '../Reducers';

function saveToLocalStorage(state: any) {
  try {
    const serialisedState = JSON.stringify(state);
    const currentDate = moment().format('DD/MM/YYYY');
    storage.setItem(STORAGE_CONSTANTS.state, serialisedState);
    storage.setItem(STORAGE_CONSTANTS.stateCreatedDate, currentDate);
  } catch (e) {
    console.log(e);
  }
}

function loadFromLocalStorage() {
  try {
    const serialisedState = storage.getItem(STORAGE_CONSTANTS.state);
    const stateCreatedDate = storage.getItem(STORAGE_CONSTANTS.stateCreatedDate);
    if (serialisedState === null) {
      return undefined;
    }
    if (stateCreatedDate) {
      const date = moment(stateCreatedDate, 'DD/MM/YYYY');
      if (moment().diff(date, 'days') > 60) {
        return undefined;
      }
    }
    return JSON.parse(serialisedState);
  } catch (e) {
    console.log(e);
    return undefined;
  }
}

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || redux.compose;
const sagaMiddleware = createSagaMiddleware();

// Creates store and sets it into singleton (Store.ts/store)
const createAppStore = (testing?: boolean | undefined | null) => {
  const persistedStore = loadFromLocalStorage();

  store = redux.createStore(
    reducers,
    persistedStore,
    composeEnhancers(
      redux.applyMiddleware(
        sagaMiddleware,
        /* LogRocket.reduxMiddleware() should be the very last 
      middleware applied, so it can properly log everything */
        LogRocket.reduxMiddleware(),
      ),
    ),
  );

  store.subscribe(() => saveToLocalStorage(store.getState()));

  sagaMiddleware.run(sagas);
  return store;
};

export type AppStore = redux.Store<ApplicationState>;
export let store: AppStore;

// initialize
createAppStore();
