import { SIZES } from '../../../lib/Constants';
import { color } from '../../../lib/Theme/Theme';
import { globalStyles } from '../../../lib/Theme/GlobalStyles';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  title: globalStyles.title as any,
  subtitle: globalStyles.subtitle as any,

  card: {
    margin: 'auto',
    marginTop: '20px',
    maxWidth: '1000px',
    borderRadius: '6px',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
    backgroundColor: color.light,
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: '15px',
    },
  },
  contentContainer: {
    padding: '30px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '15px',
      paddingBottom: '35px',
    },
  },
  titleContainer: {
    marginTop: '-15px',
  },
});

export default useStyles;
