import { WithStyles, createStyles } from '@material-ui/core';
import { color } from '../../lib/Theme/Theme';
import { SIZES } from '../../lib/Constants';

export const ResetPasswordConfirmationStyle = createStyles({
  resetPasswordConfirmationContainer: {
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '0 16px',
      backgroundColor: color.secondary,
      height: 'calc(100vh - 48px)',
      overflowY: 'scroll',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
    [`@media (min-width: ${SIZES.md}px)`]: {
      padding: '0 133px',
      backgroundColor: color.light,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
  },
  successLogo: {
    width: '72px',
    height: '82px',
    objectFit: 'contain',
    paddingTop: '111px',
    paddingBottom: '60px',
  },
  buttonContainer: {
    [`@media (min-width: ${SIZES.md}px)`]: {
      padding: '58px 0 50px',
      textAlign: 'center',
      width: '50%',
    },
    [`@media (max-width: ${SIZES.md}px)`]: {
      textAlign: 'center',
      position: 'absolute',
      left: '16px',
      right: '16px',
      bottom: 0,
    },
  },
  loginButton: {
    height: '48px',
    margin: '20px 0px',
    backgroundColor: color.primary,
  },
  loginLabel: {
    color: color.light,
  },
});

export interface ResetPasswordConfirmationComponentStyle
  extends WithStyles<typeof ResetPasswordConfirmationStyle> {}
