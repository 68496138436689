import {
  OwnershipDetails,
  userType,
  CommissionSharing,
} from '../../lib/Redux/Types/ownershipForm/OwnershipFormResponse.data';
import { BlanketForm as Form } from '../blanketForm/BlanketFormConstants';

export const getDefaultValue = (
  ownershipDetails: OwnershipDetails,
  forms: Form[],
  key: string,
  userType?: userType,
): CommissionSharing[] => {
  const keyForm = forms.find((data) => data.name === key);
  if (ownershipDetails && ownershipDetails[key] && ownershipDetails[key].length > 0) {
    return ownershipDetails[key];
  } else if (
    keyForm &&
    keyForm.defaultValues &&
    keyForm.defaultValues.length > 0 &&
    userType &&
    keyForm.canEdit?.includes(userType)
  ) {
    return keyForm.defaultValues;
  } else {
    return [];
  }
};

export const checkFormVisibility = (form: Form, ownerShipType?: string) => {
  if (form.isVisible && form.isVisible.length > 0 && ownerShipType) {
    return form.isVisible.includes(ownerShipType);
  } else {
    return true;
  }
};
