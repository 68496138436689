export const LABELS = {
  TITLE: 'Your properties',
  SUBTITLE: 'Below are properties being prepared for your management agreement ',
  OWNERSHIP: 'Ownership',
  PRIMARY_CONTACT: 'Primary contact',
  PHONE: 'Phone',
  EMAIL: 'Email',
};
export const BUTTON = {
  ADD_OWNERSHIP: 'Add Ownership',
};
