/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { takeEvery, put } from 'redux-saga/effects';
import { LoadingIndicatorActions } from '../Actions/LoadingIndicatorAction';
import { LoginAction, LoginActions, LoginActionTypes } from '../Actions/LoginActions';
import {
  LoginResponse,
  LoginPayload,
  RegisterUserPayload,
  RegisterUserResponse,
  RegisterClientResponse,
  VerificationLinkPayload,
  RefreshTokenPayload,
  RefreshTokenResponse,
  VerifyUserPayload,
  ForgotPasswordPayload,
  ResetPasswordPayload,
} from '../Services/login/LoginData';
import { SuccessToastActions } from '../Actions/SuccessToastAction';
import LoginService from '../Services/login/LoginService';
import LogRocket from 'logrocket';

export function* postLoginWorker(action: LoginAction): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data as LoginPayload;
    const response: LoginResponse = (yield LoginService.postLogin(data))!;
    yield put(LoginActions.postLoginSuccess(response));
    LogRocket.identify(data.username, {
      email: data.username,
    });
  } catch (error) {
    yield put(SuccessToastActions.showError(error.message));
    yield put(LoginActions.postLoginError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* postRegisterClientWorker(action: LoginAction): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const response: RegisterClientResponse | undefined = yield LoginService.postRegisterClient();
    if (response) yield put(LoginActions.postRegisterClientSuccess(response));
  } catch (error) {
    yield put(SuccessToastActions.showError(error.message));
    yield put(LoginActions.postRegisterClientError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* postVerificationLinkWorker(action: LoginAction): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data as VerificationLinkPayload;
    yield LoginService.postVerificationLink(data);
    yield put(LoginActions.postVerificationLinkSuccess());
  } catch (error) {
    yield put(SuccessToastActions.showError(error.message));
    yield put(LoginActions.postRegisterClientError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* postRefreshTokenWorker(action: LoginAction): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data as RefreshTokenPayload;
    const response: RefreshTokenResponse | undefined = yield LoginService.postRefreshToken(data);
    if (response) yield put(LoginActions.postRefreshTokenSuccess(response));
  } catch (error) {
    yield put(SuccessToastActions.showError(error.message));
    yield put(LoginActions.postRegisterClientError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* postVerifyUserWorker(action: LoginAction): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data as VerifyUserPayload;
    yield LoginService.postVerifyUser(data);
    yield put(LoginActions.postVerifyUserSuccess());
  } catch (error) {
    yield put(SuccessToastActions.showError(error.message));
    yield put(LoginActions.postVerifyUserError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* postForgotPasswordLinkWorker(action: LoginAction): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data as ForgotPasswordPayload;
    yield LoginService.postForgotPasswordLink(data);
    yield put(LoginActions.postForgotPasswordSuccess());
    if (data.onSuccess) {
      data.onSuccess();
    }
  } catch (error) {
    yield put(SuccessToastActions.showError(error.message));
    yield put(LoginActions.postForgotPasswordError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* postResetPasswordWorker(action: LoginAction): IterableIterator<any> {
  try {
    const data = action.data as ResetPasswordPayload;
    yield LoginService.postResetPassword(data);
    yield put(LoginActions.resetPasswordSuccess());
    if (data.onSuccess) {
      data.onSuccess();
    }
  } catch (error) {
    yield put(LoginActions.resetPasswordError(error));
  }
}

//  Sign UP
export function* postRegisterUserWorker(action: LoginAction): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data as RegisterUserPayload;
    const response: RegisterUserResponse = (yield LoginService.postRegisterUser(data))!;
    yield put(LoginActions.postRegisterUserSuccess(response));
    if (data.onSuccess) {
      data.onSuccess();
    }
  } catch (error) {
    yield put(SuccessToastActions.showError(error.message));
    yield put(LoginActions.postRegisterUserError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

const loginWatcher = [
  takeEvery(LoginActionTypes.LOGIN_REQUEST, (action) =>
    postLoginWorker({
      type: action.type,
      data: (action as LoginAction).data,
    }),
  ),
  takeEvery(LoginActionTypes.REFRESH_TOKEN_REQUEST, (action) =>
    postRefreshTokenWorker({
      type: action.type,
      data: (action as LoginAction).data,
    }),
  ),
  takeEvery(LoginActionTypes.REGISTER_USER_REQUEST, (action) =>
    postRegisterUserWorker({
      type: action.type,
      data: (action as LoginAction).data,
    }),
  ),

  takeEvery(LoginActionTypes.VERIFY_USER_REQUEST, (action) =>
    postVerifyUserWorker({
      type: action.type,
      data: (action as LoginAction).data,
    }),
  ),
  takeEvery(LoginActionTypes.VERIFICATION_LINK_REQUEST, (action) =>
    postVerificationLinkWorker({
      type: action.type,
      data: (action as LoginAction).data,
    }),
  ),

  takeEvery(LoginActionTypes.FORGOT_PASSWORD_LINK_REQUEST, (action) =>
    postForgotPasswordLinkWorker({
      type: action.type,
      data: (action as LoginAction).data,
    }),
  ),
  takeEvery(LoginActionTypes.RESET_PASSWORD_REQUEST, (action) =>
    postResetPasswordWorker({
      type: action.type,
      data: (action as LoginAction).data,
    }),
  ),
];

export default loginWatcher;
