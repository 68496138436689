import { Box, Grid, Step, StepLabel, Stepper, Typography } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  STEPS,
  getNextStep,
  getSteps,
  getBackStep,
  LABELS,
} from './OwnersInstructionsPageConstants';
import { useDispatch } from 'react-redux';

import AgencyBackground from '../../components/agencyBackground/AgencyBackground';
import { AppIconType } from '../../components/AppIcon/AppIcon';
import FormLoader from '../../components/ownership/FormLoader';
import {
  GetOwnersInstructionsDetailsResponse,
  OwnersInstructions,
} from '../../lib/Redux/Types/ownershipForm/OwnershipFormResponse.data';
import OwnersInstructionsForm from '../../components/ownership/OwnersInstructionsForm';
import { OwnersInstructionsService } from '../../lib/Redux/Services/OwnersInstructions/OwnersInstructionsService';
import SignatureForm from '../../components/ownership/SignatureForm';
import { Skeleton } from '@material-ui/lab';
import UploadingScreen from '../../components/UploadingScreen/UploadingScreen';
import { convertbase64ToPngContent } from '../../lib/helper/Content/ContentConverter';
import useFetch from '../../hooks/UseFetch';
import useStyles from '../ownership/OwnershipStyles';
import { CommonActions } from '../../lib/Redux/Actions/CommonActions';

interface OwnersInstructionsPageProps extends RouteComponentProps<{ token: string }> {}

const OwnersInstructionsPage: FC<OwnersInstructionsPageProps> = ({
  match: {
    params: { token },
    url,
  },
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const previewMode = url.split('/')[4] === 'preview';

  const onNext = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setActiveStep(getNextStep(activeStep));
  };

  const onBack = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setActiveStep(getBackStep(activeStep));
  };

  const {
    state: { data, loading, error },
    fetchData: getDetails,
  } = useFetch(
    OwnersInstructionsService.getDetails,
    (response: GetOwnersInstructionsDetailsResponse) => {
      dispatch(CommonActions.addAgencyBannerUrl(response.agency.bannerUrl));
    },
    undefined,
  );
  const {
    fetchData: submitOwnersInstructions,
    state: { loading: submittingInstructions },
  } = useFetch(OwnersInstructionsService.postDetails, onNext, undefined);
  const {
    fetchData: submitSignatures,
    state: { loading: submittingSignatures },
  } = useFetch(OwnersInstructionsService.postSignature, onNext, undefined);

  const [activeStep, setActiveStep] = useState(STEPS.OWNERS_INSTRUCTIONS_FORM);

  useEffect(() => {
    if (token) {
      getDetails(token, previewMode);
    }
  }, [token, previewMode]);

  const isSuccessStep = activeStep === STEPS.SUCCESS;
  const signedAlready = error?.message === 'You Have Signed Already';
  const steps = error ? undefined : getSteps(previewMode);
  const isLoading = loading || !data?.ownersInstructions || !data?.form;
  const isStepperLoading = !data?.form;

  const renderContent = () => {
    if (!data?.ownersInstructions || !data?.form || !data?.userType) return null;

    const { form, userType, ownersInstructions } = data;
    switch (activeStep) {
      case STEPS.OWNERS_INSTRUCTIONS_FORM:
        return (
          <OwnersInstructionsForm
            formUrl={form}
            userType={userType}
            ownersInstructions={ownersInstructions}
            onSubmit={(ownersIntructions: OwnersInstructions) =>
              submitOwnersInstructions(token, ownersIntructions)
            }
            previewMode={previewMode}
            onNext={onNext}
            submitting={submittingInstructions}
          />
        );

      case STEPS.SIGNATURE:
        return (
          <SignatureForm
            onSubmit={(signatureBase64: string, initialsBase64: string) => {
              const signatures = [
                convertbase64ToPngContent(signatureBase64),
                convertbase64ToPngContent(initialsBase64),
              ];
              submitSignatures(token, signatures);
            }}
            onBack={onBack}
            previewMode={previewMode}
            submitting={submittingSignatures}
          />
        );

      case STEPS.SUCCESS:
        return (
          <UploadingScreen
            title="All done!"
            primaryText={`Thank you for signing with ${data?.agency.tradingName}`}
            secondaryText="You can safely close your browser. Once everyone has signed, you'll receive a copy of your documents."
            icon={AppIconType.TickInCirle}
            iconType="svg"
          />
        );

      default:
        return null;
    }
  };

  return (
    <>
      {error ? (
        <UploadingScreen
          icon={AppIconType.CrossInCircle}
          iconType="svg"
          title={signedAlready ? 'Already signed!' : 'Signing link expired!'}
          primaryText={
            signedAlready
              ? 'You have already signed using this referral signing link'
              : 'The referral signing link has expired. Please contact your property manager for support.'
          }
        />
      ) : (
        <div className={!isSuccessStep ? classes.root : ''}>
          {!isSuccessStep && (
            <>
              <AgencyBackground
              // bannerUrl={
              //   data && data.agency && data.agency.bannerUrl ? data.agency.bannerUrl : ''
              // }
              />
              <div className={`${classes.progressBarContainer}`}>
                {isStepperLoading ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    style={{ width: '100%', background: 'white', height: '100%', padding: '2rem' }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Skeleton variant="rect" height={50} />
                      </Grid>
                    </Grid>
                  </Box>
                ) : (
                  <Stepper
                    alternativeLabel
                    activeStep={activeStep}
                    className={classes.progressBarWrapper}
                  >
                    {steps!.map((step) => (
                      <Step key={step.key}>
                        <StepLabel>{step.label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                )}
              </div>
            </>
          )}
          {!isSuccessStep && !isLoading && data && data.propertyAddress && (
            <Box className={classes.propertyBanner}>
              <Typography className={classes.propertyBannerContainer}>
                {LABELS.PROPERTY_BANNER_TEXT} <strong>{data.propertyAddress}</strong>
              </Typography>
            </Box>
          )}
          {isLoading ? <FormLoader /> : renderContent()}
        </div>
      )}
    </>
  );
};

export default withRouter(OwnersInstructionsPage);
