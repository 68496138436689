// @ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import './index.css';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import App from './App';
import * as serviceWorker from './ServiceWorker';
import { store } from './lib/Redux/Store';
import theme from './lib/Theme/Theme';
import './lib/Theme/app.scss';
import SuccessToast from './components/SuccessToast/SuccessToast';

ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <MuiThemeProvider theme={theme}>
        <App />
        <SuccessToast />
      </MuiThemeProvider>
    </HashRouter>
  </Provider>,
  document.getElementById('root'),
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
