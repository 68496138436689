/* eslint-disable import/prefer-default-export */
import { STORAGE_CONSTANTS, storage } from '../lib/Redux/Services/LocalStorage';

import { TYPE } from '../screens/login/LoginUtils';

//  Referral Private route list
export const referralPrivateRoute = {
  Newreferral: 'new-referral',
};
//  Check if Route is referral private route
const isReferralPrivateRoute = () => {
  const type = storage.getItem(STORAGE_CONSTANTS.type);
  const url = window.location.href;
  for (const [key, value] of Object.entries(referralPrivateRoute)) {
    if (type === TYPE.OWNER && url.includes(value)) {
      return false;
    }
  }
  return true;
};
export const routes = {
  addReferral: {
    new: (): string => {
      if (isReferralPrivateRoute()) {
        return `/new-referral`;
      }
      return `/permission-denied`;
    },
  },
  dashboard: {
    new: '/dashboard',
  },
  error: {
    new: '/404',
  },

  login: {
    viewGeneric: `/login`,
    view: (agencyCode: string): string => `/${agencyCode}/login`,
  },
  resetPassword: {
    view: '/reset-password',
  },
  applications: {
    view: '/applications',
  },
  registrationConfirmation: {
    new: '/register-confirmation',
  },
  resetPasswordConfirmation: {
    new: '/reset-password-confirmation',
  },

  authorityForm: {
    edit: '/authority/:token',
    prepare: (token: string): string => `/authority/${token}/prepare`,
    preview: (token: string): string => `/authority/${token}/preview`
  },

  ownersInstructions: {
    edit: '/authority/owners-instructions/:token',
    preview: '/authority/owners-instructions/:token/preview',
  },

  questionnaire: {
    view: '/questionnaire',
  },
  thankyouScreen: {
    view: '/thankyou-screen',
  },
  referenceToolError: {
    view: '/error-screen',
  },
};
