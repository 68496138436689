import { Checkbox, FormControlLabel, FormHelperText } from '@material-ui/core';
import { ErrorMessage, Field, FieldProps, useField } from 'formik';
import React, { FC, useEffect } from 'react';

import { useStyles } from './RenderCheckboxStyles';
import { BlanketFormFieldValidations } from '../../blanketForm/BlanketFormConstants';

interface RenderCheckboxProps {
  name: string;
  label: string;
  icon?: any;
  color?: 'primary' | 'secondary' | 'default';
  style?: any;
  checkedIcon?: AnalyserNode;
  disabled?: boolean;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  labelStyle?: any;
  forceCheck?: boolean;
  containerClass?: string;
  defaultChecked?: boolean;
  validate?: boolean;
}

const RenderCheckbox: FC<RenderCheckboxProps> = ({
  name,
  label,
  labelStyle,
  labelPlacement,
  disabled,
  icon,
  checkedIcon,
  color,
  style,
  forceCheck,
  containerClass,
  defaultChecked,
  validate,
}) => {
  const styles = useStyles();
  const [field, meta, helpers] = useField(name);

  useEffect(() => {
    const { value } = field;
    const { setValue } = helpers;
    if (value === null || value === undefined) setValue(disabled ? false : defaultChecked || false);
  }, []);
  return (
    <div className={`item-render ${containerClass || ''}`}>
      <Field type="checkbox" name={name} validate={validate}>
        {({ field, form: { setFieldValue } }: FieldProps) => {
          const { name, value } = field;
          return (
            <FormControlLabel
              classes={{ label: labelStyle || styles.label2 }}
              labelPlacement={labelPlacement || 'end'}
              control={
                <Checkbox
                  {...field}
                  value={value || forceCheck}
                  defaultValue={value || forceCheck}
                  onChange={(e) => setFieldValue(name, e.target.checked)}
                  id={name}
                  disabled={disabled}
                  icon={icon}
                  checkedIcon={checkedIcon}
                  color={color}
                  style={style}
                />
              }
              label={label}
            />
          );
        }}
      </Field>
      <FormHelperText id={`${name}-error-text`} className={styles.errorMessageStyles}>
        <ErrorMessage name={name} />
      </FormHelperText>
    </div>
  );
};

export default RenderCheckbox;
