/* eslint-disable import/prefer-default-export */
export const LABELS = {
  TITLE: 'Referrals',
  SUBTITLE: 'Here is a record of referrals you have submitted ',
  CONTACT_PERSON: 'Contact',
  DISPLAY_MOBILE: 'Phone',
  DISPLAY_EMAIL: 'Email',
  ADDITIONAL_HEADER: 'Additional information',
  TOTAL_REFERRALS: 'Referral(s)',
  SIGNED: 'Signed',
  ESTIMATES_RENT: '/ Estimated payable',
};
