import React, { ReactElement } from 'react';
import { Grid, Typography, Button, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import sortedLogo from '../../../assets/images/sorted_reference_logo.png';
import {
  referenceTool,
  knowMore,
  learnMore,
  learnMoreUrl,
  errorType,
  errorExpiredLink,
  errorExpiredMsg,
  errorUsedLink,
  errorUsedMesg,
  errorInvalidLink,
  errorInvalidMsg,
} from '../ReferenceToolConstants';
import { ErrorScreenStyle } from './ErrorScreenStyles';
import { ApplicationState } from '../../../lib/Redux/Reducers';
import { TypeHelper } from '../../../lib/helper/TypeHelper';

interface Props {
  classes: any;
  getError: string | null;
  postError: string | null;
}
interface PropsFromState {
  getError: string | null;
  postError: string | null;
}
type OwnProps = Props & PropsFromState;

function ErrorScreen(props: OwnProps): ReactElement {
  const { classes, getError, postError } = props;
  const errorMessage = getError || postError;
  if (TypeHelper.isNullOrUndefined(errorMessage)) {
    return <Typography className={classes.dataLoadingStyle} />;
  }
  return (
    <Grid className={classes.errorScreenRootStyle}>
      <div className={classes.sortedLogoStyle}>
        <img className={classes.imageStyle} src={sortedLogo} alt="" />
        <span className={classes.referenceToolStyle}>{referenceTool}</span>
      </div>
      <Typography className={classes.oopsTextStyle}>Oops!</Typography>
      {errorMessage!.includes(errorType.EXPIRED) && (
        <>
          <Typography className={classes.finishedReferenceStyle}>{errorExpiredLink}</Typography>
          <Typography className={classes.errorTextStyle}>{errorExpiredMsg}</Typography>
        </>
      )}
      {errorMessage!.includes(errorType.ALREADY_USED) && (
        <>
          <Typography className={classes.finishedReferenceStyle}>{errorUsedLink}</Typography>
          <Typography className={classes.errorTextStyle}>{errorUsedMesg}</Typography>
        </>
      )}
      {errorMessage!.includes(errorType.DOES_NOT_EXIST) && (
        <>
          <Typography className={classes.finishedReferenceStyle}>{errorInvalidLink}</Typography>
          <Typography className={classes.errorTextStyle}>{errorInvalidMsg}</Typography>
        </>
      )}

      <div className={classes.sortedInfoStyle}>
        <span className={classes.knowMoreStyle}>{knowMore}</span>
        <Button
          className={classes.learnMoreStyle}
          onClick={() => {
            window.open(learnMoreUrl, '_blank');
          }}
        >
          {learnMore}
        </Button>
      </div>
    </Grid>
  );
}
const mapStateToProps = (state: ApplicationState) => {};
const Error = withStyles(ErrorScreenStyle)(ErrorScreen);
export default connect(mapStateToProps, null)(Error); //  (withRouter(Error as any));
