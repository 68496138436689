import { ErrorMessage, Field, FieldProps, useField } from 'formik';
import {
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import { inputsFields, loginLabels, useStyles } from './RenderInputFieldStyle';

import errorIcon from '../../../assets/images/error-outline-24-px.svg';

interface RenderInputFieldProps {
  name: string;
  label?: string;
  disabled?: boolean;
  hidden?: boolean;
  disableUnderline?: boolean;
  inputClassName?: string;
  inputStyle?: any;
  type?: string;
  containerClass?: string;
  noArrowNumberInput?: boolean;
  max?: number;
  min?: number;
  step?: number;
  placeholder?: string;
  showError?: boolean;
  currencyField?: boolean;
  initialValue?: string | number;
  validate?: (value: any) => void;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  shrink?: boolean;
  fullWidth?: boolean;
}

const RenderInput: FC<RenderInputFieldProps> = ({
  name,
  type,
  disabled,
  label,
  hidden,
  disableUnderline,
  containerClass,
  noArrowNumberInput,
  max,
  min,
  step,
  placeholder,
  showError,
  currencyField,
  initialValue,
  validate,
  onChange,
  shrink,
  fullWidth,
}) => {
  const { input, input2, inputNoArrow, labels, errorMessageStyles, labelError } = useStyles();
  const inputClass = () =>
    !noArrowNumberInput
      ? name === 'fees.Tribunal Hearing'
        ? input2
        : input
      : `${input} ${inputNoArrow}`;
  const [field, meta, helpers] = useField(name);
  const showerror = !!meta.error && (meta.touched || showError);

  useEffect(() => {
    if (!field.value && initialValue) helpers.setValue(initialValue);
  }, []);

  return (
    <div style={fullWidth ? { width: '100%' } : undefined} className={`${containerClass || ''}`}>
      <FormControl fullWidth error={showerror}>
        {label && (
          <InputLabel
            style={loginLabels}
            classes={{ shrink: labels }}
            htmlFor={name}
            {...(shrink && { shrink })}
          >
            <Typography variant="subtitle1" className={showerror ? labelError : ''}>
              {label}
            </Typography>
          </InputLabel>
        )}
        {!hidden && (
          <Field name={name} validate={validate}>
            {() => {
              const { value } = field;
              return (
                <Input
                  {...field}
                  id={name}
                  type={type}
                  disabled={disabled}
                  classes={{ input: inputClass() }}
                  value={value === null || value === undefined ? '' : value}
                  style={inputsFields}
                  placeholder={placeholder}
                  multiline={name === 'fees.Tribunal Hearing'}
                  inputProps={{
                    onKeyPress: (e: React.KeyboardEvent) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                      }
                    },
                    min,
                    max,
                    step,
                  }}
                  disableUnderline={disableUnderline}
                  endAdornment={
                    <InputAdornment position="end">
                      <img src={showerror ? errorIcon : ''} alt="" />
                    </InputAdornment>
                  }
                  startAdornment={
                    currencyField && <InputAdornment position="start">$</InputAdornment>
                  }
                  {...(onChange && { onChange })}
                />
              );
            }}
          </Field>
        )}

        {!disabled && (
          <FormHelperText className={errorMessageStyles}>
            <ErrorMessage name={name} />
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

export default RenderInput;
