import { SIZES } from '../../lib/Constants';
import { color } from '../../lib/Theme/Theme';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  card: {
    margin: 'auto',
    marginTop: '20px',
    maxWidth: '1000px',
    borderRadius: '6px',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
    backgroundColor: color.light,
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: '15px',
    },
  },

  titleContainer: {
    padding: '30px',
    paddingTop: '15px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '15px',
      paddingBottom: '35px',
    },
  },

  contentContainer: {
    padding: '32px',
    paddingTop: '8px',
    width: '100%',
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '15px',
      paddingTop: '8px',
      paddingBottom: '35px',
    },
  },
  expansionPanelRoot: {
    'width': '100%',
    '&:before': {
      backgroundColor: 'white',
    },
  },
  expansionPanelSummary: {
    margin: '0 8',
    padding: '8px',
    paddingLeft: '32px',
    boxShadow: 'none',
  },

  buttonContainer: {
    margin: 'auto',
    marginTop: '20px',
    maxWidth: '1000px',
    borderRadius: '6px',
    marginBottom: '80px',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1em',
    alignItems: 'center',
  },
  errorContainer: {
    margin: 'auto',
    maxWidth: '1000px',
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  button: {
    width: '20%',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: color.sortedNavy,
    borderRadius: '6px',
    color: color.light,
    cursor: 'pointer',
    '&:hover': {
      color: color.primary,
    },
    '&:disabled': {
      color: color.light,
      backgroundColor: color.grey,
    },

    [`@media (max-width: ${SIZES.md}px)`]: {
      marginLeft: '0px',
      width: '100%',
    },
  },
});

export default useStyles;
