import { color } from '../../../lib/Theme/Theme';
import { makeStyles } from '@material-ui/core';
import { SIZES } from '../../../lib/Constants';

export const useStyles = makeStyles({
  scrollWell: {
    maxHeight: '60vh',
    overflowY: 'scroll',
  },

  contentContainer: {
    padding: '32px',

    width: '100%',
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '15px',
      paddingTop: '8px',
      paddingBottom: '35px',
    },
  },
});
