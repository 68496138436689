import { color } from '../../../lib/Theme/Theme';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  errorMessageStyles: {
    color: color.warning,
    marginTop: 10,
  },
  labelError: {
    color: color.warning,
  },
  label: {
    fontSize: '16px',
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
    paddingTop: '20px',
  },
  errorLine: {
    borderBottomColor: color.warning,
    '&:hover': {
      borderBottomColor: color.warning,
    },
  },
});
export default useStyles;
