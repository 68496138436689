/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable class-methods-use-this */
import * as axios from 'axios';

import { AppSettings } from '../../../AppSettings';
import AxiosInstance from './AxiosInstance';
import { HttpContentType } from './HttpContentType';
import { QueryPath } from './QueryPath.data';
import ServiceType from './ServiceType.data';
import { store } from '../Store';
import { storage, STORAGE_CONSTANTS } from './LocalStorage';
import { MultipartFileData, MultipartFormData } from './MultipartFormData.data';
import { showToast } from '../../helper/HelperFunctions';
import ToastType from '../Types/toastTypes/ToastTypesData.data';
import { TypeHelper } from '../../helper/TypeHelper';
import { ContentTypeHelper } from '../../helper/Content/ContentType.data';

/// <summary>
/// ApiServiceMock cannot inherit ApiService, because that's mocked and that would create an infinite loop, that's why we need ApiServiceBase.
/// </summary>
export default abstract class ApiServiceBase {
  protected readonly serviceType: ServiceType;
  protected isFetchingToken = false;
  protected tokenSubscribers: any = [];

  constructor(serviceType: ServiceType) {
    this.serviceType = serviceType;
  }

  public abstract get<T = void>(path: QueryPath): Promise<T> | T;

  public abstract post<T = void>(path: QueryPath, body: any): Promise<T> | T;

  public abstract put<T = void>(path: QueryPath, body: any): Promise<T> | T;

  public abstract patch<T = void>(path: QueryPath, body: any): Promise<T> | T;

  public abstract delete<T = void>(path: QueryPath): Promise<T>;

  public abstract postMultipart<T = void>(
    path: QueryPath,
    data: MultipartFileData | MultipartFileData[],
  ): Promise<T> | T;

  // public abstract patchMultipart<T = void>(
  //   path: QueryPath,
  //   data: MultipartFormData[],
  // ): Promise<T> | T;

  public abstract postMultipartData<T = void>(path: QueryPath, data: any): Promise<T> | T;

  /* tslint:disable:cyclomatic-complexity */
  public processError(error: any) {
    const errorCode = error.response ? error.response.status || 500 : 500;

    switch (errorCode) {
      case 404:
        return new Error('The request is not found');
      case 500:
        showToast(store.dispatch, 'Sorry, something went wrong', ToastType.ERROR);
        return new Error('Internal server error');

      case 400:
      case 422: {
        if (error.response.data.errors) {
          const err = error.response.data.errors;

          if (err instanceof Array) {
            const errArr = err;

            if (errArr.length > 0 && errArr[0]) {
              if (errArr[0].message) {
                return new Error(errArr[0].message.toString());
              } else if (errArr[0].Message) {
                return new Error(errArr[0].Message.toString());
              } else {
                return new Error(errArr[0].toString());
              }
            }
          } else if (err.message) {
            return new Error(err.message.toString());
          } else if (err.Message) {
            return new Error(err.Message.toString());
          } else {
            return new Error(err.toString());
          }
        }

        return new Error('Internal server error');
      }
      default:
        return error;
    }
  }

  /* tslint:enable */
  protected getConfig(contentType: HttpContentType): axios.AxiosRequestConfig {
    const headers =
      this.serviceType.startsWith('api') ||
      this.serviceType.startsWith('bff/api') ||
      this.serviceType.startsWith('tenancy/api')
        ? {
            'Content-Type': contentType.toString(),
            Authorization: `Bearer ${storage.getItem(STORAGE_CONSTANTS.authToken)}`,
          }
        : {
            'Content-Type': contentType.toString(),
            Authorization: `Basic ${storage.getItem(STORAGE_CONSTANTS.authTokenBasic)}`,
          };

    return {
      headers,
    };
  }

  protected isAuthTokenRequired(path: string): boolean {
    return path.includes('/api');
  }

  protected getAxiosInstance(): axios.AxiosInstance {
    const instance = AxiosInstance.create();
    const { baseUrl } = AppSettings.server;

    const subscribeTokenRefresh = (callBack: any) => {
      this.tokenSubscribers.push(callBack);
    };

    const onTokenRefreshed = (error: Error | null) => {
      this.tokenSubscribers.map((cb: any) => cb(error));
    };

    const forceLogout = () => {
      this.isFetchingToken = false;
      // store.dispatch(SuccessActions.flushData());
      // storage.clearAll();
      storage.setItem(STORAGE_CONSTANTS.hideRegister, 'true');
      window.location.href = `/#/login`;
    };

    instance.interceptors.response.use(
      (response): any => {
        return response;
      },
      (error): any => {
        if (error.response.status !== 401) {
          return Promise.reject(error);
        }

        if (error.response.status === 401) {
          if (error.config.url.includes('login')) {
            return Promise.reject(error);
          } // login returns 401 if username or password is wrong
          if (!this.isFetchingToken && this.isAuthTokenRequired(error.config.url)) {
            this.isFetchingToken = true;
            const currentRefreshToken = storage.getItem(STORAGE_CONSTANTS.refreshToken);
            return instance
              .post(
                `${baseUrl}/${ServiceType.RefreshToken}`,
                {
                  refreshToken: currentRefreshToken,
                },
                {
                  headers: {
                    Authorization: `Basic ${storage.getItem('authTokenBasic')}`,
                  },
                },
              )
              .then((response) => {
                const { authToken, refreshToken } = response.data;
                this.isFetchingToken = false;
                storage.setItem(STORAGE_CONSTANTS.authToken, authToken);
                storage.setItem(STORAGE_CONSTANTS.refreshToken, refreshToken);
                error.config.headers.Authorization = `Bearer ${authToken}`;
                return instance(error.config);
              });
          } else if (error.config.url.includes('refreshToken') && error.response.status === 401) {
            onTokenRefreshed(new Error('Unable to refresh access token'));
            this.tokenSubscribers = [];
            forceLogout();
          } else {
            const initTokenSubscriber = new Promise((resolve, reject) => {
              subscribeTokenRefresh((errRefreshing: any) => {
                if (errRefreshing) {
                  return reject(errRefreshing);
                }
                error.config.headers.Authorization = `Bearer ${storage.getItem(
                  STORAGE_CONSTANTS.authToken,
                )}`;
                return resolve(instance(error.config));
              });
            });
            return initTokenSubscriber;
          }
        }
        return Promise.reject(error);
      },
    );
    return instance;
  }

  // Generates url: {AppSettings.service.baseUrl}/{this.serviceType}/{routeParam1}/{routeParam2}/.../{routeParamN}?{queryParam1key}={queryParam1val}&{queryParam2key}={queryParam2val}...
  // Query params with null, undefined or empty string won't be appended to the url.

  /* tslint:disable:cyclomatic-complexity */
  protected getUrl(path: QueryPath): string {
    const { baseUrl } = AppSettings.server;
    let url: string = this.serviceType ? `${baseUrl}/${this.serviceType}` : `${baseUrl}`;

    if (path) {
      if (path.route && path.route.length > 0) {
        for (const route of path.route) {
          if (route && route !== 'undefined') {
            url += `/${route}`;
          }
        }
      }

      if (path.query) {
        let separator = '?';

        for (const name in path.query) {
          if (path.query[name]) {
            url += `${separator}${encodeURI(name)}=${encodeURI(path.query[name]!.toString())}`;
            separator = '&';
          }
        }
      }
    }
    return url;
  }

  protected async prepareMultiPartForm(
    data: MultipartFileData | MultipartFileData[],
  ): Promise<any> {
    const promises = new Array<Promise<void>>();
    const files: MultipartFormData[] = [];
    if (Array.isArray(data)) {
      data.forEach((file) => promises.push(this.processFile(file, files)));
    } else {
      promises.push(this.processFile(data, files));
    }
    await Promise.all(promises);
    // const item = { name: 'file', content: files[0] };
    const formData = new FormData();
    for (const item of files) {
      if (typeof item.content === 'string') {
        // Json string
        formData.append(item.name, item.content);
      } else {
        // Blob
        formData.append(item.name, item.content!.data!, item.content!.contentRef.name);
      }
    }
    return formData;
  }

  // eslint-disable-next-line class-methods-use-this
  private async processFile(file: MultipartFileData, newFiles: MultipartFormData[]): Promise<void> {
    const ctype = ContentTypeHelper.parseContentType(file.file.type);
    newFiles.push({
      name: file.name,
      content: {
        contentRef: { contentType: ctype!, name: file.file.name },
        size: file.file.size,
        data: file.file,
        dataBase64: await ContentTypeHelper.convertBlobToBase64(file.file),
      },
    });
  }

  protected prepareMultiPartFormData(data: MultipartFormData[]): FormData {
    const formData = new FormData();

    for (const item of data) {
      if (typeof item.content === 'string') {
        // Json string
        formData.append(item.name, item.content);
      } else {
        const value = !TypeHelper.isNullOrUndefined(item.content)
          ? item.content!.data!
          : new File(['file'], 'file.txt', { type: 'text/plain' });
        const fileName = !TypeHelper.isNullOrUndefined(item.content)
          ? item.content!.contentRef.name
          : '';
        // Blob
        formData.append(item.name, value, fileName);
      }
    }
    return formData;
  }
}
