import { ApplicationState, useAppSelector } from '../../lib/Redux/Reducers';
import { BUTTON_LABELS, LABELS, VALIDATION_SCHEMA, getSteps } from './NewReferralConstant';
import { Button, Dialog, Step, StepLabel, Stepper, Typography } from '@material-ui/core';
import React, { FC, useState } from 'react';
import {
  ReferralDetailsFormPayload,
  ReferralPayload,
} from '../../lib/Redux/Types/referrals/ReferralType.data';

import AddReferralCard from './addReferral/AddReferralCard';
import AgencyBackground from '../../components/agencyBackground/AgencyBackground';
import DeclarationForm from '../../components/declarationForm/DeclarationForm';
import { Formik } from 'formik';
import ProgressBar from '../../components/progressBar/ProgressBar';
import { ReferralsActions } from '../../lib/Redux/Actions/ReferralsActions';
import RenderCheckbox from '../../components/FormikComponents/RenderCheckbox/RenderCheckbox';
import SubmitReferral from './submitReferral/SubmitReferral';
import groupIcon from '../../assets/images/outline-group-24-px-1.svg';
import minusIcon from '../../assets/images/minus-symbol.svg';
import plusIcon from '../../assets/images/plus-button.svg';
import { routes } from '../../navigation/Routes';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import useStyles from './NewReferralStyles.data';

interface NewReferralProps {
  referralDetails: ReferralDetailsFormPayload;
  error: Error | null;
}
const NewReferral: FC<NewReferralProps> = ({ referralDetails }) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [newReferrals, setnewReferrals] = useState<ReferralDetailsFormPayload>();
  const [showDeclaration, setShowDeclaration] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const steps = getSteps();

  const newReferral = {
    email: null,
    title: null,
    firstName: null,
    lastName: null,
    phoneNumber: '',
    additionalInformation: '',
  };
  const { referralData, agencyCode } = useAppSelector((state: ApplicationState) => ({
    referralData: state.referral.referralData,
    agencyCode: state.dashboard.agency,
  }));
  const dispatcher = {
    addReferrals: (data: ReferralDetailsFormPayload, onSuccess: () => void) => {
      dispatch(ReferralsActions.addReferral({ ...data, onSuccess }));
    },
    saveReferrals: (referralData: ReferralDetailsFormPayload, onSuccess: () => void) => {
      dispatch(ReferralsActions.saveReferrals({ ...referralData, onSuccess }));
    },
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const postRegisterDetails = (data: ReferralDetailsFormPayload) => {
    setnewReferrals({ ...data });
    dispatcher.addReferrals(data, () => {
      setnewReferrals({ ...data });
    });
    handleNext();
  };

  const onPopUpClose = () => {
    handleBack();
    setShowDeclaration(false);
  };
  const onDeclarationAccept = () => {
    if (referralData)
      dispatcher.saveReferrals(referralData, () => history.push(routes.dashboard.new));
  };
  const termsAndConditions = () => {
    setShowDeclaration(true);
    return false;
  };
  //  Delete Referral Card
  const deleteNewReferral = (
    values: ReferralDetailsFormPayload,
    idx: number,
    setFieldValue: (fieldName: string, fieldValue: string | ReferralPayload[] | boolean) => void,
  ) => {
    if (values.referrals.length > 1) {
      values.referrals.splice(idx, 1);
      setFieldValue('referrals', values.referrals);
    }
  };

  const addNewReferral = (
    values: ReferralDetailsFormPayload,
    setFieldValue: (fieldName: string, fieldValue: string | ReferralPayload[] | boolean) => void,
  ) => {
    return (
      <>
        <div className={`${classes.card} ${classes.titleContainer}`}>
          <div className={classes.contentContainer}>
            <Typography className={classes.title} variant="h5">
              {LABELS.NEWREFERRAL_TITLE}
            </Typography>
            <Typography className={classes.subtitle} variant="subtitle1">
              {LABELS.NEWREFERRAL_SUBTITLE}
            </Typography>

            <div className={classes.howManyReferralContainer}>
              <div className={classes.counterContainer}>
                <img src={groupIcon} alt="group icon" className={classes.groupIconStyle} />
                <Typography variant="body2">{LABELS.HOW_MANY_REFERRAL}</Typography>
              </div>
              <div className={classes.counterWrapper}>
                <div>
                  <img
                    src={minusIcon}
                    alt="minus icon"
                    onClick={() =>
                      deleteNewReferral(values, values.referrals.length - 1, setFieldValue)
                    }
                    className={classes.numberChangeStyle}
                  />
                </div>

                <Typography variant="body2" className={classes.numberStyle}>
                  <div className={classes.counter}>{values.referrals.length}</div>
                </Typography>
                <div>
                  <img
                    src={plusIcon}
                    alt="plus icon"
                    onClick={() => {
                      const result = values.referrals;
                      result.push({ ...newReferral });
                      setFieldValue('referrals', result);
                    }}
                    className={classes.numberChangeStyle}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.card}>
          <div className={classes.contentContainer}>
            {/* add referral Card */}
            {values.referrals.map((referral: ReferralPayload, idx: number) => (
              <AddReferralCard
                values={values}
                setFieldValue={setFieldValue}
                deleteNewReferral={() => deleteNewReferral(values, idx, setFieldValue)}
                idx={idx}
              />
            ))}
          </div>
        </div>
        <div className={classes.card}>
          <div className={classes.agreementContainer}>
            <RenderCheckbox name="chkAgreement" label={LABELS.CHK_AGRREMENTLABEL} color="primary" />
          </div>
        </div>
      </>
    );
  };

  const initialValues: ReferralDetailsFormPayload =
    referralDetails && referralDetails.referrals.length
      ? referralDetails
      : {
          referrals: [newReferral],
          chkAgreement: false,
          agencyCode,
        };
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(data) => postRegisterDetails(data)}
        validationSchema={VALIDATION_SCHEMA}
        enableReinitialize
        render={({ values, handleSubmit, setFieldValue, errors }) => (
          <div className={classes.root}>
            <AgencyBackground />
            <div className={`${classes.progressBarContainer}`}>
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                className={classes.progressBarWrapper}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            <div>
              {activeStep === steps.length ? (
                <div>{termsAndConditions()}</div>
              ) : (
                <div>
                  <div>
                    {activeStep === 0 ? (
                      addNewReferral(values, setFieldValue)
                    ) : activeStep === 1 ? (
                      <SubmitReferral newReferrals={newReferrals} />
                    ) : (
                      'Unknown stepIndex'
                    )}
                  </div>
                </div>
              )}
            </div>
            <div>
              <div className={classes.buttonContainer}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  variant="contained"
                  className={classes.backButton}
                >
                  {BUTTON_LABELS.BACK}
                </Button>
                <Button color="primary" className={classes.button} onClick={() => handleSubmit()}>
                  {BUTTON_LABELS.NEXT}
                </Button>
              </div>
            </div>
          </div>
        )}
      />

      <Dialog open={showDeclaration} maxWidth="md">
        <DeclarationForm
          onDeclarationAccept={onDeclarationAccept}
          setShowDeclaration={() => onPopUpClose()}
        />
      </Dialog>
    </>
  );
};
export default NewReferral;
