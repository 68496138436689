import { createStyles, WithStyles } from '@material-ui/core';
import { color } from '../../../lib/Theme/Theme';

export const styles = createStyles({
  scrollBox: {
    height: '600px',
    border: `${color.borderGray} 2px solid`,
    borderRadius: '6px',
    padding: 15,
    marginBottom: 15,
    overflow: 'scroll',
    '&:hover': {
      border: `${color.teal} 2px solid`,
    },
  },
  numberStyle: { color: color.secondary500 },
  paragraphs: {
    padding: '10px',
  },
});

export interface ScrollBoxStyles extends WithStyles<typeof styles> {}
