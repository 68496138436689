import { Box, Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { FieldArray, useField } from 'formik';
import React, { useEffect } from 'react';
import { get } from 'lodash';
import { BlanketFormFieldOption } from '../../blanketForm/BlanketFormConstants';

import { useStyles } from './RenderCheckboxStyles';

interface RenderMarketingFeeItemsProps {
  name: string;
  label: string;
  options: BlanketFormFieldOption[];
  readOnly?: boolean;
}

const RenderMarketingFeeItems = ({
  name,
  label,
  options,
  readOnly,
}: RenderMarketingFeeItemsProps) => {
  const [field, meta, helper] = useField(name);

  useEffect(() => {
    if (!readOnly) {
      const checkedOptions = options.filter((option) => option.checked);
      helper.setValue(field.value ? [...field.value, ...checkedOptions] : [...checkedOptions]);
    }
  }, []);

  const getDefaultValue = (title: string) => {
    return (
      field.value &&
      field.value.length > 0 &&
      !!field.value.find((item: any) => item.title === title)
    );
  };

  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Typography variant="body2" className={classes.subSectionHeader}>
        {label}
      </Typography>
      <Grid container spacing={2} className={classes.marketingFeeContainer}>
        <FieldArray
          name={name}
          render={({ push, remove, form: { values } }) => (
            <>
              {options.map((option) => {
                return (
                  <Grid item xs={12} md={6}>
                    <Box display="flex" justifyContent="space-between">
                      <FormControlLabel
                        labelPlacement="end"
                        className={classes.width100}
                        classes={{
                          label: classes.width100,
                        }}
                        control={
                          <Checkbox
                            color="primary"
                            defaultChecked={getDefaultValue(option.title!)}
                            disabled={option.disabled || readOnly}
                            onChange={(e) => {
                              if (e.target.checked) {
                                push(option);
                              } else {
                                const idx = get(values, name)
                                  .map((item: any) => item.title)
                                  .indexOf(option.title);
                                remove(idx);
                              }
                            }}
                            id={`${name}${option.title}`}
                          />
                        }
                        label={
                          <Box display="flex" justifyContent="space-between" width="100%">
                            <Box display="flex" flexDirection="column">
                              <Typography>{option.title || ''}</Typography>
                              <Typography className={classes.fontWeight100}>
                                <div dangerouslySetInnerHTML={{ __html: option.subtitle || '' }} />
                              </Typography>
                            </Box>
                            <Typography className={classes.checkboxOption}>
                              {option.amount || ''}
                            </Typography>
                          </Box>
                        }
                      />
                    </Box>
                  </Grid>
                );
              })}
            </>
          )}
        />
      </Grid>
    </Box>
  );
};

export default RenderMarketingFeeItems;
