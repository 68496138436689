import React, { FC, useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams, RouteComponentProps } from 'react-router';
import AgencyBackground from '../../components/agencyBackground/AgencyBackground';
import { ApplicationState, useAppSelector } from '../../lib/Redux/Reducers';
import { LoginActions } from '../../lib/Redux/Actions/LoginActions';
import {
  LoginPayload,
  RegisterClientResponse,
  RegisterUserPayload,
} from '../../lib/Redux/Services/login/LoginData';
import { storage, STORAGE_CONSTANTS } from '../../lib/Redux/Services/LocalStorage';
import { clearStorage, checkPreviousURL, registerClient, loginClient, TYPE } from './LoginUtils';
import { LoginStyleProps, styles } from './LoginStyles';
import Welcome from './components/welcome/Welcome';
import LoginForm from './components/loginForm/LoginForm';
import SignupForm from './components/signupForm/SignupForm';
import VerifyEmail from './components/verifyEmail/VerifyEmail';
import ForgotPasswordForm from './components/forgotPasswordForm/ForgotPasswordForm';
import { MatchParams } from './components/welcome/WelcomeUtils';
import { routes } from '../../navigation/Routes';

interface LoginProps extends LoginStyleProps, RouteComponentProps<MatchParams> {
  registerClientDetails: RegisterClientResponse | undefined;
  postLogin: (data: LoginPayload) => void;
}

export enum View {
  Login,
  Signup,
  Verify,
  ForgotPassword,
}

const Login: FC<LoginProps> = ({ classes, match }) => {
  const [view, setView] = useState<View>(View.Login);
  const [userDetails, setUserDetails] = useState<RegisterUserPayload | null>(null);
  const { loginDetails, registerClientDetails } = useAppSelector((state: ApplicationState) => ({
    loginDetails: state.login.loginDetails,
    registerClientDetails: state.login.registerClientDetails,
  }));
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // variables and functions
  const dispatcher = {
    postRegisterClient: () => {
      dispatch(LoginActions.postRegisterClientStart());
    },
  };
  useEffect(() => {
    clearStorage();
    // TODO: move this out to an higher up auth handler
    const clientId = storage.getItem(STORAGE_CONSTANTS.clientId);
    if (!clientId || clientId === 'undefined') {
      dispatcher.postRegisterClient();
    } else {
      const clientSecret = storage.getItem(STORAGE_CONSTANTS.clientSecret);
      const basicAuthHash = window.btoa(`${clientId}:${clientSecret}`);
      storage.setItem(STORAGE_CONSTANTS.authTokenBasic, basicAuthHash);
    }
    if (clientId) {
      const clientSecret = storage.getItem(STORAGE_CONSTANTS.clientSecret);
      const basicAuthHash = window.btoa(`${clientId}:${clientSecret}`);
      storage.setItem(STORAGE_CONSTANTS.authTokenBasic, basicAuthHash);
    }

    checkPreviousURL();
  }, []);

  useEffect(() => {
    registerClient(registerClientDetails);
    const setPreviousPath = history.location.pathname || null;
    const setPreviousSearch = history.location.search || null;
    const loginType = storage.getItem(STORAGE_CONSTANTS.type);
    if (setPreviousPath) {
      history.location.state = setPreviousPath;
    }
    if (setPreviousSearch) {
      history.location.state = `${history.location.state}${setPreviousSearch}`;
    }
    if (loginDetails && loginDetails.authToken) {
      if (loginType === TYPE.OWNER) {
        history.push({
          pathname: routes.dashboard.new,
          state: { detail: 'some_value' },
        });
      } else {
        history.push(routes.addReferral.new());
      }
    }

    loginClient(loginDetails);
  }, [registerClientDetails, loginDetails]);

  const renderView = () => {
    switch (view) {
      case View.Login:
        return (
          <LoginForm
            history={history}
            userDetails={userDetails}
            setView={(aView: View) => setView(aView)}
          />
        );
      case View.Signup:
        return (
          <SignupForm
            userDetails={userDetails}
            setUserDetails={(details: RegisterUserPayload) => setUserDetails(details)}
            setView={(aView: View) => setView(aView)}
          />
        );
      case View.Verify:
        return <VerifyEmail userDetails={userDetails} setView={(aView: View) => setView(aView)} />;
      case View.ForgotPassword:
        return (
          <ForgotPasswordForm userDetails={userDetails} setView={(aView: View) => setView(aView)} />
        );
      default:
        return (
          <LoginForm
            history={history}
            userDetails={userDetails}
            setView={(aView: View) => setView(aView)}
          />
        );
    }
  };

  return (
    <div className={classes.root}>
      <AgencyBackground />
      <div className={classes.contentContainer}>
        <Welcome match={match} view={view} />
        {renderView()}
      </div>
    </div>
  );
};

export default withStyles(styles)(Login);
