/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-shadow */
import React, { FC, useEffect, useState } from 'react';
import { withStyles, Paper, Typography } from '@material-ui/core';
import { match, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { ApplicationState, useAppSelector } from '../../../../lib/Redux/Reducers';
import { LABELS } from './WelcomeConstants';
import { WelcomeStyleProps, styles } from './WelcomeStyles';
import { getAllQueryParams, getQueryParams, MatchParams } from './WelcomeUtils';
import { DashboardActions } from '../../../../lib/Redux/Actions/DashboardActions';
import { StoreAgencyPayload } from '../../../../lib/Redux/Types/agency/AgencyRequest.data';
import { CustomTermsHeaderActions } from '../../../../lib/Redux/Actions/CustomTermsHeaderActions';
import { CustomTermsHeaderPayload } from '../../../../lib/Redux/Types/customTermsHeader/CustomTermsHeaderRequest.data';
import { TYPE } from '../../LoginUtils';
import { storage, STORAGE_CONSTANTS } from '../../../../lib/Redux/Services/LocalStorage';
import { View } from '../../Login';
import PoweredBySortedLogo from '../../../../assets/images/Powered-by-Sorted.svg';
import { routes } from '../../../../navigation/Routes';

interface WelcomeProps extends WelcomeStyleProps {
  match: match<MatchParams>;
  view: View;
}

const Welcome: FC<WelcomeProps> = ({ classes, match, view }) => {
  const [URLError, setURLError] = useState<boolean>(false);
  const [URLRent, setURLRent] = useState<number | null>(null);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { error, agencyDetails, agencyCode } = useAppSelector((state: ApplicationState) => ({
    error: state.dashboard.error,
    propertyDetails: state.dashboard.propertyDetails,
    agencyDetails: state.dashboard.agencyDetails,
    agencyCode: state.dashboard.agency,
  }));
  const dispatcher = {
    getPropertyDetails: (refId: string) => {
      dispatch(DashboardActions.doGetPropertyDetails(refId));
    },
    getTermsHeader: (data: CustomTermsHeaderPayload) => {
      dispatch(CustomTermsHeaderActions.postCustomTermsHeaderStart(data));
    },
    getAgencyByAgent: (agentId: string, type: string) => {
      dispatch(DashboardActions.getAgencyByAgent({ agentId, type }));
    },
    storePropertyAgency: (data: StoreAgencyPayload) => {
      dispatch(
        DashboardActions.storePropertyAgency({
          agency: data.agency,
          branch: data.branch || '',
        }),
      );
    },
  };
  useEffect(() => {
    const { agency, refId, type, agentId } = getAllQueryParams(location, match.params);
    // If agency code is not aavailable
    if (!agency) history.push(routes.error.new);
    else {
      dispatcher.storePropertyAgency({
        agency,
        branch: match.params.branchCode,
      });
    }
    if (getQueryParams(location, 'rent')) {
      setURLRent(parseInt(getQueryParams(location, 'rent'), 10));
    }
    if (type === TYPE.OWNER) {
      storage.setItem(STORAGE_CONSTANTS.type, TYPE.OWNER);
    }

    // This gets property details depending on URL
    if (refId && type === 't') {
      dispatcher.getPropertyDetails(refId);
    } else {
      setURLError(true);
    }
    // This gets agency details
    if (agentId) {
      dispatcher.getAgencyByAgent(agentId, type);
    }

    // Stores the agency code in redux and fetches terms for declaration form

    if (agencyCode && match.params.branchCode) {
      dispatcher.storePropertyAgency({
        agency,
        branch: match.params.branchCode,
      });
      dispatcher.getTermsHeader({
        agency: agency.toUpperCase(),
        branch: match.params.branchCode,
      });
    }
  }, []);
  useEffect(() => {
    if (agencyDetails && agencyDetails.code) {
      dispatcher.getTermsHeader({ agency: agencyDetails.code.toUpperCase() });
      dispatcher.storePropertyAgency({
        agency: agencyDetails.code.toUpperCase(),
      });
    }
  }, [agencyDetails]);

  const secondaryWelcomeText = () => {
    switch (view) {
      case View.ForgotPassword:
        return LABELS.FORGOT_VIEW;
      case View.Verify:
        return LABELS.VERIFY_VIEW;
      default:
        return LABELS.SIGN_IN_TEXT;
    }
  };

  return (
    <Paper className={classes.paperBack}>
      <div className={classes.contentContainer}>
        <Typography variant="h4" className={classes.welcomeTitle}>
          {LABELS.WELCOME}
        </Typography>
        <Typography className={classes.welcomeSubtitle}>{secondaryWelcomeText()}</Typography>
        <img
          src={PoweredBySortedLogo}
          className={classes.poweredBySorted}
          alt="Powered by sorted"
        />
      </div>
    </Paper>
  );
};

export default withStyles(styles)(Welcome);
