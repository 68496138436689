/* eslint-disable max-lines */
import { AnyAction, Dispatch } from 'redux';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router';
import { Alert, Skeleton } from '@material-ui/lab';
import { connect } from 'react-redux';
import {
  GetOwnershipDetailsRequest,
  GetOwnershipPropertiesRequest,
  Owner,
  OwnershipDetailsRequest,
  PutOwnershipDetailsRequest,
  ReferralProperty,
  SignOwnershipRequest,
  UpdateOwnerRequest,
  UpdateOwnershipPropertiesRequest,
} from '../../lib/Redux/Types/ownershipForm/OwnershipFormResponse.data';
import {
  SIGNING_STEPS,
  getNextStep,
  getSteps,
  getBackStep,
  LABELS,
} from './OwnershipFormConstants';

import AgencyBackground from '../../components/agencyBackground/AgencyBackground';
import { AppIconType } from '../../components/AppIcon/AppIcon';
import { ApplicationState } from '../../lib/Redux/Reducers';
import AuthorityForm from '../../components/ownership/AuthorityForm';
import FormLoader from '../../components/ownership/FormLoader';
import { OwnershipActions } from '../../lib/Redux/Actions/OwnershipActions';
import OwnershipForm from '../../components/ownership/OwnershipForm';
import PropertiesForm from '../../components/ownership/PropertiesForm';
import SignatureForm from '../../components/ownership/SignatureForm';
import TnCPage from '../../components/ownership/TnCPage';
import UploadingScreen from '../../components/UploadingScreen/UploadingScreen';
import { convertbase64ToPngContent } from '../../lib/helper/Content/ContentConverter';
import useStyles from './OwnershipStyles';

interface OwnershipProps
  extends RouteComponentProps<{ token: string }>,
    ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const OwnershipPage: FC<OwnershipProps> = ({
  match,
  getOwnershipDetails,
  getOwnershipProperties,
  ownershipDetails,
  updateOwnershipDetails,
  property,
  updateOwnershipProperties,
  error,
  signOwnership,
  updateOwner,
  userType,
  ownerId,
  forms,
  canUpdateOwnership,
  agency,
  loading,
}) => {
  const classes = useStyles();
  const { token } = match.params;
  const previewMode = match.url.split('/')[3] === 'preview';
  const prepareMode = match.url.split('/')[3] === 'prepare' || previewMode;

  const [activeStep, setActiveStep] = useState(SIGNING_STEPS.LOADING);
  const [maxVisitedStep, setMaxVisitedStep] = useState(SIGNING_STEPS.OWNERSHIP_FORM);
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState('');

  useEffect(() => {
    if (activeStep !== SIGNING_STEPS.LOADING && activeStep > maxVisitedStep) {
      setMaxVisitedStep(activeStep);
    }
  }, [activeStep]);

  useEffect(() => {
    if (userType) {
      setActiveStep(getNextStep(userType));
    }
  }, [userType]);

  useEffect(() => {
    if (token) {
      getOwnershipDetails({
        token,
        prepareMode,
      });
      getOwnershipProperties({
        token,
        prepareMode,
      });
    }
  }, [token]);

  const onNext = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (userType) {
      setActiveStep(getNextStep(userType, activeStep));
    }
  };

  const onBack = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (userType) {
      setActiveStep(getBackStep(userType, activeStep));
    }
  };

  const isSuccessStep = activeStep === SIGNING_STEPS.SUCCESS;
  const signedAlready = error?.message === 'You Have Signed Already';

  const renderContent = () => {
    switch (activeStep) {
      case SIGNING_STEPS.OWNERSHIP_FORM:
        if (!ownershipDetails || !forms) return null;
        return (
          <OwnershipForm
            formUrl={forms.ownershipForm}
            onSubmit={(ownershipDetails: OwnershipDetailsRequest) => {
              updateOwnershipDetails({
                token,
                prepareMode,
                ownershipDetails,
                onSuccess: onNext,
                onError: (message: string) => {
                  setModalContent(message);
                  setOpenModal(true);
                },
              });
            }}
            onSubmitSecondaryOwner={(owner: Owner) => {
              updateOwner({
                token,
                prepareMode,
                details: owner,
                onSuccess: onNext,
              });
            }}
            ownershipDetails={ownershipDetails}
            userType={userType}
            ownerId={ownerId}
            canUpdateOwnership={canUpdateOwnership}
            previewMode={previewMode}
            onNext={onNext}
          />
        );

      case SIGNING_STEPS.AUTHORITY_FORM:
        if (!ownershipDetails || !forms) return null;
        return (
          <AuthorityForm
            formUrl={forms.authorityForm}
            onSubmit={(ownershipDetails: OwnershipDetailsRequest) => {
              updateOwnershipDetails({
                token,
                prepareMode,
                ownershipDetails,
                onSuccess: onNext,
              });
            }}
            onNext={onNext}
            ownershipDetails={ownershipDetails}
            userType={userType}
            onBack={onBack}
            previewMode={previewMode}
          />
        );

      case SIGNING_STEPS.REFERRAL_PROPERTY_FORM:
        if (!forms) return null;
        return (
          <PropertiesForm
            formUrl={forms.propertyForm}
            property={property}
            ownershipDetails={ownershipDetails!}
            userType={userType}
            onSubmit={(property: ReferralProperty) => {
              updateOwnershipProperties({
                token,
                prepareMode,
                property,
                onSuccess: onNext,
              });
            }}
            onNext={onNext}
            onBack={onBack}
            previewMode={previewMode}
          />
        );

      case SIGNING_STEPS.TNC:
        if (!forms) return null;
        return (
          <TnCPage
            tnc={forms.tnc}
            ownershipDetails={ownershipDetails}
            onNext={onNext}
            onBack={onBack}
          />
        );

      case SIGNING_STEPS.SIGNATURE:
        return (
          <SignatureForm
            onSubmit={(signatureBase64: string, initialsBase64: string) => {
              const signatures = [
                convertbase64ToPngContent(signatureBase64),
                convertbase64ToPngContent(initialsBase64),
              ];
              signOwnership({
                token,
                signatures,
                onSuccess: onNext,
              });
            }}
            onBack={onBack}
          />
        );

      case SIGNING_STEPS.SUCCESS:
        return (
          <UploadingScreen
            title="All done!"
            primaryText={
              prepareMode
                ? 'You can safely close your browser.'
                : `Thank you for signing with ${agency!.tradingName}`
            }
            secondaryText={
              prepareMode
                ? 'Head over to the Tenancy Portal in order to send a remote digital signing link to the primary rental provider.'
                : "You can safely close your browser. Once everyone has signed, you'll receive a copy of your documents."
            }
            icon={AppIconType.TickInCirle}
            iconType="svg"
          />
        );

      default:
        return null;
    }
  };

  if (!token || !userType) return null;

  const steps = error ? undefined : getSteps(userType);

  const isLoading = loading || !forms || !ownershipDetails || !property;

  const isStepperLoading = !forms;

  return (
    <>
      {error ? (
        <UploadingScreen
          icon={AppIconType.CrossInCircle}
          iconType="svg"
          title={signedAlready ? 'Already signed!' : 'Signing link expired!'}
          primaryText={
            signedAlready
              ? 'You have already signed using this referral signing link'
              : 'The referral signing link has expired. Please contact your property manager for support.'
          }
        />
      ) : (
        <div className={!isSuccessStep ? classes.root : ''}>
          {!isSuccessStep && (
            <>
              <AgencyBackground />
              <div className={`${classes.progressBarContainer}`}>
                {isStepperLoading ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    style={{ width: '100%', background: 'white', height: '100%', padding: '2rem' }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Skeleton variant="rect" height={50} />
                      </Grid>
                    </Grid>
                  </Box>
                ) : (
                  <Stepper
                    alternativeLabel
                    activeStep={activeStep}
                    className={classes.progressBarWrapper}
                  >
                    {steps!.map((step) => (
                      <Step
                        onClick={
                          userType !== 'Agent'
                            ? () => {
                                if (step.key <= maxVisitedStep) {
                                  setActiveStep(step.key);
                                }
                              }
                            : undefined
                        }
                        key={step.key}
                      >
                        <StepLabel>{step.label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                )}
              </div>
            </>
          )}
          {!isSuccessStep && !isLoading && ownershipDetails && ownershipDetails.propertyAddress && (
            <Box className={classes.propertyBanner}>
              <Typography className={classes.propertyBannerText}>
                {LABELS.PROPERTY_BANNER_TEXT}
              </Typography>
              <Typography className={classes.propertyBannerAddress}>
                <strong>{ownershipDetails.propertyAddress}</strong>
              </Typography>
            </Box>
          )}
          {isLoading ? <FormLoader /> : renderContent()}
          <Dialog open={openModal} onClose={() => setOpenModal(false)}>
            <DialogTitle>Alert - Duplicate email not allowed</DialogTitle>
            <DialogContent>
              <Typography variant="body1" color="inherit">
                <Alert severity="error">{modalContent}</Alert>
              </Typography>
              <br />
              <Typography variant="body1" color="inherit">
                {LABELS.SAME_EMAIL_ERROR}
              </Typography>
              <br />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenModal(false)} className={classes.buttonStyle}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  getOwnershipDetails: (data: GetOwnershipDetailsRequest) => {
    dispatch(OwnershipActions.getOwnershipDetails(data));
  },
  updateOwnershipDetails: (data: PutOwnershipDetailsRequest) => {
    dispatch(OwnershipActions.updateOwnershipDetails(data));
  },
  getOwnershipProperties: (data: GetOwnershipPropertiesRequest) => {
    dispatch(OwnershipActions.getOwnershipProperties(data));
  },
  updateOwnershipProperties: (data: UpdateOwnershipPropertiesRequest) => {
    dispatch(OwnershipActions.updateOwnershipProperties(data));
  },
  signOwnership: (data: SignOwnershipRequest) => {
    dispatch(OwnershipActions.signOwnership(data));
  },
  updateOwner: (data: UpdateOwnerRequest) => {
    dispatch(OwnershipActions.updateOwnerDetails(data));
  },
});

const mapStateToProps = (state: ApplicationState) => ({
  ownershipDetails: state.ownership.ownershipDetails,
  userType: state.ownership.userType,
  ownerId: state.ownership.ownerId,
  property: state.ownership.property,
  error: state.ownership.error,
  forms: state.ownership.forms,
  canUpdateOwnership: state.ownership.canUpdateOwnership,
  agency: state.ownership.ownershipAgency,
  loading: state.ownership.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OwnershipPage));
