import { createStyles, WithStyles } from '@material-ui/core';
import { color } from '../../../../lib/Theme/Theme';
import { SIZES } from '../../../../lib/Constants';

export const styles = createStyles({
  myAccountContentContainer: {
    margin: 'auto',
    marginTop: '-15px',
    maxWidth: '1000px',
    borderRadius: '6px',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
  },
  optionMenuIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: 6,
    top: 10,
    color: 'grey',
  },
  red: {
    color: color.warning,
  },
  referralTitleContainer: {
    padding: '30px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '15px',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  referralTitle: {
    fontSize: '38px',
    fontWeight: 300,
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: '10px',
      fontSize: '30px',
    },
  },
  referralSubtitle: {
    marginTop: '10px',
    fontSize: '20px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      fontSize: '18px',
    },
  },
  buttonContainer: {
    marginLeft: '8px',
    padding: '2px 8px 2px 12px',
    borderRadius: 6,
    minWidth: '75px',
    width: 'max-content',
    border: `${color.gray} 2px solid`,
    background: color.light,
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },

  completeText: {
    background: '#baebf4',
    textAlign: 'center',
    color: color.dark,
    minWidth: 70,
    fontSize: 14,
    height: 25,
    marginTop: 20,
    borderRadius: '6px',
    opacity: 0.8,
  },

  container: {
    padding: '20px',
  },

  propertyListDraft: {
    display: 'flex',
    alignItems: 'center',
  },
  propertyList: {
    width: '100%',
  },
  propertyContainer: {
    paddingTop: '8px',
    borderBottom: `${color.borderGray} 1px solid`,
    paddingBottom: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  staticCard: {
    border: `${color.borderGray} 2px solid`,
    borderRadius: 8,
    padding: '15px',
    paddingTop: '0px',
    paddingBottom: '0px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
  },
  card: {
    marginTop: '15px',
    border: `${color.borderGray} 2px solid`,
    borderRadius: 8,
    padding: '15px',
    paddingTop: '0px',
    paddingBottom: '0px',
    overflow: 'hidden',
  },

  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0px 20px',
    position: 'relative',
  },
  applicationContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  applicationDetailContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export interface MyReferralPropsStyleProps extends WithStyles<typeof styles> {}
