class Storage {
  private readonly localstorage = window.localStorage;

  public setItem(key: string, item: string) {
    this.localstorage.setItem(key, item);
  }

  public getItem(key: string) {
    return this.localstorage.getItem(key);
  }

  public removeItem(key: string) {
    this.localstorage.removeItem(key);
  }

  public clearAll() {
    this.localstorage.clear();
  }
}
export const STORAGE_CONSTANTS = {
  type: 'type',
  authToken: 'authToken',
  authTokenBasic: 'authTokenBasic',
  refreshToken: 'refreshToken',
  title: 'title',
  firstname: 'firstname',
  lastname: 'lastname',
  email: 'email',
  userId: 'userId',
  draftId: 'draftId',
  draftTitle: 'draftTitle',
  state: 'state',
  stateCreatedDate: 'stateCreatedDate',
  previousLoginURL: 'previousLoginURL',
  clientId: 'clientId',
  clientSecret: 'clientSecret',
  hideRegister: 'hideRegister',
  config: 'config',
};

export const storage = new Storage();
