import ServiceType from '../ServiceType.data';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { API_ROUTE } from '../ApiRoute';
import { AgencyResponse } from '../../Types/agency/AgencyResponse.data';
import { GetAgencyByAgentPayload } from '../../Types/agency/AgencyRequest.data';

const serviceType = ServiceType.Referral;

export class DashboardService {
  private readonly service: ApiServiceBase = new ApiService(serviceType);

  public getAgencyByAgent(
    payload: GetAgencyByAgentPayload,
  ): Promise<AgencyResponse> | AgencyResponse {
    const { agentId, type } = payload;
    return this.service.get<AgencyResponse>({
      route: ['agent', agentId!.toUpperCase()],
      query: { type },
    });
  }
}

const dashboardService = new DashboardService();
export default dashboardService;
