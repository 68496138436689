/* eslint-disable react/sort-comp */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { InputAdornment, Input, withStyles } from '@material-ui/core';
import { GooglePlacesHelper } from '../../lib/helper/GooglePlaces/GooglePlacesHelper';
import AddressValue from '../../lib/helper/GooglePlaces/AddressValue.data';
import { AddressPickerState } from './AddressPickerState.data';
import { AppIcon, AppIconType, AppIconSize } from '../AppIcon/AppIcon';
import { AddressPickerStyles, addressPickerStyles } from './AddressPickerStyles.data';
import { inputsFields } from '../FormikComponents/RenderInputField/RenderInputFieldStyle';

export interface AddressPickerProps {
  readonly id: string;
  readonly value?: AddressValue | undefined | null;

  // optional
  readonly disabled?: boolean | undefined | null;
  readonly placeholder?: string | undefined | null;
  readonly fixSubPremise?: boolean | undefined | null; // default: true, if true, calls GooglePlacesHelper.fixAddressSubPremise()
  readonly handleAddressChanged?: ((value: AddressValue) => void) | undefined | null;
  readonly allowCustomAddress?: boolean;
}

interface AddressPickerReduxProps {
  readonly classes: AddressPickerStyles;
}

type OwnProps = AddressPickerProps & AddressPickerReduxProps;

export class AddressPickerComponent extends React.Component<OwnProps, AddressPickerState> {
  private addressInput: HTMLInputElement;

  public constructor(props: OwnProps) {
    super(props);

    this.state = this.initializeState(props);
  }

  public componentWillReceiveProps(nextProps: Readonly<OwnProps>) {
    if (
      !AddressValue.equals(nextProps.value, this.props.value) ||
      nextProps.disabled !== this.props.disabled
    ) {
      this.setState(this.initializeState(nextProps));
    }
  }

  public componentDidMount() {
    GooglePlacesHelper.createGooglePlacesAutoComplete(this.addressInput, (value) =>
      this.handleAddressChanged(value, true),
    );
  }

  public handleAddressChanged(value: AddressValue, updateAddress = false) {
    if (this.props.fixSubPremise !== false) {
      value = GooglePlacesHelper.fixAddressSubPremise(value);
    }

    this.setState(
      {
        value,
      },
      () => this.notifyChange(updateAddress),
    );
  }

  public render() {
    const { classes } = { ...this.props };
    const { value } = this.state;
    const updateOnEveryKeyPress = this.props.allowCustomAddress;
    return (
      <Input
        id={this.props.id}
        name={this.props.id}
        value={AddressValue.format(value)}
        onChange={(e: React.ChangeEvent) =>
          this.handleAddressChanged(
            { inputValue: (e.target as HTMLInputElement).value, place: null },
            updateOnEveryKeyPress,
          )
        }
        onKeyPress={(e: React.KeyboardEvent) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        disabled={!!this.props.disabled}
        readOnly={!!this.props.disabled}
        inputProps={{
          maxLength: 200,
        }}
        placeholder={this.props.placeholder || undefined}
        endAdornment={
          <InputAdornment position="end" className={classes.searchIcon}>
            <AppIcon type={AppIconType.Search} size={AppIconSize.Normal} />
          </InputAdornment>
        }
        inputRef={(elem: HTMLInputElement) => (this.addressInput = elem)}
        className={classes.root}
        fullWidth
        style={inputsFields}
      />
    );
  }

  private initializeState(props: OwnProps): AddressPickerState {
    // this.state can be null/undefined here
    return {
      value: props.value
        ? props.value
        : // this.state ? (this.state.value || new AddressValue()) : -- controlled, always takes props
          new AddressValue(),
    };
  }

  private notifyChange(updateAddress: boolean) {
    if (this.props.handleAddressChanged && updateAddress) {
      this.props.handleAddressChanged(this.state.value);
    }
  }
}

// component with styles
export const AddressPicker = withStyles(addressPickerStyles)(AddressPickerComponent);
