import LoginState from '../State/LoginState';
import { LoginAction, LoginActionTypes } from '../Actions/LoginActions';
import {
  LoginResponse,
  RegisterUserResponse,
  RefreshTokenResponse,
  RegisterClientResponse,
  GuestTokenResponse,
} from '../Services/login/LoginData';

export const initialState: LoginState = {
  error: '',
  loginError: undefined,
  registerError: undefined,
  forgotPasswordError: undefined,
  loading: false,
  loginDetails: undefined,
  registerUserDetails: undefined,
  registerClientDetails: undefined,
  refreshTokenDetails: undefined,
  userDetails: undefined,
  guestToken: undefined,
};

export const loginReducer: (state: LoginState, action: LoginAction) => LoginState = (
  state = initialState,
  action: LoginAction,
) => {
  switch (action.type) {
    case LoginActionTypes.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case LoginActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        loginDetails: action.data as LoginResponse,
      };
    case LoginActionTypes.LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        loginError: action.data,
      };

    case LoginActionTypes.REGISTER_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case LoginActionTypes.REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        registerUserDetails: action.data as RegisterUserResponse,
      };
    case LoginActionTypes.REGISTER_USER_ERROR:
      return {
        ...state,
        loading: false,
        registerError: action.data,
      };

    case LoginActionTypes.REGISTER_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case LoginActionTypes.REGISTER_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        registerClientDetails: action.data as RegisterClientResponse,
      };
    case LoginActionTypes.REGISTER_CLIENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case LoginActionTypes.REFRESH_TOKEN_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case LoginActionTypes.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        refreshTokenDetails: action.data as RefreshTokenResponse,
      };
    case LoginActionTypes.REFRESH_TOKEN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case LoginActionTypes.VERIFICATION_LINK_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case LoginActionTypes.VERIFICATION_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    case LoginActionTypes.VERIFICATION_LINK_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case LoginActionTypes.VERIFY_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case LoginActionTypes.VERIFY_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    case LoginActionTypes.VERIFY_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case LoginActionTypes.FIND_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case LoginActionTypes.FIND_USER_ERROR:
      return {
        ...state,
        loading: false,
        userDetails: undefined,
        error: action.data,
      };
    case LoginActionTypes.CLEAR_LOGIN_ERROR: {
      return {
        ...state,
        error: null,
        userDetails: null,
      };
    }
    case LoginActionTypes.FORGOT_PASSWORD_LINK_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case LoginActionTypes.FORGOT_PASSWORD_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    case LoginActionTypes.FORGOT_PASSWORD_LINK_ERROR:
      return {
        ...state,
        loading: false,
        forgotPasswordError: action.data,
      };
    case LoginActionTypes.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case LoginActionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    case LoginActionTypes.RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case LoginActionTypes.GUEST_TOKEN_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case LoginActionTypes.GUEST_TOKEN_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        guestToken: action.data as GuestTokenResponse,
      };
    }
    case LoginActionTypes.GUEST_TOKEN_ERROR: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case LoginActionTypes.CLEAR_ERRORS: {
      return {
        ...state,
        loading: false,
        error: null,
        loginError: null,
        registerError: null,
        forgotPasswordError: null,
      };
    }

    default:
      return state;
  }
};
